import { Toast } from 'helpers';
import React, { useEffect, useState } from 'react';
import { store, adminActions } from 'store';


const NewPermission = ({ dispatch, user }) => {

    const [new_permission, setNew_permission] = useState('');
    const [to_assign, setTo_assign] = useState([]);
    const [user_perms, setUserPerms] = useState([]);
    const [showSaveBtn, setShowSavebtn] = useState(false);

    const [load, setLoad] = useState(false);


    const handleSave = user_id => {
        setLoad(true);

        setTimeout(() => {
            dispatch(adminActions.assignpermission({ user_id: user_id, permissions: to_assign }));
            setTimeout(() => {
                dispatch(adminActions.user_permissions({ user_id: user_id }));
                setLoad(false);
            }, 3000);
        }, 2000);
    };

    const handleRemovePerms = (userId, permToRevok) => {


        if (window.confirm('Please confirm and remove all permissions')) {
            setTimeout(() => {
                dispatch(adminActions.removepermission({ userId, message: 'Permission removed by admin' }));
                setTimeout(() => {
                    dispatch(adminActions.user_permissions({ user_id: userId }));
                }, 3000);
            }, 2000);
        } else {
            Toast('warning', 'Confirmation avoided', 'tr', false, false, true, 3000, false);
        }
    };

    useEffect(() => {
        dispatch(adminActions.user_permissions({ user_id: user.id }));

        let tim = setInterval(() => {
            setUserPerms(localStorage.getItem('user_permissions') !== null ? JSON.parse(localStorage.getItem('user_permissions')) : []);
        }, 400);

        return () => {
            clearInterval(tim);
            localStorage.removeItem('user_permissions');
        };
    }, [user, dispatch])

    const handleRemoveToAssign = elm => {
        let ar = [];
        if (to_assign.includes(elm)) {
            ar = [...to_assign.filter(item => item !== elm)];
        }
        setTo_assign(ar);
        if (ar.length > 0) {
            setShowSavebtn(true);
        } else {
            setShowSavebtn(false);
        }
        // console.log(ar)
    };

    const handleAddToAssign = elm => {
        let ar = to_assign;
        if (elm !== '' && elm !== '-- --') {
            if (!to_assign.includes(elm)) {
                ar = [...to_assign, elm];
            } else {
                Toast('warning', 'Permission already selected', 'tr', false, false, false, 3000, false);
            }
            setTo_assign(ar);
            setShowSavebtn(true);
        } else {
            Toast('error', 'Please select the permission', 'tr', false, false, false, 3000, false);
        }
    };

    return <>
        <div className="row">

            {

                user_perms.length !== 0
                    ? <>
                        <span className="badge mr-1">The user has:</span>
                        {
                            user_perms.map((item, key) => <span key={key} className={`badge badge-${colorizerBadge(item.name)} mr-2`}>
                                {item.name}

                            </span>)
                        }
                        <i className="fa fa-trash text-danger ml-1" style={{ cursor: 'pointer' }} title='Remove all permissions' onClick={() => handleRemovePerms(user.id)}></i>
                    </>

                    : <span className="badge text-danger">No permission(s)</span>
            }
        </div><br />


        <div className="row">
            <div className="col-md-7">

                <form>
                    <div className="form-group">
                        <label>Choose permissions</label>
                        <select className="form-control bg-white" onChange={e => setNew_permission(e.target.value)}>
                            <option value={null}>-- --</option>
                            {
                                store.getState().admin.permissions.map((permission, key) => {
                                    return <option value={permission.name} key={key}>{permission.name}</option>
                                })
                            }
                        </select>
                    </div>

                    <div className="form-group">
                        <button className="btn btn-outline-success btn-sm" type='button' onClick={() => handleAddToAssign(new_permission)}>Add</button>
                    </div>

                </form>
            </div>
            <div className="col-md-5">
                <label>To assign</label>
                <ul>
                    {
                        to_assign.map((item, key) =>
                            <li key={key}>
                                <span className="badge badge-dark">
                                    {item} <i title='Remove' style={{ cursor: 'pointer' }} className="fa fa-times ml-2 text-danger" onClick={() => handleRemoveToAssign(item)}></i>
                                </span>
                            </li>
                        )
                    }
                </ul>
            </div>
            {
                showSaveBtn
                    ?
                    load
                        ? <button className="btn btn-success btn-lg btn-block">
                            <span className="spinner-border spinner-border" style={{ height: 20, width: 20 }} role="status" aria-hidden="true"></span> Save
                        </button>
                        : <button className="btn btn-success btn-block btn-lg" type='button' onClick={() => handleSave(user.id)}>Save</button>
                    : null
            }

        </div>
    </>
};

export default NewPermission;



const colorizerBadge = elem => {
    return elem === 'create'
        ? 'success'
        : elem === 'delete'
            ? 'danger'
            : elem === 'read'
                ? 'warning'
                : 'info'
};