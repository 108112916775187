import React, { useEffect, useState } from 'react';
import { AppendScript, RemoveAppendedScript } from '../helpers';
import { NavBar, TopBar } from '../partials';


const HeaderLayout = ({ script = [], setModal, logo = null }) => {

    const [here, setHere] = useState(window.location.pathname);

    useEffect(() => {
        script.forEach(element => {
            AppendScript(element);
        });
        setHere(window.location.pathname);
        return () => RemoveAppendedScript();
    }, [])

    return (
        <header id="header" className={`navbar navbar-expand-lg navbar-end ${here !== "/profile" ? "navbar-absolute-top" : ""} ${here === "/home" || here === "/" ? "navbar-dark" : "navbar-light"} navbar-show-hide`}
            data-hs-header-options='{
                "fixMoment": 1000,
                "fixEffect": "slide"
              }'>
            <TopBar setModal={setModal} />

            <NavBar logo={logo} />
        </header>
    );
}

export default HeaderLayout;
