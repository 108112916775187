import React from 'react';
import logo from '../../assets/img/logo/new/logo_r.png';
import mastercard from '../../assets/img/svg/mastercard.svg';
import visa from '../../assets/img/svg/visa.svg';
import { formatDate } from '../../helpers';
import appConfig from '../../config';

const InvoiceViewer = ({setModal, data}) => {
    return (
        <div className="modal fade" id="accountInvoiceReceiptModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-top-cover bg-primary text-center">
                        <figure className="position-absolute end-0 bottom-0 start-0" style={{ marginBottom: "-.125rem" }}>
                            <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                                <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                            </svg>
                        </figure>

                        <div className="modal-close">
                            <button type="button" className="btn-close btn-close-light" onClick={() => setModal(<></>)} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>

                    <div className="modal-top-cover-avatar">
                        <img className="avatar avatar-lg bg-light p-1 avatar-circle avatar-centered border border-3 border-white shadow-sm" src={logo} alt="Logo" />
                    </div>

                    <div className="modal-body">
                        <div className="text-center mb-5">
                            <h3 className="mb-1">Invoice from Happyness Sarl</h3>
                            {/* <span className="d-block">Invoice #3682303</span> */}
                        </div>

                        <div className="row mb-6">
                            <div className="col-md-4 mb-3 mb-md-0">
                                <small className="text-cap text-secondary mb-0">Amount paid:</small>
                                <span className="text-dark">{data.currency === 'eur' ? <span>{data.amount}<sup>€</sup></span> : <span><sup>$</sup>{data.amount}</span>}</span>
                            </div>

                            <div className="col-md-4 mb-3 mb-md-0">
                                <small className="text-cap text-secondary mb-0">Date paid:</small>
                                <span className="text-dark">{formatDate(data.created_at, false, 'fr')}</span>
                            </div>

                            <div className="col-md-4">
                                <small className="text-cap text-secondary mb-0">Payment method:</small>
                                <div className="d-flex align-items-center">
                                    <img className="avatar avatar-xs me-2" src={
                                        data.card_brand === "visa" ? visa : mastercard
                                    } alt="Description_Image" />
                                        <span className="text-dark">{data.card_last4.slice(9,19)}</span>
                                </div>
                            </div>
                        </div>

                        <small className="text-cap mb-2">Summary</small>

                        <ul className="list-group mb-4">
                            <li className="list-group-item text-dark">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span>Payment to <span className='fw-bold text-primary'>HAPPYNESS SARL</span></span>
                                    <span>{data.currency === 'eur' ? <span>{data.amount}<sup>€</sup></span> : <span><sup>$</sup>{data.amount}</span>}</span>
                                </div>
                            </li>
                            <li className="list-group-item text-dark">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span>Tax fee</span>
                                    <span><em className='text-secondary'>NaN</em></span>
                                </div>
                            </li>
                            <li className="list-group-item list-group-item-light text-dark">
                                <div className="d-flex justify-content-between align-items-center">
                                    <strong>Amount paid</strong>
                                    <strong><em className='text-secondary'>NaN</em></strong>

                                </div>
                            </li>
                        </ul>

                        <div className="d-flex justify-content-end gap-3">
                            <span className="btn btn-white btn-xs disabled"><i className="bi-file-earmark-arrow-down-fill me-1"></i> PDF</span>
                            <span className="btn btn-white btn-xs disabled"><i className="bi-printer-fill me-1"></i> Print Details</span>
                        </div>

                        <hr className="my-5" />

                        <p className="modal-footer-text">If you have any questions, please contact us at <a href={`mailto:${appConfig.app.footer.email}`}>{appConfig.app.footer.email}</a> or call at <a className="text-nowrap" href={`tel:${appConfig.app.footer.phone[1]}`}>{appConfig.app.footer.phone[1]}</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InvoiceViewer;
