import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { EditorModules, Locations, jobsCateg } from '../../constants';
import TomSelect from 'tom-select';

import 'tom-select/dist/css/tom-select.bootstrap4.css';
import 'react-quill/dist/quill.snow.css';
import { adminActions } from 'store';




const AdminCarrerCreate = ({ dispatch, setModal }) => {


    const [showLoading, setShowLoading] = useState(false);


    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [location, setLocation] = useState('');
    const [cycle, setCycle] = useState('');
    const [type, setType] = useState('');
    const [dateline, setDateline] = useState('');
    const [text, setText] = useState('');
    const [propose, setPropose] = useState('');
    const [requierment, setRequierment] = useState('');
    const [skills, setSkills] = useState('');
    const [extraSkills, setExtraSkills] = useState('');
    const [status, setStatus] = useState('');


    useEffect(() => {
        new TomSelect(".select-beast", {
            create: false,
            sortField: {
                field: "text",
                direction: "asc"
            }
        });
        new TomSelect(".select-beast1", {
            create: false,
            sortField: {
                field: "text",
                direction: "asc"
            }
        });

        new TomSelect(".select-beast2", {
            create: false,
            sortField: {
                field: "text",
                direction: "asc"
            }
        });

        return () => {
        };
    }, []);


    const handleTitleChange = value => {
        setTitle(value);
    };

    const handleDescriptionChange = value => {
        setDescription(value);
    };

    const handleCategoryChange = value => {
        setCategory(value);
    };

    const handleLocationChange = value => {
        setLocation(value);
    };

    const handleCycleChange = value => {
        setCycle(value);
    };

    const handleTypeChange = value => {
        setType(value);
    };

    const handleDatelineChange = value => {
        setDateline(value);
    };

    const handleTextChange = value => {
        setText(value);
    };

    const handleProposeChange = value => {
        setPropose(value);
    };

    const handleRequiermentChange = value => {
        setRequierment(value);
    };

    const handleSkillsChange = value => {
        setSkills(value);
    };

    const handleExtraSkillsChange = value => {
        setExtraSkills(value);
    };

    const handleStatusChange = value => {
        setStatus(value);
    };




    const handleSubmit = () => {
        setShowLoading(true);
        setTimeout(() => {
            dispatch(adminActions.createcareer({
                title, category, status,
                location, description,
                cycle, type, date: dateline,
                text, propose, requierment,
                skills, extraSkills
            }));
            setTimeout(() => {
                setShowLoading(false);
            }, 2000);

        }, 2000);
    };


    return <div
        className="modal fade"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        id="create-career"
        style={{ display: "none" }}
        aria-hidden="true">
        <div className="modal-dialog modal-xl">
            <div className="modal-content bg-white">

                <div className="modal-body" style={{ maxHeight: 810, overflow: 'auto' }}>
                    <div className="card-header">
                        <div className="card-title">Create a new Career</div>
                        <div className="card-tools">
                            <button type="button" className="close text-danger" data-dismiss="modal" title="Close" aria-label="Close" onClick={() => setModal(<></>)}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                    </div>

                    <div className="row">

                        {/*  First Column */}
                        <div className="col-md-6">
                            {/* Title */}
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <label htmlFor='title'>Title</label>
                                        <input
                                            type="text"
                                            id='title'
                                            className="form-control bg-white"
                                            placeholder="Enter the career title"
                                            onChange={e => handleTitleChange(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-4">

                                    <div className="form-group">
                                        <label htmlFor='status'>Status</label>
                                        <select
                                            placeholder='Choose a status'
                                            className="form-control bg-white select-beast2"
                                            id='status'
                                            onChange={e => handleStatusChange(e.target.value)}
                                        >
                                            <option value=''></option>
                                            <option value='amateur'>Amateur</option>
                                            <option value='senior'>Senior</option>
                                            <option value='professionnel'>Professionnel</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                            {/* Categor and Location */}
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor='category'>Category</label>
                                        <select
                                            placeholder='Choose a category'
                                            className="form-control bg-white select-beast"
                                            id='category'
                                            onChange={e => handleCategoryChange(e.target.value)}
                                        >
                                            <option value=''></option>
                                            {
                                                jobsCateg.map(job => <option key={job.id} value={job.name}>{job.name}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor='location'>Location</label>
                                        <select
                                            placeholder='Choose a location'
                                            className="form-control bg-white select-beast1"
                                            id='location'
                                            onChange={e => handleLocationChange(e.target.value)}
                                        >
                                            <option value=''></option>
                                            {
                                                Locations.map(location => <option key={location.code} value={location.code}>{location.name}</option>)
                                            }

                                        </select>
                                    </div>
                                </div>

                            </div>

                            {/* Description */}
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor='description'>
                                            Description
                                        </label>
                                        <ReactQuill
                                            theme="snow" modules={EditorModules}
                                            onChange={handleDescriptionChange}
                                            placeholder='Career description'
                                            style={{ height: 160, marginBottom: 80 }}
                                        />

                                    </div>
                                </div>
                            </div>

                            {/* Cycle */}
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor='cycle'>Cycle</label>
                                        <select className="form-control bg-white" id='cycle' onChange={e => handleCycleChange(e.target.value)}>
                                            <option value={null}>-- --</option>
                                            <option value={"Part time"}>Part time</option>
                                            <option value={"Full time"}>Full time</option>

                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor='type'>Type</label>
                                        <select className="form-control bg-white" id='type' onChange={e => handleTypeChange(e.target.value)}>
                                            <option value={null}>-- --</option>
                                            <option value={"CDI"}>CDI</option>
                                            <option value={"CDD"}>CDD</option>

                                        </select>
                                    </div>
                                </div>

                            </div>

                            {/* dateline */}
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor='dateline'>Dateline</label>
                                        <input
                                            type="date"
                                            id='dateline'
                                            className="form-control bg-white"
                                            onChange={e => handleDatelineChange(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor='motivation'>Text Motivation</label>
                                        <input
                                            type="text"
                                            id='motivation'
                                            className="form-control bg-white"
                                            onChange={e => handleTextChange(e.target.value)}
                                        />
                                    </div>
                                </div>

                            </div>


                        </div>


                        {/*  Second Column */}
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <label htmlFor='wepropose' style={{ marginBottom: -7 }}>We propose</label>
                                            <em><small className='text-muted'>Note: By press Enter, you add new list item</small></em>
                                        </div>
                                        <textarea onChange={e => handleProposeChange(e.target.value)} id="wepropose" style={{ width: '100%', height: 100 }}></textarea>
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <label htmlFor='requierments' style={{ marginBottom: -7 }}>Requierments hability</label>
                                            <em><small className='text-muted'>Note: By press Enter, you add new list item</small></em>
                                        </div>
                                        <textarea onChange={e => handleRequiermentChange(e.target.value)} id="requierments" style={{ width: '100%', height: 100 }}></textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <label htmlFor='userhas' style={{ marginBottom: -7 }}>User Skills need</label>
                                            <em><small className='text-muted'>Note: By press Enter, you add new list item</small></em>
                                        </div>
                                        <textarea onChange={e => handleSkillsChange(e.target.value)} id="userhas" style={{ width: '100%', height: 100 }}></textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <label htmlFor='extra' style={{ marginBottom: -7 }}>Extra Skill</label>
                                            <em><small className='text-muted'>Note: By press Enter, you add new list item</small></em>
                                        </div>
                                        <textarea onChange={e => handleExtraSkillsChange(e.target.value)} id="extra" style={{ width: '100%', height: 100 }}></textarea>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4 offset-4">
                            {
                                !showLoading
                                    ? <button className="btn btn-primary btn-block btn-lg" onClick={() => handleSubmit()}>Post a career</button>
                                    : <button className="btn btn-primary btn-block btn-lg" disabled={showLoading}>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Posting...
                                    </button>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
}

export default AdminCarrerCreate;
