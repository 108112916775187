import React, { useEffect, useState } from 'react';
import { LocalizationComponent } from '../components';
import { HashLink as Link } from 'react-router-hash-link';
import { store } from '../store';

import appData from '../config';
import { Slang } from '../helpers';
import config from '../config';

const FooterLayout = ({ page = null, logo }) => {
    const [auth, setAuth] = useState(store.getState().user.user.id !== undefined && store.getState().auth.token !== null);

    useEffect(() => {

        let tim = setInterval(() => {
            setAuth(store.getState().user.user.id !== undefined && store.getState().auth.token !== null);
        }, 300);

        return () => {
            clearInterval(tim);
        };
    }, []);

    const ft = Slang('footer');


    return (
        <footer className="bg-light">
            <div className="container pb-1 pb-lg-7">
                <div className="row content-space-t-2">
                    <div className="col-lg-3 mb-7 mb-lg-0">
                        <div className="mb-5">
                            <Link className="navbar-brand" to="/" aria-label="Space">
                                <img className="navbar-brand-logo" src={logo} alt="DescriptionImage" />
                            </Link>
                        </div>

                        <ul className="list-unstyled list-py-1">
                            <li><Link className="link-sm link-secondary" to="#"><i className="bi-geo-alt-fill me-1"></i> {appData.app.footer.location}</Link></li>
                            {
                                appData.app.footer.phone.map((tel, key) => {
                                    return <li key={key}><a className="link-sm link-secondary" href={`tel:${tel}`}><i className="bi-telephone-inbound-fill me-1"></i> {tel}</a></li>
                                })
                            }
                            <li><a className="link-sm link-secondary" href={`mailto:${appData.app.footer.email}`}><i className="bi bi-envelope me-1"></i> {appData.app.footer.email}</a></li>
                        </ul>

                    </div>

                    <div className="col-sm mb-7 mb-sm-0">
                        <h5 className="mb-3">{ft('h_line.d1.title')}</h5>

                        <ul className="list-unstyled list-py-1 mb-0">
                            <li><Link className="link-sm link-secondary" to="/about">{ft('h_line.d1.about')}</Link></li>
                            <li><Link className="link-sm link-secondary" to="/careers">{ft('h_line.d1.career')} <span className="badge bg-info text-white rounded-pill ms-1">{ft('h_line.d1.we_hire')}</span></Link></li>
                            {/* <li><Link className="link-sm link-secondary" to="/blog">Blog</Link></li> */}
                            {/* <li><Link className="link-sm link-secondary" to="#">Customers <i className="bi-box-arrow-up-right small ms-1"></i></Link></li> */}
                            <li><Link className="link-sm link-secondary" to="/contact#content">{ft('h_line.d1.hire')}</Link></li>
                        </ul>
                    </div>

                    {/* <div className="col-sm mb-7 mb-sm-0">
                        <h5 className="mb-3">Features</h5>

                        <ul className="list-unstyled list-py-1 mb-0">
                            <li><Link className="link-sm link-secondary" to="#">Press <i className="bi-box-arrow-up-right small ms-1"></i></Link></li>
                            <li><Link className="link-sm link-secondary" to="#">Release Notes</Link></li>
                            <li><Link className="link-sm link-secondary" to="#">Integrations</Link></li>
                            <li><Link className="link-sm link-secondary" to="#">Pricing</Link></li>
                        </ul>
                    </div> 

                    <div className="col-sm">
                        <h5 className="mb-3">Documentation</h5>

                        <ul className="list-unstyled list-py-1 mb-0">
                            <li><Link className="link-sm link-secondary" to="#">Support</Link></li>
                            <li><Link className="link-sm link-secondary" to="#">Docs</Link></li>
                            <li><Link className="link-sm link-secondary" to="#">Status</Link></li>
                            <li><Link className="link-sm link-secondary" to="#">API Reference</Link></li>
                            <li><Link className="link-sm link-secondary" to="#">Tech Requirements</Link></li>
                        </ul>
                    </div>*/}

                    <div className="col-sm">
                        <h5 className="mb-3">{ft('h_line.d2.title')}</h5>

                        <ul className="list-unstyled list-py-1 mb-5">
                            {/* <li><Link className="link-sm link-secondary" to="#"><i className="bi-question-circle-fill me-1"></i> Help</Link></li> */}
                            {
                                auth
                                    ? store.getState().user.user.role_id === 1 || store.getState().user.user.role_id === 2
                                        ? <li><Link className="link-sm link-secondary" target='_blank' to="/dashboard"><i className="fas fa-tachometer-alt me-1"></i>{ft('h_line.d2.dash')}</Link></li>
                                        : <li><Link className="link-sm link-secondary" to="/profile"><i className="bi-person-circle me-1"></i>{ft('h_line.d2.profil')}</Link></li>
                                    : <>
                                        <li><Link className="link-sm link-secondary" to="/signin"><i className="bi-person-circle me-1"></i> {ft('h_line.d2.login')}</Link></li>
                                        <li><Link className="link-sm link-secondary" to="/signup"><i className="bi-door-open me-1"></i> {ft('h_line.d2.register')}</Link></li>

                                    </>
                            }
                        </ul>
                    </div>
                </div>

                <div className="border-top my-7"></div>

                <div className="row mb-7">
                    <div className="col-sm mb-3 mb-sm-0">
                        <ul className="list-inline list-separator mb-0">
                            <li className="list-inline-item">
                                <Link className="text-body" to="/privacy">{ft('d_line.privacy')}</Link>
                            </li>
                            <li className="list-inline-item">
                                <Link className="text-body" to="/terms">{ft('d_line.terms')}</Link>
                            </li>
                            {/* <li className="list-inline-item">
                                <Link className="text-body" to="/site-map">Site Map</Link>
                            </li> */}
                        </ul>
                    </div>

                    <div className="col-sm-auto">
                        <ul className="list-inline mb-0">

                            {
                                appData.app.footer.socials.map((social, key) => {
                                    return <li key={key} className="list-inline-item">
                                        <a className="btn btn-soft-secondary btn-xs btn-icon" target='_blank' href={social.link}>
                                            <i className={social.icon}></i>
                                        </a>
                                    </li>
                                })
                            }

                            <li className="list-inline-item">
                                <LocalizationComponent page={page} />
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="w-md-85 text-lg-center mx-lg-auto">
                    <p className="text-muted small">&copy; HappyNess. {config.app.footer.year}. {ft('d_line.rights')}</p>
                    <p className="text-muted small">
                        {ft('d_line.cookies')}
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default FooterLayout;
