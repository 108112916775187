import React, { useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import logoRsl from '../assets/img/logo/new/logo_r.png';
import logoWsl from '../assets/img/logo/new/logo_w.png';
import $ from 'jquery';
import { AppendScript, RemoveAppendedScript } from '../helpers';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-validation/build/button';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { isEmail } from 'validator';
import appConfig from '../config';
import { history, Slang } from '../helpers';
import { authActions } from '../store';
import { Helmet } from 'react-helmet-async';
import { AuthLeftCover } from 'components';


const ForgotPassword = () => {

    const authUser = useSelector(x => x.auth.token && x.user.user.id ? x.user.user : false);

    const t = Slang('page.forgot_pass');
    const tl = Slang('page.login');


    const [showLoading, setShowLoading] = useState(false);
    const [localIp, setLocalIp] = useState(appConfig.app.app_env === "production" ? true : false);

    const dispatch = useDispatch();

    const [email, setEmail] = useState(null);


    const _appendScript = () => {
        AppendScript('./assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js');
        AppendScript('./assets/vendor/hs-toggle-password/dist/js/hs-toggle-password.js');
        AppendScript('./assets/js/theme.min.js');

        AppendScript('../assets/js/resetPassScript.js');

        setInterval(() => {
            if (window.screen.width < 992) {
                $('#root').addClass('d-flex align-items-center');
                $('main').css({
                    'position': 'relative',
                    'top': '25vh',
                });
                $('html').addClass('h-100');
            } else {
                $('main').css({
                    'position': 'relative',
                    'top': '0',
                });
                $('#root').removeAttr('class');
                $('body').removeAttr('class');
                $('html').removeAttr('class');
            }
        }, 200);
    };

    const _removeScript = () => {
        RemoveAppendedScript();
        $('body').removeAttr('class');
        $('html').removeAttr('class');
    }

    const _rrequired = value => {
        if (!value) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    {t('errors.require')}
                </div>
            );
        }
    };

    useEffect(() => {
        setLocalIp(appConfig.app.app_env === "production" ? true : false);
        if (authUser) history.navigate('/');
        _appendScript();
        return () => _removeScript();
    }, [authUser]);

    const _email = value => {
        if (!isEmail(value)) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    {t('errors.email')}
                </div>
            );
        }
    };

    const handleEmailChange = e => {
        setEmail(e.target.value);
    };

    const handleSubmit = event => {
        event.preventDefault();
        setShowLoading(true);

        localStorage.setItem('emailResetPass', email);

        $.getJSON("https://jsonip.com", function (data) {
            setTimeout(() => {
                dispatch(authActions.forgot({ email, ip: data.ip }));
                setShowLoading(false);
            }, 4000);
        })
    };

    return <>
        {
            <>
                <header id="header" className="navbar navbar-expand navbar-light navbar-absolute-top">
                    <div className="container-fluid">
                        <nav className="navbar-nav-wrap">
                            <Link className="navbar-brand d-none d-lg-flex" to="/" aria-label="Front">
                                <img className="navbar-brand-logo-header" src={logoWsl} alt="Logo" />
                            </Link>
                            <Link className="navbar-brand d-flex d-lg-none" to="/" aria-label="Front">
                                <img className="navbar-brand-logo-header" src={logoRsl} alt="Logo" />
                            </Link>

                            {/* <div className="ms-auto">
                        <Link className="link link-sm link-secondary" to="/">
                            <i className="bi-chevron-left small ms-1"></i> Go to main
                        </Link>
                    </div> */}
                        </nav>
                    </div>
                </header>
                <main id="content" role="main" className="flex-grow-1">
                    <div className="container-fluid">
                        <div className="row">

                            <AuthLeftCover lang={tl} />

                            <div className="col-lg-7 col-xl-8 d-flex justify-content-center align-items-center min-vh-lg-100">
                                <div className="flex-grow-1 mx-auto" style={{ maxWidth: "28rem" }}>
                                    <div className="text-center mb-5 mb-md-7">
                                        <img src={require('../assets/img/logo/apines_red.png')} alt="logo_" style={{
                                            height: 110,
                                            marginBottom: 25
                                        }} />
                                        <h1 className="h2">{t('title')}</h1>
                                        <p>{t('subtitle')}</p>
                                    </div>

                                    <Form onSubmit={e => handleSubmit(e)} >
                                        <div className="mb-3">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <label className="form-label" for="signupModalFormResetPasswordEmail" tabindex="0">{t('form.email')}</label>
                                                <Link className="form-label-link" to="/signin">
                                                    <i className="bi-chevron-left small ms-1"></i> {t('form.link_log')}
                                                </Link>
                                            </div>
                                            {/* <label className="form-label" htmlFor="signupModalFormSignupEmail">Your email</label> */}
                                            <Input
                                                type="email"
                                                className="form-control form-control-lg"
                                                name="email"
                                                id="signupModalFormSignupEmail"
                                                placeholder="email@site.com"
                                                aria-label="email@site.com"
                                                validations={[_rrequired, _email]}
                                                onChange={e => handleEmailChange(e)}
                                            />
                                        </div>

                                        <div className="d-grid mb-3">
                                            {/* <button type="submit" className="btn btn-primary btn-lg">Submit</button> */}
                                            {
                                                !showLoading
                                                    ? <Button type="submit" className="btn btn-primary btn-lg">
                                                        {t('form.btn')}
                                                    </Button>
                                                    : <button type="button" className="btn btn-primary btn-lg" disabled={showLoading}>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        {t('form.in_progress')}
                                                    </button>
                                            }
                                        </div>
                                    </Form>


                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        }
        <Helmet>
            <link rel="stylesheet" href="./assets/css/app/bootstrap-icons/font/bootstrap-icons.css" />
            <link rel="stylesheet" href="./assets/css/app/hs-mega-menu.min.css" />
            <link rel="stylesheet" href="./assets/css/app/quill.snow.css" />
            <link rel="stylesheet" href="./assets/css/app/tom-select.css" />
            <link rel="stylesheet" href="./assets/css/app/aos.css" />
            <link rel="stylesheet" href="./assets/css/app/swiper-bundle.min.css" />
            <link rel="stylesheet" href="./assets/css/app/theme.min.css" />

            <title>Forgot Password</title>
        </Helmet>
    </>;
};

export default ForgotPassword;
