import React, { useEffect, useState } from 'react';
import { AdminContentLoader } from 'components';
import { CreateCareer, EditCareer, ViewCareer } from 'components/modals';
import { useDispatch } from 'react-redux';
import { TableData } from 'components/table';
import { adminActions, store } from '../../store';
import { Locations } from '../../constants';
import { formatDate } from '../../helpers';
import Swal from 'sweetalert2';




const CareerContent = () => {
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(<></>);

    const dispatch = useDispatch();

    useEffect(() => {

        setTimeout(() => {
            setLoading(false)
        }, 3000);

    }, []);

    return loading
        ? <AdminContentLoader />
        : <>
            <Content dispatch={dispatch} setModal={setModal} />
            {modal}
        </>
}

export default CareerContent;




const Content = ({ dispatch, setModal }) => {

    const [careersData, setCareersData] = useState(store.getState().admin.careers);


    useEffect(() => {
        dispatch(adminActions.careers());
        let tim = setInterval(() => {
            dispatch(adminActions.careers());
        }, 8000);

        let timer = setInterval(() => {
            setCareersData(store.getState().admin.careers);
        }, 1000);

        return () => {
            clearInterval(tim);
            clearInterval(timer);
        };
    }, []);

    const handleView = career => {
        setModal(<></>);
        setModal(<ViewCareer career={career} setModal={setModal} />);
    };
    
    const handleEdit = career => {
        setModal(<></>);
        setModal(<EditCareer dispatch={dispatch} career={career} setModal={setModal} />);
    };

    const handleDelete = career => {
        Swal.fire({
            title: `Are you sure`,
            text: `You won't be able to revert this operation!`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            html: `<p>Are you sure you want to delete <br /><span style="color: yellow">${career.title}</span></p>`
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(adminActions.deletecareer({ id: career.id }));
            }
        })
    };
    

    const buildArrCareers = arr => {
        let tab = [];

        for (let i = 0; i < arr.length; i++) {
            tab.push({
                id: arr[i].id,
                title: arr[i].title,
                category: arr[i].category,
                location: Locations.filter(loc => loc.code === arr[i].location)[0].name,
                cycle: arr[i].cycle,
                type: arr[i].type,
                dateline: formatDate(arr[i].date),
                action: arr[i]
            });
        }
        return tab;
    };


    const allCareersTab = {
        columns: [
            { field: 'id', headerName: 'ID', width: 20 },
            { field: 'title', headerName: 'Title', width: 300 },
            { field: 'category', headerName: 'Category', width: 300, },
            { field: 'location', headerName: 'Location', width: 300 },
            { field: 'cycle', headerName: 'Cycle', width: 150 },
            { field: 'type', headerName: 'Type', width: 120 },
            { field: 'dateline', headerName: 'DateLine', width: 170 },

            {
                field: "actions",
                headerName: "Actions",
                width: 190,
                sortable: false,
                renderCell: (params) => (
                    <span>
                        <span className="btn btn-outline-info btn-sm mr-1" data-toggle="modal" data-target="#view-career" title='View' onClick={() => handleView(params.row.action)}><i className="fas fa-eye"></i></span>
                        <span className="btn btn-outline-success btn-sm mr-1" data-toggle="modal" data-target="#edit-career" title='Edit' onClick={() => handleEdit(params.row.action)}><i className="fas fa-edit"></i></span>
                        <span className="btn btn-outline-danger btn-sm mr-1" title='Delete' onClick={() => handleDelete(params.row.action)}><i className="fas fa-trash"></i></span>
                    </span>
                ),
            }
        ],
        rows: buildArrCareers(careersData)
    };



    return <section className="content">

        <div className="row">
            <div className="col-md-6">
                <button
                    className="btn btn-outline-success mb-3"
                    title='Create a new Career'
                    onClick={() => setModal(<CreateCareer dispatch={dispatch} setModal={setModal} />)} data-toggle="modal" data-target="#create-career"
                >
                    <i className="fa fa-plus"></i> Create a Career
                </button>
            </div>
        </div>

        <div className="row">

            {/* Career */}
            <div className="col md-12">

                <div className="card">
                    <div className="card-header">
                        <span>Total Career: <span className='text-bold text-info'>{careersData.length < 10 ? `0${careersData.length}` : careersData.length}</span></span>
                        <div className="card-tools" style={{ display: 'flex' }}>
                            <div className="input-group input-group-sm" style={{ width: "150px" }}>
                                <input
                                    type="text"
                                    name="table_search"
                                    className="form-control float-right"
                                    placeholder="Search"
                                />
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-default">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="input-group input-group-sm" style={{ width: "30px", justifyContent: 'end' }}>
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className={`fas fa-minus`}></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0" style={{ maxHeight: "500px", overflow: 'auto', display: 'block' }}>
                        <TableData config={allCareersTab} size={500} rowHeight={80} />
                    </div>

                </div>
            </div>

        </div>


    </section>
};
