import React, { useEffect, useState } from 'react';
import MiniLoad from 'components/miniLoad';
import { PermissionsForm, RoleForm, TypeForm } from 'components/forms';
import { formatDate, getFileFromBack } from 'helpers';
import { store } from 'store';


const AdminUserEdit = ({ dispatch, user, setModal }) => {

    const [loadEditForm, setLoadEditForm] = useState(false);
    const [activeEditContent, setActiveEditContent] = useState('role');
    const [editForm, setEditForm] = useState(null);

    useEffect(() => {

        // setTimeout(() => {
        //     setEditForm(<>Choose an action on top</>);
        // }, 1200);

        return () => {
        };
    }, []);

    const handleSetEditForm = type => {
        setActiveEditContent(type);
        setLoadEditForm(true);
        setTimeout(() => {
            if (type === 'role') {
                setLoadEditForm(false);
                setEditForm(<RoleForm dispatch={dispatch} user={user} />);
            }
            if (type === 'type') {
                setLoadEditForm(false);
                setEditForm(<TypeForm dispatch={dispatch} user={user} />);
            }
            if (type === 'permissions') {
                setLoadEditForm(false);
                setEditForm(<PermissionsForm dispatch={dispatch} user={user} />);
            }
            if (type === 'project') {
                setLoadEditForm(false);
                setEditForm(<OnProject userId={user.id} />);
            }
        }, 2200);
    };

    return <div className="modal fade" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" id="edit-user" style={{ display: "none" }} aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content bg-transparent">
                <div className="modal-body">
                    <div className="card card-widget widget-user bg-white" style={{ boxShadow: '0px 0px 2px black' }}>
                        <div className="widget-user-header bg-primary">
                            <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close" onClick={() => setModal(<></>)}>
                                <span aria-hidden="true">×</span>
                            </button>

                            <h3 className="widget-user-username">
                                <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>{user !== null ? user.name : ''}</span>
                            </h3>
                            <h5 className="widget-user-desc">Since: {user !== null ? formatDate(user.created_at) : ''}</h5>
                            <div className="widget-user-image mt-2">
                                <img className="img-circle" src={getFileFromBack(user.avatar)} alt="User Avatar" />
                            </div>
                        </div>


                        <div className="card-footer" style={{ backgroundColor: 'white' }}>
                            <div className="row">
                                <div className="col-sm-2 border-right">
                                    <div className="description-block">

                                        <h5 className="description-header">Role</h5>
                                        <span className="description-text text-primary text-bold">
                                            {
                                                user !== null
                                                    ? user.roles.length !== 0
                                                        ? <span className="description-text text-primary text-bold" style={{ textTransform: 'uppercase', fontSize: '1.2vh' }}>{user.roles[0].name}</span>
                                                        : <span className="description-text text-danger text-bold bg-danger" style={{ textTransform: 'uppercase', fontSize: '1.2vh' }}>Not set</span>
                                                    : ''}
                                        </span>

                                    </div>
                                </div>
                                <div className="col-sm-7 border-right">
                                    <div className="description-block">
                                        <h5 className="description-header">Email</h5>
                                        <span className="description-text text-primary text-bold" style={{ textTransform: 'lowercase' }}>{user !== null ? user.email : ''}</span>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="description-block">
                                        <h5 className="description-header">Type</h5>
                                        <span className="description-text text-primary text-bold" style={{ fontSize: '0.8em' }}>{user !== null ? user.type : ''}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="row">
                                <div className="card bg-white" style={{ width: '100%', overflowY: 'auto', height: '300px' }}>
                                    <div className="card-body">
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <button className={`btn btn-${activeEditContent === 'role' ? '' : 'outline-'}primary btn-sm`} onClick={() => handleSetEditForm('role')} title='Edit Role'><i className="fa fa-edit mr-1"></i>Role</button>
                                            <button className={`btn btn-${activeEditContent === 'permissions' ? '' : 'outline-'}primary btn-sm`} onClick={() => handleSetEditForm('permissions')} title='Edit Permissions'><i className="fa fa-edit mr-1"></i>Permissions</button>
                                            <button className={`btn btn-${activeEditContent === 'type' ? '' : 'outline-'}primary btn-sm`} onClick={() => handleSetEditForm('type')} title='Edit type'><i className="fa fa-edit mr-1"></i>Type</button>
                                            <button className={`btn btn-${activeEditContent === 'project' ? '' : 'outline-'}primary btn-sm`} onClick={() => handleSetEditForm('project')} title='Edit Project'><i className="fa fa-edit mr-1"></i>Project</button>
                                        </div>

                                        <hr />

                                        <div className="card bg-white">

                                            <div className="card-body">
                                                {loadEditForm ? <MiniLoad /> : editForm}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div >
};

export default AdminUserEdit;


const OnProject = ({ userId }) => {
    let tab = [];
    const Projects = store.getState().admin.projects;

    Projects.forEach(element => {
        element.users.forEach(user => {
            if (user.id === userId) {
                if (user.pivot.is_owner === 1) {
                    tab = [...tab, { ...element, leader: true }];
                } else {
                    tab = [...tab, { ...element, leader: false }];
                }
            }
        });
    });

    return <div className='row'>
        <span className='d-block'>The user work actually on {tab.length} {tab.length > 1 ? `projects` : `project`}: </span><hr />
        {
            tab.map((project, key) => <div key={key} className='col-md-6'>
                <div style={{
                    borderLeft: '6px solid green',
                    height: 50,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                    borderRadius: 10,
                    // width: '50%',
                    boxShadow: '1px 1px 5px gray'
                }}>
                    <span>
                        {project.leader ? <i title='Leader' className='fa fa-star  text-warning'></i> : null} {project.name}
                    </span>
                </div>
            </div>)
        }
    </div>;
};
