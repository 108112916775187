import React, { useEffect, useState } from 'react';
import {
    checkPasswordFormat,
    Toast,
    Slang,
    parseUserAgent
} from '../helpers';


import $ from 'jquery';

import { isEmail } from 'validator';

import Button from 'react-validation/build/button';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { Check, Input as MyInput } from '../components/myValidatorFields';
import { useDispatch } from 'react-redux';
import appConfig from '../config';
import { authActions, store } from '../store';
import Fingerprint2 from 'fingerprintjs2';



const ModalRegister = ({ setContent, lang }) => {

    const [fp, setFp] = useState('');

    const t = Slang('page.register');

    const dispatch = useDispatch();
    const [showLoading, setShowLoading] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const [infos, setInfos] = useState({
        first_name: "",
        email: "",
        name: "",
        password: "",
        password_confirmation: "",
        confidential: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name !== "confidential") {
            setInfos({ ...infos, [name]: value });
        } else {
            setInfos({ ...infos, confidential: e.target.checked });
        }
    };


    useEffect(() => {

        Fingerprint2.get(components => {
            const values = components.map((component) => component.value);
            const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);

            setFp(fingerprint);
        });

        setInterval(() => {
            if (sessionStorage.getItem('init-btn') !== null) {
                if (parseInt(sessionStorage.getItem('init-btn')) === 0) {
                    setShowLoading(false);
                } else {
                    setShowLoading(true);
                }
            }
        }, 1000);

        return () => null;
    }, []);


    //Login functions
    const _rrequired = value => {
        if (!value) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    {t('errors.require')}
                </div>
            );
        }
    };

    const _fname = value => {
        return value !== ""
            ? value.length < 4
                ? <div className="alert alert-soft-danger mt-2" role="alert">{t('errors.min_car', { min: 4 })}</div>
                : null
            : <div className="alert alert-soft-danger mt-2" role="alert">{t('errors.empty')}</div>
    };

    const _lname = value => {
        return value !== ""
            ? value.length < 4
                ? <div className="alert alert-soft-danger mt-2" role="alert">{t('errors.min_car', { min: 4 })}</div>
                : null
            : <div className="alert alert-soft-danger mt-2" role="alert">{t('errors.empty')}</div>
    };

    const _email = value => {
        if (!isEmail(value)) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    {t('errors.email')}
                </div>
            );
        }
    };

    const _password = value => {
        if (!checkPasswordFormat(value)) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    {t('errors.invalid_pass')}
                </div>
            );
        }
    };

    const _confirmPassVerif = value => {
        if (infos.password !== infos.password_confirmation) {
            Toast('error', t('errors.confirm_pass'), 'tr', true, true, true, 3000);
            setShowLoading(false);
            return false;
        }
        return true;
    };

    const handleSubmit = event => {
        event.preventDefault();

        sessionStorage.setItem('init-btn', 1);

        if (_confirmPassVerif() && infos.confidential) {

            $.getJSON(appConfig.apip, (datas) => {
                
                setTimeout(() => {
                    var user = store.getState().user.user;

                    parseUserAgent(datas, datas.ip || datas.ipString, user.id)
                        .then(data => {
                            dispatch(authActions.register({
                                ...infos,
                                ip: datas.ip || datas.ipString,
                                device: data,
                                fingerprint: fp
                            }));
                        });
                }, 4000);

            });
        }

        if (!infos.confidential) {
            $("#signupHeroFormPrivacyCheck").css('border', '2px solid rgba(200, 10, 10, 0.6)');
            setShowLoading(false);
            Toast('error', t('errors.confirm_priv'), 'bc', true, true, true, 3000);
        }
    };



    return <>
        <div className="text-center mb-7">
            <h2>{lang('modal_register.title')}</h2>
            <p>{lang('modal_register.p')}
                <span className="js-animation-link link text-primary" style={{ cursor: "pointer" }} role="button"
                    data-hs-show-animation-options="{
                    &quot;targetSelector&quot;: &quot;#signupModalFormLogin&quot;,
                    &quot;groupName&quot;: &quot;idForm&quot;
                }"
                    onClick={() => setContent(0)}
                    data-hs-show-animation-link-group="idForm"> {lang('modal_register.link_login')}</span>
            </p>
        </div>
        <Form onSubmit={e => handleSubmit(e)} >
            <div className="mb-3">
                <label className="form-label" htmlFor="signupModalFormSignupName">{t('form.fname')}</label>
                <Input
                    type="text"
                    className="form-control form-control-lg"
                    name="first_name"
                    id="signupModalFormSignupName"
                    placeholder="Alan"
                    aria-label="Alan"
                    validations={[_rrequired, _fname]}
                    onChange={handleChange}
                />
            </div>

            <div className="mb-3">
                <label className="form-label" htmlFor="name">{t('form.lname')}</label>
                <Input
                    type="text"
                    className="form-control form-control-lg"
                    name="name"
                    id="name"
                    placeholder="Peter"
                    aria-label="Peter"
                    validations={[_rrequired, _lname]}
                    onChange={handleChange}
                />
            </div>

            <div className="mb-3">
                <label className="form-label" htmlFor="signupModalFormSignupEmail">{t('form.email')}</label>
                <Input
                    type="email"
                    className="form-control form-control-lg"
                    name="email"
                    id="signupModalFormSignupEmail"
                    placeholder="email@site.com"
                    aria-label="email@site.com"
                    validations={[_rrequired, _email]}
                    onChange={handleChange}
                />
            </div>

            {/* password */}
            <div className="mb-4">
                <div className="d-flex justify-content-between align-items-center">
                    <label className="form-label" htmlFor="signupModalFormLoginPassword">{t('form.password')}</label>
                </div>

                <MyInput
                    type={showPassword ? 'text' : 'password'}
                    className="form-control form-control-lg"
                    name="password"
                    id="signupModalFormLoginPassword"
                    placeholder={t('form.pl_hld_pass')}
                    aria-label={t('form.pl_hld_pass')}
                    minLength="8"
                    validations={[_rrequired, _password]}
                    onChange={handleChange}
                >
                    <span
                        id="changePassTarget"
                        className="input-group-append input-group-text"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        <i id="changePassIcon" className={showPassword ? 'bi-eye' : 'bi-eye-slash'}></i>
                    </span>
                </MyInput>
            </div>

            {/* Confirm password */}
            <div className="mb-4">
                <div className="d-flex justify-content-between align-items-center">
                    <label className="form-label" htmlFor="ConfirmLoginPassword">{t('form.confirm_pass')}</label>
                </div>

                <MyInput
                    type={showPasswordConfirm ? 'text' : 'password'}
                    className="form-control form-control-lg"
                    name="password_confirmation"
                    id="ConfirmLoginPassword"
                    placeholder={t('form.pl_hld_pass')}
                    aria-label={t('form.pl_hld_pass')}
                    minLength="8"
                    validations={[_rrequired, _password]}
                    onChange={handleChange}
                >
                    <span
                        id="changePassTarget"
                        className="input-group-append input-group-text"
                        onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                    >
                        <i id="changePassIcon" className={showPasswordConfirm ? 'bi-eye' : 'bi-eye-slash'}></i>
                    </span>
                </MyInput>
            </div>

            <div className="form-check mb-3">
                <Check
                    className="form-check-input"
                    type="checkbox"
                    id="signupHeroFormPrivacyCheck"
                    name="confidential"
                    validations={[_rrequired]}
                    onChange={handleChange}
                    value={infos.confidential}
                />
                <label className="form-check-label small" htmlFor="signupHeroFormPrivacyCheck"> {t('form.text_priv')} <a href='/privacy'>{t('form.link_priv')}.</a></label>
            </div>

            <div className="d-grid mb-3">
                {
                    !showLoading
                        ? <Button type="submit" className="btn btn-primary btn-lg">
                            {t('form.btn')}
                        </Button>
                        : <button type="button" className="btn btn-primary btn-lg" disabled={showLoading}>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {t('form.in_progress')}
                        </button>
                }
            </div>
        </Form>
    </>;

}

export default ModalRegister;

