const d = new Date();
let year = d.getFullYear();
export default {
    apiUrl: "https://api.happynessarl.com/api",
    backendUrl: "https://api.happynessarl.com",
    google_auth: 'https://api.happynessarl.com/api/auth',
    app: {
        footer: {
            phone: [
                "+49 1578 1559495",
                "+32 465 37 33 13"
            ],
            year: year,
            email: "offices@happynessarl.com",
            location: "Roumanie, Bucharest",
            socials: [
                {
                    icon: 'bi-facebook',
                    link: 'https://www.facebook.com/profile.php?id=100080508300409'
                }
            ]
        },
        home: {
            all: '120',
            finished: '112',
            in_progress: '8',
            customers: '89',
            uping: '40',
        },
        about: {
            experience_year: 3,
            accomplished_project: "112",
            happy_customers: "89"
        }
    },
    app_env: "development",
    // app_env: "production",
    apip: "https://api.bigdatacloud.net/data/client-info",
    get_json_php: "https://happynessarl.com/json.php",
    get_json_json: "https://happynessarl.com/json.json",
    userAgentParser: `https://api.userparser.com/1.1/detect?`,
    // userAgentParser: `http://ip-api.com/json/?`, // de JM
    defaultAvatar: "https://firebasestorage.googleapis.com/v0/b/doctor-aeb2a.appspot.com/o/image%2Favatar.png?alt=media&token=83e0d430-cc8d-49c9-a3db-29704e2277ee"
}