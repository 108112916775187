import React, { useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import logoRsl from '../assets/img/logo/new/logo_r.png';
import logoWsl from '../assets/img/logo/new/logo_w.png';
import $ from 'jquery';
import { AppendScript, RemoveAppendedScript, Toast, checkPasswordFormat, Slang } from '../helpers';
import { useDispatch } from 'react-redux';
import Button from 'react-validation/build/button';
import Form from "react-validation/build/form";
import { Input as MyInput } from '../components/myValidatorFields';
import { authActions } from '../store';
import { Helmet } from 'react-helmet-async';
import { AuthLeftCover } from 'components';
import appConfig from '../config';


const ResetPassword = () => {

    const [showLoading, setShowLoading] = useState(false);
    const [active, setActive] = useState(false);

    const dispatch = useDispatch();

    const t = Slang('page.reset_pass');
    const tl = Slang('page.login');

    const _appendScript = () => {
        AppendScript('./assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js');
        AppendScript('./assets/vendor/hs-toggle-password/dist/js/hs-toggle-password.js');
        AppendScript('./assets/js/theme.min.js');

        AppendScript('../assets/js/resetPassScript.js');

        setInterval(() => {
            if (window.screen.width < 992) {
                $('#root').addClass('d-flex align-items-center');
                $('main').css({
                    'position': 'relative',
                    'top': '25vh',
                });
                $('html').addClass('h-100');
            } else {
                $('main').css({
                    'position': 'relative',
                    'top': '0',
                });
                $('#root').removeAttr('class');
                $('body').removeAttr('class');
                $('html').removeAttr('class');
            }
        }, 200);
    };

    const _removeScript = () => {
        RemoveAppendedScript();
        $('body').removeAttr('class');
        $('html').removeAttr('class');
    }


    // const [oldPassword, setOldPassword] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPass, setConfirmPass] = useState(null);
    // const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const _rrequired = value => {
        if (!value) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    {t('errors.require')}
                </div>
            );
        }
    };

    useEffect(() => {
        _appendScript();

        $('.modal-backdrop').removeClass();

        return () => _removeScript();
    }, []);



    const _password = value => {
        if (!checkPasswordFormat(value)) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    {t('errors.password')}
                </div>
            );
        }
    };

    const handleSubmit = event => {
        event.preventDefault();
        setShowLoading(true);

        $.getJSON(appConfig.apip, function (data) {
            if (confirmPass !== password) {
                Toast('error', t('info.pass_!ok'), 'tr', false, false, true, 2000);
            } else {
                Toast('success', t('info.pass_ok'), 'tr', false, false, true, 2000);
                setTimeout(() => {
                    setShowLoading(false);
                    dispatch(authActions.resetPassword({ password, password_confirmation: confirmPass, ip: data.ip }));
                }, 4000);
            }
        });

    };

    // const handleOldPasswordChange = e => {
    //     setOldPassword(e.target.value);
    //     setActive(oldPassword !== e.target.value ? false : true);
    // };

    const handlePasswordChange = e => {
        setPassword(e.target.value);
        setActive(confirmPass !== e.target.value ? false : true);
    };

    const handleConfirmPassChange = e => {
        setConfirmPass(e.target.value);
        setActive(e.target.value !== password ? false : true);
    };


    return <>

        {
            <>
                <header id="header" className="navbar navbar-expand navbar-light navbar-absolute-top">
                    <div className="container-fluid">
                        <nav className="navbar-nav-wrap">
                            <Link className="navbar-brand d-none d-lg-flex" to="/" aria-label="Front">
                                <img className="navbar-brand-logo-header" src={logoWsl} alt="Logo" />
                            </Link>
                            <Link className="navbar-brand d-flex d-lg-none" to="/" aria-label="Front">
                                <img className="navbar-brand-logo-header" src={logoRsl} alt="Logo" />
                            </Link>
                            {/* <div className="ms-auto">
                        <Link className="link link-sm link-secondary" to="/">
                            <i className="bi-chevron-left small ms-1"></i> Go to main
                        </Link>
                    </div> */}
                        </nav>
                    </div>
                </header>
                <main id="content" role="main" className="flex-grow-1">
                    <div className="container-fluid">
                        <div className="row">

                            <AuthLeftCover lang={tl} />

                            <div className="col-lg-7 col-xl-8 d-flex justify-content-center align-items-center min-vh-lg-100">
                                <div className="flex-grow-1 mx-auto" style={{ maxWidth: "28rem" }}>
                                    <div className="text-center mb-5 mb-md-7">
                                        <img src={require('../assets/img/logo/apines_red.png')} alt="logo_" style={{
                                            height: 110,
                                            marginBottom: 25
                                        }} />
                                        <h1 className="h2">{t('title')}</h1>
                                        <p>{t('subtitle')}</p>
                                    </div>

                                    <Form onSubmit={e => handleSubmit(e)} >
                                        {/* old password */}
                                        {/* <div className="mb-4">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <label className="form-label" htmlFor="signupModalFormLoginPassword">Old Password</label>
                                    </div>

                                    <MyInput
                                        type={showOldPassword ? 'text' : 'password'}
                                        className="form-control form-control-lg"
                                        name="old_password"
                                        id="signupModalFormLoginPassword"
                                        placeholder="8+ characters required"
                                        aria-label="8+ characters required"
                                        minLength="8"
                                        validations={[_rrequired, _password]}
                                        onChange={e => handleOldPasswordChange(e)}
                                    >
                                        <Link
                                            id="changePassTarget"
                                            className="input-group-append input-group-text"
                                            to="#/"
                                            onClick={() => setShowOldPassword(!showOldPassword)}
                                        >
                                            <i id="changePassIcon" className={showOldPassword ? 'bi-eye' : 'bi-eye-slash'}></i>
                                        </Link>
                                    </MyInput>
                                </div> */}

                                        {/* password */}
                                        <div className="mb-4">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <label className="form-label" htmlFor="signupModalFormLoginPassword">{t('form.password')}</label>
                                            </div>

                                            <MyInput
                                                type={showPassword ? 'text' : 'password'}
                                                className="form-control form-control-lg"
                                                name="password"
                                                id="signupModalFormLoginPassword"
                                                placeholder={t('form.pl_hld_pass')}
                                                aria-label={t('form.pl_hld_pass')}
                                                minLength="8"
                                                validations={[_rrequired, _password]}
                                                onChange={e => handlePasswordChange(e)}
                                            >
                                                <span
                                                    id="changePassTarget"
                                                    className="input-group-append input-group-text"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    <i id="changePassIcon" className={showPassword ? 'bi-eye' : 'bi-eye-slash'}></i>
                                                </span>
                                            </MyInput>
                                        </div>

                                        {/* Confirm password */}
                                        <div className="mb-4">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <label className="form-label" htmlFor="ConfirmLoginPassword">{t('form.confirm_pass')}</label>
                                            </div>

                                            <MyInput
                                                type={showPasswordConfirm ? 'text' : 'password'}
                                                className="form-control form-control-lg"
                                                name="confirmpassword"
                                                id="ConfirmLoginPassword"
                                                placeholder={t('form.pl_hld_pass')}
                                                aria-label={t('form.pl_hld_pass')}
                                                minLength="8"
                                                validations={[_rrequired, _password]}
                                                onChange={(e => handleConfirmPassChange(e))}
                                            >
                                                <span
                                                    id="changePassTarget"
                                                    className="input-group-append input-group-text"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                                >
                                                    <i id="changePassIcon" className={showPasswordConfirm ? 'bi-eye' : 'bi-eye-slash'}></i>
                                                </span>
                                            </MyInput>
                                        </div>

                                        <div className="d-grid mb-3">
                                            {/* <button type="submit" className="btn btn-primary btn-lg">Submit</button> */}
                                            {
                                                active
                                                    ? !showLoading
                                                        ? <Button type="submit" className="btn btn-primary btn-lg">
                                                            {t('form.btn')}
                                                        </Button>
                                                        : <button type="button" className="btn btn-primary btn-lg" disabled={showLoading}>
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            {t('form.in_progress')}
                                                        </button>
                                                    : <button type="button" className="btn btn-primary btn-lg" disabled={true}>
                                                        {t('form.btn')}
                                                    </button>
                                            }
                                        </div>
                                    </Form>


                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        }

        <Helmet>
            <link rel="stylesheet" href="./assets/css/app/bootstrap-icons/font/bootstrap-icons.css" />
            <link rel="stylesheet" href="./assets/css/app/hs-mega-menu.min.css" />
            <link rel="stylesheet" href="./assets/css/app/quill.snow.css" />
            <link rel="stylesheet" href="./assets/css/app/tom-select.css" />
            <link rel="stylesheet" href="./assets/css/app/aos.css" />
            <link rel="stylesheet" href="./assets/css/app/swiper-bundle.min.css" />
            <link rel="stylesheet" href="./assets/css/app/theme.min.css" />


            <title>HappyNess | Reset Password</title>
        </Helmet>
    </>;
};

export default ResetPassword;
