import React, { useState } from 'react';
import { Toast } from 'helpers';
import { adminActions } from 'store';



const AdminCategoryCreate = ({ dispatch }) => {

    const [categoryName, setCategoryName] = useState('');
    const [categoryDescription, setCategoryDescription] = useState('');

    const handleSave = () => {
        if (categoryName.length !== 0) {
            if (categoryDescription.length !== 0) {
                dispatch(adminActions.createcategory({ name: categoryName, description: categoryDescription }));
            } else {
                Toast('error', 'Please fill the category description', 'tr', false, false, true, 3000, false);
            }
        } else {
            Toast('error', 'Please fill the category name', 'tr', false, false, true, 3000, false);
        }
    };

    return <div className="modal fade" id="create-category" style={{ display: "none" }} aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content bg-white">

                <div className="modal-body">
                    <div className="card bg-white">
                        <div className="card-header">
                            <h3 className="card-title">Create a Category</h3>

                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                                <button type="button" className="btn btn-tool" data-dismiss="modal" aria-label="Close" title="Close">
                                    <i className="fas fa-times"></i>
                                </button>
                            </div>
                        </div>

                        <div className="card-body" style={{ display: 'block' }}>
                            <div className="row">
                                <div className="col-12 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor='categ_name'>Name of Category</label>
                                        <input
                                            type="text"
                                            id='categ_name'
                                            className="form-control bg-white"
                                            placeholder="Enter the name fo the category"
                                            onChange={e => { setCategoryName(e.target.value) }}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor='description'>
                                            Category description
                                        </label>
                                        <textarea
                                            className="form-control bg-white"
                                            id='description'
                                            rows="7"
                                            placeholder="Describe the porject here..."
                                            onChange={e => { setCategoryDescription(e.target.value) }}
                                        ></textarea>
                                    </div>

                                    <div className="form-group">
                                        <button className="btn btn-primary" onClick={() => handleSave()}>Save category</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default AdminCategoryCreate;
