import React, { useEffect, useState } from 'react';
import Form from "react-validation/build/form";
import { store, userActions } from 'store';
import { Input as MyInput } from '../../components/myValidatorFields';
import { useDispatch, useSelector } from 'react-redux';
import { Toast, checkPasswordFormat } from 'helpers';
import { QRCodeCanvas } from "qrcode.react";

const TwoStepsAuth = ({ lang }) => {

    const user = useSelector(x=>x.user.user);

    const [isActivated2Fact, setisActivated2Fact] = useState(store.getState().user.user.google2fa_secret === null ? false : true);

    const [show2FactForm, setShow2FactForm] = useState(false);
    const [showPass, setShowPass] = useState(false);

    const [password, setPassword] = useState('');

    const dispatch = useDispatch();

    const handleVerifyPass = e => {
        e.preventDefault();
        if (!(password.length === 0)) {
            dispatch(userActions.verifpass({ user_id: user.id, password }));
            // console.log({ email: user.email, password });
        } else {
            Toast('error', lang('items.security.2fa.errors.toast'), 'tr', false, false, false, 3000, false);
        }
    };

    const _password = value => {
        if (!checkPasswordFormat(value)) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    {lang('items.security.2fa.errors.bad_pass')}
                </div>
            );
        }
    };


    useEffect(() => {
        setShow2FactForm(sessionStorage.getItem('passVerif') === 'true' ? true : false);
        let tim = setInterval(() => {
            setShow2FactForm(sessionStorage.getItem('passVerif') === 'true' ? true : false);
        }, 100);

        let timer = setInterval(() => {
            setisActivated2Fact(user.google2fa_secret === null ? false : true);
        }, 3000);


        return () => {
            clearInterval(tim);
            clearInterval(timer);
        };
    }, []);


    return <div className="card">
        <div className="card-header border-bottom">
            <div className="d-flex align-items-center">
                <h5 className="card-header-title">{lang('items.security.2fa.title')}</h5>
                {/* <h5 className="card-header-title">Two-step verification</h5> */}
                <span className={`badge bg-soft-${isActivated2Fact ? 'success' : 'danger'} text-${isActivated2Fact ? 'success' : 'danger'} ms-2`}>
                    {isActivated2Fact ? lang('items.security.2fa.state.on') : lang('items.security.2fa.state.off')}
                </span>
            </div>
        </div>

        <div className="card-body">

            <p className="card-text">
                {lang('items.security.2fa.desc.br1')} <br /> {lang('items.security.2fa.desc.br2')}
            </p>

            {/* <p className="card-text">
                You have enabled two factor authentication.<br />
                When two factor authentication is enabled, you will be prompted for a secure, random token during authentication.
                You may retrieve this token from your phone's Google Authenticator
                application.<br />
                Two factor authentication is now enabled. Scan the following QR code using your phone's authenticator application.
            </p> */}
            {
                !show2FactForm
                    ? <Form onSubmit={e => handleVerifyPass(e)}>
                        <div className="row mb-4">
                            <label htmlFor="accountPasswordLabel" className="col-sm-3 col-form-label form-label">{lang('items.security.2fa.form.label')}</label>

                            <div className="col-sm-9">

                                <MyInput
                                    type={showPass ? 'text' : 'password'}
                                    onChange={e => setPassword(e.target.value)}
                                    minLength="8"
                                    validations={[_password]}
                                    className="form-control"
                                    name="currentPassword"
                                    id="accountPasswordLabel"
                                    placeholder={lang('items.security.2fa.form.pl_hld_pass')}
                                    aria-label={lang('items.security.2fa.form.pl_hld_pass')}
                                >
                                    <span
                                        id="changePassTarget"
                                        className="input-group-append input-group-text"
                                        onClick={() => setShowPass(!showPass)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <i id="changePassIcon" className={showPass ? 'bi-eye' : 'bi-eye-slash'}></i>
                                    </span>
                                </MyInput>
                                <small className="form-text">{lang('items.security.2fa.form.text')}</small>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn btn-outline-primary">{lang('items.security.2fa.form.btn')}</button>
                        </div>
                    </Form>
                    : <>
                        <SwitcherActive2Fact dispatch={dispatch} lang={lang} />
                    </>
            }

        </div>
    </div>
}

export default TwoStepsAuth;




const SwitcherActive2Fact = ({ dispatch, lang }) => {
    const [check, setCheck] = useState(store.getState().user.user.google2fa_secret === null ? false : true);
    const [showQrCode, setShowQrCode] = useState(false);
    const [newCode, setNewCode] = useState('');

    const handleCheck = value => {
        setCheck(value);
        setShowQrCode(false);
        if (value) {
            console.log('enable');
            dispatch(userActions.enable2fa());
        } else {
            console.log('disable');
            dispatch(userActions.disable2fa());
        }
        setTimeout(() => {
            dispatch(userActions.getUser());
        }, 2500);

        setTimeout(() => {
            setShowQrCode(true);
        }, 5000);
    };

    const handleNewCode = code => {
        setNewCode(code);
    };

    useEffect(() => {
        if (store.getState().user.user.google2fa_secret !== null) {
            setShowQrCode(true);
        } else {
            setShowQrCode(false);
        }
        return () => {
            sessionStorage.removeItem('passVerif')
        };
    }, []);

    const handleVerif2FACode = () => {
        if (newCode.length !== 0) {
            if (newCode.length === 6) {
                dispatch(userActions.verif2facode({ code: newCode }));
            } else {
                Toast('error', lang('items.security.2fa.switch.errors.digit'), 'tr', false, false, false, 3000, false);
                return;
            }
        } else {
            Toast('error', lang('items.security.2fa.switch.errors.nocode'), 'tr', false, false, false, 3000, false);
            return;
        }
    };


    return <div className="list-group-item">
        <div className="d-flex">
            <div className="flex-grow-1 ms-3">
                <div className="row align-items-center">
                    <div className="col">
                        <h6 className="mb-1">{lang('items.security.2fa.switch.title')}</h6>
                    </div>

                    <div className="col-auto">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="connectedAccounts1" defaultChecked={check} onChange={() => handleCheck(!check)} />
                            <label className="form-check-label" htmlFor="connectedAccounts1"></label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        check
                            ? <>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <div className="alert alert-soft-default">{lang('items.security.2fa.switch.alert')}</div>
                                    </div>

                                    <div className="col-auto"><br />
                                        <div className="alert alert-soft-success text-bold">{store.getState().user.user.google2fa_secret}</div>
                                    </div>
                                </div><br />

                                <div className="row align-items-center">
                                    <div className="col-md-4">
                                        <QRCodeCanvas
                                            id="qrCode"
                                            value={qr_code(store.getState().user.user.google2fa_secret, store.getState().user.user.email)}
                                            size={200}
                                            hidden={!showQrCode}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="code">{lang('items.security.2fa.switch.label')}</label>
                                            <input type="number" id='code' className='form-control' onChange={e => handleNewCode(e.target.value)} maxLength={6} />
                                        </div><br />

                                        <div className="form-group">
                                            <input type="button" value={lang('items.security.2fa.switch.btn')} className='btn btn-success' onClick={() => handleVerif2FACode()} />
                                        </div>
                                    </div>
                                </div>

                            </>
                            : null
                    }
                </div>
            </div>
        </div>
    </div>
};

const qr_code = (secret, email) => {
    return `otpauth://totp/happyness%20web:${email}?secret=${secret}&issuer=&algorithm=SHA1&digits=6&period=30`;
};
