import React, { useEffect, useState } from 'react';
import { AdminContentLoader } from '../../components';
import ManagerProjectEdit from '../../components/managerProjectEdit';
import { useDispatch } from 'react-redux';
import { managerActions, store } from '../../store';

const ProjectContent = () => {
    const [loading, setLoading] = useState(true);
    const [box, setBox] = useState(<></>);
    const dispatch = useDispatch();


    useEffect(() => {
        setBox(<Box />);

        dispatch(managerActions.projects());
        dispatch(managerActions.users());
        dispatch(managerActions.categories());

        let tim = setInterval(() => {
            dispatch(managerActions.users());
            dispatch(managerActions.projects());
            dispatch(managerActions.categories());
        }, 8000);

        setTimeout(() => {
            setLoading(false)
        }, 2000);

        return () => {
            clearInterval(tim);
        };
    }, []);

    return loading
        ? <AdminContentLoader />
        : <div className="row">
            <LeftList dispatch={dispatch} setBox={setBox} />

            {box}
        </div>
}

export default ProjectContent;


const LeftList = ({ setBox, dispatch }) => {

    const [projects, setProjects] = useState(veri(store.getState().manager.projects, store.getState().user.user.id));

    useEffect(() => {

        let tim = setInterval(() => {
            setProjects(veri(store.getState().manager.projects, store.getState().user.user.id));
        }, 300);


        return () => {
            clearInterval(tim);
        };
    }, []);

    const loadBox = proj => {
        setBox(<div className='col-md-9' style={{
            height: '550px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div className="spinner-grow text-primary" role="status">
                <span className="visually-hidden"></span>
            </div>
        </div>);
        setTimeout(() => {
            setBox(<Box dispatch={dispatch} project={proj} />);
        }, 3000);
    };

    return <div className="col-md-3">
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">List</h3>
            </div>
            <div className="card-body p-0">
                <ul className="nav nav-pills flex-column">
                    {
                        projects.length > 0
                            ? projects.map((project, key) =>
                                <li key={key} className="nav-item active" onClick={() => loadBox(project)}>
                                    <span className="nav-link" style={{ cursor: 'pointer' }}>
                                        # {key + 1} - <i className="fas fa-book"></i>&nbsp;{project.name}
                                        {/* <span className="badge bg-primary float-right">12</span> */}
                                    </span>
                                </li>
                            )
                            : <li className="nav-item">
                                <span className="nav-link">
                                    <div className="alert p-2" style={{ backgroundColor: '#efe1e6', color: '#b5285d' }}>
                                        No project
                                        <span className="float-right">x</span>
                                    </div>
                                </span>
                            </li>
                    }
                </ul>
            </div>

        </div>

    </div>
};




const Box = ({ dispatch, project }) => {
    return <ManagerProjectEdit dispatch={dispatch} project={project} />
};


const veri = (data, user_id) => {
    let tab1 = [];
    data.forEach(elm => {
        elm.users.forEach(user => {
            if (user.role_id === 2 && user.id === user_id) { //role_id === 2 means that it is the manager
                tab1.push(elm)
            }
        })
    })

    return tab1;
};