import React, { useEffect, useState } from 'react';
import logo from '../assets/img/logo/logo_footer.png';
import { AdminContentLoader, Goto } from '../components';
import { FooterLayout, HeaderLayout, ProfileLayout } from '../layouts';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import { userActions, store } from '../store';
import { checkPresenceOfUser } from '../helpers';
import { Helmet } from 'react-helmet-async';
import { connectionVerif } from './home';


const Profile = () => {

    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();
    const auth = useSelector(x => x.auth);

    useEffect(() => {
        console.log("mount");
        $('.remove').remove();

        setTimeout(() => {
            connectionVerif(dispatch, setLoading, store.getState().user, auth);
        }, 1200);

        setInterval(() => {
            $('iframe').remove();
        }, 200);


        dispatch(userActions.getUser());


        setTimeout(() => {
            checkPresenceOfUser();
        }, 10000);

        let tim = setInterval(() => {
            dispatch(userActions.projects());
        }, 8000);
        return () => {
            clearInterval(tim);
            $('.remove').remove();
            console.log('unmount');
        }
    }, [dispatch]);

    return <>
        {
            loading ? <AdminContentLoader /> : (<Group logo={logo}/>)
        }

        <Helmet>
            <link rel="stylesheet" href="./assets/css/app/bootstrap-icons/font/bootstrap-icons.css" />
            <link rel="stylesheet" href="./assets/css/app/hs-mega-menu.min.css" />
            <link rel="stylesheet" href="./assets/css/app/quill.snow.css" />
            <link rel="stylesheet" href="./assets/vendor/tom-select/dist/css/tom-select.bootstrap5.css" />
            <link rel="stylesheet" href="./assets/css/app/aos.css" />
            <link rel="stylesheet" href="./assets/css/app/swiper-bundle.min.css" />
            <link rel="stylesheet" href="./assets/css/app/theme.min.css" />


            <script defer async src='./assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js'></script>
            <script defer async src='./assets/vendor/hs-header/dist/hs-header.min.js'></script>
            <script defer async src='./assets/vendor/hs-mega-menu/dist/hs-mega-menu.min.js'></script>
            <script defer async src='./assets/vendor/hs-show-animation/dist/hs-show-animation.min.js'></script>
            <script defer async src='./assets/vendor/hs-go-to/dist/hs-go-to.min.js'></script>
            <script defer async src='./assets/vendor/hs-sticky-block/dist/hs-sticky-block.min.js'></script>

            <script defer async src='./assets/vendor/hs-file-attach/dist/hs-file-attach.min.js'></script>
            <script defer async src='./assets/vendor/hs-add-field/dist/hs-add-field.min.js'></script>
            <script defer async src='./assets/vendor/imask/dist/imask.min.js'></script>
            <script defer async src='./assets/vendor/quill/dist/quill.min.js'></script>
            <script defer async src='./assets/vendor/tom-select/dist/js/tom-select.complete.min.js'></script>

            <script defer async src='./assets/js/theme.min.js'></script>
            <title>HappyNess | Profile</title>
        </Helmet>

    </>
}

export default Profile;

const Group = ({ logo }) => {
    const [modal, setModal] = useState(<></>);

    useEffect(() => {

        let tt = setTimeout(() => {
            let html = `<script>
            new HSHeader('#header').init()
        
            new HSMegaMenu('.js-mega-menu', {
                desktop: {
                    position: 'left'
                }
            })
            
            new HSShowAnimation('.js-animation-link')

                HSBsValidation.init('.js-validate', {
                onSubmit: data => {
                    data.event.preventDefault()
                    alert('Submited')
                }
            })
        
            HSBsDropdown.init()
        
            new HSGoTo('.js-go-to')

            new HSFileAttach('.js-file-attach')


            new HSAddField('.js-add-field', {

                addedField: el => {
                    el.querySelectorAll('.js-select-dynamic').forEach(item => {
                        HSCore.components.HSTomSelect.init(item)
                    })

                    HSCore.components.HSMask.init('.js-input-mask-dynamic')
                }
            })
        
            HSCore.components.HSMask.init('.js-input-mask')

            HSCore.components.HSQuill.init('.js-quill')
        
        </script>`;
            $('body').append(html);
        }, 1000);



        return () => {
            clearTimeout(tt);
        };
    }, []);

    return <>
        <HeaderLayout />
        <ProfileLayout setModal={setModal} />
        <FooterLayout page="profile" logo={logo} />
        <Goto />
        {modal}
    </>
};
