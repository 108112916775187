import React, { useEffect, useState } from 'react';
import logo from '../assets/img/logo/logo_footer.png';
import { AdminContentLoader, Goto } from '../components';
import { FooterLayout, HeaderLayout, CareerOverViewLayout } from '../layouts';
import { Helmet } from 'react-helmet-async';

const CareerOverView = () => {

    const [load, setLoad] = useState(true);
    const [modal, setModal] = useState(<></>);
    useEffect(() => {

        let tim1 = setTimeout(() => {
            setLoad(false)
        }, 2000);

        return () => {
            clearInterval(tim1);
        };
    }, []);

    return <>
        {
            load ? <AdminContentLoader /> : <>
                <HeaderLayout setModal={setModal} />

                <CareerOverViewLayout auth={true /*hasToken && hasUser ? true : false*/} />
                <FooterLayout logo={logo} />
                <Goto />
                {modal}
            </>
        }

        <Helmet>
            <link rel="stylesheet" href="../assets/css/app/bootstrap-icons/font/bootstrap-icons.css" />
            <link rel="stylesheet" href="../assets/css/app/hs-mega-menu.min.css" />
            <link rel="stylesheet" href="../assets/css/app/quill.snow.css" />
            <link rel="stylesheet" href="../assets/css/app/tom-select.css" />
            <link rel="stylesheet" href="../assets/css/app/aos.css" />
            <link rel="stylesheet" href="../assets/css/app/swiper-bundle.min.css" />
            <link rel="stylesheet" href="../assets/css/app/theme.min.css" />


            <script defer async src='../assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js'></script>
            <script defer async src='../assets/vendor/hs-header/dist/hs-header.min.js'></script>
            <script defer async src='../assets/vendor/hs-mega-menu/dist/hs-mega-menu.min.js'></script>
            <script defer async src='../assets/vendor/hs-show-animation/dist/hs-show-animation.min.js'></script>
            <script defer async src='../assets/vendor/hs-go-to/dist/hs-go-to.min.js'></script>
            <script defer async src='../assets/js/theme.min.js'></script>
            {/* <script defer async src='./assets/js/homeScript.js'></script> */}
            <title>Career Overview</title>
        </Helmet>
    </>;
}

export default CareerOverView;
