import React from 'react';
import { Circle } from 'react-circle';

const MyCircle = ({ data }) => {
    const {
        title,
        titleColor,
        circleSize,
        animate,
        time,
        lineWidth,
        evolution,
        radius,
        Percent,
        percentSymbol,
        evolutionColor,
        bgColor,
        textColor,
    } = data;
    return <div>
        <div style={{width: `${circleSize}%`, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Circle
                animate={animate} // Boolean: Animated/Static progress
                animationDuration={`${time}s`} // String: Length of animation
                responsive={true} // Boolean: Make SVG adapt to parent size
                size="100" // String: Defines the size of the circle.
                lineWidth={lineWidth} // String: Defines the thickness of the circle's stroke.
                progress={evolution} // String: Update to change the progress and percentage.
                progressColor={evolutionColor} // String: Color of "progress" portion of circle.
                bgColor={bgColor} // String: Color of "empty" portion of circle.
                textColor={textColor} // String: Color of percentage text color.
                textStyle={{
                    font: 'bold 4rem Helvetica, Arial, sans-serif' // CSSProperties: Custom styling for percentage.
                }}
                percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
                roundedStroke={radius} // Boolean: Rounded/Flat line ends
                showPercentage={Percent} // Boolean: Show/hide percentage.
                showPercentageSymbol={percentSymbol} // Boolean: Show/hide only the "%" symbol.
            />
            <span className={`fw-bold text-capitalize text-${titleColor}`} style={{fontSize: '0.8rem'}}>{title}</span>
        </div>

    </div>;
}

export default MyCircle;
