import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { store, userActions } from '../../store';
import { Toast } from '../../helpers';

const UserProjectCreate = ({ categ, setModal, lang }) => {

    const dispatch = useDispatch();

    const [projectTitle, setProjectTitle] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [projectCategory, setProjectCategory] = useState('');
    const [projectPortee, setProjectPortee] = useState(null);
    // const [projectBudget, setLProjectBudget] = useState(0);
    const [loadingSaveBtn, setLoadingSaveBtn] = useState(false);

    const handleSave = () => {

        if (projectTitle !== '') {
            if (projectCategory !== '') {
                if (projectDescription !== '') {
                    if (projectDescription.length > 50) {
                        if (projectPortee !== null) {
                            // if (parseInt(projectBudget) > 0) {

                                setTimeout(() => {
                                    dispatch(userActions.createproject({
                                        user_id: store.getState().user.user.id,
                                        name: projectTitle,
                                        status: 'PENDING',
                                        // budget: projectBudget,
                                        description: projectDescription,
                                        portee: projectPortee,
                                        categories_id: parseInt(projectCategory),
                                    }));
                                }, 3600);

                                setLoadingSaveBtn(true);

                                setTimeout(() => {
                                    setLoadingSaveBtn(false);
                                }, 4000);

                            // } else {
                            //     Toast('error', lang('items.user_project.create.errors.require', { field: `Budget error or format error` }));
                            //     // Toast('error', 'Portee field is required');
                            // }
                        } else {
                            Toast('error', lang('items.user_project.create.errors.require', { field: lang('items.user_project.create.form.lb_access') }));
                            // Toast('error', 'Portee field is required');
                        }
                    } else {
                        Toast('error', lang('items.user_project.create.errors.length', { length: 50 }));
                        // Toast('error', 'The project descriptoion must have 50 characters min');
                    }
                } else {
                    Toast('error', lang('items.user_project.create.errors.require', { field: lang('items.user_project.create.form.lb_desc_field') }));
                    // Toast('error', 'Please set the project Description');
                }
            } else {
                Toast('error', lang('items.user_project.create.errors.require', { field: lang('items.user_project.create.form.lb_category') }));
                // Toast('error', 'Please set the project category');
            }
        } else {
            Toast('error', lang('items.user_project.create.errors.require', { field: lang('items.user_project.create.form.lb_title') }));
            // Toast('error', 'Please set the project title');
        }

        // dispatch(adminActions.createproject({ jasdla: null }));
    };


    return <div className={`modal fade`} data-bs-backdrop="static" id="userCreateProject" tabIndex="-1" aria-labelledby="userCreateProjectLabel" style={{ display: 'none' }} aria-modal="true" role="dialog">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title" id="userCreateProjectLabel">{lang('items.user_project.create.title')}</h4>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal(<></>)}></button>
                </div>

                <div className="modal-body">
                    <div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-4">
                                    <label htmlFor="editCardNameLabel" className="form-label">{lang('items.user_project.create.form.lb_title')}</label>
                                    <input
                                        onChange={e => setProjectTitle(e.target.value)}
                                        type="text"
                                        className="form-control"
                                        id="editCardNameLabel"
                                        placeholder={lang('items.user_project.create.form.pl_hld_title')}
                                        aria-label={lang('items.user_project.create.form.pl_hld_title')}
                                        value={projectTitle}
                                    />
                                </div>
                            </div>

                            {/* <div className="col-md-4">
                                <div className="mb-4">
                                    <label htmlFor="editCardBudgetLabel" className="form-label">Budget</label>
                                    <input
                                        onChange={e => setLProjectBudget(e.target.value)}
                                        type="number"
                                        minLength={3}
                                        className="form-control"
                                        id="editCardBudgetLabel"
                                        placeholder="Budget"
                                        aria-label="Budget"
                                        value={projectBudget}
                                    />
                                </div>
                            </div> */}
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-4">
                                    <label htmlFor="categorySelect">{lang('items.user_project.create.form.lb_category')}</label>
                                    <select className="form-control" id="categorySelect" onChange={e => setProjectCategory(e.target.value)}>
                                        <option value={''}>{lang('items.user_project.create.form.sl_category')}</option>
                                        {
                                            categ.map((categ, key) => <option key={key} value={categ.id}>{categ.name}</option>)
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-4">
                                    <label htmlFor="access">{lang('items.user_project.create.form.lb_access')}</label>
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <div className="form-check mb-3">
                                            <input type="radio" name='r1' id="validRadio1" className="form-check-input"
                                                value={0} onChange={e => { setProjectPortee(parseInt(e.target.value)) }} />
                                            <label className="form-check-valid" htmlFor="validRadio1">{lang('items.user_project.create.form.opt_pub')}</label>
                                        </div>
                                        <div className="form-check mb-3">
                                            <input type="radio" name='r1' id="validRadio2" className="form-check-input"
                                                value={1} onChange={e => { setProjectPortee(parseInt(e.target.value)) }} />
                                            <label className="form-check-valid" htmlFor="validRadio2">{lang('items.user_project.create.form.opt_pri')}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="descriptionProjet">{lang('items.user_project.create.form.lb_desc')}</label>
                                <textarea
                                    id="descriptionProjet" className="form-control" placeholder={lang('items.user_project.create.form.pl_hld_desc')}
                                    onChange={e => setProjectDescription(e.target.value)}
                                    rows="10"
                                ></textarea>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end gap-3">
                            <button type="button" className="btn btn-white" onClick={() => setModal(<></>)} data-bs-dismiss="modal">{lang('items.user_project.create.form.btn.close')}</button>
                            {
                                loadingSaveBtn
                                    ? <button type="button" className="btn btn-primary" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        {lang('items.user_project.create.form.btn.submiting')}
                                    </button>
                                    : <button type="button" onClick={() => handleSave()} className="btn btn-primary">{lang('items.user_project.create.form.btn.submit')}</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default UserProjectCreate;
