import React, { useState } from "react";
import { CreateProjectForm } from "components/forms";
import { adminActions, store } from "store";
import { Toast } from "helpers";




const AdminProjectCreate = ({ dispatch }) => {


    const [projectTitle, setProjectTitle] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [projectCategory, setProjectCategory] = useState('');
    const [projectPortee, setProjectPortee] = useState(null);
    const [projectTeams, setProjectTeams] = useState([]);
    const [loadingSaveBtn, setLoadingSaveBtn] = useState(false);

    const handleSave = () => {

        if (projectTeams.length > 0) {
            if (projectTitle !== '') {
                if (projectCategory !== '') {
                    if (projectDescription !== '') {
                        if (projectDescription.length > 50) {
                            if (projectPortee !== null) {

                                setTimeout(() => {
                                    dispatch(adminActions.createproject({
                                        user_id: store.getState().user.user.id,
                                        name: projectTitle,
                                        status: 'PENDING',
                                        description: projectDescription,
                                        portee: projectPortee,
                                        team: projectTeams,
                                        categories_id: parseInt(projectCategory),
                                    }));
                                }, 3600);

                                setLoadingSaveBtn(true);

                                setTimeout(() => {
                                    setLoadingSaveBtn(false);
                                }, 4000);

                            } else {
                                Toast('error', 'Portee field is required');
                            }
                        } else {
                            Toast('error', 'The project descriptoion must have 50 characters min');
                        }
                    } else {
                        Toast('error', 'Please set the project Description');
                    }
                } else {
                    Toast('error', 'Please set the project category');
                }
            } else {
                Toast('error', 'Please set the project title');
            }
        } else {
            Toast('error', 'Please assign the worker(s) to the project before save');
        }

        // dispatch(adminActions.createproject({ jasdla: null }));
    };


    return <div className="modal fade" id="create-project" style={{ display: "none" }} aria-hidden="true">
        <div className="modal-dialog modal-lg">
            <div className="modal-content bg-transparent">

                <div className="modal-body">
                    {/* <button className='btn btn-info' onClick={() => handleSave()}>Test</button> */}
                    <CreateProjectForm
                        setProjectTitle={setProjectTitle}
                        setProjectDescription={setProjectDescription}
                        setProjectPortee={setProjectPortee}
                        setProjectTeams={setProjectTeams}
                        setProjectCategory={setProjectCategory}
                        handleSave={handleSave}
                        loadingSaveBtn={loadingSaveBtn}
                    />
                </div>
            </div>
        </div>
    </div>
};

export default AdminProjectCreate;