import React from 'react';


const AdminPreviewFiles = ({ src }) => {



    return <div className="modal fade" id="preview-files" style={{ display: "none" }} aria-hidden="true">
        <div className="modal-dialog modal-xl">
            <div className="modal-content bg-white">

                <div className="modal-body">
                    <div className="card bg-white">
                        <div className="card-header">
                            <h3 className="card-title">File Preview</h3>

                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-dismiss="modal" aria-label="Close" title="Close">
                                    <i className="fas fa-times"></i>
                                </button>
                            </div>
                        </div>

                        <div className="card-body" style={{ display: 'block' }}>
                            <iframe src={src} frameborder="0" style={{width: '100%', height: '400px'}}></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default AdminPreviewFiles;


