import React, { useEffect, useState } from 'react';
import Button from 'react-validation/build/button';
import Form from "react-validation/build/form";
import logo from '../assets/img/logo/apines_red.png';
import { LinkScript } from '../constants';
import { AppendScript, Toast } from '../helpers';
import AdminContentLoader from './adminContentLoader';
import $ from 'jquery';
import appConfig from '../config';
import { Helmet } from 'react-helmet-async';


const DeviceConfirmation = ({ type }) => {

    const [load, setLoad] = useState(true);
    const [showLoading, setShowLoading] = useState(false);
    const [expired, setExpired] = useState(false);



    useEffect(() => {

        let srt_url = atob(window.location.pathname.split('confirm/')[1]);
        let value = srt_url.split('_');

        let token_date = new Date(value[1]);
        let now_date = new Date();

        if (now_date.getTime() > token_date.getTime()) {
            setExpired(true);

            setTimeout(() => {
                window.close();
            }, 10000);
        }

        setTimeout(() => {
            setLoad(false);
        }, 3000);


        LinkScript('confirm-device').forEach(elt => {
            AppendScript(elt)
        });

    }, []);



    const handleSubmit = event => {
        event.preventDefault();

        setShowLoading(true);
        let token = window.location.pathname.split('confirm/')[1];


        $.get(`${appConfig.apiUrl}/device/confirm/${token}`, data => {
            if (data.status === 'success') {
                Toast('success', data.data, 'tr', false, false, false, 3000, false);
                setTimeout(() => {
                    window.close();
                }, 3100);
            }
        });

        setTimeout(() => {
            setShowLoading(false);
            setTimeout(() => {
                // window.location.href = '/signin';
            }, 200);

        }, 4000);
        // Toast('success', 'Well done.', 'tc', true, true, true, 2000);

    };


    return <>
        {
            load
                ? <AdminContentLoader />
                : <div className="row">
                    <div className="col-md-2 offset-5">
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(230, 230, 230, 0.7    )',
                            height: '100vh',
                            width: '100%',
                            position: 'fixed',
                            left: 0,
                            top: 0,
                        }}>
                            <Form onSubmit={e => handleSubmit(e)} >

                                <div className="card elevation-3 text-center">
                                    <div className="card-body">
                                        <h1 className="card-title fs-5" id="staticBackdropLabel">
                                            Device confirmation
                                        </h1>
                                        <img src={logo} alt="logo" className='mb-2' width={100} height={75} />

                                        {
                                            expired
                                                ? <>
                                                    <div className="mb-3 mt-3">
                                                        <div className="alert alert-soft-danger">
                                                            The email has expired. Please try to signin again.
                                                        </div>
                                                    </div>
                                                    <span className="btn btn-block btn-success" onClick={() => window.location.href = '/signin'}>Go to signin page</span>
                                                </>

                                                : <><div className="mb-3">
                                                    <label className="form-label" htmlFor="signupModalFormSignupName">
                                                        Please click below to confirm your device
                                                    </label>
                                                </div>
                                                    {
                                                        !showLoading
                                                            ? <Button type="submit" className="btn btn-success btn-lg fw-bold btn-block">
                                                                Confirm
                                                            </Button>
                                                            : <button type="button" className="btn btn-success btn-block" disabled={showLoading}>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                Loading
                                                            </button>
                                                    }
                                                </>
                                        }
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
        }


        <Helmet>
        <link rel="stylesheet" href="../assets/css/app/bootstrap-icons/font/bootstrap-icons.css" />
            <link rel="stylesheet" href="../assets/css/app/hs-mega-menu.min.css" />
            <link rel="stylesheet" href="../assets/css/app/quill.snow.css" />
            <link rel="stylesheet" href="../assets/css/app/tom-select.css" />
            <link rel="stylesheet" href="../assets/css/app/aos.css" />
            <link rel="stylesheet" href="../assets/css/app/swiper-bundle.min.css" />
            <link rel="stylesheet" href="../assets/css/app/theme.min.css" />

            <script defer async src='../assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js'></script>
            <script defer async src='../assets/js/theme.min.js'></script>
            <title>Device Confirmation | HAPPYNESS</title>
        </Helmet>
    </>

}

export default DeviceConfirmation;
