import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// import { Toast, history } from '../helpers';
import { fetchWrapper } from '../helpers/fetch-wrapper';
// import Swal from 'sweetalert2';
import appConfig from '../config';


// create slice

const name = 'business';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const businessActions = { ...slice.actions, ...extraActions };
export const businessReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in

        error: null,
    }
}

function createReducers() {
    return {
        logout,
        // resetSlice
    };

    // function resetSlice(state) { return createInitialState(); };

    function logout(state) {
        // history.navigate('/');
    }
}

function createExtraActions() {
    const baseUrl = `${appConfig.apiUrl}`;

    return {
        // users: allUsers(),
    };

    /**
     * Get all users
     * @method GET 
     * */
    function allUsers() {
        return createAsyncThunk(
            `${name}/get/users`,
            async () => await fetchWrapper.get(`${baseUrl}/get/all/users`)
        );
    }





















}




function createExtraReducers() {
    return (builder) => {
       // users();



        /* -------------------------------------------------------------------------- */
        /*                                    USERS                                   */
        /* -------------------------------------------------------------------------- */

        // function users() {
        //     var { pending, fulfilled, rejected } = extraActions.users;
        //     builder
        //         .addCase(pending, (state) => {
        //             state.error = null;
        //         })
        //         .addCase(fulfilled, (state, action) => {
        //             const users = action.payload.data;
        //             state.users = users;
        //         })
        //         .addCase(rejected, (state, action) => {
        //             state.error = action.error;
        //         })
        // };


    }
}