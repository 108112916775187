import React, { useEffect, useState } from 'react';
import { store, adminActions } from '../../store';
import $ from 'jquery';
import { Toast, formatDate, getFileFromBack, sliptTab } from '../../helpers';
import Swal from 'sweetalert2';
import { AssignUserToTask, EditTask } from './';
import { FileUploader, TimeProgressBarTask } from '../../components';
import axios from 'axios';



const AdminProjectEdit = ({ dispatch, project, setModal }) => {

    const [showContent, setShowContent] = useState(0);

    return <div className="modal fade" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" id="edit-project" style={{ display: "none" }} aria-hidden="true">
        <div className="modal-dialog modal-xl">
            <div className="modal-content bg-white">

                <div className="card-header">
                    <div className="card-title">Project Editor</div>
                    <div className="card-tools">
                        <button type="button" className="close text-danger" data-dismiss="modal" title="Close" aria-label="Close" onClick={() => setModal(<></>)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                </div>

                <div className="modal-body" style={{ maxHeight: 810, overflow: 'auto' }}>
                    <div className="col-12">
                        <div className="card card-light card-tabs">
                            <div className="card-header p-0 pt-1">
                                <ul className="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                                    {
                                        projectTabs.map((tab, key) => {
                                            return <li key={key} className="nav-item" onClick={() => setShowContent(key)}>
                                                <a className={`nav-link text-bold ${key === 0 ? 'active' : ''}`}
                                                    id={`custom-tabs-one-${tab.title}-tab`}
                                                    data-toggle="pill" href={`#custom-tabs-one-${tab.title}`}
                                                    role="tab" aria-controls={`custom-tabs-one-${tab.title}`}
                                                    aria-selected="true"
                                                >
                                                    <i className={`${tab.icon} mr-2`}></i>
                                                    {tab.title}
                                                </a>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="card-body bg-white" style={{ maxHeight: 700, overflow: 'auto' }}>
                                <div className="tab-content" id="custom-tabs-one-tabContent">
                                    <div className={`tab-pane fade show active`} id={`#custom-tabs-one-${projectTabs[showContent].title}`} role="tabpanel"
                                        aria-labelledby={`custom-tabs-one-${projectTabs[showContent].title}-tab`}>
                                        {projectTabs[showContent].content(project, dispatch, setModal)}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
};

export default AdminProjectEdit;





const TechnoForm = ({
    setbudget,
    setbackend_name,
    setfrontend_name,
    setbdd,
    setvirtualiseur,
    setbrocker,
    setsecurity
}) => {

    return <>
        <div className="col-md-6">
            <input
                type="number"
                id='budget'
                className="form-control mt-2 bg-white"
                placeholder="Enter the budget"
                onChange={e => setbudget(e.target.value)}
            />

        </div>
        <div className="col-md-6">
            <input
                type="text"
                id='back'
                className="form-control mt-2 bg-white"
                placeholder="Enter the back-end technology"
                onChange={e => setbackend_name(e.target.value)}
            />

        </div>
        <div className="col-md-6">
            <input
                type="text"
                id='front'
                className="form-control mt-2 bg-white"
                placeholder="Enter the front-end technology"
                onChange={e => setfrontend_name(e.target.value)}
            />

        </div>
        <div className="col-md-6">
            <input
                type="text"
                id='bdd'
                className="form-control mt-2 bg-white"
                placeholder="Enter the database technology"
                onChange={e => setbdd(e.target.value)}
            />

        </div>
        <div className="col-md-6">
            <input
                type="text"
                id='vir'
                className="form-control mt-2 bg-white"
                placeholder="Enter the virtualizer technology"
                onChange={e => setvirtualiseur(e.target.value)}
            />

        </div>
        <div className="col-md-6">
            <input
                type="text"
                id='broker'
                className="form-control mt-2 bg-white"
                placeholder="Enter the broker technology"
                onChange={e => setbrocker(e.target.value)}
            />

        </div>
        <div className="col-md-6">
            <input
                type="text"
                id='secu'
                className="form-control mt-2 bg-white"
                placeholder="Enter the security technology"
                onChange={e => setsecurity(e.target.value)}
            />

        </div>

    </>
};

const TechnoView = ({
    budget,
    backend_name,
    frontend_name,
    bdd,
    virtualiseur,
    brocker,
    security
}) => {

    return <div style={{ maxHeight: '180px', overflow: 'auto', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <div className="row">

            <div className="col-md-6">
                <p>budget: {budget !== null && budget !== '' && budget > 0 ? <span className='text-bold text-info'>${budget}</span> : <span className='text-danger'>Not set</span>}</p>
            </div>

            <div className="col-md-6">
                <p>backend: {backend_name !== null && backend_name.length > 0 ? <span className='text-bold text-info'>{backend_name}</span> : <span className='text-danger'>Not set</span>}</p>
            </div>

            <div className="col-md-6">
                <p>frontend: {frontend_name !== null && frontend_name.length > 0 ? <span className='text-bold text-info'>{frontend_name}</span> : <span className='text-danger'>Not set</span>}</p>
            </div>

            <div className="col-md-6">
                <p>database: {bdd !== null && bdd.length > 0 ? <span className='text-bold text-info'>{bdd}</span> : <span className='text-danger'>Not set</span>}</p>
            </div>

            <div className="col-md-6">
                <p>virtualiseur: {virtualiseur !== null && virtualiseur.length > 0 ? <span className='text-bold text-info'>{virtualiseur}</span> : <span className='text-danger'>Not set</span>}</p>
            </div>

            <div className="col-md-6">
                <p>brocker: {brocker !== null && brocker.length > 0 ? <span className='text-bold text-info'>{brocker}</span> : <span className='text-danger'>Not set</span>}</p>
            </div>

            <div className="col-md-6">
                <p>security: {security !== null && security.length > 0 ? <span className='text-bold text-info'>{security}</span> : <span className='text-danger'>Not set</span>}</p>
            </div>
        </div>
    </div>
};


const projectTabs = [
    {
        title: 'General',
        icon: 'fas fa-globe',
        content: (project, dispatch = null) => <General project={project} dispatch={dispatch} />
    },
    {
        title: 'Workers',
        icon: 'fa fa-users',
        content: (project, dispatch = null) => <Workers project={project} dispatch={dispatch} />
    },
    {
        title: 'Tasks',
        icon: 'fas fa-tasks',
        content: (project, dispatch = null) => <Tasks project={project} dispatch={dispatch} />
    },
    {
        title: 'Technos',
        icon: 'fa fa-laptop-code',
        content: (project, dispatch = null) => <Technologies project={project} dispatch={dispatch} />
    },
    {
        title: 'Files',
        icon: 'far fa-folder-open',
        content: (project, dispatch = null) => <Files project={project} dispatch={dispatch} />
    }
];




// 
export const General = ({ project, dispatch, setModal }) => {

    const [data, setData] = useState({
        projectName: project.name,
        projectDescription: project.description,
        projectStatus: project.status,
        projectPortee: project.portee,
        projectCategory: project.categories_id,
        projectMeetLink: "",
        projectBudget: project.budget === null ? 0 : parseInt(project.budget)
    });


    const [showLoadingGeneral, setShowLoadingGeneral] = useState(false);

    const handleChange = e => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };


    const handleSaveGeneralInfo = () => {
        setShowLoadingGeneral(true);
        dispatch(adminActions.updateproject({
            id: project.id,
            user_id: store.getState().user.user.id,
            name: data.projectName,
            description: data.projectDescription,
            status: data.projectStatus,
            portee: data.projectPortee,
            categories_id: data.projectCategory,
            meetspacelink: data.projectMeetLink,
            budget: data.projectBudget
        }));
        setTimeout(() => {
            setShowLoadingGeneral(false);
        }, 3000);
    };



    return <>

<div className="row">
            <div className="col-md-8">
                <div className="form-group">
                    <label htmlFor="inputName">Project Name:&nbsp; </label>
                    <input
                        name='projectName'
                        type="text"
                        id="inputName"
                        className={`form-control bg-white`}
                        value={data.projectName}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="col-md-3">

                <div className="form-group">
                    <label htmlFor="inputBudget">Project Budget:&nbsp; </label>
                    <input
                        name='projectBudget'
                        type="number"
                        id="inputBudget"
                        className={`form-control bg-white`}
                        value={data.projectBudget}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="col-md-1">

                <div className="form-group">
                    <label >&nbsp; </label>
                    <input
                        style={{border:'none'}}
                        type="text"
                        id="inputBudget"
                        className={`form-control bg-white`}
                        value="XAF"
                        disabled
                    />
                </div>
            </div>
        </div>




        <div className="form-group">
            <label htmlFor="inputDescription">Project Description:&nbsp;</label>

            <textarea
                id="inputDescription"
                className={`form-control bg-white`}
                rows="4"
                name='projectDescription'
                defaultValue={data.projectDescription}
                onChange={handleChange}
            ></textarea>
        </div>
        {
            project.meetspacelink === null
                ? <div className="form-group">
                    <label htmlFor="inputMeetLink">MeetSpace Work group link:&nbsp; </label>
                    <input
                        type="url"
                        id="inputMeetLink"
                        name='projectMeetLink'
                        className={`form-control bg-white`}
                        value={data.projectMeetLink}
                        placeholder='insert the MeetSpace join goup link here'
                        onChange={handleChange}
                    />
                </div>
                : null
        }

        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="inputStatus">Status:&nbsp;</label>
                    <select id="inputStatus" name='projectStatus' className={`form-control bg-white custom-select`} onChange={handleChange}>
                        <option selected={data.projectStatus === "PENDING" ? true : false} value="PENDING">PENDING</option>
                        <option selected={data.projectStatus === "APPROVED" ? true : false} value="APPROVED">APPROVED</option>
                        <option selected={data.projectStatus === "REJECTED" ? true : false} value="REJECTED">REJECTED</option>
                        <option selected={data.projectStatus === "CANCELLED" ? true : false} value="CANCELLED">CANCELLED</option>
                        <option selected={data.projectStatus === "TESTING" ? true : false} value="TESTING">TESTING</option>
                        <option selected={data.projectStatus === "FINISH" ? true : false} value="FINISH">FINISHED</option>
                    </select>
                </div>

            </div>

            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="categInput">Category:&nbsp;</label>
                    <select id="categInput" name='projectCategory' className={`form-control bg-white custom-select`} onChange={handleChange}>
                        <option value={''}>-- --</option>
                        {
                            store.getState().admin.categories
                                .map((elem, key) => {
                                    return <option selected={elem.id === data.projectCategory ? true : false} key={key} value={elem.id}>{elem.name}</option>
                                })
                        }
                    </select>
                </div>
            </div>
        </div>

        <div className="form-group">
            <label htmlFor="access">Project Access:&nbsp;&nbsp;</label>
            <input type="radio" name="portee" checked={data.projectPortee === 0 ? true : false} id="public" value={0} /> <label htmlFor="public">Public</label>&nbsp;&nbsp;&nbsp;&nbsp;
            <input type="radio" name="portee" checked={data.projectPortee === 1 ? true : false} id="private" value={1} /> <label htmlFor="private">Private</label>
        </div>

        {
            !showLoadingGeneral
                ? <button className="btn btn-primary" type='button' onClick={() => handleSaveGeneralInfo()}>Save changes</button>
                : <button type="button" className="btn btn-primary " disabled={showLoadingGeneral}>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading
                </button>
        }


    </>
};









export const Workers = ({ project, dispatch, setModal }) => {


    const [idNewUser, setIdNewUser] = useState(null);
    const [idNewManager, setIdNewManager] = useState(null);
    const [isOwn, setIsOwn] = useState(null);

    const [showLoadingUser, setShowLoadingUser] = useState(false);
    const [showLoadingManager, setShowLoadingManager] = useState(false);



    const userHasProject = user_id => {
        let arr = [], er = [];
        let user_project = store.getState().admin.projects;

        arr = [...user_project.map(elt => elt.users)]


        arr.forEach(elm => {
            er.push(...elm)
        })

        let nbr_proj = er.filter(user => user.id === user_id).length;


        return nbr_proj === 10; //Define the number of projects that the user can work on
    };



    const users = store.getState().admin.users
        .filter(user => user.id !== store.getState().user.user.id && user.roles.length > 0)
        .filter(user => user.roles[0].name !== 'user' && user.email_verified_at !== null)
        .filter(user => user.roles[0].name === 'worker' && !userHasProject(user.id));

    const handleSaveUser = () => {
        setShowLoadingUser(true);
        if (isOwn === true) {
            $('#setLeader').remove();
        }
        dispatch(adminActions.projectassignuser({ project_id: project.id, user_id: idNewUser, is_owner: isOwn !== null ? isOwn : false }));
        // console.log({ project_id: id, user_id: id_user, is_owner: is_own });
        setIsOwn(false)
        setTimeout(() => {

            setShowLoadingUser(false);
        }, 3000);
    };


    const handleSaveManager = () => {
        setShowLoadingManager(true);
        dispatch(adminActions.setmanagerproject({ user_id: idNewManager, project_id: project.id }));
        // console.log({ project_id: id, user_id: id_user, is_owner: is_own });
        setTimeout(() => {
            setShowLoadingManager(false);
        }, 3000);
    };

    const handleRemoveUserFromProject = (user) => {
        Swal.fire({
            title: `Are you sure`,
            text: `You can reassign this worker after this operation!`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Remove the worker!',
            html: `<p>Are you sure you want to remove <br /><span style="color: #28a745"> ${user.name} </span>?</p>`
        }).then((result) => {
            if (result.isConfirmed) {
                // setShowLoadingDeletingTask(true);

                setTimeout(() => {
                    // setShowLoadingDeletingTask(false);
                    // setCleDel(null);
                }, 3000);
                dispatch(adminActions.removeassigneduserproject({ user_id: user.id, projets_id: project.id }));
            }
        })
    };

    const handleRemoveManagerFromProject = (user) => {
        Swal.fire({
            title: `Are you sure`,
            text: `You can reassign this manager after this operation!`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Remove the manager!',
            html: `<p>Are you sure you want to remove <br /><span style="color: #28a745"> ${user.name} </span>?</p>`
        }).then((result) => {
            if (result.isConfirmed) {
                // setShowLoadingDeletingTask(true);

                setTimeout(() => {
                    // setShowLoadingDeletingTask(false);
                    // setCleDel(null);
                }, 3000);
                // dispatch(adminActions.removeassigneduserproject({ user_id: user.id, projets_id: project.id }));
            }
        })
    };


    const hasManager = users => {
        let ret = false;
        [...users].forEach(user => {
            if (user.role_id === 2) {
                ret = user;
            }
        });
        return ret;
    };

    const handleChooseWorker = user_id => {
        setIdNewUser(user_id);
    }

    return <div className="row">
        {/* User to a project */}
        <div className="col-md-6">
            <div className="card">
                <div className="card-header">Assign user to a project</div>
                <div className="card-body bg-white">
                    {
                        store.getState().admin.projects.filter(proj => proj.id === project.id)[0].users.length < 7
                            ? <div className="row">
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <label htmlFor="leaderProject">Select a worker (You can add {
                                            7 - store.getState().admin.projects
                                                .filter(proj => proj.id === project.id)[0].users.length
                                        } workers more)</label>
                                        <select id="leaderProject" className="form-control bg-white custom-select" onChange={e => handleChooseWorker(e.target.value)}>
                                            <option value={''}>-- --</option>
                                            {
                                                users.map((elem, key) => <option key={key} value={elem.id}>{elem.name}</option>)
                                            }
                                        </select>
                                        {
                                            store.getState().admin.projects.filter(proj => proj.id === project.id)[0].users.filter(ite => ite.pivot.is_owner === 1).length === 0
                                                ? <><div className="form-group">
                                                    <div className="custom-control custom-checkbox" id='setLeader'>
                                                        <input className="custom-control-input" type="checkbox" id="customCheckbox3" onChange={e => setIsOwn(e.target.checked)} />
                                                        <label htmlFor="customCheckbox3" className="custom-control-label">Set the worker as Leader</label>
                                                    </div>
                                                </div><br /></>
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>&nbsp;</label>
                                        {
                                            !showLoadingUser
                                                ? <button className="btn btn-success btn-block text-bold" type='button' onClick={() => handleSaveUser()}>Assign</button>
                                                : <button type="button" className="btn btn-success btn-block" disabled={showLoadingUser}>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    Loading...
                                                </button>
                                        }
                                    </div>
                                </div>
                            </div>
                            : <div className="alert alert-dismissible" style={{ backgroundColor: '#f2d1d1' }}>
                                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                                <h5 className='text-danger'><i className="icon fas fa-ban text-danger"></i> Alert!</h5>
                                This project has the require number of workers
                            </div>
                    }

                    {/* Project users */}
                    <div className="row" style={{ maxHeight: '350px', overflow: 'auto', padding: 4 }}>
                        {
                            store.getState().admin.projects
                                .filter(proj => proj.id === project.id)[0].users
                                .map((user, key) => {
                                    return <div key={key} className="col-md-3 elevation-1 ml-2 mt-2" style={{ maxWidth: '24%', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', borderRadius: 5, height: '30%' }}>
                                        <img src={getFileFromBack(user.avatar)} alt='user avatar' title={user.name} style={{ borderRadius: '50%', height: 80, width: 80, alignSelf: 'center' }} className="elevation-2 mr-1" />
                                        <span className='text-center'>{user.first_name}
                                            <i className="bi bi-trash text-danger" style={{ display: 'flex', cursor: 'pointer', flexDirection: 'row', justifyContent: 'center' }} title='Remove this user' onClick={() => handleRemoveUserFromProject(user)}></i>
                                        </span>
                                    </div>
                                })
                        }
                    </div>
                </div>

            </div>
        </div>


        {/* Manager to a project */}
        <div className="col-md-6">
            <div className="card">
                <div className="card-header">Assign Manager to a project</div>
                <div className="card-body bg-white">
                    {
                        hasManager(store.getState().admin.projects.filter(proj => proj.id === project.id)[0].users) === false
                            ? <div className="row">
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <label htmlFor="managerProject">Select a manager</label>
                                        <select id="managerProject" className="form-control bg-white custom-select" onChange={e => setIdNewManager(e.target.value)}>
                                            <option value={''}>-- --</option>
                                            {
                                                store.getState().admin.users
                                                    .filter(user => user.id !== store.getState().user.user.id && user.roles.length > 0)
                                                    .filter(user => user.roles[0].name !== 'user' && user.email_verified_at !== null)
                                                    .filter(user => user.roles[0].name === 'manager' /*&& !userHasProject(user.id)*/)
                                                    .map((elem, key) => <option key={key} value={elem.id}>{elem.name}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>&nbsp;</label>
                                        {
                                            !showLoadingManager
                                                ? <button className="btn btn-success btn-block text-bold" type='button' onClick={() => handleSaveManager()}>Assign</button>
                                                : <button type="button" className="btn btn-success btn-block" disabled={showLoadingManager}>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    Loading...
                                                </button>
                                        }
                                    </div>
                                </div>
                            </div>
                            : <div className="col-md-3 elevation-1 ml-2 mt-2" style={{ maxWidth: '24%', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', borderRadius: 5, height: '30%' }}>
                                <img src={getFileFromBack(
                                    hasManager(store.getState().admin.projects.filter(proj => proj.id === project.id)[0].users)
                                        .avatar
                                )} alt='user avatar' title={hasManager(
                                    store.getState().admin.projects.filter(proj => proj.id === project.id)[0].users
                                ).name} style={{ borderRadius: '50%', height: 80, width: 80, alignSelf: 'center' }} className="elevation-2 mr-1" />
                                <span className='text-center'>{hasManager(
                                    store.getState().admin.projects.filter(proj => proj.id === project.id)[0].users
                                ).first_name}
                                    <i className="bi bi-trash text-danger"
                                        style={{ display: 'flex', cursor: 'pointer', flexDirection: 'row', justifyContent: 'center' }}
                                        title='Remove this user'
                                        onClick={() => handleRemoveManagerFromProject(
                                            hasManager(
                                                store.getState().admin.projects.filter(proj => proj.id === project.id)[0].users
                                            ).user)}></i>
                                </span>
                            </div>
                    }
                </div>

            </div>
        </div>
    </div>
};

export const Files = ({ project, dispatch, setModal }) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${store.getState().auth.token}`;

    const [arrFiles, setArrFiles] = useState([]);


    const handleSendFiles = () => {
        const data = new FormData();
        const url = `${process.env.REACT_APP_API_URL}/projects/upload/avatar/${project.id}`;
        if (arrFiles.length > 0) {
            for (let i = 0; i < arrFiles.length; i++) {
                data.append("files[]", arrFiles[i]);
            }
            axios
                .post(url, data, {
                    // receive two parameter endpoint url ,form data
                })
                .then((res) => {
                    Swal.fire(
                        'Uploaded!',
                        'Files have been uploaded successfully.',
                        'success'
                    );
                    $('body').attr('class', 'hold-transition dark-mode sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed');
                    $('body').attr('style', '');
                    $('#edit-project').css('display', 'none');
                    $('.modal-backdrop').remove();
                    setModal(<></>)
                    console.log({ message: res.data.message, paths: res.data.uploaded_files });
                }, error => {
                    console.log(error);
                });
        } else {
            Toast('error', 'Please browse the files that you want to upload', 'tr', false, false, false, 3000, false);
        }
    };




    return <div className="col-md-12">
        <FileUploader setArrFiles={setArrFiles} />
        <br />
        <div className="row">
            <div className="col-md-4 offset-4">
                <button className='btn btn-primary btn-block btn-lg' onClick={() => handleSendFiles()}>Send files</button>
            </div>
        </div>
    </div>
};



export const Technologies = ({ project, dispatch }) => {


    const [budget, setbudget] = useState(0);
    const [backend_name, setbackend_name] = useState('');
    const [frontend_name, setfrontend_name] = useState('');
    const [bdd, setbdd] = useState('');
    const [virtualiseur, setvirtualiseur] = useState('');
    const [brocker, setbrocker] = useState('');
    const [security, setsecurity] = useState('');

    const handleSaveTechologies = () => {
        Swal.fire({
            title: `Are you sure`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, add the technology!',
            html: `<p>Are you sure you want to add the technology?</p>`
        }).then((result) => {
            if (result.isConfirmed) {
                // setShowLoadingDeletingTask(true);

                setTimeout(() => {
                    // setShowLoadingDeletingTask(false);
                    // setCleDel(null);
                }, 3000);
                dispatch(adminActions.createtechnology({
                    project_id: project.id,
                    budget,
                    backend_name,
                    frontend_name,
                    bdd,
                    virtualiseur,
                    brocker,
                    security
                }));
            }
        })
    };

    return <div className="col-md-5">
        <div className='card'>
            <div className="card-header">Set the project technologies</div>

            <div className="card-body bg-white">
                <TechnoView
                    budget={budget}
                    backend_name={backend_name}
                    frontend_name={frontend_name}
                    bdd={bdd}
                    virtualiseur={virtualiseur}
                    brocker={brocker}
                    security={security}
                />
                <div className="row">

                    <TechnoForm
                        setbudget={setbudget}
                        setbackend_name={setbackend_name}
                        setfrontend_name={setfrontend_name}
                        setbdd={setbdd}
                        setvirtualiseur={setvirtualiseur}
                        setbrocker={setbrocker}
                        setsecurity={setsecurity}
                    />
                </div>
                <div className="row">
                    <button className="btn btn-block btn-outline-success mt-2" onClick={() => handleSaveTechologies()}>Save</button>
                </div>
            </div>


        </div>
    </div>
};

export const Tasks = ({ project, dispatch }) => {
    const today = new Date().toISOString().split("T")[0];


    const [tasks, setTasks] = useState([]);
    const [taskName, setTaskName] = useState('');
    const [taskDescription, setTaskDescription] = useState('');
    const [taskStartDate, setTaskStartDate] = useState(today);
    const [taskEndDate, setTaskEndDate] = useState('');

    const [storedTasks, setStoredTasks] = useState(store.getState().admin.projecttasks);

    const [showLoadingTask, setShowLoadingTask] = useState(false);
    const [showLoadingCheckingTask, setShowLoadingCheckingTask] = useState(false);
    const [showLoadingDeletingTask, setShowLoadingDeletingTask] = useState(false);
    const [showLoadingRestoringTask, setShowLoadingRestoringTask] = useState(false);
    const [cle, setCle] = useState(null);
    const [cleDel, setCleDel] = useState(null);
    const [cleRest, setCleRest] = useState(null);


    const [modalAssignUser, setModalAssignUser] = useState(<></>);

    const handleTaskNameChange = value => setTaskName(value);
    const handleTaskDescriptionChange = value => setTaskDescription(value);
    const handleTaskStartDateChange = value => {
        setTaskStartDate(value)
    };
    const handleTaskEndDateChange = value => {
        setTaskEndDate(value)
    };


    const handleAddTask = task => {
        if (tasks.length === 0) {
            setTasks([task]);
        } else {
            tasks.forEach(item => {
                if (item.name === task.name && item.description === task.description) {
                    Toast('warning', 'This task has already added', 'tr', false, false, true, 3000, false);
                    setTasks([...tasks]);

                } else {
                    setTasks([...tasks, task]);
                }
            });
        }

    };

    const handleRemoveTask = task => {
        setTasks(tasks.filter(item => item.name !== task.name));
    };

    useEffect(() => {

        let tim = setInterval(() => {
            setStoredTasks(store.getState().admin.projecttasks);
        }, 300);


        let time = setInterval(() => {
            dispatch(adminActions.projecttasks({ project_id: project.id }));
        }, 5000);

        return () => {
            clearInterval(tim);
            clearInterval(time);
        };
    }, [dispatch, project.id]);

    const handleSaveTasks = () => {
        setShowLoadingTask(true);

        dispatch(adminActions.createtaks({ tasks, projects_id: project.id }));
        // console.log({ project_id: id, user_id: id_user, is_owner: is_own });
        // setIsOwn(false)
        setTimeout(() => {

            setShowLoadingTask(false);
        }, 3000);
    };

    const handleCheckTask = (task, key) => {
        setShowLoadingCheckingTask(true);
        setCle(key);
        dispatch(adminActions.completetask({
            task_id: task.id,
            name: task.name,
            description: task.description,
            start_date: task.start_date,
            end_date: task.end_date,
            projects_id: task.projects_id,
            completed: true
        }));
        setTimeout(() => {
            setShowLoadingCheckingTask(false);
            setCle(null);
        }, 6000);
    };


    const handleRestoreTask = (task, key) => {

        Swal.fire({
            title: `Are you sure`,
            text: `You won't be able to revert this operation!`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, restore it!',
            html: `<p>Are you sure you want to restore <br /><span style="color: #28a745">This task?</span></p>`
        }).then((result) => {
            if (result.isConfirmed) {
                setShowLoadingRestoringTask(true);
                setCleRest(key);
                dispatch(adminActions.completetask({
                    task_id: task.id,
                    name: task.name,
                    description: task.description,
                    start_date: task.start_date,
                    end_date: task.end_date,
                    projects_id: task.projects_id,
                    completed: false
                }));
                setTimeout(() => {
                    setShowLoadingRestoringTask(false);
                    setCleRest(null);
                }, 6000);
            }
        })

    };


    const handleDeleteTask = (task, key) => {

        Swal.fire({
            title: `Are you sure`,
            text: `You won't be able to revert this operation!`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            html: `<p>Are you sure you want to delete <br /><span style="color: #28a745">This task?</span></p>`
        }).then((result) => {
            if (result.isConfirmed) {
                setShowLoadingDeletingTask(true);
                setCleDel(key);

                setTimeout(() => {
                    setShowLoadingDeletingTask(false);
                    setCleDel(null);
                }, 3000);

                dispatch(adminActions.deletetask({ id: task.id }));
            }
        })
    };

    const openModal = task => {
        setModalAssignUser(<></>);
        setModalAssignUser(<AssignUserToTask dispatch={dispatch} task={task} project={project} />);
    };

    const openModalEdit = task => {
        setModalAssignUser(<></>);
        setModalAssignUser(<EditTask task={task} project={project} setModal={setModalAssignUser} />);
    };

    const handleCancelTaskWorker = task => {
        Swal.fire({
            title: `Are you sure`,
            icon: 'question',
            text: 'You want to cancel the task',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, cancel it!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(adminActions.workerCompleteTask({ task_id: task.id, is_completed: 0 }));
            }
        })
    };



    return <div className="row">

        {/* Build tasks */}
        <div className="col-md-12">
            <div className='card'>
                <div className="card-header">Build project task</div>

                <div className="card-body bg-white">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col md-12">
                                    <div className="form-group">
                                        <label htmlFor="taskName">Task Name:&nbsp; </label>
                                        <input
                                            type="text"
                                            id="taskName"
                                            className={`form-control bg-white`}
                                            value={taskName}
                                            onChange={e => handleTaskNameChange(e.target.value)}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="taskDescription">Task Description:&nbsp;</label>

                                        <textarea
                                            id="taskDescription"
                                            className={`form-control bg-white`}
                                            rows="4"
                                            defaultValue={taskDescription}
                                            onChange={e => handleTaskDescriptionChange(e.target.value)}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="start-date">Start date</label>
                                        <div className="input-group date" id="start-date" data-target-input="nearest">
                                            <input
                                                type="date"
                                                className="form-control bg-white datetimepicker-input"
                                                data-target="#start-date"
                                                onChange={e => handleTaskStartDateChange(e.target.value)}
                                                min={today}
                                                value={taskStartDate}
                                            />
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="end-date">End date</label>
                                        <div className="input-group date" id="end-date" data-target-input="nearest">
                                            <input
                                                type="date"
                                                className="form-control bg-white datetimepicker-input"
                                                data-target="#end-date"
                                                onChange={e => handleTaskEndDateChange(e.target.value)}
                                                min={taskStartDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <button className="btn btn-primary btn-block" onClick={() => handleAddTask({
                                        projects_id: parseInt(project.id),
                                        name: taskName,
                                        description: taskDescription,
                                        start_date: taskStartDate,
                                        end_date: taskEndDate,
                                    })}>Add</button>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-5" style={{ maxHeight: '320px', padding: 5, overflow: 'auto' }}>
                            <div className="row">

                                {
                                    tasks.map((task, key) => {
                                        return <div key={key} style={{ listStyleType: 'none' }} className='col-md-12'>
                                            <div className='callout callout-info bg-white' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                {task.name}
                                                <i className="fa fa-times text-danger" style={{ cursor: 'pointer' }} title='Remove' onClick={() => handleRemoveTask(task)}></i>
                                            </div>
                                            <hr />
                                        </div>
                                    })
                                }


                            </div>
                        </div>
                        <div className="col-md-4 offset-4">
                            {
                                !showLoadingTask
                                    ? <button className="btn btn-success btn-block" onClick={() => handleSaveTasks()}>Save</button>
                                    : <button type="button" className="btn btn-success btn-block " disabled={showLoadingTask}>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Saving
                                    </button>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className='card'>
                <div className="card-header">
                    {storedTasks.filter(task => task.completed === 1).length}&nbsp;
                    {storedTasks.filter(task => task.completed === 1).length > 1 ? 'tasks' : 'task'}&nbsp;
                    completed on&nbsp;
                    {storedTasks.filter(task => task).length}
                </div>
                <div className="card-body bg-white">
                    {
                        sliptTab(storedTasks, 2).map((item, key) =>
                            <div className="row" key={key}>
                                {
                                    item.map((task, ky) => <div key={ky} className="col-md-6">
                                        <div className="card">
                                            <div className="card-body p-1 bg-white">
                                                <h5>
                                                    <span className='text-info text-bold'>{task.name}</span>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between'
                                                    }}>
                                                        {
                                                            task.completed !== 1 && task.worker_completed === 1
                                                                ? <span>
                                                                    <span className='my-alert my-alert-warning'> <i className='fa fa-bell'></i> Worker has finished</span>
                                                                    <i className="bi bi-trash text-danger ml-1"
                                                                        style={{ cursor: 'pointer' }}
                                                                        title='Cancle the task'
                                                                        onClick={() => handleCancelTaskWorker(task)}
                                                                    ></i>
                                                                </span>
                                                                : null
                                                        }
                                                        <span className={`badge badge-${task.completed === 1 ? 'success' : 'danger'}`}>
                                                            {task.completed === 1 ? <><i className='far fa-check-circle'></i> {`Completed`}</> : 'Not completed'}
                                                        </span>
                                                    </div>
                                                    <TimeProgressBarTask task={task} />

                                                </h5>

                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <div className='mr-1'>
                                                        <span style={{ color: '#05c438', fontSize: 11, fontWeight: 'bold' }}>{formatDate(task.start_date)}</span>&nbsp;&nbsp;
                                                        <span style={{ color: '#f25959', fontSize: 11, fontWeight: 'bold' }}>{formatDate(task.end_date)}</span>
                                                        <p className='text-muted'>
                                                            {task.description}
                                                        </p>
                                                        <p style={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                                                            {
                                                                task.users.map((user, key) => {
                                                                    return <img key={key} src={getFileFromBack(user.avatar)} title={user.name + ' ' + user.first_name} className='img-circle elevation-2' style={{ height: 50 }} alt="Avtr" />
                                                                })
                                                            }
                                                        </p>
                                                    </div>

                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                        <span
                                                            className={`btn btn-outline-info btn-sm mb-1`}
                                                            data-toggle="modal"
                                                            data-target="#user-to-task"
                                                            onMouseOver={() => openModal(task)}
                                                        >
                                                            <i className="fa fa-cog"></i>
                                                        </span>

                                                        {
                                                            task.completed !== 1
                                                                ? <>
                                                                    {
                                                                        task.worker_completed !== 1
                                                                            ? <span className="btn btn-outline-primary btn-sm mb-1" title='Edit this task' onMouseOver={() => openModalEdit(task)}><i className="fa fa-edit" data-toggle="modal" data-target="#edit-task"></i></span>
                                                                            : null
                                                                    }
                                                                    {/* {   //update a task
                                                                                ? 
                                                                        showLoadingCheckingTask && cle === task.id
                                                                            ? <span type="button" className="btn btn-outline-success mb-1" disabled={showLoadingCheckingTask}>
                                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                            </span>
                                                                            : task.worker_completed === 1
                                                                                : null
                                                                    } */}

                                                                    {   //Checking a task
                                                                        showLoadingCheckingTask && cle === task.id
                                                                            ? <span type="button" className="btn btn-outline-success mb-1" disabled={showLoadingCheckingTask}>
                                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                            </span>
                                                                            : task.worker_completed === 1
                                                                                ? <span className="btn btn-outline-success btn-sm mb-1" title='Validate this task' onClick={() => handleCheckTask(task, task.id)}><i className="fa fa-check"></i></span>
                                                                                : null
                                                                    }
                                                                    {
                                                                        task.worker_completed !== 1
                                                                            ? showLoadingDeletingTask && cleDel === task.id
                                                                                ? <span type="button" className="btn btn-outline-danger btn-block " disabled={showLoadingDeletingTask}>
                                                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                                </span>
                                                                                : <span className="btn btn-outline-danger btn-sm" title='Delete this task' onClick={() => handleDeleteTask(task, task.id)}><i className="fa fa-trash"></i></span>
                                                                            : null
                                                                    }

                                                                </>
                                                                :
                                                                showLoadingRestoringTask && cleRest === task.id
                                                                    ? <span type="button" className="btn btn-outline-warning btn-block " disabled={showLoadingRestoringTask}>
                                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                    </span>
                                                                    : <span className="btn btn-outline-warning btn-sm" title='Uncheck this task' onClick={() => handleRestoreTask(task, task.id)}><i className="bi bi-arrow-90deg-left"></i></span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                }
                            </div>

                        )
                    }
                </div>
            </div>
        </div>
        {modalAssignUser}
    </div>


};
