    import React, { useEffect, useState } from 'react';
    import $ from 'jquery';
    import { Slang, parseUserAgent } from '../helpers';
    import { AdminContentLoader } from 'components';
    import { Helmet } from 'react-helmet-async';
    import { useLocation } from 'react-router-dom';
    import { useDispatch, useSelector } from 'react-redux';
    import { authActions } from '../store';
    import Fingerprint2 from 'fingerprintjs2';
    import appConfig from '../config';


    const GoogleCallback = () => {
        const [load, setLoad] = useState(true);

        const user = useSelector(x => x.user.user);

        const location = useLocation();

        const dispatch = useDispatch();
        const [fp, setFp] = useState('');

        useEffect(() => {
            Fingerprint2.get(components => {
                const values = components.map((component) => component.value);
                const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
                setFp(fingerprint);
                sessionStorage.setItem('fp', fingerprint);
            });


            // setTimeout(() => {
            $.getJSON(appConfig.apip, (datas) => {
                setTimeout(() => {

                    parseUserAgent(datas, datas.ip || datas.ipString, user.id)
                        .then(data => {
                            dispatch(authActions.google_login_callback({
                                ip: datas.ip || datas.ipString,
                                data: location.search,
                                device: data,
                                fingerprint: sessionStorage.getItem('fp')
                            }));
                        });

                }, 3000);
            });
            // }, 4000);

            let tt = setTimeout(() => {
                $('body').addClass('d-flex flex-column justify-content-center align-items-center h-100');
                $('html').addClass('h-100');
            }, 200);


            let tim = setTimeout(() => {
                setLoad(false);
            }, 3000);

            return () => {
                clearTimeout(tim);
                clearTimeout(tt);
                $('body').removeAttr('class');
                $('html').removeAttr('class');
            };
        }, [])

        const t = Slang('page.waiting_device_conf');

        return <>
            {
                load
                    ? <AdminContentLoader />
                    : <>
                        <header id="header" className="navbar navbar-expand navbar-light navbar-absolute-top">
                            <div className="container">
                                <nav className="navbar-nav-wrap">
                                    <span className="navbar-brand" aria-label="Front">
                                        <img className="navbar-brand-logo" src={require('../assets/img/logo/apines_white.png')} alt="Logo" />
                                    </span>
                                </nav>
                            </div>
                        </header>

                        <main id="content" role="main">
                            <div className="container text-center">

                                <div className="mb-4">
                                    <div className="spinner-border spinner-border-sm text-info" role="status"></div>
                                </div>

                            </div>
                        </main>

                        <footer className="position-absolute start-0 end-0 bottom-0">
                            <div className="container pb-5 content-space-b-sm-1">
                                <div className="row justify-content-between align-items-center">
                                    <div className="col-sm mb-3 mb-sm-0">
                                        <p className="small mb-0">&copy; HappyNess. 2023.</p>
                                    </div>

                                    <div className="col-sm-auto">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item">
                                                <a className="btn btn-soft-secondary btn-xs btn-icon" href="https://www.facebook.com/profile.php?id=100080508300409" target='_blank'>
                                                    <i className="bi-facebook"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </footer>

                    </>
            }
            <Helmet>
                <link rel="stylesheet" href="../assets/css/app/bootstrap-icons/font/bootstrap-icons.css" />
                <link rel="stylesheet" href="../assets/css/app/hs-mega-menu.min.css" />
                <link rel="stylesheet" href="../assets/css/app/quill.snow.css" />
                <link rel="stylesheet" href="../assets/css/app/tom-select.css" />
                <link rel="stylesheet" href="../assets/css/app/aos.css" />
                <link rel="stylesheet" href="../assets/css/app/swiper-bundle.min.css" />
                <link rel="stylesheet" href="../assets/css/app/theme.min.css" />

                <script defer async src='../assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js'></script>
                <script defer async src='../assets/js/theme.min.js'></script>
                <title>Auth Google | HAPPYNESS</title>
            </Helmet>

        </>

    }


    export default GoogleCallback;
