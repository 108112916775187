import React, { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { useDispatch } from 'react-redux';
import { store } from 'store';
import { getFileFromBack } from 'helpers';
import DashContent from './dashContent';
import ProjectContent from './projectContent';
import WorkerContent from './workerContent';
import InboxMailContent from './inboxMailContent';


const SidebarAdmin = ({ setContent, setModal }) => {

    const [active, setActive] = useState('dash');
    const dispatch = useDispatch();


    const handleSetContent = type_content => {

        setActive(type_content);

        if (type_content === 'dash') {
            setContent({
                icon: 'tachometer-alt',
                name: 'Dashboard',
                component: () => <DashContent />
            });
        }

        if (type_content === 'projects') {
            // dispatch(adminActions.projects());
            // dispatch(adminActions.categories());
            setContent({
                icon: 'book',
                name: 'Projects',
                component: () => <ProjectContent/>
            });
        }

        if (type_content === 'worker') {
            setContent({
                icon: 'users',
                name: 'Workers',
                component: () => <WorkerContent setModal={setModal}/>
            });
        }

        if (type_content === 'inbox') {
            setContent({
                icon: 'envelope',
                name: 'HappyNess Mailer',
                component: () => <InboxMailContent />
            });
        }

    };

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <Link to="/" className="brand-link">
                <img src={require('../../assets/img/logo/apines_white.png')} alt="AdminLTE Logo" className="brand-image" style={{ opacity: .8 }} />
                <span className="brand-text font-weight-light fw-bold">HappyNess</span>
            </Link>

            <div className="sidebar">
                <div className="mt-3 pb-0 mb-1 d-flex" style={{ justifyContent: 'center' }}>
                    <div>
                        <img src={getFileFromBack(store.getState().user.user.avatar)} className="img-circle elevation-1" style={{ height: 70 }} alt="User_picture" />
                    </div>
                    <div className='mt-3 pb-3 mb-1 d-flex ml-3' style={{ justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', flexDirection: 'column' }}>
                        <div className="info">
                            <span className="d-block text-info text-bold">{
                                store.getState().user.user.first_name
                            }</span>
                        </div>
                        Manager
                    </div>
                </div>

                <div className="form-inline">
                    <div className="input-group" data-widget="sidebar-search">
                        <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                        <div className="input-group-append">
                            <button className="btn btn-sidebar">
                                <i className="fas fa-search fa-fw"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <li className="nav-item">
                            <span style={{ cursor: 'pointer' }} className={`nav-link ${active === 'dash' ? 'active' : null}`} onClick={() => handleSetContent('dash')}>
                                <i className="nav-icon fas fa-tachometer-alt"></i>
                                <p>Dashboard</p>
                            </span>
                        </li>

                        <li className="nav-item">
                            <span style={{ cursor: 'pointer' }} className={`nav-link ${active === 'inbox' ? 'active' : null}`} onClick={() => handleSetContent('inbox')}>
                                <i className="nav-icon fas fa-envelope"></i>
                                <p>Inbox</p>
                            </span>
                        </li>

                        <li className="nav-item">
                            <span style={{ cursor: 'pointer' }} className={`nav-link ${active === 'projects' ? 'active' : null}`} onClick={() => handleSetContent('projects')}>
                                <i className="nav-icon fas fa-book"></i>
                                <p>Projects</p>
                            </span>
                        </li>

                        <li className="nav-item">
                            <span style={{ cursor: 'pointer' }} className={`nav-link ${active === 'worker' ? 'active' : null}`} onClick={() => handleSetContent('worker')}>
                                <i className="nav-icon fas fa-users"></i>
                                <p>Workers</p>
                            </span>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    );
};

export default SidebarAdmin;
