import React, { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { projectsSliptTab } from '../helpers';
import logoW from '../assets/img/logo/logo_header.png';
import logoR from '../assets/img/logo/logoRhSlog.png';
import { Slang } from '../helpers';


const NavBar = ({ logo = null }) => {
    const [here, setHere] = useState(window.location.pathname);
    const [mobileMenu, setMobileMenu] = useState(false);
    const [distanceScrolled, setDistanceScrolled] = useState(0);
    const nb = Slang('navbar');


    useEffect(() => {
        setHere(window.location.pathname);
    }, []);

    window.onscroll = () => {
        setDistanceScrolled(document.documentElement.scrollTop);
    };

    return (
        <div className="container bg-transparent">
            <nav className="js-mega-menu navbar-nav-wrap">
                <Link className="navbar-brand" to="/" aria-label="Front" >
                    {
                        (distanceScrolled < 1025 && here === '/blog') ? (
                            <img className="navbar-brand-logo-header" src={logo === null ? logoW : logo} alt="Logo" />
                        ) : (
                            <img className="navbar-brand-logo-header" src={logo === null ? logoW : logo} alt="Logo" />
                        )
                    }
                </Link>

                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={() => setMobileMenu(!mobileMenu)}
                    data-bs-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-default">
                        {mobileMenu ? <i className="bi-x"></i> : <i className="bi-list"></i>}
                    </span>
                </button>

                <div className={mobileMenu ? "collapse navbar-collapse show" : "collapse navbar-collapse"} id="navbarNavDropdown tre">
                    <div className="navbar-absolute-top-scroller">
                        <ul className="navbar-nav">

                            <li className="nav-item">
                                <Link className={`nav-link fw-bold ${here === '/' ? 'active' : (here === '/blog' && distanceScrolled < 1025) ? 'text-light' : ''}`} to="/" >{nb('t1')}</Link>
                            </li>

                            <li className="hs-has-mega-menu nav-item">
                                <Link id="demosMegaMenu" className={`fw-bold hs-mega-menu-invoker nav-link dropdown-toggle ${(here === '/blog' && distanceScrolled < 1025) ? 'text-light' : ''}`} aria-current="page" to="#/" role="button">{nb('t2')}</Link>

                                <div className="hs-mega-menu dropdown-menu w-100" aria-labelledby="demosMegaMenu" style={{ minWidth: "40rem" }}>

                                    {
                                        projectsSliptTab().map((item, key) => (
                                            <div key={key} className="navbar-dropdown-menu-promo">
                                                {
                                                    item.map((elt, cle) => (
                                                        <div className="navbar-dropdown-menu-promo-item" key={cle}>
                                                            <Link className={`navbar-dropdown-menu-promo-link ${elt.disabled ? 'disabled' : ''}`} to={'https://' + elt.link}>
                                                                <div className="d-flex">
                                                                    <div className="flex-shrink-0">
                                                                        <span className={`svg-icon svg-icon-sm text-${elt.disabled ? 'dark' : 'primary'}`}>
                                                                            {elt.iconSvg()}
                                                                        </span>
                                                                    </div>

                                                                    <div className="flex-grow-1 ms-3">
                                                                        <span className="navbar-dropdown-menu-media-title">{elt.name} {elt.hot(elt.disabled)}</span>
                                                                        <p className="navbar-dropdown-menu-media-desc">{elt.description}</p>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        ))
                                    }

                                </div>
                            </li>

                            <li className="nav-item">
                                <Link className={`nav-link fw-bold ${here === '/careers' ? 'active' : (here === '/blog' && distanceScrolled < 1025) ? 'text-light' : ''}`} to="/careers" >{nb('t3')}</Link>
                            </li>

                            {/* <li className="nav-item">
                                <Link className={`nav-link fw-bold ${(here === '/blog' && distanceScrolled < 1025) ? 'active text-light' : ''}`} to="/blog" >{nb('t4')}</Link>
                            </li> */}

                            <li className="nav-item">
                                <Link className={`nav-link fw-bold ${here === '/about' ? 'active' : (here === '/blog' && distanceScrolled < 1025) ? 'text-light' : ''}`} to="/about" >{nb('t5')}</Link>
                            </li>

                            <li className="nav-item">
                                <Link className={`nav-link fw-bold ${here === '/contact' ? 'active' : (here === '/blog' && distanceScrolled < 1025) ? 'text-light' : ''}`} to="/contact" >{nb('t6')}</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default NavBar;
