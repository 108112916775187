import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../helpers/fetch-wrapper';
import { Toast, history, retrieveUserInProject } from '../helpers';
import Swal from 'sweetalert2';
import appConfig from '../config';



// create slice

const name = 'user';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const userActions = { ...slice.actions, ...extraActions };
export const usersReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        user: {},
        error: {},
        twofact: null,
        twoFactChecked: null,
        twoFactActivated: null,
        devices: [],
        allprojects: [],
        projects: [],
        projectdisplaytasks: [],
        projecttasks: [],
        projecttech: [],
        categories: [],
        paiements: { from_back: false, data: [] },
    }
}

function createReducers() {
    return {
        logout,
        resetSlice
    };

    function logout(state) {
        state.user = {};
        state.twofact = null;
        state.twoFactActivated = null;
        state.twoFactChecked = null;
        state.qr = null;
        state.projectdisplaytasks = [];
        state.devices = [];
        state.paiements = { from_back: false, data: [] };
        localStorage.removeItem('token');
        localStorage.removeItem('ftoken');
        localStorage.removeItem('emailResetPass');
        localStorage.removeItem('emailLogin');
        localStorage.removeItem('admin_project_actions');
        localStorage.removeItem('admin_user_actions');
        localStorage.removeItem('user');
        sessionStorage.removeItem('applyJob');
        sessionStorage.removeItem('passVerif');
        sessionStorage.removeItem('projects_files');
        sessionStorage.removeItem('tasks');
    }

    function resetSlice(state) { return createInitialState(); };
}

function createExtraActions() {
    const base_Url = `${appConfig.apiUrl}`;
    const baseUrl = `${appConfig.apiUrl}/getuser`;
    const baseVerifEmail = `${appConfig.apiUrl}/active/account`;

    return {
        getUser: getUser(),
        verifEmail: verifEmail(),
        resendCode: resendCode(),
        verifpass: verifPass(),
        enable2fa: enable2fa(),
        disable2fa: disable2fa(),
        verif2facode: verif2facode(),
        resetPassword: resetPassword(),
        getdevices: getDevices(),
        get_task: getTasks(),
        logoutdevice: disconnectDevice(),
        projects: getProjectsWorkOnList(),
        usertasks: getUserTaskOnProject(),
        projecttasks: projectTasks(),
        workerCompleteTask: workerCompleteTask(),
        createproject: createProject(),
        categories: allCategories(),
        gettechnology: getTechnology(),
        getuserpaiements: getUserPaiements(),
    };

    function getUser() {
        return createAsyncThunk(
            `${name}/getuser`,
            async () => await fetchWrapper.get(baseUrl)
        );
    }

    function verifEmail() {
        return createAsyncThunk(
            `${name}/verif/email`,
            async ({ pin }) => await fetchWrapper.post(baseVerifEmail, { pin })
        );
    }

    function resendCode() {
        return createAsyncThunk(
            `${name}/register/resend/pin`,
            async ({ email }) => await fetchWrapper.post(
                `${base_Url}/resent/register/active/code`,
                { email }
            )
        );
    }

    function verifPass() { // to set up the 2Fact verification
        return createAsyncThunk(
            `${name}/2fact/verif`,
            async ({ user_id, password }) => await fetchWrapper.post(`${base_Url}/confirm-password`, { user_id, password })
        );
    }

    function enable2fa() { // to set up the 2Fact verification
        return createAsyncThunk(
            `${name}/enable/2fa`,
            async () => await fetchWrapper.get(`${base_Url}/enable/two/2fauth`)
        );
    }

    function disable2fa() { // to set up the 2Fact verification
        return createAsyncThunk(
            `${name}/disable/2fa`,
            async () => await fetchWrapper.get(`${base_Url}/disable/two/2fauth`)
        );
    }

    function verif2facode() { // to set up the 2Fact verification
        return createAsyncThunk(
            `${name}/verif/2fa/code`,
            async ({ code }) => await fetchWrapper.post(`${base_Url}/valide/2FA/code`, { code })
        );
    }

    function resetPassword() {
        return createAsyncThunk(
            `${name}/update/password/from/profil`,
            async ({
                oldpassword,
                password,
                password_confirmation
            }) => await fetchWrapper.post(`${base_Url}/update/user/auth`, {
                oldpassword,
                password,
                password_confirmation
            })
        );
    }

    function getDevices() {
        return createAsyncThunk(
            `${name}/get/devices`,
            async ({ user_id }) => await fetchWrapper.get(`${base_Url}/get/all-oder/user-sessions/${parseInt(user_id)}`)
        );
    }

    function disconnectDevice() {
        return createAsyncThunk(
            `${name}/disconnect/devices`,
            // async ({ user_id, password }) => await fetchWrapper.post(`${base_Url}/clear/one/user-sessions`, { user_id, password })
            async ({ deviceId }) => await fetchWrapper.get(`${base_Url}/logout-device/${deviceId}`)
        );
    }


    function getProjectsWorkOnList() {
        return createAsyncThunk(
            `${name}/get/user/work/on/project`,
            async () => await fetchWrapper.get(`${base_Url}/projects`)
        );
    }

    function getUserTaskOnProject() {
        return createAsyncThunk(
            `${name}/get/user/task/on/project`,
            async (user_id) => await fetchWrapper.get(`${base_Url}/get/user/task/${user_id}`)
        );
    }

    function getTasks() {
        return createAsyncThunk(
            `${name}/get/task/on/project`,
            async ({ project_id }) => await fetchWrapper.get(`${base_Url}/get/task/${project_id}`)
        );
    }

    /**
     * Get all task for a specific project
     * @method GET
     * @param project_id
     */
    function projectTasks() {
        return createAsyncThunk(
            `${name}/get/project/tasks`,
            async ({ project_id }) => await fetchWrapper.get(`${base_Url}/get/task/${project_id}`)
        );
    }

    function workerCompleteTask() {
        return createAsyncThunk(
            `${name}/worker/complete/task`,
            async ({ task_id, is_completed }) => await fetchWrapper.patch(`${base_Url}/task/completed/${task_id}/${is_completed}`)
        );
    }

    /**
    * Create a project
    * @method POST
    * @param user_id
    * @param name
    * @param status
    * @param description
    * @param portee
    * @returns 
    */
    function createProject() {
        return createAsyncThunk(
            `${name}/create/project`,
            async ({ user_id, name, status, description, portee, categories_id }) => await fetchWrapper.post(`${base_Url}/create/user/projects`, { user_id, name, status, description, portee, categories_id })
        );
    }

    /**
 * Get all roles
 * @method GET
 *  */
    function allCategories() {
        return createAsyncThunk(
            `${name}/get/categories`,
            async () => await fetchWrapper.get(`${base_Url}/categories`)
        );
    }

    /**
* Get a specific technology on a project
* @method GET
* @param projet_id
*/
    function getTechnology() {
        return createAsyncThunk(
            `${name}/get/project/technology`,
            async ({ projets_id }) => await fetchWrapper.get(`${base_Url}/get/techno/${projets_id}`)
        );
    }

    function getUserPaiements() {
        return createAsyncThunk(
            `${name}/get/user/paiements`,
            async () => await fetchWrapper.get(`${base_Url}/get-payments`)
        );
    }









}

function createExtraReducers() {
    return builder => {
        getUser();
        verifEmail();
        resendCode();
        verifPass();
        enable2fa();
        disable2fa();
        verif2facode();
        resetPassword();
        getDevices();
        disconnectDevice();
        getProjectsWorkOnList();
        getUserTaskOnProject();
        tasksByProject();
        workerCompleteTask();
        createProject();
        categories();
        getTechnology();
        projectdisplaytasks();
        getuserpaiements();



        function getUser() {
            var { pending, fulfilled, rejected } = extraActions.getUser;
            builder
                .addCase(pending, state => {
                    // state.user = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    const user = action.payload.data;

                    state.user = user;

                    if (user.google2fa_secret) {
                        state.twoFactActivated = true;
                    } else {
                        state.twoFactActivated = null;
                    }

                    state.error = {};
                    setTimeout(() => {
                        if (sessionStorage.getItem('nekot') !== null) {
                            localStorage.setItem('token', sessionStorage.getItem('nekot'));
                            sessionStorage.removeItem('nekot');
                        }
                    }, 1000);
                })
                .addCase(rejected, (state, action) => {
                    state.error = { error: action.error };
                    state.user = {};
                    // state.user = {  };
                })
        }

        function verifEmail() {
            var { pending, fulfilled, rejected } = extraActions.verifEmail;
            builder
                .addCase(pending, state => {
                    // state.user = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    Toast('success', action.payload.data.message, 'tr', false, false, false, 6000, false);
                    sessionStorage.removeItem('token');
                    setTimeout(() => {
                        history.navigate('/signin');
                    }, 3000);
                })
                .addCase(rejected, (state, action) => {
                    Toast('error', action.error.message, 'tr', false, false, false, 6000);
                    state.error = { error: action.error };
                    state.user = {};
                })
        }

        //Resend pin code
        function resendCode() {
            var { pending, fulfilled, rejected } = extraActions.resendCode;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload;
                    // console.log(data);
                    if (data.status === "success") {
                        Toast('success', data.data.message, 'tr', false, false, false, 6000, false);
                    }
                })
                .addCase(rejected, (state, action) => {
                    Toast('error', action.error.message, 'tr', false, false, false, 6000, false);
                    state.error = action.error;
                })
        }

        function verifPass() {
            var { pending, fulfilled, rejected } = extraActions.verifpass;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const status = action.payload.status;
                    if (status) {
                        sessionStorage.setItem('passVerif', 'true');
                    }
                    //state.twofact = status === 'success' ? true : false;
                })
                .addCase(rejected, (state, action) => {
                    // state.error = action.error;
                    Toast('error', 'Incorrect password', 'tr', false, false, false, 6000, false);
                })
        };

        function enable2fa() {
            var { pending, fulfilled, rejected } = extraActions.enable2fa;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload.data;
                    // console.log(data);
                    state.twofact = data.secret;
                    state.qr = data.qr_code;
                    state.twoFactChecked = true;
                    setTimeout(() => {
                        Toast('success', '2FA Enabled successfully!', 'tr', false, false, false, 3000, false);
                    }, 3000);
                })
                .addCase(rejected, (state, action) => {
                    // state.error = action.error;
                    Toast('error', 'Incorrect password', 'tr', false, false, false, 6000, false);
                })
        };

        function disable2fa() {
            var { pending, fulfilled, rejected } = extraActions.disable2fa;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload;
                    console.log(data)
                    if (data.status === "success")
                        state.twofact = null;
                    state.qr = null;
                    state.twoFactChecked = null;
                    // state.twoFactActivated = null;

                    setTimeout(() => {
                        Toast('success', '2FA Disabled successfully!', 'tr', false, false, false, 3000, false);
                    }, 3000);
                })
                .addCase(rejected, (state, action) => {
                    // state.error = action.error;
                    // Toast('error', 'Error ', 'tr', false, false, false, 6000, false);
                })
        };

        function verif2facode() {
            var { pending, fulfilled, rejected } = extraActions.verif2facode;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload;
                    localStorage.setItem('token', data.data.token);
                    state.twoFactChecked = true;
                    const { from } = history.location.state || { from: { pathname: '/' } };
                    Toast('success', 'Successfully logged in!', 'tr', false, false, false, 3000, false);
                    setTimeout(() => {
                        history.navigate(from);
                    }, 3000);
                })
                .addCase(rejected, (state, action) => {
                    // state.error = action.error;
                    Toast('error', 'Incorrect password', 'tr', false, false, false, 6000, false);
                })
        };

        function resetPassword() {
            var { pending, fulfilled, rejected } = extraActions.resetPassword;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload;
                    // console.log(data);
                    if (data.status === "success") {
                        Toast('success', data.data.message, 'tr', false, false, false, 6000, false);
                    }
                })
                .addCase(rejected, (state, action) => {
                    Toast('error', action.error.message, 'tr', false, false, false, 6000, false);
                    state.error = action.error;
                })
        }

        function getDevices() {
            var { pending, fulfilled, rejected } = extraActions.getdevices;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload;

                    // if (data.data.filter(device => device.payload.user_agent === window.navigator.userAgent).length !== 1) {
                    //     sessionStorage.setItem('resetStore', 1);
                    // } else {
                    //     sessionStorage.removeItem('resetStore');
                    // }


                    if (data.status === "success") {
                        //Toast('success', data.data.message, 'tr', false, false, false, 6000, false);
                        state.devices = data.data;
                    } else {
                        state.devices = [];
                    }
                })
                .addCase(rejected, (state, action) => {
                    if (action.error.name !== "SyntaxError" && action.error.name !== "TypeError") {
                        //Toast('error', action.error.message, 'tc', false, false, true, 6000, false);
                        state.error = action.error;
                    } else {
                        state.error = action.error.name;
                    }
                })
        }

        function disconnectDevice() {
            var { pending, fulfilled, rejected } = extraActions.logoutdevice;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload.data;
                    console.log(data);
                    if (action.payload.status === "success") {
                        Toast('success', "Device successfully diconnected", 'tr', false, false, false, 6000, false);
                        //state.devices = data;
                        console.log(data);
                    }
                })
                .addCase(rejected, (state, action) => {
                    Toast('error', action.error.message, 'tr', false, false, true, 6000, false);
                    state.error = action.error;
                    state.devices = [];
                })
        }

        function getProjectsWorkOnList() {
            var { pending, fulfilled, rejected } = extraActions.projects;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload.data;
                    // let userId = JSON.parse(localStorage.getItem('user')).id;
                    let userId = state.user.id;
                    // console.log(state)

                    state.projects = retrieveUserInProject(userId, data);
                    state.allprojects = data;
                })
                .addCase(rejected, (state, action) => {
                    console.log('getProjectsWorkOnList', action.error)
                    Toast('error', action.error.message, 'tr', false, false, true, 6000, false);
                    state.error = action.error;
                    state.projects = [];
                    state.allprojects = [];
                })
        }

        function getUserTaskOnProject() {
            var { pending, fulfilled, rejected } = extraActions.usertasks;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    //const data = action.payload.data;
                    // console.log(data)
                    // let userId = JSON.parse(localStorage.getItem('user')).id;

                    // state.projects = retrieveUserInProject(userId, data);
                })
                .addCase(rejected, (state, action) => {
                    // Toast('error', action.error.message, 'tr', false, false, true, 6000, false);
                    // state.error = action.error;
                    console.log('getUserTaskOnProject', action.error)
                })
        }

        function tasksByProject() {
            var { pending, fulfilled, rejected } = extraActions.projecttasks;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    let data = action.payload.data;
                    // let tab1 = [], currentUser = JSON.parse(localStorage.getItem('user')).id;
                    let tab1 = [], currentUser = state.user.id;

                    data.forEach(elm => {
                        elm.users.forEach(user => {
                            if (user.id === currentUser) {
                                tab1.push(elm)
                            }
                        })
                    })
                    state.projecttasks = tab1;
                    console.log('Project tasks has been loaded successfully');
                })
                .addCase(rejected, (state, action) => {
                    // const getErr = JSON.parse(action.error.message);

                    state.error = action.error;
                })
        };

        function projectdisplaytasks() {
            var { pending, fulfilled, rejected } = extraActions.get_task;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    let data = action.payload.data;
                    // let tab1 = [], currentUser = JSON.parse(localStorage.getItem('user')).id;
                    // console.log(data);
                    state.projectdisplaytasks = data;
                    // console.log('Project tasks has been loaded successfully');
                })
                .addCase(rejected, (state, action) => {
                    // const getErr = JSON.parse(action.error.message);

                    state.error = action.error;
                })
        };

        function workerCompleteTask() {
            var { pending, fulfilled, rejected } = extraActions.workerCompleteTask;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    setTimeout(() => {
                        Swal.fire(
                            'Well done!',
                            'Task complete! Notification sent successfully.',
                            'success'
                        );
                    }, 3000);
                })
                .addCase(rejected, (state, action) => {
                    // const getErr = JSON.parse(action.error.message);

                    state.error = action.error;
                })
        };

        function createProject() {
            var { pending, fulfilled, rejected } = extraActions.createproject;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    Toast('success', 'The projet has been created successfully', 'tr', false, false, true, 3000, false);
                })
                .addCase(rejected, (state, action) => {
                    Toast('error', JSON.parse(action.error.message).name[0], 'tr', false, false, true, 3000, false);
                    state.error = action.error;
                })
        };

        function categories() {
            var { pending, fulfilled, rejected } = extraActions.categories;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const categories = action.payload.data;
                    state.categories = categories;
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                })
        };

        function getTechnology() {
            var { pending, fulfilled, rejected } = extraActions.gettechnology;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const technos = action.payload.data;
                    state.projecttech = technos;
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                })
        };

        function getuserpaiements() {
            var { pending, fulfilled, rejected } = extraActions.getuserpaiements;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    // console.log(action.payload);
                    if (action.payload.status === 'success') {
                        if (action.payload.data === undefined) {
                            state.paiements = { from_back: true, data: [] };
                        } else {
                            state.paiements = { from_back: true, data: action.payload.data };
                        }
                    }
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                    state.paiements = { from_back: true, data: [] };
                })
        };




    };

}