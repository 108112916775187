import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { I18n } from './helpers';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import './styles/App.css'
import 'react-loading-skeleton/dist/skeleton.css';
import { HelmetProvider } from 'react-helmet-async';

I18n();

const root = ReactDOM.createRoot(document.getElementById('root'));
let persistor = persistStore(store);
const helmetContext = {};

root.render(
	<React.Fragment>
		<BrowserRouter>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<HelmetProvider context={helmetContext}>
						<App />
					</HelmetProvider>
				</PersistGate>
			</Provider>

		</BrowserRouter>
	</React.Fragment>
);
