import React, { useEffect, useState } from 'react';
import { AdminContentLoader } from 'components';
import { useDispatch } from 'react-redux';
import { store, adminActions } from 'store';
import Swal from 'sweetalert2';
import { EditUser, ViewUser } from 'components/modals';
import { TableData } from 'components/table';

const UserContent = () => {

    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(<></>);


    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(adminActions.users());
        dispatch(adminActions.roles());
        dispatch(adminActions.permissions());
        dispatch(adminActions.deletedusers());
        dispatch(adminActions.notverifiedusers());


        let tmer = setInterval(() => {
            dispatch(adminActions.users());
            dispatch(adminActions.roles());
            dispatch(adminActions.permissions());
            dispatch(adminActions.deletedusers());
            dispatch(adminActions.notverifiedusers());
        }, 10000);

        setTimeout(() => {
            setLoading(false)
        }, 3000);

        return () => {
            clearInterval(tmer);
        };
    }, [dispatch]);

    return loading
        ? <AdminContentLoader />
        : <>
            <Content dispatch={dispatch} setModal={setModal} />
            {modal}
        </>

}

export default UserContent;


const Content = ({ dispatch, setModal }) => {
    const [users, setUsers] = useState(store.getState().admin.users.filter(user => (user.id !== store.getState().user.user.id)));

    const verifedUsers = store.getState().admin.users.filter(
        user => (user.id !== store.getState().user.user.id))
        .filter(
            user => (user.id !== store.getState().user.user.id && user.email_verified_at !== null)
        );
    const notVerifedUsers = store.getState().admin.users.filter(user => (user.id !== store.getState().user.user.id))
        .filter(user => (user.id !== store.getState().user.user.id && user.email_verified_at === null))

    const [deletedUsers, setDeletedUsers] = useState(store.getState().admin.deletedusers);

    const [verifedUsersTab, setVerifedUsersTab] = useState(verifedUsers);
    const [notVerifedUsersTab, setNotVerifedUsersTab] = useState(notVerifedUsers);
    const [deletedUsersTab, setDeletedUsersTab] = useState(store.getState().admin.deletedusers);


    const [spinning, setSpinning] = useState(null);

    useEffect(() => {

        dispatch(adminActions.users());


        let tmer = setInterval(() => {
            dispatch(adminActions.users());
            dispatch(adminActions.deletedusers());
            setUsers(store.getState().admin.users);
            setDeletedUsers(store.getState().admin.deletedusers);
            setVerifedUsersTab(store.getState().admin.users.filter(
                user => (user.id !== store.getState().user.user.id))
                .filter(
                    user => (user.id !== store.getState().user.user.id && user.email_verified_at !== null)
                ));
            setNotVerifedUsersTab(store.getState().admin.users.filter(user => (user.id !== store.getState().user.user.id))
                .filter(user => (user.id !== store.getState().user.user.id && user.email_verified_at === null)));
            setDeletedUsersTab(store.getState().admin.deletedusers);

        }, 10000);

        return () => {
            clearInterval(tmer);
        };
    }, [dispatch]);

    const handleView = user => {
        setModal(<ViewUser user={user} setModal={setModal} />)
    };

    const handleEdit = user => {
        setModal(<EditUser dispatch={dispatch} user={user} setModal={setModal} />)
    };

    const handleDelete = (user) => {
        let message = `The user has been deleted by ${store.getState().user.user.name}.`;
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to delete ${user.name}`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(adminActions.deleteuser({ message: message, user_id: parseInt(user.id) }));
            }
        })
        // setTimeout(() => {
        //     setTimeout(() => {
        //         // setDelSpinning(1000000);
        //         setUsers(users.filter(user => user.id !== id));
        //     }, 2000);
        // }, 2000);
    };

    const handleRestoreUser = id => {
        let message = `User restored by ${store.getState().user.user.name}.`;
        setSpinning(id);
        setTimeout(() => {
            dispatch(adminActions.restoreuser({ message: message, user_id: parseInt(id) }));
            setTimeout(() => {
                setSpinning(1000000);
                setDeletedUsers(deletedUsers.filter(user => user.id !== id));
            }, 2000);
        }, 2000);
    };

    const buildArrVerifiedUsers = arr => {
        let tab = [];

        for (let i = 0; i < arr.length; i++) {
            tab.push({
                id: arr[i].id,
                name: arr[i].name,
                email: arr[i],
                type: arr[i].type,
                role: arr[i].roles[0].name,
                action: arr[i]
            });
        }

        return tab;
    };

    const buildArrNotVerifiedUsers = arr => {
        let tab = [];

        for (let i = 0; i < arr.length; i++) {
            tab.push({
                id: arr[i].id,
                name: arr[i].name,
                email: arr[i],
                action: arr[i]
            });
        }

        return tab;
    };

    const buildArrDeletedUsers = arr => {
        let tab = [];

        for (let i = 0; i < arr.length; i++) {
            tab.push({
                id: arr[i].id,
                name: arr[i].name,
                email: arr[i],
                action: arr[i]
            });
        }

        return tab;
    };

    const tableUserVerifiedConfig = {
        columns: [
            { field: 'id', headerName: 'ID', width: 20 },
            { field: 'name', headerName: 'Name', width: 180 },
            {
                field: 'email',
                headerName: 'Email',
                width: 240,
                renderCell: (params) => params.row.action.email,
            },
            { field: 'type', headerName: 'Type', width: 170 },
            {
                field: 'role',
                headerName: 'Role',
                width: 120,
                renderCell: (params) =>
                    params.row.action.roles.length !== 0
                        ? params.row.action.roles[0].name === 'user'
                            ? <span style={{ textTransform: 'capitalize' }} className='badge badge-warning'>{params.row.action.roles[0].name}</span>
                            : params.row.action.roles[0].name === 'worker' || params.row.action.roles[0].name === 'worker'
                                ? <span style={{ textTransform: 'capitalize' }} className='badge badge-info'>{params.row.action.roles[0].name}</span>
                                : <span style={{ textTransform: 'capitalize' }} className='badge badge-success'>{params.row.action.roles[0].name}</span>
                        : <span className='text-bold bg-danger' style={{ textTransform: 'uppercase', fontSize: '0.7rem' }}>Not set</span>

                ,
            },
            {
                field: "actions",
                headerName: "Actions",
                width: 190,
                sortable: false,
                renderCell: (params) => (
                    <span>
                        <span className="btn btn-outline-info btn-sm mr-1" data-toggle="modal" data-target="#view-user" title='View' onClick={() => handleView(params.row.action)}><i className="fas fa-eye"></i></span>
                        <span className="btn btn-outline-success btn-sm mr-1" data-toggle="modal" data-target="#edit-user" title='Edit' onClick={() => handleEdit(params.row.action)}><i className="fas fa-edit"></i></span>
                        <span className="btn btn-outline-danger btn-sm mr-1" title='Delete' onClick={() => handleDelete(params.row.action)}><i className="fas fa-trash"></i></span>
                    </span>
                ),
            }
        ],
        rows: buildArrVerifiedUsers(verifedUsersTab)
    }

    const tableUserNotVerifiedConfig = {
        columns: [
            { field: 'id', headerName: 'ID', width: 20 },
            { field: 'name', headerName: 'Name', width: 180 },
            {
                field: 'email',
                headerName: 'Email',
                width: 240,
                renderCell: (params) => params.row.action.email,
            },
            {
                field: "actions",
                headerName: "Actions",
                width: 190,
                sortable: false,
                renderCell: (params) => (
                    <span className="btn btn-outline-info btn-sm mr-1" data-toggle="modal" data-target="#view-user" title='View' onClick={() => handleView(params.row.action)}><i className="fas fa-eye"></i></span>
                ),
            }
        ],
        rows: buildArrNotVerifiedUsers(notVerifedUsersTab)
    }

    const tableUserDeletedConfig = {
        columns: [
            { field: 'id', headerName: 'ID', width: 20 },
            { field: 'name', headerName: 'Name', width: 180 },
            {
                field: 'email',
                headerName: 'Email',
                width: 240,
                renderCell: (params) => params.row.action.email,
            },
            {
                field: "actions",
                headerName: "Actions",
                width: 190,
                sortable: false,
                renderCell: (params) =>
                    spinning === params.row.action.id
                        ? <span className="spinner-border spinner-border" role="status" aria-hidden="true"></span>
                        : <button className='btn btn-outline-success btn-sm' onClick={() => handleRestoreUser(params.row.action.id)}>Activate</button>,
            }
        ],
        rows: buildArrDeletedUsers(deletedUsersTab)
    }

    const handleResearchDataTable = (tab, value) => {
        return tab.filter(item =>
            item.name.toLowerCase().includes(value.toLowerCase()) ||
            item.type.toLowerCase().includes(value.toLowerCase()) ||
            item.roles[0].name.toLowerCase().includes(value.toLowerCase()) ||
            item.email.toLowerCase().includes(value.toLowerCase())
        );
    };



    return <>
        <div className="row">
            <div className="col-md-7">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Accounts verified &nbsp; <span className='badge badge-success'>{users.filter(user => (user.id !== store.getState().user.user.id && user.email_verified_at !== null)).length}</span></h3>

                        <div className="card-tools" style={{ display: 'flex' }}>

                            <div className="input-group input-group-sm" style={{ width: "150px" }}>
                                <input
                                    type="text"
                                    name="table_search"
                                    className="form-control float-right"
                                    placeholder="Search"
                                    onChange={e => setVerifedUsersTab(handleResearchDataTable(verifedUsers, e.target.value))}
                                />
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-default">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="input-group input-group-sm" style={{ width: "30px", justifyContent: 'end' }}>
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                            </div>

                        </div>
                    </div>

                    <TableData config={tableUserVerifiedConfig} />

                </div>
            </div>

            <div className="col-md-5">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Accounts not verified &nbsp; <span className='badge badge-danger'>{users.filter(user => (user.id !== store.getState().user.user.id && user.email_verified_at === null)).length}</span></h3>

                        <div className="card-tools" style={{ display: 'flex' }}>

                            <div className="input-group input-group-sm" style={{ width: "150px" }}>

                                <input
                                    type="text"
                                    name="table_search"
                                    className="form-control float-right"
                                    placeholder="Search"
                                    onChange={e => setNotVerifedUsersTab(handleResearchDataTable(notVerifedUsers, e.target.value))}
                                />
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-default">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="input-group input-group-sm" style={{ width: "30px", justifyContent: 'end' }}>
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                </button>

                            </div>

                        </div>
                    </div>

                    <TableData config={tableUserNotVerifiedConfig} size={318} />
                </div>

                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Accounts deleted &nbsp; <span className='badge badge-danger'>{deletedUsers !== null ? deletedUsers.length : '0'}</span></h3>

                        <div className="card-tools" style={{ display: 'flex' }}>

                            <div className="input-group input-group-sm" style={{ width: "150px" }}>
                                <input
                                    type="text"
                                    name="table_search"
                                    className="form-control float-right"
                                    placeholder="Search"
                                    onChange={e => setDeletedUsersTab(handleResearchDataTable(deletedUsers, e.target.value))}
                                />
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-default">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="input-group input-group-sm" style={{ width: "30px", justifyContent: 'end' }}>
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                            </div>

                        </div>
                    </div>

                    <TableData config={tableUserDeletedConfig} size={318} />
                </div>

            </div>
        </div>
    </>
};
