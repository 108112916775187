import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Toast, history } from '../helpers';
import { fetchWrapper } from '../helpers/fetch-wrapper';
import Swal from 'sweetalert2';
import appConfig from '../config';


// create slice

const name = 'manager';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const managerActions = { ...slice.actions, ...extraActions };
export const managerReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        // token: localStorage.getItem('token'),
        users: null,
        categories: [], //think to remove the array
        projects: [],
        careers: [],
        contacts: [],
        oneproject: null,
        projecttech: null,
        projecttasks: [],
        roles: null,
        permissions: null,
        deletedusers: null,
        notverifiedusers: null,
        messages: null,
        error: null,
    }
}

function createReducers() {
    return {
        logout,
        resetSlice
    };

    function resetSlice(state) { return createInitialState(); };

    function logout(state) {
        state.oneproject = null;
        state.roles = null;
        state.permissions = null;
        state.deletedusers = null;
        state.notverifiedusers = null;
        state.user = null;
        // state.token = null;
        state.projecttasks = [];
        state.categories = [];
        state.users = null;
        state.projects = [];
        state.messages = null;
        state.projecttech = null;
        localStorage.removeItem('token');
        localStorage.removeItem('ftoken');
        localStorage.removeItem('emailResetPass');
        localStorage.removeItem('user');
        sessionStorage.removeItem('applyJob');
        sessionStorage.removeItem('projects_files');
        history.navigate('/');
    }
}

function createExtraActions() {
    const baseUrl = `${appConfig.apiUrl}`;

    return {
        users: allUsers(),
        roles: allRoles(),
        permissions: allPermissions(),
        deletedusers: deletedUsers(),
        restoreuser: restoreDeletedUser(),
        deleteuser: deletedUser(),
        notverifiedusers: notVerifiedUser(),
        assignrole: assignRole(),
        assignpermission: assignPermission(),
        projects: allProjects(),
        oneproject: getProject(),
        createproject: createProject(),
        user_role: getRole(),
        user_permissions: getPermission(),
        removepermission: removePermission(),
        updateproject: updateProject(),
        projectassignuser: projectAssignUser(),
        deleteproject: deleteProject(),
        categories: allCategories(),
        createcategory: createCategory(),
        deletecategory: deleteCategory(),
        projecttasks: projectTasks(),
        createtaks: createTask(),
        edit_task: editTask(),
        completetask: completeTask(),
        deletetask: deleteTask(),
        removeassigneduserproject: removeAssignedUserProject(),
        createtechnology: createTechnology(),
        gettechnology: getTechnology(),
        assignusertotask: assignUserToTask(),
        removeusertotask: removeUserToTask(),
        uploadfilesproject: uploadFilesProject(),
        createcareer: createCareer(),
        careers: allCareers(),
        updatecareer: updateCareer(),
        deletecareer: deleteCareer(),
        addNewWorker: addNewWorker(),
        workerCompleteTask: workerCompleteTask(),
        allcontacts: getAllContacts(),
        createcontact: createContact(),
        deletecontact: deleteContact(),
        getprojectidea: getProjectIdea(),
    };

    /**
     * Get all users
     * @method GET 
     * */
    function allUsers() {
        return createAsyncThunk(
            `${name}/get/users`,
            async () => await fetchWrapper.get(`${baseUrl}/get/all/users`)
        );
    }

    /**
     * Get all roles
     * @method GET
     *  */
    function allRoles() {
        return createAsyncThunk(
            `${name}/get/roles`,
            async () => await fetchWrapper.get(`${baseUrl}/roles`)
        );
    }

    /**
     * @method GET
     * Get all permissions */
    function allPermissions() {
        return createAsyncThunk(
            `${name}/get/permissions`,
            async () => await fetchWrapper.get(`${baseUrl}/permissions`)
        );
    }

    /**
     * Get role from a user
     * @method GET
     */
    function getRole() {
        return createAsyncThunk(
            `${name}/get/user/role`,
            async ({ user_id }) => await fetchWrapper.get(`${baseUrl}/get/user/role/${user_id}`)
        );
    }

    /**
     * Get permissions from a user
     * @method GET
     */
    function getPermission() {
        return createAsyncThunk(
            `${name}/get/user/permissions`,
            async ({ user_id }) => await fetchWrapper.get(`${baseUrl}/get/user/permissions/${user_id}`)
        );
    }

    /**
     * @method GET
     * Get all deleted users */
    function deletedUsers() {
        return createAsyncThunk(
            `${name}/get/all/deleted/user`,
            async () => await fetchWrapper.get(`${baseUrl}/get/alluser/desabled`)
        );
    }

    /**
     * Restore a deleted user
     * @method POST
     * @param user_id
     * @param message  */
    function restoreDeletedUser() {
        return createAsyncThunk(
            `${name}/restore/deleted/user`,
            async ({ message, user_id }) => await fetchWrapper.post(`${baseUrl}/restore/user/desabled/${user_id}`, { message })
        );
    }

    /**
     * Delete a user
     * @method POST
     * @param user_id
     * @param message 
     */
    function deletedUser() {
        return createAsyncThunk(
            `${name}/deleted/user`,
            async ({ message, user_id }) => await fetchWrapper.post(`${baseUrl}/deseable/user/${user_id}`, { message })
        );
    }


    /**
     * Get all users who not verified their account
     * @method GET
     */
    function notVerifiedUser() {
        return createAsyncThunk(
            `${name}/not/verified/user`,
            async () => await fetchWrapper.get(`${baseUrl}/get/user/not/validated`)
        );
    }

    /**
     * Assign a role to a user
     * @method POST
     * @param user_id
     * @param role_name
     * 
     */
    function assignRole() {
        return createAsyncThunk(
            `${name}/assign/role/user`,
            async ({ user_id, role_name }) => await fetchWrapper.post(`${baseUrl}/assign/role/${user_id}`, { role_name: role_name })
        );
    }

    /**
     * Assign a permission to a user
     * @method PATCH
     * @param user_id
     * @param permissions[]
     */
    function assignPermission() {
        return createAsyncThunk(
            `${name}/assign/permission/user`,
            async ({ user_id, permissions }) => await fetchWrapper.patch(`${baseUrl}/assign/permission/${user_id}`, { permissions: permissions })
        );
    }

    /**
 * Revoke a permission to a user
 * @method POST
 * @param user_id
 * @param permissions[]
 */
    function removePermission() {
        return createAsyncThunk(
            `${name}/revoke/permission`,
            async ({ userId, message }) => await fetchWrapper.post(`${baseUrl}/revoke/permission/${userId}`, { message })
        );
    }

    /**
     * Get all projects
     * @method GET
     */
    function allProjects() {
        return createAsyncThunk(
            `${name}/get/all/projects`,
            async () => await fetchWrapper.get(`${baseUrl}/projects`)
        );
    }

    /**
     * Get a specific project
     * @method GET
     * @param id of project
     */
    function getProject() {
        return createAsyncThunk(
            `${name}/get/one/projects`,
            async ({ id }) => await fetchWrapper.get(`${baseUrl}/projects/${id}`)
        );
    }

    /**
     * Create a project
     * @method POST
     * @param user_id
     * @param name
     * @param status
     * @param description
     * @param portee
     * @param team[]
     * @ leader to provide
     * @returns 
     */
    function createProject() {
        return createAsyncThunk(
            `${name}/create/project`,
            async ({ user_id, name, status, description, portee, categories_id }) => await fetchWrapper.post(`${baseUrl}/create/user/projects`, { user_id, name, status, description, portee, categories_id })
        );
    }


    /**
 * Update a project
 * @method PATCH
 * @param id
 * @param name
 * @param description
 * @param status
 * @param portee
 */
    function updateProject() {
        return createAsyncThunk(
            `${name}/update/project`,
            async ({ id, user_id, name, description, status, portee, categories_id, meetspacelink, budget }) => await fetchWrapper.patch(
                `${baseUrl}/projects/update/${id}`,
                { user_id, name, description, status, portee, categories_id, meetspacelink, budget }
            )
        );
    }


    /**
 * Set leader to a project
 * @method POST
 * @param project_id
 * @param user_id
 */
    function projectAssignUser() {
        return createAsyncThunk(
            `${name}/update/project/set/leader`,
            async ({ project_id, user_id, is_owner }) => await fetchWrapper.post(
                `${baseUrl}/projects/assign/${project_id}/${user_id}`,
                { is_owner }
            )
        );
    }

    /**
    * Delete a project
    * @method DELETE
    * @param project_id
    */
    function deleteProject() {
        return createAsyncThunk(
            `${name}/delete/project`,
            async ({ project_id }) => await fetchWrapper.delete(
                `${baseUrl}/projects/delete/${project_id}`
            )
        );
    }

    /**
     * Get all roles
     * @method GET
     *  */
    function allCategories() {
        return createAsyncThunk(
            `${name}/get/categories`,
            async () => await fetchWrapper.get(`${baseUrl}/categories`)
        );
    }


    /**
    * Create a Category
    * @method POST
    * @param name
    * @param description
    */
    function createCategory() {
        return createAsyncThunk(
            `${name}/create/category`,
            async ({ name, description }) => await fetchWrapper.post(
                `${baseUrl}/categories/create`,
                { name, description }
            )
        );
    }

    /**
    * Delete a category
    * @method DELETE
    * @param id of category to delete
    */
    function deleteCategory() {
        return createAsyncThunk(
            `${name}/delete/category`,
            async ({ id }) => await fetchWrapper.delete(
                `${baseUrl}/categories/delete/${id}`
            )
        );
    }


    /**
 * Create a tasks
 * @method POST
 * @param name
 * @param description
 * @param start_date
 * @param end_date
 * @param projects_id
 */
    function createTask() {
        return createAsyncThunk(
            `${name}/create/project/tasks`,
            async ({ tasks, projects_id }) => await fetchWrapper.post(`${baseUrl}/create/task/${projects_id}`, { tasks: tasks })
        );
    }

    /**
* Create a tasks
* @method POST
* @param name
* @param description
* @param start_date
* @param end_date
* @param projects_id
*/
    function editTask() {
        return createAsyncThunk(
            `${name}/edit/task`,
            async ({ task }) => await fetchWrapper.patch(`${baseUrl}/update/task/${task.id}`, { ...task })
        );
    }

    /**
     * Get all task for a specific project
     * @method GET
     * @param project_id
     */
    function projectTasks() {
        return createAsyncThunk(
            `${name}/get/project/tasks`,
            async ({ project_id }) => await fetchWrapper.get(`${baseUrl}/get/task/${project_id}`)
        );
    }

    /**
* Update a task
* @method PATCH
* @param id
* @param name
* @param description
* @param status
* @param portee
*/
    function completeTask() {
        return createAsyncThunk(
            `${name}/complete/task`,
            async ({ task_id, name, description, start_date, end_date, projects_id, completed }) => await fetchWrapper.patch(
                `${baseUrl}/update/task/${task_id}`,
                { name, description, start_date, end_date, projects_id, completed }
            )
        );
    }

    /**
    * Delete a task
    * @method DELETE
    * @param id of task to delete
    */
    function deleteTask() {
        return createAsyncThunk(
            `${name}/delete/task`,
            async ({ id }) => await fetchWrapper.delete(
                `${baseUrl}/delete/task/${id}`
            )
        );
    }


    /**
 * Revokevoke a permission to a user
 * @method PATCH
 * @param user_id
 * @param project_id
 */
    function removeAssignedUserProject() {
        return createAsyncThunk(
            `${name}/remove/assigned/user/project`,
            async ({ user_id, projets_id }) => await fetchWrapper.patch(`${baseUrl}/remove/user/projects/${projets_id}/${user_id}`)
        );
    }


    /**
    * Create a Technology
    * @method POST
    * @param projects_id
    * @param budget
    * @param backend_name
    * @param frontend_name
    * @param bdd
    * @param virtualiseur
    * @param brocker
    * @param security
    * @param description
    */

    function createTechnology() {
        return createAsyncThunk(
            `${name}/create/technology`,
            async ({ project_id, budget, backend_name, frontend_name, bdd, virtualiseur, brocker, security }) => await fetchWrapper.post(
                `${baseUrl}/create/techno/${project_id}`,
                { budget, backend_name, frontend_name, bdd, virtualiseur, brocker, security }
            )
        );
    }

    /**
 * Get a specific technology on a project
 * @method GET
 * @param projet_id
 */
    function getTechnology() {
        return createAsyncThunk(
            `${name}/get/project/technology`,
            async ({ projets_id }) => await fetchWrapper.get(`${baseUrl}/get/techno/${projets_id}`)
        );
    }

    /**
    * Set user to a specific task
    * @method PATCH
    * @param user_id array
    */
    function assignUserToTask() {
        return createAsyncThunk(
            `${name}/assign/user/to/task`,
            async ({ task_id, user_id }) => await fetchWrapper.patch(`${baseUrl}/assign/user/task/${task_id}`, { user_id })
        );
    }

    /**
    * Remove user from a sppecific task
    * @method PATCH
    * @param user_id array
    */
    function removeUserToTask() {
        return createAsyncThunk(
            `${name}/remove/user/to/task`,
            async ({ task_id, user_id }) => await fetchWrapper.patch(`${baseUrl}/remove/user/${user_id}/${task_id}`)
        );
    }


    /**
    * Get a specific technology on a project
    * @method PATCH
    * @param user_id array
    */
    function uploadFilesProject() {
        return createAsyncThunk(
            `${name}/upload/files/project`,
            async ({ files, project_id }) => await fetchWrapper.patch(`${baseUrl}/projects/upload/avatar/${project_id}`, { files, project_id })
        );
    }



    function allCareers() {
        return createAsyncThunk(`${name}/get/all/careers`, async () => await fetchWrapper.get(`${baseUrl}/getcarrer`));
    }


    /**
     * Create a career
     * @method POST
     */
    function createCareer() {
        return createAsyncThunk(
            `${name}/create/career`,
            async ({ title, category, status,
                location, description,
                cycle, type, date,
                text, propose, requierment,
                skills, extraSkills }) => await fetchWrapper.post(
                    `${baseUrl}/create/carrer`,
                    {
                        title, category, status,
                        location, description,
                        cycle, type, date,
                        text, propose, requierment,
                        skills, extraSkills
                    }
                )
        );
    }

    /**
* Update a career
* @method PATCH
*  @param title
*  @param category
*  @param status
*  @param location
*  @param description
*  @param cycle
*  @param type
*  @param dateline
*  @param text
*  @param propose
*  @param requierment
*  @param skills
*  @param extraSkills
*  @param title
*/
    function updateCareer() {
        return createAsyncThunk(
            `${name}/update/career`,
            async ({ id, title, category, status,
                location, description,
                cycle, type, date,
                text, propose, requierment,
                skills, extraSkills }) => await fetchWrapper.patch(
                    `${baseUrl}/update/carrer/${id}`,
                    {
                        title, category, status,
                        location, description,
                        cycle, type, date,
                        text, propose, requierment,
                        skills, extraSkills
                    }
                )
        );
    }


    function deleteCareer() {
        return createAsyncThunk(
            `${name}/delete/career`,
            async ({ id }) => await fetchWrapper.delete(
                `${baseUrl}/destroy/carrer/${id}`
            )
        );
    }


    function addNewWorker() {
        return createAsyncThunk(
            `${name}/register/new/worker`,
            async ({ name, first_name, email, password, password_confirmation, ip }) => await fetchWrapper.post(
                `${baseUrl}/new-user-registed`,
                { name, first_name, email, password, password_confirmation, ip }
            )
        );
    }

    function workerCompleteTask() {
        return createAsyncThunk(
            `${name}/worker/complete/task`,
            async ({ task_id, is_completed }) => await fetchWrapper.patch(`${baseUrl}/task/completed/${task_id}/${is_completed}`)
        );
    }

    function getAllContacts() {
        return createAsyncThunk(
            `${name}/get/all/contacts/`,
            async () => await fetchWrapper.get(`${baseUrl}/contacts`)
        );
    }

    function createContact() {
        return createAsyncThunk(
            `${name}/create/contact`,
            async ({ first_name, last_name, phone, email, subject, message }) =>
                await fetchWrapper.post(`${baseUrl}/contacts/create`, { first_name, last_name, phone, email, subject, message }));
    }

    function deleteContact() {
        return createAsyncThunk(
            `${name}/delete/contact/`,
            async ({ id }) => await fetchWrapper.delete(`${baseUrl}/contacts/delete/${id}`)
        );
    }

    /**
* Get a specific project idea
* @method GET
* @param projet_id
*/
    function getProjectIdea() {
        return createAsyncThunk(
            `${name}/get/project/idea`,
            async () => await fetchWrapper.get(`${baseUrl}/get-project-idea`)
        );
    }























}




function createExtraReducers() {
    return (builder) => {
        users();
        roles();
        permissions();
        deletedUsers();
        restoreDeletedUser();
        deletedUser();
        notVerifiedUsers();
        assignRole();
        assignPermission();
        projects();
        oneProject();
        createProject();
        userPermissions();
        removePermission();
        updateProject();
        projectAssignUser();
        deleteProject();
        categories();
        createCategory();
        deleteCategory();
        tasksByProject();
        createTask();
        editTask();
        completeTask();
        deleteTask();
        removeAssignedUserProject();
        createTechnology();
        getTechnology();
        assignUserToTask();
        removeUserToTask();
        uploadFilesProject();
        createCareer();
        allCareers();
        updateCareer();
        deleteCareer();
        addNewWorker();
        workerCompleteTask();
        getAllContacts();
        createContact();
        deleteContact();
        get_project_idea();


        /* -------------------------------------------------------------------------- */
        /*                                    USERS                                   */
        /* -------------------------------------------------------------------------- */

        function users() {
            var { pending, fulfilled, rejected } = extraActions.users;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const users = action.payload.data;
                    state.users = users;
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                })
        };

        function roles() {
            var { pending, fulfilled, rejected } = extraActions.roles;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const roles = action.payload.data;
                    state.roles = roles;
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                })
        };

        function permissions() {
            var { pending, fulfilled, rejected } = extraActions.permissions;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const permissions = action.payload.data;
                    state.permissions = permissions;
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                })
        };

        function deletedUsers() {
            var { pending, fulfilled, rejected } = extraActions.deletedusers;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const users = action.payload.data;
                    state.deletedusers = users;
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                })
        };

        function restoreDeletedUser() {
            var { pending, fulfilled, rejected } = extraActions.restoreuser;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    Toast('success', 'The user has been restored successfully', 'tr', false, false, true, 3000, false);
                })
                .addCase(rejected, (state, action) => {
                    Toast('error', action.error, 'tr', false, false, true, 3000, false);
                    state.error = action.error;
                })
        };

        function deletedUser() {
            var { pending, fulfilled, rejected } = extraActions.deleteuser;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    Swal.fire(
                        'Deleted!',
                        'The user has been disabled successfully',
                        'success'
                    )
                })
                .addCase(rejected, (state, action) => {
                    Toast('error', action.error, 'tr', false, false, true, 3000, false);
                    state.error = action.error;
                })
        };

        function notVerifiedUsers() {
            var { pending, fulfilled, rejected } = extraActions.notverifiedusers;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const users = action.payload.data.userNotValidedAccount;
                    state.notverifiedusers = users;
                })
                .addCase(rejected, (state, action) => {
                    Toast('error', action.error, 'tr', false, false, true, 3000, false);
                    state.error = action.error;
                })
        };

        function assignRole() {
            var { pending, fulfilled, rejected } = extraActions.assignrole;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    Toast('success', 'The role has been assigned successfully', 'tr', false, false, true, 3000, false);
                })
                .addCase(rejected, (state, action) => {
                    Toast('error', action.error, 'tr', false, false, true, 3000, false);
                    state.error = action.error;
                })
        };

        function assignPermission() {
            var { pending, fulfilled, rejected } = extraActions.assignpermission;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    Toast('success', 'The permission has been assigned successfully', 'tr', false, false, true, 3000, false);
                })
                .addCase(rejected, (state, action) => {
                    Toast('error', action.error, 'tr', false, false, true, 3000, false);
                    state.error = action.error;
                })
        };

        function userPermissions() {
            var { pending, fulfilled, rejected } = extraActions.user_permissions;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const permissions = action.payload.data;
                    localStorage.setItem('user_permissions', JSON.stringify(permissions));
                    // Toast('success', 'The projet has been created successfully', 'tr', false, false, true, 3000, false);
                })
                .addCase(rejected, (state, action) => {
                    Toast('error', JSON.parse(action.error.message).name[0], 'tr', false, false, true, 3000, false);
                    state.error = action.error;
                })
        };

        function removePermission() {
            var { pending, fulfilled, rejected } = extraActions.removepermission;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    Toast('success', 'The permission has been removed successfully', 'tr', false, false, true, 3000, false);
                })
                .addCase(rejected, (state, action) => {
                    Toast('error', action.error, 'tr', false, false, true, 3000, false);
                    state.error = action.error;
                })
        };


        /* -------------------------------------------------------------------------- */
        /*                                  PROJECTS                                  */
        /* -------------------------------------------------------------------------- */
        function projects() {
            var { pending, fulfilled, rejected } = extraActions.projects;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const projects = action.payload.data;
                    // console.log(projects);
                    state.projects = projects;
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                })
        };

        function oneProject() {
            var { pending, fulfilled, rejected } = extraActions.oneproject;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const project = action.payload.data;
                    state.oneproject = project;
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                })
        };

        function createProject() {
            var { pending, fulfilled, rejected } = extraActions.createproject;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    Toast('success', 'The projet has been created successfully', 'tr', false, false, true, 3000, false);
                })
                .addCase(rejected, (state, action) => {
                    Toast('error', JSON.parse(action.error.message).name[0], 'tr', false, false, true, 3000, false);
                    state.error = action.error;
                })
        };

        function updateProject() {
            var { pending, fulfilled, rejected } = extraActions.updateproject;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    console.log(action.payload);
                    Toast('success', 'The project has been updated successfully', 'tr', false, false, true, 3000, false);
                })
                .addCase(rejected, (state, action) => {
                    const getErr = JSON.parse(action.error.message);
                    if (getErr.name !== undefined) {
                        Toast('error', getErr.name[0], 'tr', false, false, true, 3000, false);
                    }
                    state.error = action.error;
                })
        };

        function projectAssignUser() {
            var { pending, fulfilled, rejected } = extraActions.projectassignuser;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    Toast('success', 'The User has been assigned successfully', 'tr', false, false, true, 3000, false);
                })
                .addCase(rejected, (state, action) => {
                    // const getErr = JSON.parse(action.error.message);

                    state.error = action.error;
                })
        };

        function deleteProject() {
            var { pending, fulfilled, rejected } = extraActions.deleteproject;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    setTimeout(() => {
                        Swal.fire(
                            'Deleted!',
                            'The project has been deleted.',
                            'success'
                        );
                    }, 3000);
                })
                .addCase(rejected, (state, action) => {
                    // const getErr = JSON.parse(action.error.message);

                    state.error = action.error;
                })
        };

        function removeAssignedUserProject() {
            var { pending, fulfilled, rejected } = extraActions.removeassigneduserproject;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    setTimeout(() => {
                        Swal.fire(
                            'Well done!',
                            'User is removed successfully.',
                            'success'
                        );
                    }, 3000);
                })
                .addCase(rejected, (state, action) => {
                    // const getErr = JSON.parse(action.error.message);

                    state.error = action.error;
                })
        };

        function uploadFilesProject() {
            var { pending, fulfilled, rejected } = extraActions.uploadfilesproject;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    Swal.fire({
                        title: 'File Uploader',
                        text: 'Files are uploaded successfully!',
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        icon: 'success'
                    })
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                })
        };

        /* -------------------------------------------------------------------------- */
        /*                                 CATEGORIES                                 */
        /* -------------------------------------------------------------------------- */
        function categories() {
            var { pending, fulfilled, rejected } = extraActions.categories;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const categories = action.payload.data;
                    state.categories = categories;
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                })
        };

        function createCategory() {
            var { pending, fulfilled, rejected } = extraActions.createcategory;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    console.log(action.payload.data);
                    Toast('success', 'The Category has been created successfully', 'tr', false, false, true, 3000, false);
                })
                .addCase(rejected, (state, action) => {
                    Toast('error', action.error.name[0], 'tr', false, false, true, 3000, false);
                    state.error = action.error;
                })
        };

        function deleteCategory() {
            var { pending, fulfilled, rejected } = extraActions.deletecategory;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    setTimeout(() => {
                        Swal.fire(
                            'Deleted!',
                            'The Category has been deleted.',
                            'success'
                        );
                    }, 3000);
                })
                .addCase(rejected, (state, action) => {
                    // const getErr = JSON.parse(action.error.message);

                    state.error = action.error;
                })
        };


        /* -------------------------------------------------------------------------- */
        /*                                    TASKS                                   */
        /* -------------------------------------------------------------------------- */
        function tasksByProject() {
            var { pending, fulfilled, rejected } = extraActions.projecttasks;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    let data = action.payload.data;
                    // state.projecttasks = [];
                    state.projecttasks = data;
                    // console.log(data, 'Project tasks has been loaded successfully');
                })
                .addCase(rejected, (state, action) => {
                    // const getErr = JSON.parse(action.error.message);

                    state.error = action.error;
                })
        };

        function createTask() {
            var { pending, fulfilled, rejected } = extraActions.createtaks;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    setTimeout(() => {
                        Swal.fire(
                            'Task!',
                            'The tasks are added successfully!.',
                            'success'
                        );
                    }, 3000);
                })
                .addCase(rejected, (state, action) => {
                    // const getErr = JSON.parse(action.error.message);

                    state.error = action.error;
                })
        };

        function editTask() {
            var { pending, fulfilled, rejected } = extraActions.edit_task;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    // console.log(action)
                    setTimeout(() => {
                        Swal.fire(
                            'Task!',
                            'The tasks are successfully updated!.',
                            'success'
                        );
                    }, 3000);
                })
                .addCase(rejected, (state, action) => {
                    // const getErr = JSON.parse(action.error.message);

                    state.error = action.error;
                })
        };

        function completeTask() {
            var { pending, fulfilled, rejected } = extraActions.completetask;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    setTimeout(() => {
                        Swal.fire(
                            'Well done!',
                            'Task is completed successfully.',
                            'success'
                        );
                    }, 3000);
                })
                .addCase(rejected, (state, action) => {
                    // const getErr = JSON.parse(action.error.message);

                    state.error = action.error;
                })
        };

        function deleteTask() {
            var { pending, fulfilled, rejected } = extraActions.deletetask;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    setTimeout(() => {
                        Swal.fire(
                            'Well done!',
                            'Task is deleted successfully.',
                            'success'
                        );
                    }, 3000);
                })
                .addCase(rejected, (state, action) => {
                    // const getErr = JSON.parse(action.error.message);

                    state.error = action.error;
                })
        };

        function assignUserToTask() {
            var { pending, fulfilled, rejected } = extraActions.assignusertotask;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload.data;
                    console.log(data);
                    Toast('success', "The user has been successfully added to this task", 'tr', false, false, false, 3000, false);
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                })
        };

        function removeUserToTask() {
            var { pending, fulfilled, rejected } = extraActions.removeusertotask;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    Swal.fire(
                        'Task!',
                        'The user has been successfully removed from this task!.',
                        'success'
                    );
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                })
        };


        /* -------------------------------------------------------------------------- */
        /*                                TECHNOLOGIES                                */
        /* -------------------------------------------------------------------------- */
        function createTechnology() {
            var { pending, fulfilled, rejected } = extraActions.createtechnology;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    setTimeout(() => {
                        Swal.fire(
                            'Task!',
                            'The tecnhology is added successfully!.',
                            'success'
                        );
                    }, 3000);
                })
                .addCase(rejected, (state, action) => {
                    // const getErr = JSON.parse(action.error.message);

                    state.error = action.error;
                })
        };

        function getTechnology() {
            var { pending, fulfilled, rejected } = extraActions.gettechnology;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const technos = action.payload.data;
                    state.projecttech = technos;
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                })
        };


        /* -------------------------------------------------------------------------- */
        /*                                   CAREERS                                  */
        /* -------------------------------------------------------------------------- */

        function createCareer() {
            var { pending, fulfilled, rejected } = extraActions.createcareer;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    // console.log("createCareer", action.payload)
                    Swal.fire({
                        title: 'New Career',
                        text: 'New Career has been created successfully!',
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        icon: 'success'
                    })
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                    console.log("createCareer", action.error)
                })
        };

        function allCareers() {
            var { pending, fulfilled, rejected } = extraActions.careers;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload;
                    state.careers = data.data
                    // console.log("get all careers", action.payload)
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                    console.log("get all careers", action.error)
                })
        };

        function updateCareer() {
            var { pending, fulfilled, rejected } = extraActions.updatecareer;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    // console.log("update career", action.payload)
                    Swal.fire({
                        title: 'Update Career',
                        text: 'The career has been updated successfully!',
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        icon: 'success'
                    })
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                    console.log("update career", action.error)
                })
        };

        function deleteCareer() {
            var { pending, fulfilled, rejected } = extraActions.deletecareer;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    // console.log("delete career", action.payload)
                    Swal.fire({
                        title: 'Delete Career',
                        text: 'The career has been deleted successfully!',
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        icon: 'success'
                    })
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                    console.log("delete career", action.error)
                })
        };


        function addNewWorker() {
            var { pending, fulfilled, rejected } = extraActions.addNewWorker;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    // console.log("Adding new worker", action.payload)
                    Swal.fire({
                        title: 'Adding new worker',
                        text: 'The Worker has been added successfully!',
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        icon: 'success'
                    })
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                    Toast('error', action.error.message, 'tr', false, false, false, 3000, false);
                    console.log("Adding new worker", action.error);
                })
        };





        function workerCompleteTask() {
            var { pending, fulfilled, rejected } = extraActions.workerCompleteTask;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    setTimeout(() => {
                        Swal.fire(
                            'Well done!',
                            'Task complete! Notification sent successfully.',
                            'success'
                        );
                    }, 3000);
                })
                .addCase(rejected, (state, action) => {
                    // const getErr = JSON.parse(action.error.message);

                    state.error = action.error;
                })
        };

        function getAllContacts() {
            var { pending, fulfilled, rejected } = extraActions.allcontacts;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const contacts = action.payload.data;
                    state.contacts = contacts;
                    // setTimeout(() => {
                    //     Swal.fire(
                    //         'Well done!',
                    //         'Task complete! Notification sent successfully.',
                    //         'success'
                    //     );
                    // }, 3000);

                })
                .addCase(rejected, (state, action) => {
                    const getErr = JSON.parse(action.error.message);
                    console.log(getErr);
                    state.error = action.error;
                })
        };

        function createContact() {
            var { pending, fulfilled, rejected } = extraActions.createcontact;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    // const data = action.payload;
                    setTimeout(() => {
                        Swal.fire(
                            'Well done!',
                            'Notification sent successfully.',
                            'success'
                        );
                    }, 3000);
                    // console.log("send a contact form", data)
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                    let errorKeys = [...Object.keys(JSON.parse(action.error.message))];
                    // console.log("send a contact form", JSON.parse(action.error.message)[Object.keys(JSON.parse(action.error.message))])
                    errorKeys.map(key => {
                        return Toast('error', JSON.parse(action.error.message)[key], 'tr', false, false, false, 5000, false);
                    });
                })
        };

        function deleteContact() {
            var { pending, fulfilled, rejected } = extraActions.deletecontact;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    // console.log("assign a manager to a project", action.payload)
                    Swal.fire({
                        title: 'Deleting a Contact',
                        text: 'The contact has been deleted successfully!',
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        icon: 'success'
                    })
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                    console.log("deleting a contact", action.error)
                })
        };

        function get_project_idea() {
            var { pending, fulfilled, rejected } = extraActions.getprojectidea;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload;
                    console.log(data)
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                    console.log("deleting a contact", action.error)
                })
        };

    }
}