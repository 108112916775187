
import React, { useEffect, useState } from 'react';
import {
    checkPasswordFormat,
    parseUserAgent,
    Slang,
} from '../helpers';

import $ from 'jquery';
import Form from "react-validation/build/form";
import Button from 'react-validation/build/button';
import Input from "react-validation/build/input";
import { isEmail } from 'validator';
import { Input as MyInput } from '../components/myValidatorFields';

import { useDispatch } from 'react-redux';

import { authActions, userActions, store } from '../store';
import Fingerprint2 from 'fingerprintjs2';
import appConfig from '../config';



const ModalLogin = ({ setContent, lang }) => {

    const t = Slang('page.login');

    const [fp, setFp] = useState('');

    const [showLoading, setShowLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {

        Fingerprint2.get(components => {
            const values = components.map((component) => component.value);
            const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
            setFp(fingerprint);
        });

        setInterval(() => {
            if (sessionStorage.getItem('init-btn') !== null) {
                if (parseInt(sessionStorage.getItem('init-btn')) === 0) {
                    setShowLoading(false);
                } else {
                    setShowLoading(true);
                }
            }
        }, 1000);

        return () => {
        };
    }, []);




    //Login functions
    const _required = value => {
        if (!value) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    {t('errors.require')}
                </div>
            );
        }
    };

    const _email = value => {
        if (!isEmail(value)) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    {t('errors.email')}
                </div>
            );
        }
    };

    const _password = value => {
        if (!checkPasswordFormat(value)) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    {t('errors.password')}
                </div>
            );
        }
    };

    const handleEmailChange = e => {
        const email = e.target.value;
        setEmail(email);
    };

    const handlePasswordChange = e => {
        const password = e.target.value;
        setPassword(password);
    };


    const handleSubmit = e => {
        e.preventDefault();
        sessionStorage.setItem('init-btn', 1);

        localStorage.setItem('emailLogin', email);

        $.getJSON(appConfig.apip, (datas) => {
            setTimeout(() => {

                var user = store.getState().user.user;

                parseUserAgent(datas, datas.ip || datas.ipString, user.id)
                    .then(data => { 
                        dispatch(authActions.login({
                            email,
                            password,
                            ip: datas.ip || datas.ipString,
                            device: data,
                            fingerprint: fp
                        }));
                    });

                setTimeout(() => {
                    dispatch(userActions.getUser());
                }, 1000);

            }, 4000);
        });
    };

    return <>
        <div className="text-center mb-7">
            <h2>{lang('modal_login.title')}</h2>
            <p>{lang('modal_login.p')}
                <span className="js-animation-link text-primary link" role="button" style={{ cursor: "pointer" }}
                    data-hs-show-animation-options="{
                        &quot;targetSelector&quot;: &quot;#signupModalFormSignup&quot;,
                        &quot;groupName&quot;: &quot;idForm&quot;
                    }" data-hs-show-animation-link-group="idForm"
                    onClick={() => setContent(1)}
                >&nbsp;
                    {lang('modal_login.link_signup')}
                </span>
            </p>
        </div>


        <Form onSubmit={e => handleSubmit(e)}>
            <div className="mb-4">
                <label className="form-label" htmlFor="signupModalFormLoginEmail">{t('form.email')}</label>
                <Input
                    autoFocus
                    type="email"
                    className="form-control form-control-lg"
                    onChange={e => handleEmailChange(e)}
                    name="email"
                    id="signupModalFormLoginEmail"
                    placeholder="email@site.com"
                    aria-label="email@site.com"
                    validations={[_required, _email]}
                />
            </div>

            <div className="mb-4">
                <div className="d-flex justify-content-between align-items-center">
                    <label className="form-label" htmlFor="signupModalFormLoginPassword">{t('form.password')}</label>
                    <span className="form-label-link text-primary" style={{ cursor: "pointer" }} onClick={() => setContent(4)}>{t('form.text_forgot')}?</span>
                </div>

                <MyInput
                    type={showPassword ? 'text' : 'password'}
                    className="form-control form-control-lg"
                    name="password"
                    id="signupModalFormLoginPassword"
                    placeholder={t('form.pl_hld_pass')}
                    aria-label={t('form.pl_hld_pass')}
                    onChange={e => handlePasswordChange(e)}
                    minLength="8"
                    validations={[_required, _password]}
                >
                    <span
                        id="changePassTarget"
                        className="input-group-append input-group-text"
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        <i id="changePassIcon" className={showPassword ? 'bi-eye' : 'bi-eye-slash'}></i>
                    </span>
                </MyInput>
            </div>

            <div className="d-grid mb-3">
                {
                    !showLoading
                        ? <Button type="submit" className="btn btn-primary btn-lg">
                            {t('form.btn')}
                        </Button>
                        : <button type="button" className="btn btn-primary btn-lg" disabled={showLoading}>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {t('form.in_progress')}
                        </button>
                }

            </div>
        </Form>
    </>
};

export default ModalLogin;



