import React from 'react';

const Head = ({logout, navItem, lang}) => {
    return (
        <>
            <div className="navbar-dark bg-primary" style={{backgroundImage: "url(../assets/img/svg/components/wave-pattern-light.svg)"}}>
                <div className="container content-space-1 content-space-b-lg-3">
                    <div className="row align-items-center">
                        <div className="col">
                            <div className="d-none d-lg-block">
                                <h1 className="h2 text-white">{navItem}</h1>
                            </div>

                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-light mb-0">
                                    <li className="breadcrumb-item">{lang('header.li1')}</li>
                                    <li className="breadcrumb-item active" aria-current="page">{navItem}</li>
                                </ol>
                            </nav>
                        </div>

                        <div className="col-auto">
                            {/* <div className="d-none d-lg-block">
                                <a className="btn btn-soft-light btn-sm fw-bold" onClick={() => logout()} href="#">{lang('header.logout')} <i className="bi bi-box-arrow-right"></i></a>
                            </div> */}

                            <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse"
                                data-bs-target="#sidebarNav" aria-controls="sidebarNav" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span className="navbar-toggler-default">
                                    <i className="bi-list"></i>
                                </span>
                                <span className="navbar-toggler-toggled">
                                    <i className="bi-x"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Head;
