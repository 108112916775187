import React from 'react';
// import { HashLink as Link } from 'react-router-hash-link';
// import { dataTeam } from '../constants';
import { Team } from '../partials';
import { Slang } from '../helpers';
import configApp from '../config';
import { htmlizer } from './termsLayout';

const AboutLayout = () => {

    const t = Slang('page.about');

    return (
        <main id="content" role="main">
            <div className="container content-space-t-3 content-space-t-lg-5">
                <div className="w-lg-75 text-center mx-lg-auto">
                    <div className="mb-5 mb-md-10">
                        <h1 className="display-4">{t('title')}</h1>
                        <p className="lead">{htmlizer(t('desc'), 'lead').map(el => el === 0 ? "" : "")}</p>
                    </div>
                </div>

                {/* <div className="row gx-3">
                    <div className="col mb-3">
                        <div className="bg-img-start" style={{ backgroundImage: "url(./assets/img/480x320/img17.jpg)", height: "15rem" }}></div>
                    </div>

                    <div className="col-3 d-none d-md-block mb-3">
                        <div className="bg-img-start" style={{ backgroundImage: "url(./assets/img/480x320/img6.jpg)", height: "15rem" }}></div>
                    </div>

                    <div className="col mb-3">
                        <div className="bg-img-start" style={{ backgroundImage: "url(./assets/img/480x320/img14.jpg)", height: "15rem" }}></div>
                    </div>

                    <div className="w-100"></div>

                    <div className="col mb-3 mb-md-0">
                        <div className="bg-img-start" style={{ backgroundImage: "url(./assets/img/480x320/img25.jpg)", height: "15rem" }}></div>
                    </div>

                    <div className="col-4 d-none d-md-block mb-3 mb-md-0">
                        <div className="bg-img-start" style={{ backgroundImage: "url(./assets/img/480x320/img16.jpg)", height: "15rem" }}></div>
                    </div>

                    <div className="col">
                        <div className="bg-img-start" style={{ backgroundImage: "url(./assets/img/480x320/img12.jpg)", height: "15rem" }}></div>
                    </div>
                </div> */}

                <div style={{ textAlign: 'justify', fontSize: '1.2em' }} className='mb-5 p1'>
                    {htmlizer(t('p1'), 'p1').map(el => el === 0 ? "" : "")}
                </div>

                <div style={{ textAlign: 'justify', fontSize: '1.2em' }} className='mb-5 p2'>
                    {htmlizer(t('p2'), 'p2').map(el => el === 0 ? "" : "")}
                </div>

                <div style={{ textAlign: 'justify', fontSize: '1.2em' }} className='mb-5 p3'>
                    {htmlizer(t('p3'), 'p3').map(el => el === 0 ? "" : "")}
                </div>
            </div>

            <div className="container content-space-2 content-space-lg-3">
                <div className="row justify-content-lg-center">
                    <div className="col-sm-4 col-lg-3 mb-7 mb-sm-0">
                        <div className="text-center">
                            <h2 className="display-4">{configApp.app.about.experience_year}</h2>
                            <p className="small">{t('stats.exp')}</p>
                        </div>
                    </div>

                    <div className="col-sm-4 col-lg-3 mb-7 mb-sm-0">
                        <div className="text-center">
                            <h2 className="display-4">{configApp.app.about.accomplished_project}</h2>
                            <p className="small">{t('stats.accomplishment')}</p>
                        </div>
                    </div>

                    <div className="col-sm-4 col-lg-3">
                        <div className="text-center">
                            <h2 className="display-4">{configApp.app.about.happy_customers}%</h2>
                            <p className="small">{t('stats.happy_custo')}</p>
                        </div>
                    </div>
                </div>
            </div>



            <div className="border-top mx-auto" style={{ maxWidth: "25rem" }}></div>

            <div className="container content-space-2 content-space-lg-3">
                <div className="row justify-content-lg-between">
                    <div className="col-lg-5 mb-5 mb-lg-0">
                        <h1 className='text-uppercase'>{t('section1.title')}</h1>
                        <span>{t('section1.subtitle')}</span>
                    </div>

                    <div className="col-lg-7 ddesc" style={{ textAlign: 'justify' }}>
                        {htmlizer(t('section1.desc'), 'ddesc').map(el => el === 0 ? "" : "")}
                    </div>
                </div>
            </div>

            <div className="border-top mx-auto" style={{ maxWidth: "25rem" }}></div>

            <div className="container content-space-2 content-space-lg-3 alt">
            {htmlizer(t('section1.alert'), 'alt').map(el => el === 0 ? "" : "")}
            </div>

            {/* <Team lang={t} /> */}

        </main>
    );
}

export default AboutLayout;
