import { UserDisconnectDevices } from 'components/modals';
import { formatDate } from 'helpers';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from 'store';

const DevicesConnected = ({ setModal, lang }) => {



    const [loading, setLoading] = useState(true);
    const [thisAgent, setThisAgent] = useState(null);
    // const [dbDevices, setdbDevices] = useState([]);
    const dbDevices = useSelector(x => x.user.devices);


    useEffect(() => {
        setThisAgent(window.navigator.userAgent);
        setTimeout(() => {
            // setdbDevices(store.getState().user.devices !== null ? store.getState().user.devices : []);
            setLoading(false);
        }, 3000);

        let tim = setInterval(() => {
            // setdbDevices(store.getState().user.devices !== null ? store.getState().user.devices : []);
        }, 300);

        return () => clearInterval(tim);
    }, []);

    return <div className="card">
        <div className="card-header border-bottom">
            <h5 className="card-header-title">{lang('items.security.devices.title')}</h5>

        </div>

        <div className="card-body">
            {
                loading
                    ? <div className='text-center'>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </div>
                    : <>
                        <div className="text-muted">
                            {/* <p>
                                If necessary, you may logout of all of your other browser sessions across all of your devices.
                                Some of your recent sessions are listed below; however, this list may not be exhaustive.
                                If you feel your account has been compromised, you should also update your password.
                            </p> */}
                            <p>{lang('items.security.devices.muted')}</p>
                        </div>
                        <div className="list-group list-group-flush list-group-no-gutters">

                            {
                                [...dbDevices].length !== 0
                                    ? dbDevices.map((device, key) => {
                                        return device !== null ? <div className="list-group-item" key={key}>
                                            <div className="d-flex">
                                                <div className="flex-shrink-0">
                                                    <i className={`bi-${device.payload.device.type === 'Desktop' || device.payload.device.type === 'desktop' ? 'laptop' : 'phone'} list-group-icon h2`}></i>
                                                </div>

                                                <div className="flex-grow-1">
                                                    <div className="row align-items-center">
                                                        <div className="col-sm mb-1 mb-sm-0">
                                                            <h6 className="mb-1">{device.payload.device.type !== 'unknown' ? device.payload.device.type : `#${key + 1} ${lang('items.security.devices.info_device.title')}`} <span className="badge bg-primary ms-1">{device.payload.info.ua === thisAgent ? lang('items.security.devices.info_device.current') : null}</span></h6>
                                                            <ul className="list-inline list-separator text-body small">
                                                                <li className="list-inline-item"><span className="fw-semi-bold">{lang('items.security.devices.info_device.ip')}:</span> {device.ip_address}</li>
                                                                <li className="list-inline-item"><span className="fw-semi-bold">{lang('items.security.devices.info_device.last_connect')}:</span> {device.last_activity === null ? 'Now' : formatDate(device.last_activity, true)}</li>
                                                            </ul>
                                                        </div>

                                                        <div className="col-sm mb-1 mb-sm-0">
                                                            {/* <h6 className="mb-1">User Agent <span className="text-muted ms-1" style={{ fontSize: 12 }}>{device.user_agent}</span></h6> */}
                                                            <ul className="list-inline list-separator text-body small">
                                                                <li className="list-inline-item"><span className="fw-semi-bold text-black">{lang('items.security.devices.info_device.browser')}:</span> {device.browser}({device.browserVersion})</li>
                                                                <li className="list-inline-item"><span className="fw-semi-bold text-black">{lang('items.security.devices.info_device.os')}:</span> {device.os}</li>
                                                            </ul>

                                                            <ul className="list-inline text-body small" style={{
                                                                display: 'flex',
                                                                width: '100%',
                                                                flexWrap: 'wrap',
                                                                marginTop: 3
                                                            }}>
                                                                <li className="list-inline-item" style={{ marginRight: '7%' }}>
                                                                    <span style={{ width: '10%' }} className="fw-semi-bold text-black">{lang('items.security.devices.info_device.country')}:</span> {device.payload.location.countryName} ({device.payload.location.countryCode2})
                                                                </li>
                                                                <li className="list-inline-item" style={{ marginRight: '7%' }}>
                                                                    <span style={{ width: '10%' }} className="fw-semi-bold text-black">{lang('items.security.devices.info_device.capital')}:</span> {device.payload.location.capitalCity}
                                                                </li>
                                                                <li className="list-inline-item" style={{ marginRight: '7%' }}>
                                                                    <span style={{ width: '10%' }} className="fw-semi-bold text-black">{lang('items.security.devices.info_device.continent')}:</span> {device.payload.location.continentName}
                                                                </li>
                                                                <li className="list-inline-item" style={{ marginRight: '7%' }}>
                                                                    <span style={{ width: '10%' }} className="fw-semi-bold text-black">{lang('items.security.devices.info_device.clalling')}:</span> ({device.payload.location.callingCode})
                                                                </li>
                                                                <li className="list-inline-item" style={{ marginRight: '7%' }}>
                                                                    <span style={{ width: '10%' }} className="fw-semi-bold text-black">{lang('items.security.devices.info_device.currency')}:</span> {device.payload.location.currencyCode}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        {
                                                            //device.payload.info.ua === thisAgent
                                                            (device.payload.info.ua.split(' ')[0] === thisAgent.split(' ')[0] &&
                                                                device.payload.info.ua.split(' ')[1] === thisAgent.split(' ')[1] &&
                                                                device.payload.info.ua.split(' ')[2] === thisAgent.split(' ')[2] &&
                                                                device.payload.info.ua.split(' ')[3] === thisAgent.split(' ')[3] &&
                                                                device.payload.info.ua.split(' ')[4] === thisAgent.split(' ')[4] &&
                                                                device.payload.info.ua.split(' ')[5] === thisAgent.split(' ')[5] &&
                                                                device.payload.info.ua.split(' ')[6] === thisAgent.split(' ')[6] &&
                                                                device.payload.info.ua.split(' ')[7] === thisAgent.split(' ')[7])
                                                                ? null
                                                                : <div className="col-sm-auto">
                                                                    <button
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#userDeviceLogout"
                                                                        className="btn btn-white btn-xs"
                                                                        onMouseOver={() => setModal(<UserDisconnectDevices setModal={setModal} data={device} />)}
                                                                    >{lang('items.security.devices.info_device.log_out')}</button>
                                                                </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div> : <div className='text-center'>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </div>
                                    })
                                    : <div className='text-center'>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </div>
                            }


                            {/* <div className="list-group-item">
                                <div className="d-flex">
                                    <div className="flex-shrink-0">
                                        <i className="bi-laptop list-group-icon"></i>
                                    </div>

                                    <div className="flex-grow-1">
                                        <div className="row align-items-center">
                                            <div className="col-sm mb-1 mb-sm-0">
                                                <h6 className="mb-1">Dell XPS 15 <span className="badge bg-primary ms-1">Current</span></h6>
                                                <ul className="list-inline list-separator text-body small">
                                                    <li className="list-inline-item"><span className="fw-semi-bold">IP address:</span> 213.230.93.79</li>
                                                    <li className="list-inline-item"><span className="fw-semi-bold">Last active:</span> Now</li>
                                                </ul>
                                            </div>

                                            <div className="col-sm-auto">
                                                <button className="btn btn-white btn-xs">Log out</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="list-group-item">
                                <div className="d-flex">
                                    <div className="flex-shrink-0">
                                        <i className="bi-display list-group-icon"></i>
                                    </div>

                                    <div className="flex-grow-1">
                                        <div className="row align-items-center">
                                            <div className="col-sm mb-1 mb-sm-0">
                                                <h6 className="mb-1">Microsoft Studio 2</h6>
                                                <ul className="list-inline list-separator text-body small">
                                                    <li className="list-inline-item"><span className="fw-semi-bold">IP address:</span> 213.230.93.79</li>
                                                    <li className="list-inline-item"><span className="fw-semi-bold">Last active:</span> 3 days ago</li>
                                                </ul>
                                            </div>

                                            <div className="col-sm-auto">
                                                <button className="btn btn-white btn-xs">Log out</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="list-group-item">
                                <div className="d-flex">
                                    <div className="flex-shrink-0">
                                        <i className="bi-phone list-group-icon"></i>
                                    </div>

                                    <div className="flex-grow-1">
                                        <div className="row align-items-center">
                                            <div className="col-sm mb-1 mb-sm-0">
                                                <h6 className="mb-1">Google Pixel 3a</h6>
                                                <ul className="list-inline list-separator text-body small">
                                                    <li className="list-inline-item"><span className="fw-semi-bold">IP address:</span> 213.230.93.79</li>
                                                    <li className="list-inline-item"><span className="fw-semi-bold">Last active:</span> 22 minutes ago</li>
                                                </ul>
                                            </div>

                                            <div className="col-sm-auto">
                                                <button className="btn btn-white btn-xs">Log out</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </>
            }
        </div>
    </div>;
}

export default DevicesConnected;
