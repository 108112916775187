import React, { useEffect, useState } from 'react';
import { AdminContentLoader } from 'components';

const SettingsContent = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {



        setTimeout(() => {
            setLoading(false)
        }, 2000);

        return () => {
        };
    }, []);

    return loading
        ? <AdminContentLoader />
        : <>Settings content</>


}

export default SettingsContent;
