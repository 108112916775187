import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
// import { animated, useSpring } from '@react-spring/web';
import Button from 'react-validation/build/button';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Textarea from "react-validation/build/textarea";
import { isEmail } from 'validator';
import Fingerprint2 from 'fingerprintjs2';
import $ from 'jquery';
import appConfig from '../../config';




const HomeProjectsModal = ({ setModal }) => {

    const [showLoading, setShowLoading] = useState(false);

    const [fp, setFp] = useState('');

    const dispatch = useDispatch();


    const [info, setInfo] = useState({
        lastname: '',
        firstname: '',
        email: '',
        phone: '',
        description: '',
    });
    const [localIp, setLocalIp] = useState(appConfig.app.app_env === "production" ? true : false);




    const _rrequired = value => {
        if (!value) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    This field is required!
                </div>
            );
        }
    };

    const _fname = value => {
        return value !== ""
            ? value.length < 4
                ? <div className="alert alert-soft-danger mt-2" role="alert">The Firstname field require 4 caracters.</div>
                : null
            : <div className="alert alert-soft-danger mt-2" role="alert">This field is empty.</div>
    };

    const _lname = value => {
        return value !== ""
            ? value.length < 4
                ? <div className="alert alert-soft-danger mt-2" role="alert">The Lastname field require 4 caracters.</div>
                : null
            : <div className="alert alert-soft-danger mt-2" role="alert">This field is empty.</div>
    };

    const _textarea = value => {
        return value !== ""
            ? value.length < 100
                ? <div className="alert alert-soft-danger mt-2" role="alert">The Lastname field require 100 caracters.</div>
                : null
            : <div className="alert alert-soft-danger mt-2" role="alert">This field is empty.</div>
    };

    const _email = value => {
        if (!isEmail(value)) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    This is not a valid email.
                </div>
            );
        }
    };

    const handleChange = e => {
        setInfo({ ...info, [e.target.name]: e.target.value });
    }






    const handleSubmit = event => {
        event.preventDefault();


        Fingerprint2.get(components => {
            const values = components.map((component) => component.value);
            const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);

            //setFp(fingerprint);

            localIp
                ? $.getJSON("http://jsonip.com", function (data) {
                    setTimeout(() => {

                        var device = {};
                        var payload = {};

                        var agent = window.navigator;

                        payload['ip_address'] = data.ip;
                        device['ip_address'] = data.ip;


                        $.post(
                            'https://api.apicagent.com',
                            { ua: agent.userAgent },
                            data => {
                                setShowLoading(false);

                                payload['browser_family'] = data.browser_family;
                                payload['browser_engine'] = data.client.engine;
                                payload['browser_engine_version'] = data.client.engine_version;
                                payload['browser'] = data.client.name;
                                device['browser'] = data.client.name;
                                payload['browser_type'] = data.client.type;
                                payload['browser_version'] = data.client.version;
                                device['browser_version'] = data.client.version;

                                payload['device_brand'] = data.device.brand;
                                payload['device_model'] = data.device.model;
                                payload['device_type'] = data.device.type;

                                device['device_type'] = data.device.type;

                                payload['os'] = data.os.name;
                                device['os'] = data.os.name;
                                payload['platform'] = data.os.platform;
                                payload['osVersion'] = data.version;
                                device['osVersion'] = data.version;
                                payload['os_family'] = data.os_family;
                                payload['user_agent'] = data.user_agent;

                                device['payload'] = payload;


                                // dispatch(authActions.register({
                                //     first_name: fname,
                                //     email,
                                //     name: lname,
                                //     ip: data.ip,
                                //     device,
                                //     fingerprint
                                // }));
                            }
                        );


                        setTimeout(() => {
                            // dispatch(userActions.getUser());
                        }, 1000);

                    }, 4000);
                })
                : setTimeout(() => {

                    var payload = {};
                    var device = {};

                    var agent = window.navigator;

                    payload['ip_address'] = '192.168.1.1';
                    device['ip_address'] = '192.168.1.1';


                    $.post(
                        'https://api.apicagent.com',
                        { ua: agent.userAgent },
                        data => {
                            setShowLoading(false);

                            payload['browser_family'] = data.browser_family;
                            payload['browser_engine'] = data.client.engine;
                            payload['browser_engine_version'] = data.client.engine_version;
                            payload['browser'] = data.client.name;
                            device['browser'] = data.client.name;
                            payload['browser_type'] = data.client.type;
                            payload['browser_version'] = data.client.version;
                            device['browser_version'] = data.client.version;

                            payload['device_brand'] = data.device.brand;
                            payload['device_model'] = data.device.model;
                            payload['device_type'] = data.device.type;

                            device['device_type'] = data.device.type;

                            payload['os'] = data.os.name;
                            device['os'] = data.os.name;
                            payload['platform'] = data.os.platform;
                            payload['osVersion'] = data.version;
                            device['osVersion'] = data.version;
                            payload['os_family'] = data.os_family;
                            payload['user_agent'] = data.user_agent;

                            device['payload'] = payload;

                            console.log(JSON.stringify({
                                first_name: info.firstname,
                                email: info.email,
                                name: info.lastname,
                                phone: info.phone,
                                description: info.description,
                                ip: data.ip,
                                device,
                                fingerprint
                            }));

                            // dispatch(authActions.register({
                            //     first_name: fname,
                            //     email,
                            //     name: lname,
                            //     ip: data.ip,
                            //     device,
                            //     fingerprint
                            // }));
                        }
                    );


                    setTimeout(() => {
                        // dispatch(userActions.getUser());
                    }, 1000);

                }, 4000);
        });

        setShowLoading(true);



    };

    return (
        <div className="modal fade bd-example-modal-xl" id='homeModalProject' data-bs-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="homeModalProjectTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content" style={{
                    backgroundColor: 'rgb(220, 230, 250)'
                }} >

                    <div className="modal-header" style={{justifyContent:'center'}}>
                        {/* <h1 className="modal-title h1" id="homeModalProjectTitle"> <i className='fa fa-list-ol'></i> <span className='text-primary'>Exprimer votre besoin</span> </h1> */}
                        <h1 className="modal-title h1" id="homeModalProjectTitle"> <i className='fa fa-list-ol'></i> <span className='text-primary'>Express your need</span> </h1>
                        {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                    </div>


                    <div className="modal-body">
                        <Form onSubmit={e => handleSubmit(e)} >
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="signupModalFormSignupName">First Name</label>
                                        <Input
                                            type="text"
                                            className="form-control form-control-lg"
                                            name="firstname"
                                            id="signupModalFormSignupName"
                                            placeholder="Alan"
                                            aria-label="Alan"
                                            validations={[_rrequired, _fname]}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="signupModalFormSignupName">Last Name</label>
                                        <Input
                                            type="text"
                                            className="form-control form-control-lg"
                                            name="lastname"
                                            id="signupModalFormSignupName"
                                            placeholder="Peter"
                                            aria-label="Peter"
                                            validations={[_rrequired, _lname]}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="phone">Your phone number</label>
                                        <Input
                                            type="tel"
                                            className="form-control form-control-lg"
                                            name="phone"
                                            id="phone"
                                            placeholder="+x xxx xxxxx"
                                            aria-label="+x xxx xxxxx"
                                            validations={[_rrequired,]}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="signupModalFormSignupEmail">Your email</label>
                                        <Input
                                            type="email"
                                            className="form-control form-control-lg"
                                            name="email"
                                            id="signupModalFormSignupEmail"
                                            placeholder="email@site.com"
                                            aria-label="email@site.com"
                                            validations={[_rrequired, _email]}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>



                            <div className="mb-2">
                                <label className="form-label" htmlFor="description">Details of your need</label>

                                <Textarea className="form-control form-control-lg"
                                    rows={10}
                                    name="description"
                                    id="description"
                                    placeholder="Describe your need"
                                    aria-label="Describe your need"
                                    validations={[_rrequired, _textarea]}
                                    onChange={handleChange}
                                />
                            </div>




                            <div className="d-grid mt-4 col-md-6 offset-3">
                                {
                                    !showLoading
                                        ? <Button type="submit" className="btn btn-soft-primary text-white fw-bold btn-lg">
                                            Submit
                                        </Button>
                                        : <button type="button" className="btn btn-soft-primary text-white fw-bold btn-lg" disabled={showLoading}>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Submitting...
                                        </button>
                                }
                            </div>
                        </Form>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-soft-danger" onClick={() => setModal(<></>)} data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeProjectsModal;



// const Anim = () => {
//     const [springs, api] = useSpring(() => ({
//         from: { x: 0 },
//     }))

//     const handleClick = () => {
//         api.start({
//             from: {
//                 x: 0,
//             },
//             to: {
//                 x: 500,
//             },
//         })
//     }


//     return (
//         <animated.div
//             onClick={handleClick}
//             style={{
//                 width: 80,
//                 height: 80,
//                 borderRadius: 8,
//                 ...springs
//             }}
//         >d</animated.div>
//     )
// }
