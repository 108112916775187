import React, { useEffect, useState } from 'react';
import { calculProgressDate } from '../helpers';
import $ from 'jquery';
import appConfig from '../config';
const TimeProgressBarTask = ({ task }) => {

    const [now, setNow] = useState("1970-01-01 00:00:00");

    useEffect(() => {
        
        $.get(
            appConfig.get_json_php,
            data=> setNow(data.time)
        );
        
        
        return () => {

        };
    }, []);

    return <div style={{
        marginTop: '5px',
        padding: 5,
        border: '1px solid rgba(120, 120, 120, .2)',
        boxShadow: '1px 2px 3px rgba(190, 190, 190, .8)',
        borderRadius: 5
    }}>

        <div>{calculProgressDate(task.start_date, task.end_date, now).rest}</div>

        <div className="progress" style={{ height: "20px", marginTop: 3 }}>
            <div
                className="progress-bar progress-bar-striped"
                role="progressbar"
                style={{
                    width: calculProgressDate(task.start_date, task.end_date, now).val,
                    backgroundColor: calculProgressDate(task.start_date, task.end_date, now).color
                }}
                aria-valuenow={calculProgressDate(task.start_date, task.end_date, now).val.split('%')[0]}
                aria-valuemin="0" aria-valuemax="100">
            </div>
        </div>
    </div>;
}

export default TimeProgressBarTask;
