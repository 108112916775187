import React, { useEffect, useState } from 'react';
import { MiniLoad } from '../components';
import { HashLink as Link } from 'react-router-hash-link';
import Form from "react-validation/build/form";
import Button from 'react-validation/build/button';

import Input from "react-validation/build/input";
import { isEmail } from 'validator';
import { Toast, checkPhoneNumberFormat, Slang, history } from '../helpers';
// import TextArea from "react-validation/build/textarea";

import $ from 'jquery';
import { Locations } from '../constants';
import { store } from '../store';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { htmlizer } from './termsLayout';


const CareerOverViewLayout = () => {

    const [load, setLoad] = useState(true);
    const [job, setJob] = useState([]);
    const jobs = useSelector(x => x.guest.careers);

    const [showLoading, setShowLoading] = useState(false);
    const [auth, setAuth] = useState(store.getState().user.user.id !== undefined && store.getState().auth.token !== null);

    const t = Slang('page.career_overview');

    // apply career informations

    const [allInfos, setAllInfos] = useState({
        cvUpload: null,
        letteMotivUpload: null,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',
        linkedin: '',
        github: '',
        portfolio: '',
        website: '',
        wishSalary: 0,
        availableDate: '',
        additionalInfos: ''
    });

    const acccepted = [
        'application/pdf',
        'application/vnd.oasis.opendocument.text',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];


    const selectCvFile = (event) => {
        let { name } = event.target;

        if (acccepted.includes(event.target.files[0].type)) {
            setAllInfos({ ...allInfos, [name]: $("#resumeCVCareersForm")[0].files[0] });
        } else {
            Toast('warning', 'Invalid document format. Please select a .pdf, .doc, .docx or .odt file', 'tr', false, false, true, 3000, false);
        }
    };

    const selectLettreMotivFile = (event) => {
        let { name } = event.target;
        if (acccepted.includes(event.target.files[0].type)) {
            setAllInfos({ ...allInfos, [name]: $("#resumeLetterCareersForm")[0].files[0] });
        } else {
            Toast('warning', 'Invalid document format. Please select a .pdf, .doc, .docx or .odt file', 'tr', false, false, true, 3000, false);
        }
    };


    const _required = value => {
        if (!value) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    This field is required!
                </div>
            );
        }
    };

    const _filesAcceptedCv = value => {
        if (!acccepted.includes($("#resumeCVCareersForm")[0].files[0].type)) {
            return <div className="alert alert-soft-danger mt-2" role="alert">
                Please, select the appropriate format.
            </div>
        }
    };

    const _filesAcceptedResume = value => {
        if (!acccepted.includes($("#resumeLetterCareersForm")[0].files[0].type)) {
            return <div className="alert alert-soft-danger mt-2" role="alert">
                Please, select the appropriate format.
            </div>
        }
    };

    const _email = value => {
        if (!isEmail(value)) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    This is not a valid email.
                </div>
            );
        }
    };


    // Handle user informations
    const handleChange = e => {
        let { name, value } = e.target;
        setAllInfos({ ...allInfos, [name]: value });
    };

    const _phone = value => {
        if (!checkPhoneNumberFormat(value)) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    Please enter a valid phone format
                </div>
            );
        }
    };

    useEffect(() => {
        if (jobs.filter(job => job.id === parseInt(window.location.pathname.split('/')[2])).length === 0) {
            history.navigate('/careers');
            Toast('error', 'Error: Job not found!', 'tr', false, false, false, 7000, false);
        }

        let tim1 = setTimeout(() => {
            setJob(jobs.filter(job => job.id === parseInt(window.location.pathname.split('/')[2]))[0]);
        }, 2000);

        let tim2 = setInterval(() => {
            $('.career-desctipion').html(job.description);
            setAuth(store.getState().user.user.id !== undefined && store.getState().auth.token !== null);
        }, 400);


        let tim3 = setTimeout(() => {
            setLoad(false)
        }, 3000);

        let titi = setInterval(() => {
            $('iframe').remove();

        }, 200);

        let tt = setTimeout(() => {
            let html = `<script>
            new HSHeader('#header').init()

            new HSMegaMenu('.js-mega-menu', {
                desktop: {
                    position: 'left'
                }
            })

            new HSShowAnimation('.js-animation-link')

            HSBsValidation.init('.js-validate', {
                onSubmit: data => {
                    data.event.preventDefault()
                    //alert('Submited')
                }
            })

            HSBsDropdown.init()

            // new HSGoTo('.js-go-to')
            </script > `
            $('body').append(html);
        }, 4000);

        return () => {
            clearTimeout(tt);
            clearInterval(titi);
            clearInterval(tim2);
            clearTimeout(tim1);
            clearTimeout(tim3);
        };

    }, []);


    const handleSubmit = e => {
        e.preventDefault();
        setShowLoading(true);


        var fd = new FormData();

        let adInfo = {
            extra_info: allInfos.additionalInfos,
            firstName: allInfos.firstName,
            lastName: allInfos.lastName,
            email: allInfos.email,
            phone: allInfos.phone,
            company: allInfos.company,
            linkedin: allInfos.linkedin,
            github: allInfos.github
        };

        fd.append('user_id', store.getState().user.user.id);
        fd.append('career_id', job.id);
        fd.append('available_start_date', allInfos.availableDate);
        fd.append('desired_salary', allInfos.wishSalary);
        fd.append('extra_info', adInfo);
        fd.append('cv', allInfos.cvUpload);//$("#resumeCVCareersForm")[0].files[0]);
        fd.append('lettremotivation', allInfos.letteMotivUpload);//$("#resumeLetterCareersForm")[0].files[0]);




        $.ajax({
            type: 'POST',
            url: `${process.env.REACT_APP_API_URL}/create/usercareers`,
            data: fd,
            dataType: 'json',
            contentType: false,
            cache: false,
            processData: false,
            beforeSend: xhr => {
                xhr.setRequestHeader("Authorization", `Bearer ${store.getState().auth.token}`);
            },
            success: response => {
                if (response.status === 'success') {
                    Toast('success', 'Application submitted successfully.', 'tr', false, false, true, 3000, false);
                    setTimeout(() => {
                        setShowLoading(false);
                        window.location.href = '/careers';
                    }, 3200);
                } else {
                    Toast('error', "Please contact the support at: <a href='/contact'>Support</a>", 'tr', false, false, true, 20000, true);
                    console.log(response);
                    setShowLoading(false);
                }
            },
            fail: error => {
                console.log(error);
                setShowLoading(false);
            }
        });
    };




    return (<>
        <main id="content" role="main" className="bg-light">
            <div className="container content-space-t-3 content-space-t-lg-5 content-space-b-2 content-space-b-lg-3">
                <div className="w-lg-75 mx-auto">
                    <div className="mb-5">
                        <Link className="link" to="/careers">
                            <i className="bi-chevron-left small ms-1"></i> {t('btn_list')}
                        </Link>
                    </div>

                    <div className="card card-lg">
                        <div className="card-body">
                            {
                                load
                                    ? <>
                                        <div className="card card-lg">
                                            <div className="card-body">
                                                <MiniLoad />
                                            </div>
                                        </div>
                                    </>
                                    : <>
                                        <div className="row justify-content-sm-between align-items-sm-center mb-5">
                                            <div className="col-sm mb-3 mb-sm-0">
                                                <h1 className="card-title h2">{job.title}</h1>
                                                <p className="card-text">{Locations.filter(loc => loc.code === job.location)[0].name} - {job.category} - {job.cycle} - <span style={{ textTransform: 'capitalize' }}>{job.status}</span></p>
                                            </div>

                                            <div className="col-sm-auto">
                                                <Link className="js-go-to position-static btn btn-primary btn-transition" to={auth ? "#applyForJob" : "/signin"}>
                                                    {t('btn_apply')}
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="mb-5 career-desctipion">
                                            <div className="pragraph">
                                                {htmlizer(job.description, 'pragraph').map(el => el === 0 ? "" : "")}
                                            </div>
                                        </div>

                                        <div className="mb-5">
                                            <h2 className="h4">{t('we_propose')}:</h2>
                                            <ul>{formListItems(job.propose).map((item, key) => <li key={key}>{item}</li>)}</ul>
                                        </div>

                                        <div className="mb-5">
                                            <h3 className="h4">{t('requis')}:</h3>
                                            <ul> {formListItems(job.requierment).map((item, key) => <li key={key}>{item}</li>)}</ul>
                                        </div>

                                        <div className="mb-5">
                                            <h3 className="h4">{t('you_have')}:</h3>
                                            <ul> {formListItems(job.skills).map((item, key) => <li key={key}>{item}</li>)}</ul>
                                        </div>

                                        <div className="mb-5">
                                            <h3 className="h4">{t('extra')}:</h3>
                                            <ul> {formListItems(job.extraSkills).map((item, key) => <li key={key}>{item}</li>)}</ul>
                                        </div>

                                        <div className="mb-5">
                                            <p>{job.text}</p>
                                        </div>

                                        {
                                            auth ? <>
                                                <div id="applyForJob" className="border-top text-center pt-5 pt-md-9 mb-5 mb-md-9">
                                                    <h2>{t('apply.title')}</h2>
                                                </div>
                                                <Form className="js-validate" onSubmit={handleSubmit} encType="multipart/form-data">

                                                    <div className="mb-5">
                                                        <h3>{t('apply.subtitle')}</h3>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label htmlFor="resumeCVCareersForm" className="col-sm-4 col-form-label">{t('apply.form.cv')}<sup className='text-danger text-bold'>*</sup></label>
                                                        <div className="col-sm-8">
                                                            <Input
                                                                type="file"
                                                                className="js-file-attach form-control"
                                                                onChange={e => selectCvFile(e)}
                                                                name="cvUpload"
                                                                id="resumeCVCareersForm"
                                                                validations={[_required, _filesAcceptedCv]}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label htmlFor="resumeCVCareersForm" className="col-sm-4 col-form-label">{t('apply.form.resume')}<sup className='text-danger text-bold'>*</sup></label>
                                                        <div className="col-sm-8">
                                                            <Input
                                                                type="file"
                                                                className="js-file-attach form-control"
                                                                onChange={e => selectLettreMotivFile(e)}
                                                                name="letteMotivUpload"
                                                                id="resumeLetterCareersForm"
                                                                validations={[_required, _filesAcceptedResume]}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label htmlFor="firstNameCareer" className="col-sm-4 col-form-label">{t('apply.form.fname')}<sup className='text-danger text-bold'>*</sup></label>
                                                        <div className="col-sm-8">
                                                            <Input
                                                                type="text"
                                                                className="form-control form-control-lg"
                                                                onChange={e => handleChange(e)}
                                                                name="firstName"
                                                                id="firstNameCareer"
                                                                placeholder="John Doe"
                                                                aria-label="John Doe"
                                                                validations={[_required]}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label htmlFor="LastName" className="col-sm-4 col-form-label">{t('apply.form.lname')}<sup className='text-danger text-bold'>*</sup></label>
                                                        <div className="col-sm-8">
                                                            <Input
                                                                type="text"
                                                                className="form-control form-control-lg"
                                                                onChange={e => handleChange(e)}
                                                                name="lastName"
                                                                id="LastName"
                                                                placeholder="John Doe"
                                                                aria-label="John Doe"
                                                                validations={[_required]}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label htmlFor="emailCareersForm" className="col-sm-4 col-form-label">{t('apply.form.email')}<sup className='text-danger text-bold'>*</sup></label>
                                                        <div className="col-sm-8">
                                                            <Input
                                                                type="email"
                                                                className="form-control form-control-lg"
                                                                onChange={e => handleChange(e)}
                                                                name="email"
                                                                id="emailCareersForm"
                                                                placeholder="email@site.com"
                                                                aria-label="email@site.com"
                                                                validations={[_required, _email]}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label htmlFor="phoneCareersForm" className="col-sm-4 col-form-label">{t('apply.form.phone')}<sup className='text-danger text-bold'>*</sup></label>
                                                        <div className="col-sm-8">
                                                            <Input
                                                                type="tel"
                                                                className="form-control form-control-lg"
                                                                onChange={e => handleChange(e)}
                                                                name="phone"
                                                                id="phoneCareersForm"
                                                                placeholder="Enter your phone"
                                                                aria-label="Enter your phone"
                                                                validations={[_phone, _required]}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label htmlFor="currentCompanyCareersForm" className="col-sm-4 col-form-label">{t('apply.form.cur_comp')}</label>
                                                        <div className="col-sm-8">
                                                            <Input
                                                                type="text"
                                                                className="form-control form-control-lg"
                                                                onChange={e => handleChange(e)}
                                                                name="company"
                                                                id="currentCompanyCareersForm"
                                                                placeholder="Google"
                                                                aria-label="Google"
                                                            />
                                                        </div>
                                                    </div>

                                                    <hr className="my-5 my-sm-10" />

                                                    <div className="mb-5">
                                                        <h3>{t('apply.form.link_title')}</h3>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label htmlFor="linkedinURLCareersForm" className="col-sm-4 col-form-label">{t('apply.form.linkedin')}</label>
                                                        <div className="col-sm-8">
                                                            <Input
                                                                type="text"
                                                                className="form-control form-control-lg"
                                                                onChange={e => handleChange(e)}
                                                                name="linkedin"
                                                                id="linkedinURLCareersForm"
                                                                placeholder="www.linkedin.com/jacob"
                                                                aria-label="www.linkedin.com/jacob"
                                                            />
                                                        </div>
                                                    </div>



                                                    <div className="row mb-3">
                                                        <label htmlFor="githubURLCareersForm" className="col-sm-4 col-form-label">{t('apply.form.github')}</label>
                                                        <div className="col-sm-8">
                                                            <Input
                                                                type="text"
                                                                className="form-control form-control-lg"
                                                                onChange={e => handleChange(e)}
                                                                name="github"
                                                                id="githubURLCareersForm"
                                                                placeholder="www.github.com/jacob"
                                                                aria-label="www.github.com/jacob"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label htmlFor="portfolioURLCareersForm" className="col-sm-4 col-form-label">{t('apply.form.portfolio')}</label>
                                                        <div className="col-sm-8">
                                                            <Input
                                                                type="text"
                                                                className="form-control form-control-lg"
                                                                onChange={e => handleChange(e)}
                                                                name="portfolio"
                                                                id="portfolioURLCareersForm"
                                                                placeholder="www.mysite.com/jacob"
                                                                aria-label="www.mysite.com/jacob"
                                                            />

                                                        </div>
                                                    </div>

                                                    {/* <div className="row mb-3">
                                                        <label htmlFor="otherWebsiteCareersForm" className="col-sm-4 col-form-label">Other
                                                            website</label>
                                                        <div className="col-sm-8">
                                                            <input type="password" className="form-control" id="otherWebsiteCareersForm"
                                                                name="otherWebsiteCareersFormName" placeholder="www.site.com"
                                                                aria-label="www.site.com" />
                                                        </div>
                                                    </div> */}

                                                    <hr className="my-5 my-sm-10" />

                                                    <div className="mb-5">
                                                        <h3>{t('apply.form.text_info_sup')}</h3>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label htmlFor="desiredSalaryCareersForm" className="col-sm-4 col-form-label">{t('apply.form.salary')}<sup className='text-danger text-bold'>*</sup></label>
                                                        <div className="col-sm-8">
                                                            <Input
                                                                type="number"
                                                                className="form-control form-control-lg"
                                                                onChange={e => handleChange(e)}
                                                                name="wishSalary"
                                                                id="desiredSalaryCareersForm"
                                                                placeholder="Enter your desire salary"
                                                                validations={[_required]}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label htmlFor="availableStartDateCareersForm" className="col-sm-4 col-form-label">{t('apply.form.available')}<sup className='text-danger text-bold'>*</sup></label>
                                                        <div className="col-sm-8">
                                                            <Input
                                                                type="date"
                                                                className="form-control form-control-lg"
                                                                onChange={e => handleChange(e)}
                                                                name="availableDate"
                                                                id="availableStartDateCareersForm"
                                                                placeholder="When are availaibe to start?"
                                                                validations={[_required]}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* <hr className="my-5 my-sm-10" />

                                                    <div className="mb-5">
                                                        <h3>Additional information<sup className='text-danger text-bold'>*</sup></h3>
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="additionalInfoCareersForm" className="form-label visually-hidden">Additional information</label>
                                                        <TextArea className="form-control" name="additionalInfos"
                                                            id="additionalInfoCareersForm"
                                                            onChange={e => handleChange(e)}
                                                            placeholder="Add a cover letter or anything else you want to share."
                                                            aria-label="Add a cover letter or anything else you want to share."
                                                            rows="5"
                                                            validations={[_required]}
                                                        />
                                                    </div> */}

                                                    <div className="d-grid text-center mt-7">

                                                        {
                                                            !showLoading
                                                                ? <>
                                                                    <Button type="submit" className="btn btn-primary btn-lg">
                                                                        {t('apply.form.btn')}
                                                                    </Button>
                                                                </>
                                                                : <button type="button" className="btn btn-primary btn-lg" disabled={showLoading}>
                                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                    {t('apply.form.in_progress')}
                                                                </button>
                                                        }

                                                    </div>
                                                </Form>
                                            </>
                                                : <div className="alert alert-soft-info text-center">
                                                    <span className="bi bi-exclamation-triangle-fill text-info"></span>
                                                    {t('apply.form.auth_text_before')} <Link title='Create your account' to="/signin">{t('apply.form.auth_link')}</Link> {t('apply.form.auth_text_after')}</div>
                                        }
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </>);
}

export default CareerOverViewLayout;


const formListItems = value => {
    return value.split('\n')
};
