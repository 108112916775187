import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { RemoveAppendedScript, history, Slang } from '../helpers';
import { useDispatch } from 'react-redux';
import { authActions } from 'store';
import { AdminContentLoader } from 'components';
import { Helmet } from 'react-helmet-async';
import config from '../config';



const WaitConfirmationDevice = () => {
    const dispatch = useDispatch();
    const [load, setLoad] = useState(true);

    useEffect(() => {

        if (sessionStorage.getItem('token') === null) {
            history.navigate('/');
        }



        let tt = setTimeout(() => {
            $('body').addClass('d-flex flex-column justify-content-center align-items-center h-100');
            $('html').addClass('h-100');
        }, 200);

        let timer = setInterval(() => {
            if (sessionStorage.getItem('deviceId') !== null) {
                let deviceId = sessionStorage.getItem('deviceId');

                dispatch(authActions.waitConfirmDevicePage({ deviceId }));

            }
        }, 10000);

        let tim = setTimeout(() => {
            setLoad(false);
        }, 3000);

        return () => {
            clearInterval(timer);
            clearTimeout(tim);
            clearTimeout(tt);
            RemoveAppendedScript();
            $('body').removeAttr('class');
            $('html').removeAttr('class');
        };
    }, [])

    const t = Slang('page.waiting_device_conf');

    return <>
        {
            load
                ? <AdminContentLoader />
                : <>
                    <header id="header" className="navbar navbar-expand navbar-light navbar-absolute-top">
                        <div className="container">
                            <nav className="navbar-nav-wrap">
                                <span className="navbar-brand" aria-label="Front">
                                    <img className="navbar-brand-logo" src={require('../assets/img/logo/apines_white.png')} alt="Logo" />
                                </span>
                            </nav>
                        </div>
                    </header>

                    <main id="content" role="main">
                        <div className="container text-center">
                            <div className="mb-3">
                                <img className="img-fluid" src="/assets/img/svg/illustrations/oc-error.svg" alt="Description_image" style={{ width: "30rem" }} />
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }} className='text-primary'>
                                <span className="spinner-border spinner-border" role="status" aria-hidden="true"></span>
                            </div>

                            <div className="mb-4">
                                <p className="fs-4 mb-0">{t('text')}</p>
                                <p className="fs-4">{t('wait_text')}</p>
                            </div>

                        </div>
                    </main>

                    <footer className="position-absolute start-0 end-0 bottom-0">
                        <div className="container pb-5 content-space-b-sm-1">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-sm mb-3 mb-sm-0">
                                    <p className="small mb-0">&copy; HappyNess. {config.app.footer.year}.</p>
                                </div>

                                <div className="col-sm-auto">
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item">
                                            <a className="btn btn-soft-secondary btn-xs btn-icon" href="https://www.facebook.com/profile.php?id=100080508300409" target='_blank'>
                                                <i className="bi-facebook"></i>
                                            </a>
                                        </li>

                                        {/* <li className="list-inline-item">
                                <a className="btn btn-soft-secondary btn-xs btn-icon" href="#/">
                                    <i className="bi-google"></i>
                                </a>
                            </li>

                            <li className="list-inline-item">
                                <a className="btn btn-soft-secondary btn-xs btn-icon" href="#/">
                                    <i className="bi-twitter"></i>
                                </a>
                            </li>

                            <li className="list-inline-item">
                                <a className="btn btn-soft-secondary btn-xs btn-icon" href="#/">
                                    <i className="bi-github"></i>
                                </a>
                            </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </footer>
                </>
        }

        <Helmet>
            <link rel="stylesheet" href="./assets/css/app/bootstrap-icons/font/bootstrap-icons.css" />
            <link rel="stylesheet" href="./assets/css/app/hs-mega-menu.min.css" />
            <link rel="stylesheet" href="./assets/css/app/quill.snow.css" />
            <link rel="stylesheet" href="./assets/css/app/tom-select.css" />
            <link rel="stylesheet" href="./assets/css/app/aos.css" />
            <link rel="stylesheet" href="./assets/css/app/swiper-bundle.min.css" />
            <link rel="stylesheet" href="./assets/css/app/theme.min.css" />

            <script defer async src='./assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js'></script>
            <script defer async src='./assets/js/theme.min.js'></script>
            <title>Device Confirmation</title>
        </Helmet>
    </>;

}


export default WaitConfirmationDevice;
