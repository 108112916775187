import React, { useEffect, useRef, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Slang, SlangObj } from '../helpers';
// import HomeSlideLayout from './homeSlideLayout';
import { HomeProjectsModal } from '../components/modals';
import { store, userActions } from '../store';
import { useDispatch } from 'react-redux';
import Typed from 'typed.js';
import { Partners } from '../partials';
import tasos from '../assets/img/pictures/tasos.jpeg';
// import logoRhSvg from '../assets/img/logo/logoRh.svg';
import logoRhSvg from '../assets/img/favicon.ico';
import configApp from '../config';
import { htmlizer } from './termsLayout';


/* ---------------------------------------------------------------- */
/*                           Import images                          */
/* ---------------------------------------------------------------- */

import img_commerce from "../assets/img/480x320/commerce.jpg";



const MainLayout = ({ slide, tpdisitem }) => {

    const t = Slang('header.typejs');
    const ph = Slang('page.home');
    const tpdis = Slang('page.home.dicover_section');

    const dispatch = useDispatch();

    // const el = useRef(null);


    useEffect(() => {

        setTimeout(() => {
            if (store.getState().auth.token !== null && store.getState().user.user.id !== undefined) {
                dispatch(userActions.getdevices({ user_id: store.getState().user.user.id }));
            }
        }, 6000);

        // const typed = new Typed(el.current, {
        //     strings: ['HAPPYNESS?'],
        //     typeSpeed: 90,
        //     loop: true,
        //     backSpeed: 30,
        //     backDelay: 3500
        // });

        return () => {
            // typed.destroy();
        };
    }, []);


    return (
        <main id="content" role="main">
            {/* <HomeSlideLayout /> */}

            <HeroSection1 lang={t} />


            {/* <div className="d-lg-flex position-relative">
                <div className="container d-lg-flex align-items-lg-center content-space-t-3 content-space-lg-0 min-vh-lg-100" >
                    <div className="w-100">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="mb-5">
                                    <h2 className="display-6 mb-3">
                                        {t('text')}&nbsp;

                                        <span className="text-primary text-highlight-warning">
                                            <span className="js-typedjs" ref={el} />
                                        </span>
                                    </h2>

                                    <p className="lead">
                                        {t('description')}
                                    </p>
                                </div>

                                <div className="d-grid d-sm-flex gap-3">
                                    <Link
                                        to="/init-project"
                                        className="btn btn-primary btn-transition px-6"
                                    >{t('btn.1')}</Link>
                                    <Link className="btn btn-link" to="/about">{t('btn.2')} <i className="bi-chevron-right small ms-1"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7 col-xl-6 d-none d-lg-block position-absolute top-0 end-0 pe-0" style={{ marginTop: "6.75rem" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1137.5 979.2">

                            <defs>
                                <path id="mainHeroSVG1" d="M1137.5,0H450.4l-278,279.7C22.4,430.6,24.3,675,176.8,823.5l0,0C316.9,960,537.7,968.7,688.2,843.6l449.3-373.4V0z" />
                            </defs>
                            <clipPath id="mainHeroSVG2">
                                <use xlinkHref="#mainHeroSVG1" />
                            </clipPath>
                            <g transform="matrix(1 0 0 1 0 0)" clipPath="url(#mainHeroSVG2)">
                                <image width="750" height="750" xlinkHref={require(`../assets/img/750x750/img${slide}.jpg`)} transform="matrix(1.4462 0 0 1.4448 52.8755 0)"></image>
                            </g>
                        </svg>
                    </div>

                </div>
            </div> */}


            <div className="container content-space-2 content-space-lg-3">
                <div className="w-md-75 w-lg-150 text-center mx-md-auto mb-5">
                    <h2 className='title'>{htmlizer(tpdis('title'), 'title').map(el => el === 0 ? "" : "")}</h2>
                </div>

                <div className="overflow-hidden">
                    <div className="row gx-lg-7">


                        {
                            tpdisitem.map((item, key) => {
                                return <div className="col-sm-6 col-lg-4 mb-5" key={key}>
                                    <Link className="card card-flush h-100" to="#/">
                                        <img className="card-img" src={`./assets/img/480x320/${item.img}`} alt="DescriptionImage" />
                                        <div className="card-body">
                                            <span className="card-subtitle text-body">{item.text}</span>
                                            <h4 className="card-title text-inherit">{item.title}</h4>
                                            <p className="card-text text-body">{item.description}</p>
                                            <ul className="list-pointer mb-0">
                                                {
                                                    item.elm.map((re, ki) =>
                                                        <li key={ki} className="list-pointer-item">{re}</li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </Link>
                                </div>
                            })
                        }


                    </div>
                </div>


                {/* Learn More button */}
                {/* <div className="text-center">
                    <div className="card card-info-link card-sm">
                        <div className="card-body">
                            {tpdis('text')} <Link className="card-link ms-2" to="#/">{tpdis('link')} <span className="bi-chevron-right small ms-1"></span></Link>
                        </div>
                    </div>
                </div> */}
            </div>

            <div className="position-relative bg-light rounded-2 mx-3 mx-lg-10">
                <Partners />
            </div>

            <div className="container content-space-2 content-space-lg-3">
                <div className="row align-items-md-center">
                    <div className="col-md-5 d-none d-md-block">
                        <img className="img-fluid rounded-2" src={tasos} alt="DescriptionImage" />
                    </div>

                    <div className="col-md-7">
                        <figure className="pe-md-7">
                            <div className="mb-4">
                                {/* <img className="avatar avatar-xl avatar-4x3" src={logoRhSvg} alt="DescriptionImage" /> */}
                                <small className='text-primary fw-bold'><img src={logoRhSvg} alt="icoico" />HappyNess</small>
                            </div>

                            <div className="blockquote blockquote-lg" style={{
                                textAlign: 'justify',
                                color: '#677788',
                                fontSize: '1.3em'
                            }}>
                                {`"${ph('tasos.text')}"`}
                            </div>

                            <figcaption className="blockquote-footer">
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 d-md-none">
                                        <img className="avatar avatar-circle" src={tasos} alt="DescriptionImage" />
                                    </div>
                                    <div className="flex-grow-1 ms-3 ms-md-0">
                                        {`${ph('tasos.name')}`}
                                        <span className="blockquote-footer-source">{`${ph('tasos.post')}`}</span>
                                    </div>
                                </div>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </div>

            <div className="bg-light rounded-2 mx-3 mx-lg-10">
                <div className="container content-space-2">
                    <div className="row justify-content-center">
                        <div className="col-sm-6 col-md-2 mb-7 mb-md-0">
                            <div className="text-center">
                                <h2 className="display-7"><i className="bi bi-journal-bookmark text-info"></i> {configApp.app.home.all}</h2>
                                <p className="mb-0">{ph('stats.all')}</p>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-2 mb-7 mb-md-0">
                            <div className="text-center">
                                <h2 className="display-7"><i className="bi bi-journal-check text-success"></i> {configApp.app.home.finished
                                }</h2>
                                <p className="mb-0">{ph('stats.finished')}</p>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-2 mb-7 mb-md-0">
                            <div className="text-center">
                                <h2 className="display-7"><i className="bi bi-journal-bookmark text-warning"></i> {configApp.app.home.in_progress}</h2>
                                <p className="mb-0">{ph('stats.in_progress')}</p>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-2 mb-7 mb-md-0">
                            <div className="text-center">
                                <h2 className="display-7"><i className="bi bi-emoji-smile-fill text-success"></i> {configApp.app.home.customers}%</h2>
                                <p className="mb-0">{ph('stats.customers')}</p>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-2 mb-7 mb-md-0">
                            <div className="text-center">
                                <h2 className="display-7"><i className="bi bi-activity text-info"></i> {configApp.app.home.uping}%</h2>
                                <p className="mb-0">{ph('stats.uping')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
    );
}

export default MainLayout;


const HeroSection1 = ({ lang }) => {

    const datas = [...SlangObj('page.home.dicover_section.items')];


    const [array, setArray] = useState([]);

    useEffect(() => {
        setInterval(() => {
            setArray([
                {
                    img: 'maquette.png',
                    title: `${lang('text')} HAPPYNESS ?`,
                    description: lang('description'),
                    btn: true,
                    text_btn: lang('btn.1'),
                    link_btn: '/init-project'
                },
                ...datas
            ])
        }, 200);
    }, []);

    return <>
        <div className="js-swiper-blog-metro-hero swiper">
            <div className="swiper-wrapper">

                {
                    array.map((data, key) => {
                        return <div key={key} className="swiper-slide d-lg-flex min-vh-lg-100 gradient-x-overlay-lg-dark-dior bg-img-start content-space-5" style={{ backgroundImage: `url(../assets/img/480x320/${data.img})` }}>
                            <div className="container d-lg-flex align-items-lg-center">
                                <div className="w-lg-65 text-center mx-lg-auto">
                                    {
                                        data.img === 'maquette.png'
                                            ? <>
                                                <h1 className="h1 text-white" style={{ fontSize: 50 }}>{data.title}</h1>
                                                <div className="mb-md-7">
                                                    <span className="text-white-70" style={{ fontSize: 20 }}>{data.description}</span>
                                                </div>
                                            </>
                                            : <>
                                                <span className="text-cap text-white-70">{data.title}</span>
                                                <div className="4 mb-md-7">
                                                    <h1 className="text-white">{data.description}</h1>
                                                </div>
                                            </>
                                    }

                                    {
                                        data.btn
                                            ? <a className="btn btn-light btn-transition" href={`${data.link_btn}`}>{data.text_btn} <i className="bi-chevron-right small ms-1"></i></a>
                                            : null
                                    }

                                </div>
                            </div>
                        </div>
                    })
                }
            </div>

            <div className="js-swiper-blog-metro-hero-pagination swiper-pagination swiper-pagination-light position-absolute bottom-0 start-0 end-0 mb-5"></div>
        </div>
    </>;
};

const HeroSection2 = ({ lang }) => {

    const datas = [...SlangObj('page.home.dicover_section.items')];


    const [array, setArray] = useState([]);

    useEffect(() => {
        setInterval(() => {
            setArray([
                {
                    img: 'maquette.png',
                    title: `${lang('text')} HAPPYNESS ?`,
                    description: lang('description'),
                    btn: true,
                    text_btn: lang('btn.1'),
                    link_btn: '/init-project'
                },
                ...datas
            ])
        }, 200);
    }, []);

    return <>
        {/* <div className="js-swiper-blog-metro-hero swiper">
            <div className="swiper-wrapper">

                {
                    array.map((data, key) => {
                        return <div key={key} className="swiper-slide d-lg-flex min-vh-lg-100 gradient-x-overlay-lg-dark bg-img-start content-space-5" style={{ backgroundImage: `url(../assets/img/480x320/${data.img})` }}>
                            <div className="container d-lg-flex align-items-lg-center">
                                <div className="w-lg-65 text-center mx-lg-auto">
                                    {
                                        data.img === 'maquette.png'
                                            ? <>
                                                <h1 className="h1 text-white" style={{ fontSize: 50 }}>{data.title}</h1>
                                                <div className="mb-md-7">
                                                    <span className="text-white-70" style={{ fontSize: 20 }}>{data.description}</span>
                                                </div>
                                            </>
                                            : <>
                                                <span className="text-cap text-white-70">{data.title}</span>
                                                <div className="4 mb-md-7">
                                                    <h1 className="text-white">{data.description}</h1>
                                                </div>
                                            </>
                                    }

                                    {
                                        data.btn
                                            ? <a className="btn btn-light btn-transition" href={`${data.link_btn}`}>{data.text_btn} <i className="bi-chevron-right small ms-1"></i></a>
                                            : null
                                    }

                                </div>
                            </div>
                        </div>
                    })
                }
            </div>

            <div className="js-swiper-blog-metro-hero-pagination swiper-pagination swiper-pagination-light position-absolute bottom-0 start-0 end-0 mb-5"></div>
        </div> */}

        <div className="position-relative">
            <div className="js-swiper-blog-journal-hero swiper">
                <div className="swiper-wrapper">

                    {
                        array.map((data, key) => {
                            return <div key={key} className="swiper-slide d-flex gradient-x-overlay-sm-dark bg-img-start" style={{ backgroundImage: `url(../assets/img/480x320/${data.img})`, minHeight: "40rem" }}>
                                <div className="container d-flex align-items-center" style={{ minHeight: "40rem" }}>
                                    <div className="w-lg-50 me-3">
                                        <div className="d-flex align-items-center mb-3">
                                            <div className="flex-shrink-0">
                                                <div className="avatar avatar-circle">
                                                    <img className="avatar-img" src="../assets/img/160x160/img10.jpg" alt="description_image" />
                                                </div>
                                            </div>

                                            <div className="flex-grow-1 ms-3">
                                                <a className="text-white" href="../blog-article.html">{data.title}</a>
                                            </div>
                                        </div>

                                        <div className="mb-5">
                                            <h2 className="h1 text-white">{data.title}</h2>
                                            <p className='text-white' style={{ fontSize: '1.2em' }}>{data.description}</p>
                                        </div>

                                        {
                                            data.btn
                                                ? <a className="btn btn-light btn-transition" href={`${data.link_btn}`}>{data.text_btn} <i className="bi-chevron-right small ms-1"></i></a>
                                                : null
                                        }

                                        {/* <a className="btn btn-primary btn-transition" href="../blog-article.html">Read article <i className="bi-chevron-right small ms-1"></i></a> */}
                                    </div>
                                </div>
                            </div>

                        })
                    }


                </div>

                <div className="js-swiper-blog-journal-hero-pagination swiper-pagination swiper-pagination-light swiper-pagination-vertical swiper-pagination-middle-y-end me-3 d-lg-none"></div>
            </div>

            <div className="d-none d-lg-block container translate-middle-y position-absolute top-50 start-0 end-0 zi-2">
                <div className="translate-middle-y position-absolute top-50 end-0">
                    <div className="js-swiper-blog-journal-hero-thumbs swiper" style={{ opacity: 0, maxWidth: "13rem" }}>
                        <div className="swiper-wrapper">

                            {
                                array.map((data, key) => {
                                    return <div key={key} className="swiper-slide swiper-pagination-progress swiper-pagination-progress-light">
                                        <p className="text-white">{data.description}</p>

                                        <div className="swiper-pagination-progress-body">
                                            <div className="js-swiper-pagination-progress-body-helper swiper-pagination-progress-body-helper"></div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
};