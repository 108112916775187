import React, { useEffect, useState } from 'react';
import {
    MultipleFileUpload,
    MultipleFileUploadMain,
    MultipleFileUploadStatus,
    MultipleFileUploadStatusItem,
    HelperText,
    HelperTextItem
} from '@patternfly/react-core';
import UploadIcon from '@patternfly/react-icons/dist/esm/icons/upload-icon';
import $ from 'jquery';



const MultipleFileUploadBasic = ({setArrFiles}) => {

    const [isHorizontal, setIsHorizontal] = useState(true);
    const [fileUploadShouldFail, setFileUploadShouldFail] = useState(false);
    const [currentFiles, setCurrentFiles] = useState([]);
    const [readFileData, setReadFileData] = useState([]);
    const [showStatus, setShowStatus] = useState(false);
    const [statusIcon, setStatusIcon] = useState('inProgress');

    if (!showStatus && currentFiles.length > 0) {
        setShowStatus(true);
    }

    useEffect(() => {
        var uplBtn = $('.pf-c-button');
        uplBtn.css({
            border: '1px solid gray',
            padding: '10px 30px',
            borderRadius: 5, 
            // color: '#00bc8c',
            // margin: '40px 60px'
        });

        // uplBtn.on('mouseover', () => $('.pf-c-button').css({backgroundColor: '#00bc8c', color: '#fff'}))
        // uplBtn.on('mouseleave', () => $('.pf-c-button').css({backgroundColor: '#fff', color: '#00bc8c'}))

        if (readFileData.length < currentFiles.length) {
            setStatusIcon('inProgress');
        } else if (readFileData.every(file => file.loadResult === 'success')) {
            setStatusIcon('success');
        } else {
            setStatusIcon('danger');
        }

        setArrFiles(currentFiles);

    }, [readFileData, currentFiles, setArrFiles]);


    const removeFiles = namesOfFilesToRemove => {
        const newCurrentFiles = currentFiles.filter(currentFile => !namesOfFilesToRemove.some(fileName => fileName === currentFile.name));
        setCurrentFiles(newCurrentFiles);
        const newReadFiles = readFileData.filter(readFile => !namesOfFilesToRemove.some(fileName => fileName === readFile.fileName));
        setReadFileData(newReadFiles);
    };

    const updateCurrentFiles = files => {
        if (fileUploadShouldFail) {
            const corruptedFiles = files.map(file => ({
                ...file,
                lastModified: 'foo'
            }));
            setCurrentFiles(prevFiles => [...prevFiles, ...corruptedFiles]);
        } else {
            setCurrentFiles(prevFiles => [...prevFiles, ...files]);
        }
    };


    const handleFileDrop = droppedFiles => {
        const currentFileNames = currentFiles.map(file => file.name);
        const reUploads = droppedFiles.filter(droppedFile => currentFileNames.includes(droppedFile.name));
        Promise.resolve().then(() => removeFiles(reUploads.map(file => file.name))).then(() => updateCurrentFiles(droppedFiles));
    };

    const handleReadSuccess = (data, file) => {
        setReadFileData(prevReadFiles => [...prevReadFiles, {
            data,
            fileName: file.name,
            loadResult: 'success'
        }]);
    };

    const handleReadFail = (error, file) => {
        setReadFileData(prevReadFiles => [...prevReadFiles, {
            loadError: error,
            fileName: file.name,
            loadResult: 'danger'
        }]);
    };

    const createHelperText = file => {
        const fileResult = readFileData.find(readFile => readFile.fileName === file.name);
        if (fileResult?.loadError) {
            return <HelperText isLiveRegion>
                <HelperTextItem variant={'error'}>{fileResult.loadError.toString()}</HelperTextItem>
            </HelperText>;
        }
    };

    const successfullyReadFileCount = readFileData.filter(fileData => fileData.loadResult === 'success').length;

    return <>
        <MultipleFileUpload 
            onFileDrop={handleFileDrop}
            dropzoneProps={{
                accept: 'image/jpeg,'
                    + 'image/png,'
                    + 'audio/mpeg,'
                    + 'application/pdf,'
                    + 'application/vnd.openxmlformats-officedocument.presentationml.presentation,'
                    + 'application/vnd.ms-powerpoint,'
                    + 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,'
                    + 'application/vnd.ms-excel,'
                    + 'application/zip,'
                    + 'application/x-7z-compressed,'
                    + 'application/vnd.rar,'
                    + 'audio/aac,'
                    + 'application/vnd.oasis.opendocument.presentation,'
                    + 'application/vnd.oasis.opendocument.spreadsheet,'
                    + 'application/vnd.oasis.opendocument.text,'
                    + 'application/msword,'
                    + 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,'
            }}
            isHorizontal={false}
            >

            <MultipleFileUploadMain
                titleIcon={<UploadIcon />}
                titleText="Drag and drop files here"
                titleTextSeparator="or"
                infoText="Accepted file types: JPEG, JPG, PNG, MP3, PDF, PPT, PPTX, XLS, XLSX, 7zip, RAR, ZIP, AAC, ODP, ODS, ODT, DOC, DOCX"
                
            />
            {
                showStatus &&
                <MultipleFileUploadStatus
                    statusToggleText={`${successfullyReadFileCount} of ${currentFiles.length} files uploaded`}
                    statusToggleIcon={statusIcon}
                >
                    {
                        currentFiles.map(
                            file => <MultipleFileUploadStatusItem
                                file={file}
                                key={file.name}
                                onClearClick={() => removeFiles([file.name])}
                                onReadSuccess={handleReadSuccess}
                                onReadFail={handleReadFail}
                                progressHelperText={createHelperText(file)}
                            />)}
                </MultipleFileUploadStatus>}
        </MultipleFileUpload>
    </>;
};

export default MultipleFileUploadBasic;
