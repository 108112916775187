
import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { InterLang } from '../helpers';
import { get } from 'local-storage';


const LocalizationComponent = ({ page = null }) => {

    const [show, setShow] = useState(false);
    const [goodFlag, setGooFlag] = useState(false);

    useEffect(() => {
        if (page === 'blog_article' || page === 'blog_author_profile' || page === 'profile') {
            setGooFlag(true);
        }
    }, [page])

    const [btnInter, setBtnInter] = React.useState(
        InterLang.find(itm => {
            return itm.code === (
                get('i18nextLng') !== null
                    ? get('i18nextLng')
                    : 'fr'
            )
        })
    );

    const handleLang = lang => {
        setBtnInter(lang);
        i18next.changeLanguage(lang.code);
    };

    return <div className="btn-group">
        <button onClick={() => setShow(!show)} type="button" className={show ? `btn btn-soft-secondary btn-xs dropdown-toggle show` : `btn btn-soft-secondary btn-xs dropdown-toggle`} id="footerLanguageSelect" data-bs-toggle="dropdown" aria-expanded={show ? 'true' : 'false'} data-bs-dropdown-animation>
            <span className="d-flex align-items-center">
                <img className="avatar avatar-xss avatar-circle me-2" src={goodFlag ? `../${btnInter.flag}` : btnInter.flag} alt={btnInter.alt} width="16" />
                <span>{btnInter.title}</span>
            </span>
        </button>
        <div className={`dropdown-menu ${show ? 'show' : ''}`} aria-labelledby="footerLanguageSelect">
            {InterLang.map((lang, key) =>
                <span key={key} className="btn dropdown-item d-flex align-items-center" onClick={() => handleLang(lang)}>
                    <img className="avatar avatar-xss avatar-circle me-2" src={goodFlag ? `../${lang.flag}` : lang.flag} alt={lang.alt} width="16" />
                    <span>{lang.title}</span>
                </span>
            )}
        </div>
    </div>
}

export default LocalizationComponent;