import React, { useState } from 'react';
import { Toast } from 'helpers';
import { adminActions, store } from 'store';


const NewRoleForm = ({ dispatch, user }) => {
    const [new_role, setNew_role] = useState('');
    const [load, setLoad] = useState(false);

    const handleSave = user_id => {
        setLoad(true);

        setTimeout(() => {
            if (new_role.length !== 0) {
                dispatch(adminActions.assignrole({ user_id, role_name: new_role }));
                setTimeout(() => {
                    setLoad(false);
                }, 3000);
            } else {
                setLoad(false);
                Toast('error', 'Please choose a role', 'tr', false, false, false, 3000, false);
            }

        }, 2000);
    };

    return <>
        <div className="form-group">
            <label>Choose a new Role</label>
            <select className="form-control bg-white" onChange={e => setNew_role(e.target.value)}>
                <option value={null}>-- --</option>
                {
                    store.getState().admin.roles.map((role, key) => {
                        return <option value={role.name} key={key}>{role.name}</option>
                    })
                }
            </select>
        </div>

        <div className="form-group">

            {
                load
                    ? <button className="btn btn-success btn-lg btn-block">
                        <span className="spinner-border spinner-border" style={{ height: 20, width: 20 }} role="status" aria-hidden="true"></span> Save
                    </button>
                    : <button className="btn btn-success btn-lg btn-block" onClick={() => handleSave(user.id)}>Save</button>
            }

        </div>
    </>
};

export default NewRoleForm;
