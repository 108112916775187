import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { EditorModules, Locations, jobsCateg } from '../../constants';
import TomSelect from 'tom-select';

import 'tom-select/dist/css/tom-select.bootstrap4.css';
import 'react-quill/dist/quill.snow.css';
import { adminActions } from '../../store';


const AdminCareerEdit = ({ dispatch, career, setModal }) => {

    const [showLoading, setShowLoading] = useState(false);


    const [title, setTitle] = useState(career.title);
    const [description, setDescription] = useState(career.description);
    const [category, setCategory] = useState(career.category);
    const [location, setLocation] = useState(career.location);
    const [cycle, setCycle] = useState(career.cycle);
    const [type, setType] = useState(career.type);
    const [dateline, setDateline] = useState(career.date);
    const [text, setText] = useState(career.text);
    const [propose, setPropose] = useState(career.propose);
    const [requierment, setRequierment] = useState(career.requierment);
    const [skills, setSkills] = useState(career.skills);
    const [extraSkills, setExtraSkills] = useState(career.extraSkills);
    const [status, setStatus] = useState(career.status);


    useEffect(() => {
        new TomSelect(".select-beast", {
            create: false,
            sortField: {
                field: "text",
                direction: "asc"
            }
        });
        new TomSelect(".select-beast1", {
            create: false,
            sortField: {
                field: "text",
                direction: "asc"
            }
        });

        new TomSelect(".select-beast2", {
            create: false,
            sortField: {
                field: "text",
                direction: "asc"
            }
        });

        return () => {
        };
    }, []);

    const handleTitleChange = value => {
        setTitle(value);
    };

    const handleDescriptionChange = value => {
        setDescription(value);
    };

    const handleCategoryChange = value => {
        setCategory(value);
    };

    const handleLocationChange = value => {
        setLocation(value);
    };

    const handleCycleChange = value => {
        setCycle(value);
    };

    const handleTypeChange = value => {
        setType(value);
    };

    const handleDatelineChange = value => {
        setDateline(value);
    };

    const handleTextChange = value => {
        setText(value);
    };

    const handleProposeChange = value => {
        setPropose(value);
    };

    const handleRequiermentChange = value => {
        setRequierment(value);
    };

    const handleSkillsChange = value => {
        setSkills(value);
    };

    const handleExtraSkillsChange = value => {
        setExtraSkills(value);
    };

    const handleStatusChange = value => {
        setStatus(value);
    };


    const handleSubmit = () => {
        setShowLoading(true);
        setTimeout(() => {
            dispatch(adminActions.updatecareer({
                id: career.id, title, category, status,
                location, description,
                cycle, type, date: dateline,
                text, propose, requierment,
                skills, extraSkills
            }));
            setTimeout(() => {
                setShowLoading(false);
            }, 2000);

        }, 2000);
    };




    return <div className="modal fade" id="edit-career" style={{ display: "none" }} aria-hidden="true">
        <div className="modal-dialog modal-xl">
            <div className="modal-content bg-white">

                <div className="modal-body">
                    <div className="card bg-white">
                        <div className="card-header">
                            <h3 className="card-title">Career Editor</h3>

                            <div className="card-tools">
                                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                    <i className="fas fa-minus"></i>
                                </button> */}
                                <button type="button" onClick={() => setModal(<></>)} className="btn btn-tool" data-dismiss="modal" aria-label="Close" title="Close">
                                    <i className="fas fa-times"></i>
                                </button>
                            </div>
                        </div>

                        <div className="card-body" style={{ display: 'block' }}>


                            <div className="row">

                                {/*  First Column */}
                                <div className="col-md-6">
                                    {/* Title */}
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <label htmlFor='title'>Title</label>
                                                <input
                                                    type="text"
                                                    id='title'
                                                    value={title}
                                                    className="form-control bg-white"
                                                    placeholder="Enter the career title"
                                                    onChange={e => handleTitleChange(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">

                                            <div className="form-group">
                                                <label htmlFor='status'>Status</label>
                                                <select
                                                    placeholder='Choose a status'
                                                    className="form-control bg-white select-beast2"
                                                    id='status'
                                                    onChange={e => handleStatusChange(e.target.value)}
                                                >
                                                    <option selected={status === '' ? true : false} value=''></option>
                                                    <option selected={status === 'amateur' ? true : false} value='amateur'>Amateur</option>
                                                    <option selected={status === 'senior' ? true : false} value='senior'>Senior</option>
                                                    <option selected={status === 'professionnel' ? true : false} value='professionnel'>Professionnel</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>

                                    {/* Categor and Location */}
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor='category'>Category</label>
                                                <select
                                                    placeholder='Choose a category'
                                                    className="form-control bg-white select-beast"
                                                    id='category'
                                                    onChange={e => handleCategoryChange(e.target.value)}
                                                >
                                                    <option value=''></option>
                                                    {
                                                        jobsCateg.map(job => <option selected={category === job.name ? true : false} key={job.id} value={job.name}>{job.name}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor='location'>Location</label>
                                                <select
                                                    placeholder='Choose a location'
                                                    className="form-control bg-white select-beast1"
                                                    id='location'
                                                    onChange={e => handleLocationChange(e.target.value)}
                                                >
                                                    <option value=''></option>
                                                    {
                                                        Locations.map(loca => <option selected={location === loca.code ? true : false} key={loca.code} value={loca.code}>{loca.name}</option>)
                                                    }

                                                </select>
                                            </div>
                                        </div>

                                    </div>

                                    {/* Description */}
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor='description'>
                                                    Description
                                                </label>
                                                <ReactQuill
                                                    theme="snow" modules={EditorModules}
                                                    onChange={handleDescriptionChange}
                                                    placeholder='Career description'
                                                    value={description}
                                                    style={{ height: 160, marginBottom: 80 }}
                                                />

                                            </div>
                                        </div>
                                    </div>

                                    {/* Cycle */}
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor='cycle'>Cycle</label>
                                                <select className="form-control bg-white" id='cycle' onChange={e => handleCycleChange(e.target.value)}>
                                                    <option selected={cycle === null ? true : false} value={null}>-- --</option>
                                                    <option selected={cycle === "Part time" ? true : false} value={"Part time"}>Part time</option>
                                                    <option selected={cycle === "Full time" ? true : false} value={"Full time"}>Full time</option>

                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor='type'>Type</label>
                                                <select className="form-control bg-white" id='type' onChange={e => handleTypeChange(e.target.value)}>
                                                    <option selected={type === null ? true : false} value={null}>-- --</option>
                                                    <option selected={type === "CDI" ? true : false} value={"CDI"}>CDI</option>
                                                    <option selected={type === "CDD" ? true : false} value={"CDD"}>CDD</option>

                                                </select>
                                            </div>
                                        </div>

                                    </div>

                                    {/* dateline */}
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor='dateline'>Dateline</label>
                                                <input
                                                    type="date"
                                                    id='dateline'
                                                    className="form-control bg-white"
                                                    value={dateline}
                                                    onChange={e => handleDatelineChange(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor='motivation'>Text Motivation</label>
                                                <input
                                                    type="text"
                                                    id='motivation'
                                                    value={text}
                                                    className="form-control bg-white"
                                                    onChange={e => handleTextChange(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                    </div>


                                </div>


                                {/*  Second Column */}
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <label htmlFor='wepropose' style={{ marginBottom: -7 }}>We propose</label>
                                                    <em><small className='text-muted'>Note: By press Enter, you add new list item</small></em>
                                                </div>
                                                <textarea onChange={e => handleProposeChange(e.target.value)} id="wepropose" value={propose} style={{ width: '100%', height: 100 }}></textarea>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <label htmlFor='requierments' style={{ marginBottom: -7 }}>Requierments hability</label>
                                                    <em><small className='text-muted'>Note: By press Enter, you add new list item</small></em>
                                                </div>
                                                <textarea onChange={e => handleRequiermentChange(e.target.value)} id="requierments" value={requierment} style={{ width: '100%', height: 100 }}></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <label htmlFor='userhas' style={{ marginBottom: -7 }}>User Skills need</label>
                                                    <em><small className='text-muted'>Note: By press Enter, you add new list item</small></em>
                                                </div>
                                                <textarea onChange={e => handleSkillsChange(e.target.value)} id="userhas" value={skills} style={{ width: '100%', height: 100 }}></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <label htmlFor='extra' style={{ marginBottom: -7 }}>Extra Skill</label>
                                                    <em><small className='text-muted'>Note: By press Enter, you add new list item</small></em>
                                                </div>
                                                <textarea onChange={e => handleExtraSkillsChange(e.target.value)} id="extra" value={extraSkills} style={{ width: '100%', height: 100 }}></textarea>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4 offset-4">
                                    {
                                        !showLoading
                                            ? <button className="btn btn-primary btn-block btn-lg" onClick={() => handleSubmit()}>Update a career</button>
                                            : <button className="btn btn-primary btn-block btn-lg" disabled={showLoading}>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                Updating...
                                            </button>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default AdminCareerEdit;