export default [
    {svg_icon: "amazon-dark.svg"},
    {svg_icon: "google-dark.svg"},
    {svg_icon: "kaplan-dark.svg"},
    {svg_icon: "shopify-dark.svg"},
    {svg_icon: "airbnb-dark.svg"},
    {svg_icon: "capsule-dark.svg"},
    {svg_icon: "vidados-dark.svg"},
    {svg_icon: "business-insider-dark.svg"},
    {svg_icon: "forbes-dark.svg"},
    {svg_icon: "layar-dark.svg"},
    {svg_icon: "hubspot-dark.svg"}
];

