import React, { useEffect } from 'react';
import {
	// Blog,
	// BlogArticle,
	// BlogAuthorProfile,
	Contact,
	Career,
	Home,
	Login,
	NotFoundPage,
	Privacy,
	Register,
	ForgotPassword,
	Terms,
	CareerOverView,
	About,
	Profile,
	ResetPassword,
	Dashboard as AdminDash,
	FaForm,
	ManagerBoard,
	WaitConfirmationDevice,
	PaypalProcess,
	GoogleCallback,
	InitProject,
	Business,
	Investor,
	Cookies,
	StripeProcess,
} from './pages';
import $ from 'jquery';
import {
	Routes as Switch,
	Route,
	useNavigate,
	useLocation
} from "react-router-dom";
import { DeviceConfirmation, Forma, PrivateRoute } from './components';
import { history, resetStore } from './helpers';
import { Anonymous } from './components/privateRoute';
import {
	//adminActions,
	//authActions,
	//guestActions,
	//managerActions,
	store,
	userActions
} from './store';
import { useDispatch, useSelector } from 'react-redux';




const App = () => {
	const dispatch = useDispatch();

	const auth = useSelector(x => x.auth);
	const user = useSelector(x => x.user);

	history.location = useLocation();
	history.navigate = useNavigate();

	useEffect(() => {
		$('body iframe').remove();
		setTimeout(() => {
			if (auth.token !== null && user.id !== undefined) {
				dispatch(userActions.getdevices({ user_id: user.id }));
			}
		}, 6000);
		setTimeout(() => {
			if (user.error) {
				if (user.error.message === 'Authentication failed') {
					resetStore();
				}
			}
		}, 10000);

		let tt = setInterval(() => {
			if (parseInt(sessionStorage.getItem('resetStore')) === 1) {
				resetStore();
				sessionStorage.removeItem('resetStore');
				// window.location.reload();
			}
		}, 1000);

		let tr = setInterval(() => {
			if (['signin', 'forgot-password', 'reset-password', 'signup'].includes(window.location.pathname.split('/')[1])) {
				$('#header .container-fluid').css({
					background: 'none'
				});
			}
		}, 100);


		// console.log('app mount');

		return () => {
			// console.log('app unmount');
			clearInterval(tt);
			clearInterval(tr);
			$('head .remove').remove();
		};
	}, [auth, dispatch, user]);




	return <>
		<Switch>
			<Route index path="/" element={<Home />}></Route>
			<Route path="/home" element={<Home />}></Route>
			<Route path="/privacy" element={<Privacy />}></Route>
			<Route path="/cookies" element={<Cookies />}></Route>
			<Route path="/contact" element={<Contact />}></Route>
			<Route path="/about" element={<About />}></Route>
			<Route path="/terms" element={<Terms />}></Route>
			{/* <Route path="/blog" element={<Blog />}></Route> */}
			<Route path="/init-project" element={<InitProject />}></Route>
			{/* <Route path="/blog/article" element={<BlogArticle />}></Route> */}
			{/* <Route path="/blog/author" element={<BlogAuthorProfile />}></Route> */}
			<Route path="/careers" element={<Career />}></Route>
			<Route path="/career-overview/:id" element={<CareerOverView />}></Route>
			<Route path="/wait-confirm-device" element={<WaitConfirmationDevice />}></Route>
			<Route path="/*" element={<NotFoundPage />}></Route>
			<Route path="/device-confirm/*" element={<DeviceConfirmation />}></Route>


			<Route element={<Anonymous auth={
				{
					token: store.getState().auth.token,
					user: store.getState().user.user.id !== undefined ? store.getState().user.user : null
				}
			} />} >
				<Route path="/2fa" element={<FaForm />}></Route>
				<Route path="/signin" element={<Login />}></Route>
				<Route path="/signup" element={<Register />}></Route>
				<Route path="/forgot-password" element={<ForgotPassword />}></Route>
				<Route path="/reset-password" element={<ResetPassword />}></Route>
				<Route path='/cover-reset-pass' element={<Forma type={'reset_password'} />}></Route>
				<Route path='/email-validation' element={<Forma type={'email'} />}></Route>
				<Route path='/auth/google' element={<GoogleCallback />}></Route>
			</Route>


			<Route element={<PrivateRoute />}>
				<Route path="/profile" element={<Profile />}></Route>
				<Route path="/dashboard" element={<AdminDash />}></Route>
				<Route path="/manager" element={<ManagerBoard />} />
				<Route path="/payment-stripe-process" element={<StripeProcess />} />
				<Route path='/payment-paypal-process' element={<PaypalProcess />} />
			</Route>


		</Switch>
	</>
}

export default App;

