export default [
  {
    "rate": 0.0062,
    "code": "AED",
    "title": "United Arab Emirates Dirham"
  },
  {
    "rate": 0.12,
    "code": "AFN",
    "title": "Afghan Afghani"
  },
  {
    "rate": 0.16,
    "code": "ALL",
    "title": "Albanian Lek"
  },
  {
    "rate": 0.67,
    "code": "AMD",
    "title": "Armenian Dram"
  },
  {
    "rate": 0.003,
    "code": "ANG",
    "title": "Netherlands Antillean Guilder"
  },
  {
    "rate": 1.39,
    "code": "AOA",
    "title": "Angolan Kwanza"
  },
  {
    "rate": 0.6,
    "code": "ARS",
    "title": "Argentine Peso"
  },
  {
    "rate": 0.0025,
    "code": "AUD",
    "title": "Australian Dollar"
  },
  {
    "rate": 0.003,
    "code": "AWG",
    "title": "Aruban Florin"
  },
  {
    "rate": 0.0028,
    "code": "AZN",
    "title": "Azerbaijani Manat"
  },
  {
    "rate": 0.003,
    "code": "BAM",
    "title": "Bosnia-Herzegovina Convertible Mark"
  },
  {
    "rate": 0.0034,
    "code": "BBD",
    "title": "Barbadian Dollar"
  },
  {
    "rate": 0.18,
    "code": "BDT",
    "title": "Bangladeshi Taka"
  },
  {
    "rate": 0.003,
    "code": "BGN",
    "title": "Bulgarian Lev"
  },
  {
    "rate": 0.00063,
    "code": "BHD",
    "title": "Bahraini Dinar"
  },
  {
    "rate": 4.76,
    "code": "BIF",
    "title": "Burundian Franc"
  },
  {
    "rate": 0.0017,
    "code": "BMD",
    "title": "Bermudan Dollar"
  },
  {
    "rate": 0.0022,
    "code": "BND",
    "title": "Brunei Dollar"
  },
  {
    "rate": 0.012,
    "code": "BOB",
    "title": "Bolivian Boliviano"
  },
  {
    "rate": 0.0082,
    "code": "BRL",
    "title": "Brazilian Real"
  },
  {
    "rate": 0.0017,
    "code": "BSD",
    "title": "Bahamian Dollar"
  },
  {
    "rate": 4.4e-8,
    "code": "BTC",
    "title": "Bitcoin"
  },
  {
    "rate": 0.14,
    "code": "BTN",
    "title": "Bhutanese Ngultrum"
  },
  {
    "rate": 0.023,
    "code": "BWP",
    "title": "Botswanan Pula"
  },
  {
    "rate": 0.0055,
    "code": "BYN",
    "title": "Belarusian Ruble"
  },
  {
    "rate": 0.0034,
    "code": "BZD",
    "title": "Belize Dollar"
  },
  {
    "rate": 0.0023,
    "code": "CAD",
    "title": "Canadian Dollar"
  },
  {
    "rate": 4.46,
    "code": "CDF",
    "title": "Congolese Franc"
  },
  {
    "rate": 0.0015,
    "code": "CHF",
    "title": "Swiss Franc"
  },
  {
    "rate": 0.000053,
    "code": "CLF",
    "title": "Chilean Unit of Account (UF)"
  },
  {
    "rate": 1.45,
    "code": "CLP",
    "title": "Chilean Peso"
  },
  {
    "rate": 0.0119657,
    "code": "CNH",
    "title": "Chinese Yuan (Offshore)"
  },
  {
    "rate": 0.012,
    "code": "CNY",
    "title": "Chinese Yuan"
  },
  {
    "rate": 6.65,
    "code": "COP",
    "title": "Colombian Peso"
  },
  {
    "rate": 0.89,
    "code": "CRC",
    "title": "Costa Rican Colón"
  },
  {
    "rate": 0.0017,
    "code": "CUC",
    "title": "Cuban Convertible Peso"
  },
  {
    "rate": 0.044,
    "code": "CUP",
    "title": "Cuban Peso"
  },
  {
    "rate": 0.17,
    "code": "CVE",
    "title": "Cape Verdean Escudo"
  },
  {
    "rate": 0.038,
    "code": "CZK",
    "title": "Czech Republic Koruna"
  },
  {
    "rate": 0.3,
    "code": "DJF",
    "title": "Djiboutian Franc"
  },
  {
    "rate": 0.011,
    "code": "DKK",
    "title": "Danish Krone"
  },
  {
    "rate": 0.098,
    "code": "DOP",
    "title": "Dominican Peso"
  },
  {
    "rate": 0.22,
    "code": "DZD",
    "title": "Algerian Dinar"
  },
  {
    "rate": 0.051,
    "code": "EGP",
    "title": "Egyptian Pound"
  },
  {
    "rate": 0.02492,
    "code": "ERN",
    "title": "Eritrean Nakfa"
  },
  {
    "rate": 0.094,
    "code": "ETB",
    "title": "Ethiopian Birr"
  },
  {
    "rate": 0.0015,
    "code": "EUR",
    "title": "Euro"
  },
  {
    "rate": 0.0038,
    "code": "FJD",
    "title": "Fijian Dollar"
  },
  {
    "rate": 0.00132346,
    "code": "FKP",
    "title": "Falkland Islands Pound"
  },
  {
    "rate": 0.0013,
    "code": "GBP",
    "title": "British Pound Sterling"
  },
  {
    "rate": 0.0044,
    "code": "GEL",
    "title": "Georgian Lari"
  },
  {
    "rate": 0.0013,
    "code": "GGP",
    "title": "Guernsey Pound"
  },
  {
    "rate": 0.02,
    "code": "GHS",
    "title": "Ghanaian Cedi"
  },
  {
    "rate": 0.00131,
    "code": "GIP",
    "title": "Gibraltar Pound"
  },
  {
    "rate": 0.11,
    "code": "GMD",
    "title": "Gambian Dalasi"
  },
  {
    "rate": 14.8,
    "code": "GNF",
    "title": "Guinean Franc"
  },
  {
    "rate": 0.013,
    "code": "GTQ",
    "title": "Guatemalan Quetzal"
  },
  {
    "rate": 0.35,
    "code": "GYD",
    "title": "Guyanaese Dollar"
  },
  {
    "rate": 0.013,
    "code": "HKD",
    "title": "Hong Kong Dollar"
  },
  {
    "rate": 0.041,
    "code": "HNL",
    "title": "Honduran Lempira"
  },
  {
    "rate": 0.0115,
    "code": "HRK",
    "title": "Croatian Kuna"
  },
  {
    "rate": 0.22,
    "code": "HTG",
    "title": "Haitian Gourde"
  },
  {
    "rate": 0.59,
    "code": "HUF",
    "title": "Hungarian Forint"
  },
  {
    "rate": 26.15,
    "code": "IDR",
    "title": "Indonesian Rupiah"
  },
  {
    "rate": 0.0061,
    "code": "ILS",
    "title": "Israeli New Sheqel"
  },
  {
    "rate": 0.0013085,
    "code": "IMP",
    "title": "Manx pound"
  },
  {
    "rate": 0.14,
    "code": "INR",
    "title": "Indian Rupee"
  },
  {
    "rate": 2.18,
    "code": "IQD",
    "title": "Iraqi Dinar"
  },
  {
    "rate": 69.7,
    "code": "IRR",
    "title": "Iranian Rial"
  },
  {
    "rate": 0.23,
    "code": "ISK",
    "title": "Icelandic Króna"
  },
  {
    "rate": 0.00131,
    "code": "JEP",
    "title": "Jersey Pound"
  },
  {
    "rate": 0.26,
    "code": "JMD",
    "title": "Jamaican Dollar"
  },
  {
    "rate": 0.0012,
    "code": "JOD",
    "title": "Jordanian Dinar"
  },
  {
    "rate": 0.24,
    "code": "JPY",
    "title": "Japanese Yen"
  },
  {
    "rate": 0.24,
    "code": "KES",
    "title": "Kenyan Shilling"
  },
  {
    "rate": 0.15,
    "code": "KGS",
    "title": "Kyrgystani Som"
  },
  {
    "rate": 6.75,
    "code": "KHR",
    "title": "Cambodian Riel"
  },
  {
    "rate": 0.75,
    "code": "KMF",
    "title": "Comorian Franc"
  },
  {
    "rate": 1.49,
    "code": "KPW",
    "title": "North Korean Won"
  },
  {
    "rate": 2.2,
    "code": "KRW",
    "title": "South Korean Won"
  },
  {
    "rate": 0.00051,
    "code": "KWD",
    "title": "Kuwaiti Dinar"
  },
  {
    "rate": 0.0014,
    "code": "KYD",
    "title": "Cayman Islands Dollar"
  },
  {
    "rate": 0.75,
    "code": "KZT",
    "title": "Kazakhstani Tenge"
  },
  {
    "rate": 34.56,
    "code": "LAK",
    "title": "Laotian Kip"
  },
  {
    "rate": 148.6,
    "code": "LBP",
    "title": "Lebanese Pound"
  },
  {
    "rate": 0.51,
    "code": "LKR",
    "title": "Sri Lankan Rupee"
  },
  {
    "rate": 0.32,
    "code": "LRD",
    "title": "Liberian Dollar"
  },
  {
    "rate": 0.032,
    "code": "LSL",
    "title": "Lesotho Loti"
  },
  {
    "rate": 0.008,
    "code": "LYD",
    "title": "Libyan Dinar"
  },
  {
    "rate": 0.017,
    "code": "MAD",
    "title": "Moroccan Dirham"
  },
  {
    "rate": 0.03,
    "code": "MDL",
    "title": "Moldovan Leu"
  },
  {
    "rate": 7.54,
    "code": "MGA",
    "title": "Malagasy Ariary"
  },
  {
    "rate": 0.094,
    "code": "MKD",
    "title": "Macedonian Denar"
  },
  {
    "rate": 3.48,
    "code": "MMK",
    "title": "Myanma Kyat"
  },
  {
    "rate": 5.62,
    "code": "MNT",
    "title": "Mongolian Tugrik"
  },
  {
    "rate": 0.013,
    "code": "MOP",
    "title": "Macanese Pataca"
  },
  {
    "rate": 0.066,
    "code": "MRU",
    "title": "Mauritanian Ouguiya"
  },
  {
    "rate": 0.076,
    "code": "MUR",
    "title": "Mauritian Rupee"
  },
  {
    "rate": 0.025,
    "code": "MVR",
    "title": "Maldivian Rufiyaa"
  },
  {
    "rate": 2.79,
    "code": "MWK",
    "title": "Malawian Kwacha"
  },
  {
    "rate": 0.028215032,
    "code": "MXN",
    "title": "Mexican Peso"
  },
  {
    "rate": 0.0078870924,
    "code": "MYR",
    "title": "Malaysian Ringgit"
  },
  {
    "rate": 0.10567237,
    "code": "MZN",
    "title": "Mozambican Metical"
  },
  {
    "rate": 0.031566976,
    "code": "NAD",
    "title": "Namibian Dollar"
  },
  {
    "rate": 2.598367,
    "code": "NGN",
    "title": "Nigerian Naira"
  },
  {
    "rate": 0.060862001,
    "code": "NIO",
    "title": "Nicaraguan Córdoba"
  },
  {
    "rate": 0.017405059,
    "code": "NOK",
    "title": "Norwegian Krone"
  },
  {
    "rate": 0.21954489,
    "code": "NPR",
    "title": "Nepalese Rupee"
  },
  {
    "rate": 0.002679385,
    "code": "NZD",
    "title": "New Zealand Dollar"
  },
  {
    "rate": 0.00063690186,
    "code": "OMR",
    "title": "Omani Rial"
  },
  {
    "rate": 0.0016543054,
    "code": "PAB",
    "title": "Panamanian Balboa"
  },
  {
    "rate": 0.0062657527,
    "code": "PEN",
    "title": "Peruvian Nuevo Sol"
  },
  {
    "rate": 0.00619973,
    "code": "PGK",
    "title": "Papua New Guinean Kina"
  },
  {
    "rate": 0.092679963,
    "code": "PHP",
    "title": "Philippine Peso"
  },
  {
    "rate": 0.46238248,
    "code": "PKR",
    "title": "Pakistani Rupee"
  },
  {
    "rate": 0.0065626362,
    "code": "PLN",
    "title": "Polish Zloty"
  },
  {
    "rate": 12.021756,
    "code": "PYG",
    "title": "Paraguayan Guarani"
  },
  {
    "rate": 0.0060215839,
    "code": "QAR",
    "title": "Qatari Rial"
  },
  {
    "rate": 0.007586,
    "code": "RON",
    "title": "Romanian Leu"
  },
  {
    "rate": 0.17867876,
    "code": "RSD",
    "title": "Serbian Dinar"
  },
  {
    "rate": 0.15149947,
    "code": "RUB",
    "title": "Russian Ruble"
  },
  {
    "rate": 2.1165277,
    "code": "RWF",
    "title": "Rwandan Franc"
  },
  {
    "rate": 0.0062018916,
    "code": "SAR",
    "title": "Saudi Riyal"
  },
  {
    "rate": 0.013613087,
    "code": "SBD",
    "title": "Solomon Islands Dollar"
  },
  {
    "rate": 0.023534136,
    "code": "SCR",
    "title": "Seychellois Rupee"
  },
  {
    "rate": 0.99344222,
    "code": "SDG",
    "title": "Sudanese Pound"
  },
  {
    "rate": 0.017036868,
    "code": "SEK",
    "title": "Swedish Krona"
  },
  {
    "rate": 0.0022213744,
    "code": "SGD",
    "title": "Singapore Dollar"
  },
  {
    "rate": 0.0013035094,
    "code": "SHP",
    "title": "Saint Helena Pound"
  },
  {
    "rate": 37.602292,
    "code": "SLL",
    "title": "Sierra Leonean Leone"
  },
  {
    "rate": 0.94437297,
    "code": "SOS",
    "title": "Somali Shilling"
  },
  {
    "rate": 0.058361047,
    "code": "SRD",
    "title": "Surinamese Dollar"
  },
  {
    "rate": 0.22,
    "code": "SSP",
    "title": "South Sudanese Pound"
  },
  {
    "rate": 0.037684519,
    "code": "STN",
    "title": "São Tomé and Príncipe Dobra"
  },
  {
    "rate": 0.014475182,
    "code": "SVC",
    "title": "Salvadoran Colón"
  },
  {
    "rate": 21.509317,
    "code": "SYP",
    "title": "Syrian Pound"
  },
  {
    "rate": 0.031582002,
    "code": "SZL",
    "title": "Swazi Lilangeni"
  },
  {
    "rate": 0.059348417,
    "code": "THB",
    "title": "Thai Baht"
  },
  {
    "rate": 0.01812401,
    "code": "TJS",
    "title": "Tajikistani Somoni"
  },
  {
    "rate": 0.0057798248,
    "code": "TMT",
    "title": "Turkmenistani Manat"
  },
  {
    "rate": 0.0051570141,
    "code": "TND",
    "title": "Tunisian Dinar"
  },
  {
    "rate": 0.003886782,
    "code": "TOP",
    "title": "Tongan Pa'anga"
  },
  {
    "rate": 0.051506495,
    "code": "TRY",
    "title": "Turkish Lira"
  },
  {
    "rate": 0.011217988,
    "code": "TTD",
    "title": "Trinidad and Tobago Dollar"
  },
  {
    "rate": 0.05223607,
    "code": "TWD",
    "title": "New Taiwan Dollar"
  },
  {
    "rate": 4.2161522,
    "code": "TZS",
    "title": "Tanzanian Shilling"
  },
  {
    "rate": 0.063392228,
    "code": "UAH",
    "title": "Ukrainian Hryvnia"
  },
  {
    "rate": 6.482811,
    "code": "UGX",
    "title": "Ugandan Shilling"
  },
  {
    "rate": 0.0017,
    "code": "USD",
    "title": "United States Dollar"
  },
  {
    "rate": 0.066,
    "code": "UYU",
    "title": "Uruguayan Peso"
  },
  {
    "rate": 20.59,
    "code": "UZS",
    "title": "Uzbekistan Som"
  },
  {
    "rate": 0.0596,
    "code": "VES",
    "title": "Venezuelan Bolívar Soberano"
  },
  {
    "rate": 40.65,
    "code": "VND",
    "title": "Vietnamese Dong"
  },
  {
    "rate": 0.2,
    "code": "VUV",
    "title": "Vanuatu Vatu"
  },
  {
    "rate": 0.0046,
    "code": "WST",
    "title": "Samoan Tala"
  },
  {
    "rate": 1,
    "code": "XAF",
    "title": "CFA Franc BEAC"
  },
  {
    "rate": 0.000067,
    "code": "XAG",
    "title": "Silver Ounce"
  },
  {
    "rate": 8.21e-7,
    "code": "XAU",
    "title": "Gold Ounce"
  },
  {
    "rate": 0.0045,
    "code": "XCD",
    "title": "East Caribbean Dollar"
  },
  {
    "rate": 0.0013,
    "code": "XDR",
    "title": "Special Drawing Rights"
  },
  {
    "rate": 1,
    "code": "XOF",
    "title": "CFA Franc BCEAO"
  },
  {
    "rate": 0.000001722119342,
    "code": "XPD",
    "title": "Palladium Ounce"
  },
  {
    "rate": 0.18,
    "code": "XPF",
    "title": "CFP Franc"
  },
  {
    "rate": 0.000001847183974,
    "code": "XPT",
    "title": "Platinum Ounce"
  },
  {
    "rate": 0.42,
    "code": "YER",
    "title": "Yemeni Rial"
  },
  {
    "rate": 0.031,
    "code": "ZAR",
    "title": "South African Rand"
  },
  {
    "rate": 0.04,
    "code": "ZMW",
    "title": "Zambian Kwacha"
  },
  {
    "rate": 0.54,
    "code": "ZWL",
    "title": "Zimbabwean Dollar"
  }
]