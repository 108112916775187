import React, { useEffect, useState } from 'react';
import logoRsl from '../assets/img/logo/new/logo_r.png';
import logoWsl from '../assets/img/logo/new/logo_w.png';
import { HashLink as Link } from 'react-router-hash-link';
import {
    AppendScript,
    checkPasswordFormat,
    RemoveAppendedScript,
    Toast,
    history,
    Slang,
    google_window_login,
    parseUserAgent
} from '../helpers';


import $ from 'jquery';

import appConfig from '../config';
import { isEmail } from 'validator';

import Button from 'react-validation/build/button';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { Check, Input as MyInput } from '../components/myValidatorFields';
import { useSelector, useDispatch } from 'react-redux';

import { authActions, store } from '../store';
import Fingerprint2 from 'fingerprintjs2';
import { AdminContentLoader, AuthLeftCover } from '../components';
import { Helmet } from 'react-helmet-async';



const Register = () => {

    const [fp, setFp] = useState('');

    const t = Slang('page.register');
    const tl = Slang('page.login');
    const t_modals = Slang('topbar.modals');

    const [googleBtnLoading, setGoogleBtnLoading] = useState(false);



    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.token ? x.auth.user : false);
    const [showLoading, setShowLoading] = useState(false);

    const [load, setLoad] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const [infos, setInfos] = useState({
        first_name: "",
        email: "",
        name: "",
        password: "",
        password_confirmation: "",
        confidential: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name !== "confidential") {
            setInfos({ ...infos, [name]: value });
        } else {
            setInfos({ ...infos, confidential: e.target.checked });
        }
    };


    const _appendScript = () => {
        AppendScript('./assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js');
        AppendScript('./assets/vendor/hs-toggle-password/dist/js/hs-toggle-password.js');
        AppendScript('./assets/js/theme.min.js');

        AppendScript('../assets/js/registerScript.js');

        setInterval(() => {
            if (window.screen.width < 992) {
                $('#root').addClass('d-flex align-items-center');
                $('main').css({
                    'position': 'relative',
                    'top': '10vh',
                });
                $('html').addClass('h-100');
            } else {
                $('main').css({
                    'position': 'relative',
                    'top': '0',
                });
                $('#root').removeAttr('class');
                $('body').removeAttr('class');
                $('html').removeAttr('class');
            }
        }, 200);
    };

    // Travail sur la liberation des ressources une fois que le composant est demonte
    const _removeScript = () => {
        RemoveAppendedScript();
        $('body').removeAttr('class');
        $('html').removeAttr('class');
    }

    useEffect(() => {

        Fingerprint2.get(components => {
            const values = components.map((component) => component.value);
            const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);

            setFp(fingerprint);
        });

        setTimeout(() => {
            setLoad(false);
        }, 2000);

        setInterval(() => {
            if (sessionStorage.getItem('init-btn') !== null) {
                if (parseInt(sessionStorage.getItem('init-btn')) === 0) {
                    setShowLoading(false);
                } else {
                    setShowLoading(true);
                }
            }
        }, 1000);

        if (authUser) history.navigate('/');
        _appendScript();
        return () => _removeScript();
    }, [authUser]);


    //Login functions
    const _rrequired = value => {
        if (!value) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    {t('errors.require')}
                </div>
            );
        }
    };

    const _fname = value => {
        return value !== ""
            ? value.length < 4
                ? <div className="alert alert-soft-danger mt-2" role="alert">{t('errors.min_car', { min: 4 })}</div>
                : null
            : <div className="alert alert-soft-danger mt-2" role="alert">{t('errors.empty')}</div>
    };

    const _lname = value => {
        return value !== ""
            ? value.length < 4
                ? <div className="alert alert-soft-danger mt-2" role="alert">{t('errors.min_car', { min: 4 })}</div>
                : null
            : <div className="alert alert-soft-danger mt-2" role="alert">{t('errors.empty')}</div>
    };

    const _email = value => {
        if (!isEmail(value)) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    {t('errors.email')}
                </div>
            );
        }
    };

    const _password = value => {
        if (!checkPasswordFormat(value)) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    {t('errors.invalid_pass')}
                </div>
            );
        }
    };

    const _confirmPassVerif = value => {
        if (infos.password !== infos.password_confirmation) {
            Toast('error', t('errors.confirm_pass'), 'tr', true, true, true, 3000);
            setShowLoading(false);
            return false;
        }
        return true;
    };

    const handleSubmit = event => {
        event.preventDefault();

        sessionStorage.setItem('init-btn', 1);

        if (_confirmPassVerif() && infos.confidential) {
            $.getJSON(appConfig.apip, (datas) => {

                setTimeout(() => {
                    var user = store.getState().user.user;
                    var agent = window.navigator;

                    parseUserAgent(datas, datas.ip || datas.ipString, user.id)
                        .then(data => {
                            dispatch(authActions.register({
                                ...infos,
                                ip: datas.ip || datas.ipString,
                                device: data,
                                fingerprint: fp
                            }));
                        });
                }, 4000);

            });
        }

        if (!infos.confidential) {
            $("#signupHeroFormPrivacyCheck").css('border', '2px solid rgba(200, 10, 10, 0.6)');
            setShowLoading(false);
            Toast('error', t('errors.confirm_priv'), 'bc', true, true, true, 3000);
        }
    };


    return <>
        {
            load
                ? <AdminContentLoader />
                : <>
                    <header id="header" className="navbar navbar-expand navbar-light navbar-absolute-top">
                        <div className="container-fluid">
                            <nav className="navbar-nav-wrap">
                                <Link className="navbar-brand d-none d-lg-flex" to="/" aria-label="Front">
                                    <img className="navbar-brand-logo-header" src={logoWsl} alt="Logo" />
                                </Link>

                                <Link className="navbar-brand d-flex d-lg-none" to="/" aria-label="Front">
                                    <img className="navbar-brand-logo-header" src={logoRsl} alt="Logo" />
                                </Link>

                                {/* <div className="ms-auto">
                            <Link className="link link-sm link-secondary" to="/">
                                <i className="bi-chevron-left small ms-1"></i> Go to Home
                            </Link>
                        </div> */}
                            </nav>
                        </div>
                    </header>

                    <main id="content" role="main" className="flex-grow-1">
                        <div className="container-fluid">
                            <div className="row">
                                <AuthLeftCover lang={tl} />

                                <div className="col-lg-7 col-xl-8 d-flex justify-content-center align-items-center min-vh-lg-100">
                                    <div className="flex-grow-1 mx-auto" style={{ maxWidth: "28rem" }}>
                                        <div className="text-center mb-5 mb-md-7">
                                            {/* <img src={require('../assets/img/logo/apines_red.png')} alt="logo_" style={{
                                        height: 80,
                                        marginBottom: 8
                                    }} /> */}
                                            <h1 className="h2">{t('title')}</h1>
                                            <p>{t('subtitle')}</p>
                                        </div>

                                        <Form onSubmit={e => handleSubmit(e)} >
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="signupModalFormSignupName">{t('form.fname')}</label>
                                                <Input
                                                    type="text"
                                                    className="form-control form-control-lg"
                                                    name="first_name"
                                                    id="signupModalFormSignupName"
                                                    placeholder="Alan"
                                                    aria-label="Alan"
                                                    validations={[_rrequired, _fname]}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="signupModalFormSignupName">{t('form.lname')}</label>
                                                <Input
                                                    type="text"
                                                    className="form-control form-control-lg"
                                                    name="name"
                                                    id="signupModalFormSignupName"
                                                    placeholder="Peter"
                                                    aria-label="Peter"
                                                    validations={[_rrequired, _lname]}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="signupModalFormSignupEmail">{t('form.email')}</label>
                                                <Input
                                                    type="email"
                                                    className="form-control form-control-lg"
                                                    name="email"
                                                    id="signupModalFormSignupEmail"
                                                    placeholder="email@site.com"
                                                    aria-label="email@site.com"
                                                    validations={[_rrequired, _email]}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            {/* password */}
                                            <div className="mb-4">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <label className="form-label" htmlFor="signupModalFormLoginPassword">{t('form.password')}</label>
                                                </div>

                                                <MyInput
                                                    type={showPassword ? 'text' : 'password'}
                                                    className="form-control form-control-lg"
                                                    name="password"
                                                    id="signupModalFormLoginPassword"
                                                    placeholder={t('form.pl_hld_pass')}
                                                    aria-label={t('form.pl_hld_pass')}
                                                    minLength="8"
                                                    validations={[_rrequired, _password]}
                                                    onChange={handleChange}
                                                >
                                                    <span
                                                        id="changePassTarget"
                                                        className="input-group-append input-group-text"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    >
                                                        <i id="changePassIcon" className={showPassword ? 'bi-eye' : 'bi-eye-slash'}></i>
                                                    </span>
                                                </MyInput>
                                            </div>

                                            {/* Confirm password */}
                                            <div className="mb-4">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <label className="form-label" htmlFor="ConfirmLoginPassword">{t('form.confirm_pass')}</label>
                                                </div>

                                                <MyInput
                                                    type={showPasswordConfirm ? 'text' : 'password'}
                                                    className="form-control form-control-lg"
                                                    name="password_confirmation"
                                                    id="ConfirmLoginPassword"
                                                    placeholder={t('form.pl_hld_pass')}
                                                    aria-label={t('form.pl_hld_pass')}
                                                    minLength="8"
                                                    validations={[_rrequired, _password]}
                                                    onChange={handleChange}
                                                >
                                                    <span
                                                        id="changePassTarget"
                                                        className="input-group-append input-group-text"
                                                        onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                                    >
                                                        <i id="changePassIcon" className={showPasswordConfirm ? 'bi-eye' : 'bi-eye-slash'}></i>
                                                    </span>
                                                </MyInput>
                                            </div>

                                            <div className="form-check mb-3">
                                                <Check
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="signupHeroFormPrivacyCheck"
                                                    name="confidential"
                                                    validations={[_rrequired]}
                                                    onChange={handleChange}
                                                    value={infos.confidential}
                                                />
                                                <label className="form-check-label small" htmlFor="signupHeroFormPrivacyCheck"> {t('form.text_priv')} <a href='/privacy'>{t('form.link_priv')}.</a></label>
                                            </div>

                                            <div className="d-grid mb-3 gap-3">
                                                {
                                                    !showLoading
                                                        ? <Button type="submit" className="btn btn-primary btn-lg">
                                                            {t('form.btn')}
                                                        </Button>
                                                        : <button type="button" className="btn btn-primary btn-lg" disabled={showLoading}>
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            {t('form.in_progress')}
                                                        </button>
                                                }
                                                {
                                                    googleBtnLoading
                                                        ? <>
                                                            <span className="btn btn-white btn-lg" style={{ cursor: "progress" }}>
                                                                <span className="d-flex justify-content-center align-items-center">
                                                                    <div className="spinner-border spinner-border-sm text-primary" role="status">
                                                                    </div>&nbsp;&nbsp;
                                                                    <img className="avatar avatar-xss me-2" src="./assets/img/svg/brands/google-icon.svg"
                                                                        alt="google_brand" />
                                                                    {t_modals('google_register.btn_google')}
                                                                </span>
                                                            </span>
                                                        </>
                                                        : <span className="btn btn-white btn-lg disabled" onClick={() => console.log('inconstruction')}>
                                                        {/* : <span className="btn btn-white btn-lg" onClick={() => google_window_login(setGoogleBtnLoading)}> */}
                                                            <span className="d-flex justify-content-center align-items-center">
                                                                <img className="avatar avatar-xss me-2" src="./assets/img/svg/brands/google-icon.svg"
                                                                    alt="google_brand" />
                                                                {/* {t_modals('google_register.btn_google')} */}
                                                                Coming soon
                                                            </span>
                                                        </span>
                                                }

                                            </div>

                                            <div className="text-center">
                                                <p>{t('form.text_log')} <a className="link" href="/signin">{t('form.link_log')}</a></p>
                                            </div>
                                        </Form>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
        }
        <Helmet>

            <link rel="stylesheet" href="./assets/css/app/bootstrap-icons/font/bootstrap-icons.css" />
            <link rel="stylesheet" href="./assets/css/app/hs-mega-menu.min.css" />
            <link rel="stylesheet" href="./assets/css/app/quill.snow.css" />
            <link rel="stylesheet" href="./assets/css/app/tom-select.css" />
            <link rel="stylesheet" href="./assets/css/app/aos.css" />
            <link rel="stylesheet" href="./assets/css/app/swiper-bundle.min.css" />
            <link rel="stylesheet" href="./assets/css/app/theme.min.css" />

            <title>HappyNess | Signup</title>
        </Helmet>
    </>;

}

export default Register;

