import { Toast } from 'helpers';
import React, { useState } from 'react';
import { store } from 'store';


const CreateProjectForm = ({ setProjectTitle,
    setProjectDescription,
    setProjectPortee,
    setProjectTeams,
    handleSave,
    loadingSaveBtn,
    setProjectCategory }) => {

    let tar = store.getState().admin.users;
    const [arrWorker, setArrWorker] = useState([]);
    const [tab, setTab] = useState(tar);
    const [carDesc, setCarDesc] = useState(0);


    const handleAddWorker = person => {
        if (arrWorker.length === 0) {
            setProjectTeams([person]);
            setArrWorker([person]);
            setTab(tab.filter(elm => elm.id !== person.id));
        } else {
            if (arrWorker.length <= 6) {
                if (arrWorker.filter(elm => elm.id === person.id).length === 0) {
                    setProjectTeams([...arrWorker, person]);
                    setArrWorker([...arrWorker, person]);
                    setTab(tab.filter(elm => elm.id !== person.id));
                } else {
                    Toast('error', 'The worker already added', 'tr', false, false, true, 3000, false);
                }
            } else {
                Toast('warning', 'You have reached the user limit for the project', 'tr', false, false, true, 3000, false);
            }
        }
    };

    const handleRemoveWorker = person => {
        setProjectTeams(arrWorker.filter(elm => elm.id !== person.id));
        setArrWorker(arrWorker.filter(elm => elm.id !== person.id));
        setTab([...tab, person]);
    };


    return <div className="card card-primary bg-white">
        <div className="card-header">
            <h3 className="card-title" style={{ fontWeight: 'bold' }}>Create a New Project</h3>
        </div>
        <div className="card-body">
            <>
                <div className="row">
                    <div className="col-md-12">

                        <div className="form-group">
                            <label htmlFor='title'>Title</label>
                            <input
                                type="text"
                                id='title'
                                className="form-control bg-white"
                                placeholder="Enter the project title"
                                onChange={e => { setProjectTitle(e.target.value) }}
                            />
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div className="form-group">
                            <label htmlFor='description'>
                                Project description [Length: <span className={`text-${carDesc < 50 ? 'danger' : 'success'}`}>{carDesc} {carDesc > 1 ? 'characters' : 'character'}</span>]
                            </label>
                            <textarea
                                className="form-control bg-white"
                                id='description'
                                rows="7"
                                placeholder="Describe the porject here..."
                                onChange={e => { setProjectDescription(e.target.value); setCarDesc(e.target.value.length) }}
                            ></textarea>
                        </div>

                        <div className="form-group clearfix">
                            <label className='mr-2'>Access: </label>
                            <div className="d-inline">
                                <input type="radio" id="public" name="r1" value={0} onChange={e => { setProjectPortee(parseInt(e.target.value)) }} />
                                <label htmlFor="public">Public</label>
                            </div>
                            <div className="ml-3 d-inline">
                                <input type="radio" id="private" name="r1" value={1} onChange={e => { setProjectPortee(parseInt(e.target.value)) }} />
                                <label htmlFor="private">Private</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        {/* Category */}
                        <div className="form-group">
                            <label htmlFor="projectCategory">Select a category</label>
                            <select id="projectCategory" className="form-control bg-white custom-select" onChange={e => setProjectCategory(e.target.value)}>
                                <option value={''}>-- --</option>
                                {
                                    store.getState().admin.categories
                                        .map((categ, key) => {
                                            return <option key={key} value={categ.id}>{categ.name}</option>
                                        })
                                }

                            </select>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <label>Affect users to the project. Available: {tab.filter(user => user.id !== store.getState().user.user.id && (user.role_id === 3 || user.role_id === 2)).length}</label>
                        <div className="card">
                            <div className="card-body bg-white">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap', maxHeight: 200, overflow: "auto" }}>
                                    {
                                        tab.filter(user => user.id !== store.getState().user.user.id && (user.role_id === 3 || user.role_id === 2)) //Just user who have worker and manager role
                                            .map((user, key) => <span
                                                key={key}
                                                onClick={() => handleAddWorker(user)}
                                                style={{ padding: 5, margin: 5 }}
                                                className='btn btn-outline-info btn-sm'
                                                title={user.role_id === 3 ? 'Add Worker' : 'Add Manager'}
                                            >
                                                <i className="fa fa-plus text-success"></i> {user.first_name}
                                            </span>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label>{arrWorker.length < 2 ? 'Worker' : 'Workers'} on the project: {arrWorker.length}</label>
                        <div className="card">
                            <div className="card-body bg-white">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap', maxHeight: 200, overflow: "auto" }}>
                                    {
                                        arrWorker
                                            .map((user, key) => <span
                                                key={key}
                                                onClick={() => handleRemoveWorker(user)}
                                                style={{ padding: 5, margin: 5 }}
                                                className='btn btn-outline-danger btn-sm'
                                                title={user.role_id === 3 ? 'Remove Worker' : 'Remove Manager'}
                                            >
                                                <i className="fa fa-trash text-danger"></i> {user.first_name}
                                            </span>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col-md-4">
                    </div>
                    <div className="col-md-4">
                    </div>
                    <div className="col-md-4">
                        {
                            loadingSaveBtn
                                ? <button type="button" className="btn btn-success btn-block" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading
                                </button>
                                : <button className="btn btn-success btn-block" type='button' onClick={() => handleSave()}>Save</button>
                        }
                    </div>

                </div>
            </>
        </div>
    </div>;
}

export default CreateProjectForm;
