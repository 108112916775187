import React, { useEffect, useState } from 'react';
import { store } from 'store';
import { getFileFromBack, progressValue } from 'helpers';
import { PreviewFiles } from '.';


const AdminProjectView = ({ project }) => {


    const [techno, setTechno] = useState(store.getState().admin.projecttech !== null ? store.getState().admin.projecttech[0] : {});
    const [tasks, setTasks] = useState(store.getState().admin.projecttasks !== null ? store.getState().admin.projecttasks : []);
    const [files, setFiles] = useState([]);
    const [modal, setModal] = useState(<></>);

    useEffect(() => {
        let tim = setInterval(() => {
            setTechno(store.getState().admin.projecttech !== null ? store.getState().admin.projecttech[0] : {});
            setTasks(store.getState().admin.projecttasks !== null ? store.getState().admin.projecttasks : []);
        }, 2000);

        let timer = setInterval(() => {
            setFiles(JSON.parse(window.sessionStorage.getItem('projects_files')) !== null ? JSON.parse(window.sessionStorage.getItem('projects_files')) : []);
        }, 500);

        return () => {
            clearInterval(tim);
            clearInterval(timer);
        };
    }, []);


    const handleViewFile = src => {
        setModal(<></>);
        setModal(<PreviewFiles src={src} />);
    };


    return <>
        <div className="modal fade" id="view-project" style={{ display: "none" }} aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content bg-white">

                    <div className="modal-body">
                        <div className="card bg-white">
                            <div className="card-header">
                                <h3 className="card-title">Project Details</h3>

                                <div className="card-tools">
                                    <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                        <i className="fas fa-minus"></i>
                                    </button>
                                    <button type="button" className="btn btn-tool" data-dismiss="modal" aria-label="Close" title="Close">
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>

                            <div className="card-body" style={{ display: 'block' }}>
                                <div className="row">
                                    <div className="col-12 col-md-8 col-lg-8 order-2 order-md-1">

                                        <div className="row">
                                            <div className="col-12 col-sm-4">
                                                <div className="info-box bg-white">
                                                    <div className="info-box-content">
                                                        <span className="info-box-text text-center text-primary text-bold">Budget</span>
                                                        <span className="info-box-number text-center text-info mb-0">${techno !== undefined ? techno.budget : 0}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-4">
                                                <div className="info-box bg-white">
                                                    <div className="info-box-content">
                                                        <span className="info-box-text text-center text-primary text-bold">Total amount spent</span>
                                                        <span className="info-box-number text-center text-info mb-0">$2000</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-4">
                                                <div className="info-box bg-white">
                                                    <div className="info-box-content">
                                                        <span className="info-box-text text-center text-primary text-bold">Duration (days)</span>
                                                        <span className="info-box-number text-center text-info mb-0">20</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <h4 className='text-bold'>
                                                    Project's Tasks (<span className='text-info'>{project.tasks.length}</span>)
                                                    <div className="progress elevation-2" style={{ borderRadius: 5, backgroundColor: '#d7eac2' }}>
                                                        <div className="progress-bar bg-green progress-bar-striped" role="progressbar" aria-valuenow={`${isNaN(progressValue(project.tasks)) ? '' : progressValue(project.tasks)}`} aria-valuemin="0" aria-valuemax="100" style={{ width: `${isNaN(progressValue(project.tasks)) ? '0' : progressValue(project.tasks)}%`, borderRadius: 5 }}>
                                                            <span className="">{isNaN(progressValue(project.tasks)) ? '' : progressValue(project.tasks)} %</span>
                                                        </div>
                                                    </div>
                                                </h4><hr />
                                                <div style={{ borderRadius: 5, maxHeight: 300, overflow: 'auto' }}>
                                                    {
                                                        tasks.map((task, key) => {
                                                            return <React.Fragment key={key}>
                                                                <div className="post elevation-1" style={{ borderRadius: 15 }}>
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <span>
                                                                            <span className='text-info text-bold'>
                                                                                <i className="fa fa-list mr-2 text-info text-bold"></i>
                                                                                {task.name}
                                                                            </span>
                                                                            <span className={`badge badge-${task.completed === 1 ? 'success' : 'danger'} ml-2`}>{task.completed === 1 ? 'Completed' : 'Not started'}</span>
                                                                        </span>
                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                            {[...task.users].slice(0, 5).map((user, key) => <img key={key} src={getFileFromBack(user.avatar)} alt={user.name} title={user.name} style={{ borderRadius: '50%', height: 30, width: 30 }} className="elevation-2 mr-1" />)
                                                                            }
                                                                        </div>
                                                                        <span className="description text-muted text-bold" style={{ fontSize: '0.7rem' }}>Shared publicly - 7:45 PM today</span>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-1"></div>
                                                                        <div className="col-md-11">
                                                                            <p style={{ color: 'black' }}>{task.description}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <hr /> */}
                                                            </React.Fragment>
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card card-light">
                                                    <div className="card-header">
                                                        <h5 className='card-title'>Project Team (<span className='text-primary' style={{ fontStyle: 'italic' }}>{project.users.length + `${project.users.length > 1 ? ' members' : ' member'}`} </span>) </h5>
                                                    </div>
                                                </div>

                                                <div className="card-body" style={{ maxHeight: 190, overflow: 'auto' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap' }}>

                                                        {
                                                            project.users.map((user, key) => <div key={key} style={{ display: 'flex', flexDirection: 'column' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }} className='text-center'>
                                                                    <img src={getFileFromBack(user.avatar)} alt={user.name} style={{ borderRadius: '50%', height: 130, width: 130 }} className="elevation-2" />
                                                                    {
                                                                        user.pivot.is_owner === 1
                                                                            ? <i style={{ position: 'relative', float: 'right', alignSelf: 'end', right: 115 }} className="fa fa-star fa-2x text-warning"></i>
                                                                            : null
                                                                    }
                                                                </div>
                                                                <span className='text-center'>{user.name}</span>
                                                            </div>)
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-12 col-md-4 col-lg-4 order-1 order-md-2" style={{
                                        maxHeight: '720px',
                                        overflow: 'auto',
                                        // boxShadow: '0px 0px 5px gray',
                                        borderRadius: 10
                                    }}>

                                        <h3 className="text-primary mt-1 text-bold"><i className="fa fa-book mr-2"></i>{project.name}</h3>
                                        <p className="text-muted">
                                            {project.description}
                                        </p>
                                        <br />

                                        <div className="text-muted">
                                            <div className="row">
                                                <div className="col-md-12" style={{ borderRadius: 5 }}>
                                                    <span className="text-sm" ><span style={{ textShadow: '0px 0x 1px #000', color: 'gray' }}>Proprietor</span>
                                                        <span className="d-block text-primary text-bold">{store.getState().admin.users.filter(user => user.id === project.user_id)[0].name}</span>
                                                    </span>
                                                </div>
                                                <div className="col-md-12" style={{ borderRadius: 5 }}>
                                                    <span className="text-sm"><span style={{ textShadow: '0px 0x 1px #000', color: 'gray' }}>Project Leader</span>
                                                        <span className="d-block text-primary text-bold">{
                                                            project.users.filter(user => user.pivot.is_owner === 1).length !== 0
                                                                ? project.users.filter(user => user.pivot.is_owner === 1)[0].name
                                                                : 'Not set'
                                                        }</span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12 mt-2" style={{ borderRadius: 5 }}>
                                                    <span className="text-sm"><span style={{ textShadow: '0px 0x 1px #000', color: 'gray' }}>Join MeetSpace</span>
                                                        {
                                                            project.meetspacelink === null
                                                                ? <span className="d-block text-primary text-bold">Not set</span>
                                                                : <a href={project.meetspacelink}
                                                                    target='_blank'
                                                                    className="d-block text-primary text-bold"
                                                                >Go to MeetSapce <i className="bi bi-box-arrow-up-right"></i></a>
                                                        }
                                                    </span>
                                                </div>

                                            </div>
                                        </div>


                                        <h4 className="mt-5 text-muted">Project technologies</h4>
                                        <ul className="list-unstyled">
                                            {
                                                techno !== undefined
                                                    ? <>

                                                        <li>
                                                            <i className="fab fa-laravel mr-1"></i>Back-end: {techno.backend_name}
                                                        </li>

                                                        <li>
                                                            <i className="fab fa-react mr-1"></i>Front-end: {techno.frontend_name}
                                                        </li>

                                                        <li>
                                                            <i className="fa fa-database mr-1"></i>Database: {techno.bdd}
                                                        </li>
                                                        <li>
                                                            <i className="fab fa-docker mr-1"></i>Virtualizer: {techno.virtualiseur}
                                                        </li>

                                                        <li>
                                                            <i className="fab fa-keycdn mr-1"></i>Security: {techno.security}
                                                        </li>

                                                        <li>
                                                            <i className="fab fa-aws mr-1"></i>Broker: {techno.brocker}
                                                        </li>
                                                    </>
                                                    : null
                                            }

                                        </ul>


                                        <h4 className="mt-5 text-muted">Project files</h4>
                                        <ul className="list-unstyled">
                                            {
                                                rebuildFilesArray(files).length > 0
                                                    ? rebuildFilesArray(files).map(file => {
                                                        return <li key={file.key}>
                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                    <i className={`far fa-fw ${file.icon} mr-1`}></i>
                                                                    {file.filename}
                                                                </div>

                                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                    <a href={getFileFromBack(file.path)} download target='_blank' >
                                                                        <i className={`bi bi-download mr-2`} style={{ cursor: 'pointer', color: 'green', fontWeight: 'bold' }} title='Download'></i>
                                                                    </a>
                                                                    {
                                                                        fileType(file.icon)
                                                                            ? <i style={{ cursor: 'pointer', color: 'navy', fontWeight: 'bold' }} title='Open in modal' className={`bi-box-arrow-up-right`} onClick={() => handleViewFile(getFileFromBack(file.path))} data-toggle="modal" data-target="#preview-files"></i>
                                                                            : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </li>
                                                    })
                                                    : <div className='alert' style={{backgroundColor: '#FDE4EB', color:'#ED4C78'}}>No files</div>
                                            }
                                        </ul>


                                        <div className="text-center mt-5 mb-3">
                                            <button className="btn btn-xl btn-primary disabled" disabled>
                                                <i className="bi bi-printer mr-2"></i>
                                                Generate a report
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
        {modal}
    </>
};

export default AdminProjectView;



const extractFileName = file => {
    let spliter = file.split('/')[3].split('_');
    return spliter[spliter.length - 1];
};

const faFileIcon = file => {
    let ext = file.split('.')[1];

    return ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gif'
        ? 'fa-image'
        : ext === 'pdf'
            ? 'fa-file-pdf'
            : ext === 'doc' || ext === 'docx' || ext === 'odt'
                ? 'fa-file-word'
                : ext === 'zip' || ext === 'rar' || ext === '7zip'
                    ? 'fa-file-archive'
                    : ext === 'mp3' || ext === 'aac'
                        ? 'fa-file-audio'
                        : ext === 'xls' || ext === 'xlsx' || ext === 'ods'
                            ? 'fa-file-excel' : 'file-code'
};

const fileType = icon => {
    return icon === 'fa-image' || icon === 'fa-file-pdf' ? true : false
};

const rebuildFilesArray = arr => {
    let tab = [];

    for (let i = 0; i < arr.length; i++) {
        tab[i] = {
            key: i + 1,
            filename: extractFileName(arr[i]),
            icon: faFileIcon(arr[i]),
            path: arr[i]
        };
    }

    return tab;
};

