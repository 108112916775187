import React, { useEffect, useState } from 'react';
import '../../assets/css/all.min.css';
import '../../assets/vendor/bootstrap-icons/font/bootstrap-icons.css';

import { store, userActions, adminActions } from '../../store';
import $ from 'jquery';
import { history } from '../../helpers';
import NavbarAdmin from './navbarAdmin';
import SidebarAdmin from './sidebarAdmin';
import { HashLink as Link } from 'react-router-hash-link';
import { useDispatch, useSelector } from 'react-redux';
import { AdminCreateWorker } from '../../components/modals';
import InboxMailContent from './inboxMailContent';
import { Helmet } from 'react-helmet-async';
import { connectionVerif } from '../home';




const Admin = () => {

    const dispatch = useDispatch();

    const [modal, setModal] = useState(<></>);
    const [loading, setLoading] = useState(true);
    const auth = useSelector(x => x.auth);


    /* ---------------------------------------------------------------------------------------------- */
    /*           Il faut activer ceci une fois que le travail sur le dashboard va commender           */
    /* ---------------------------------------------------------------------------------------------- */

    // const [content, setContent] = useState({
    //     icon: 'tachometer-alt',
    //     name: 'Dashboard',
    //     component: () => <DashContent />
    // });


    const [content, setContent] = useState({
        icon: 'envelope',
        name: 'HappyNess Mailer',
        component: () => <InboxMailContent />
    });
    // const [loadContent, setLoadContent] = useState(false);


    useEffect(() => {

        setTimeout(() => {
            connectionVerif(dispatch, setLoading, store.getState().user, auth);
        }, 1200);

        let user = store.getState().user.user.id !== undefined ? store.getState().user.user : null;

        if (!user || user.role_id !== 1) history.navigate('/');

        dispatch(userActions.getUser());
        dispatch(adminActions.roles());
        dispatch(adminActions.users());
        dispatch(adminActions.deletedusers());
        dispatch(adminActions.notverifiedusers());
        dispatch(adminActions.projects());

        setTimeout(() => {
            connectionVerif(dispatch, setLoading, store.getState().user, auth);
        }, 1200);

        return () => {
            $('body').removeClass('hold-transition dark-mode sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed');
        }
    }, []);




    return <>
        {
            !loading ? (
                <>
                    <NavbarAdmin />
                    <SidebarAdmin setContent={setContent} setModal={setModal} />

                    <div className="content-wrapper">
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>
                                            <i className={`fas fa-${content.icon}`}></i>&nbsp;
                                            {content.name} {content.name !== "Users"
                                                ? null
                                                : <span className='btn btn-sm btn-success'
                                                    title='Create a new worker'
                                                    data-toggle="modal"
                                                    data-target="#create-worker"
                                                    onClick={() => setModal(<AdminCreateWorker setModal={setModal} />)}
                                                >
                                                    <i className="fa fa-plus"></i>
                                                </span>
                                            }
                                        </h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><Link to="/" className='text-light'><i className="fas fa-home mr-1" style={{ color: '#c94a79' }}></i>Home</Link></li>
                                            {/* <li className="breadcrumb-item active">Dashboard v2</li> */}
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                {
                                    content.component()
                                }
                            </div>
                        </section>
                    </div>
                    {modal}

                </>
            ) : (
                <div className="preloader flex-column justify-content-center align-items-center">
                    <span className="spinner-border spinner-border" role="status" aria-hidden="true"></span>
                </div>
            )
        }

        <Helmet>
            <link rel="stylesheet" href="./assets/css/admin/all.min.css" />
            <link rel="stylesheet" href="./assets/css/admin/adminlte.min.css" />

            <script defer src="./assets/js/admin/jquery/jquery.min.js"></script>
            <script defer src="./assets/js/admin/bootstrap/js/bootstrap.bundle.min.js"></script>
            <script defer src="./assets/js/admin/overlayScrollbars/js/jquery.overlayScrollbars.min.js"></script>
            <script defer src="./assets/js/admin/dist/js/adminlte.js"></script>
            <script defer src="./assets/js/admin/jquery-mousewheel/jquery.mousewheel.js"></script>
            <script defer src="./assets/js/admin/raphael/raphael.min.js"></script>
            <script defer src="./assets/js/admin/jquery-mapael/jquery.mapael.min.js"></script>
            <script defer src="./assets/js/admin/jquery-mapael/maps/usa_states.min.js"></script>
            <script defer src="./assets/js/admin/chart.js/Chart.min.js"></script>
            <script defer src="./assets/js/admin/dist/js/pages/dashboard2.js"></script>

            {/* <script async src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&callback=initMap"></script> */}
            <title>HappyNess | DashBoard</title>
        </Helmet>
    </>

}

export default Admin;
