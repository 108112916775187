import React from 'react';
import { Toast, history } from '../../helpers';
import { useDispatch } from 'react-redux';
import { adminActions, authActions, userActions, managerActions, store } from '../../store';

const NavbarAdmin = () => {


    const dispatch = useDispatch();

    const handleLogout = () => {

        Toast('info', 'See you next time!', 'tr', true, true, true, 2800);

        setTimeout(() => {
            dispatch(authActions.signout({ user_id: store.getState().user.user.id }))
            dispatch(authActions.logout())
            dispatch(managerActions.logout());
            dispatch(userActions.logout());
            dispatch(adminActions.logout());
        }, 3000);
    };

    return (
        <nav className="main-header navbar navbar-expand navbar-dark">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <span className="nav-link" onClick={() => history.navigate('/')} style={{ cursor: 'pointer' }}>Home</span>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <span className="nav-link" onClick={() => history.navigate('/account/profile')} style={{ cursor: 'pointer' }}>Profile</span>
                </li>
            </ul>

            <ul className="navbar-nav ml-auto">

                <li className="nav-item dropdown">
                    <span className="nav-link" data-toggle="dropdown" href="#">
                        <i className="far fa-bell"></i>
                        <span className="badge badge-danger navbar-badge">1</span>
                    </span>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                        <span className="dropdown-item dropdown-header">15 Notifications</span>
                        <div className="dropdown-divider"></div>
                        <a href="#" className="dropdown-item">
                            <i className="fas fa-envelope mr-2"></i> 4 new messages
                            <span className="float-right text-muted text-sm">3 mins</span>
                        </a>
                        <div className="dropdown-divider"></div>
                        <a href="#" className="dropdown-item">
                            <i className="fas fa-users mr-2"></i> 8 friend requests
                            <span className="float-right text-muted text-sm">12 hours</span>
                        </a>
                        <div className="dropdown-divider"></div>
                        <a href="#" className="dropdown-item">
                            <i className="fas fa-file mr-2"></i> 3 new reports
                            <span className="float-right text-muted text-sm">2 days</span>
                        </a>
                        <div className="dropdown-divider"></div>
                        <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
                    </div>
                </li>


                <li className="nav-item dropdown">
                    <span className="nav-link" data-toggle="dropdown" role='button'>
                        <i className="far fa-user"></i>
                    </span>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                        <span className="dropdown-item" style={{ cursor: 'pointer' }}>
                            <i className="fas fa-user mr-2"></i>Profile
                        </span>
                        <span className="dropdown-item" style={{ cursor: 'pointer' }}>
                            <i className="fas fa-cog mr-2"></i>Settings
                        </span>
                        <div className="dropdown-divider"></div>
                        <span className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => handleLogout()}>
                            <i className="fas fa-sign-out-alt mr-2"></i>Logout
                        </span>
                    </div>
                </li>
            </ul>
        </nav>
    );
};

export default NavbarAdmin;
