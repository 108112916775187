import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { RemoveAppendedScript } from '../helpers';
import { AdminContentLoader } from '../components';
import { HeaderLayout } from '../layouts';
import { Helmet } from 'react-helmet-async';
import config from '../config';
import { connectionVerif } from './home';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '../store';
import axios from 'axios'


const StripeProcess
    = () => {

        const [error, setError] = useState([]);
        const [modal, setModal] = useState(<></>);
        const [load, setLoad] = useState(true);
        const [payed, setPayed] = useState(false);
        const dispatch = useDispatch();
        const auth = useSelector(x => x.auth);
        const token = useSelector(x => x.auth.token);


        const paiment_token = localStorage.getItem('stripe');
        // console.log(paiment_token)


        useEffect(() => {

            setTimeout(() => {
                connectionVerif(dispatch, setLoad, store.getState().user, auth);
            }, 1200);

            let titi = setInterval(() => {
                $('iframe').remove();

            }, 200);

            let tt = setTimeout(() => {
                let html = `<script>
                new HSHeader('#header').init()

                new HSMegaMenu('.js-mega-menu', {
                    desktop: {
                    position: 'left'
                    }
                })
            
                new HSShowAnimation('.js-animation-link')
            
            
                HSBsDropdown.init()
                
                new HSGoTo('.js-go-to')
                
                </script>`;
                $('body').append(html);
            }, 4000);


            const checkPaiement = async () => {
                setError([]);
                if (paiment_token) {
                    try {
                        const { data } = await axios.post(
                            'https://api.happynessarl.com/api/pip/execute',
                            {
                                token: paiment_token
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            });
                        if (data.status === "success") {
                            setPayed(true);
                            setError(["Paiement has been done successfully"]);

                            setTimeout(() => {
                                localStorage.removeItem('stripe');
                                window.close();
                            }, 3000);
                        }
                        // console.log("Stripe process", data);
                    } catch (error) {
                        setError([error.response.data.reason]);
                        console.log("Stripe process", error);
                        setTimeout(() => {
                            localStorage.removeItem('stripe');
                            window.close();
                        }, 3000);
                    }

                }
            }

            let timer = setTimeout(() => {
                checkPaiement()
            }, 7000);


            $('body').addClass('d-flex flex-column justify-content-center align-items-center h-100');
            $('html').addClass('h-100');


            return () => {
                RemoveAppendedScript();
                $('body').removeAttr('class');
                $('html').removeAttr('class');

                clearTimeout(tt);
                clearTimeout(timer);
                clearInterval(titi);
            };
        }, []);

        return <>
            {
                load
                    ? <AdminContentLoader />
                    : <>
                        <HeaderLayout setModal={setModal} />
                        <main id="content" role="main">
                            <h1 style={{ fontWeight: 'bold' }} className="container text-center mb-3">
                                {
                                    error.length > 0
                                        ? payed
                                            ? <span style={{ fontSize: '4rem' }}>
                                                Payment has been done successfully!
                                            </span>
                                            : <span className='text-danger'>Sorry! something went wrong.</span>

                                        : <>
                                            <span style={{ fontSize: '4rem' }}>
                                                Waiting for payment status...
                                            </span>
                                            <br />
                                        </>
                                }

                            </h1>
                            <div className="container text-center">
                                {
                                    error.length > 0
                                        ? <div className="mb-3">

                                            {
                                                payed
                                                    ? <div className="alert alert-soft-success alert-sm">
                                                        <span style={{ fontSize: '1em' }}>{error[0]}</span>
                                                        <img className="img-fluid" src="/assets/img/svg/illustrations/oc-to-do.svg" alt="Description_image" style={{ width: "30rem" }} />
                                                    </div>
                                                    : <>
                                                        <img className="img-fluid" src="/assets/img/svg/illustrations/oc-error.svg" alt="Description_image" style={{ width: "30rem" }} />
                                                        <br />
                                                        <br />
                                                        <div className="alert alert-soft-danger alert-sm">
                                                            <span style={{ fontSize: '1em' }}>{error[0]}</span>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                        : <div className="mb-3">
                                            <span className="spinner-border spinner-border-md text-info" style={{ height: 50, width: 50 }} role="status" aria-hidden="true"></span>
                                        </div>
                                }


                                <div className="mb-4">
                                    {/* {
                            loading
                                ? <>
                                    <span class="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
                                    <span className='text-primary'>Redirect to profile...</span>
                                </>
                                : null
                        } */}
                                    {/* <p className="fs-4 mb-0">Oops! Looks like you followed a bad link.</p>
                            <p className="fs-4">If you think this is a problem with us, please <a className="link" href="/contact">tell us</a>.</p> */}
                                </div>
                            </div>
                        </main>

                        <footer className="position-absolute start-0 end-0 bottom-0">
                            <div className="container pb-5 content-space-b-sm-1">
                                <div className="row justify-content-between align-items-center">
                                    <div className="col-sm mb-3 mb-sm-0">
                                        <p className="small mb-0">&copy; HappyNess. {config.app.footer.year}.</p>
                                    </div>

                                    <div className="col-sm-auto">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item">
                                                <a className="btn btn-soft-secondary btn-xs btn-icon" href="https://www.facebook.com/profile.php?id=100080508300409" target='_blank'>
                                                    <i className="bi-facebook"></i>
                                                </a>
                                            </li>

                                            {/* <li className="list-inline-item">
                                <a className="btn btn-soft-secondary btn-xs btn-icon" href="#/">
                                    <i className="bi-google"></i>
                                </a>
                            </li>

                            <li className="list-inline-item">
                                <a className="btn btn-soft-secondary btn-xs btn-icon" href="#/">
                                    <i className="bi-twitter"></i>
                                </a>
                            </li>

                            <li className="list-inline-item">
                                <a className="btn btn-soft-secondary btn-xs btn-icon" href="#/">
                                    <i className="bi-github"></i>
                                </a>
                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </footer>
                        {modal}
                    </>
            }


            <Helmet>
                <link rel="stylesheet" href="./assets/css/app/bootstrap-icons/font/bootstrap-icons.css" />
                <link rel="stylesheet" href="./assets/css/app/hs-mega-menu.min.css" />
                <link rel="stylesheet" href="./assets/css/app/quill.snow.css" />
                <link rel="stylesheet" href="./assets/css/app/tom-select.css" />
                <link rel="stylesheet" href="./assets/css/app/aos.css" />
                <link rel="stylesheet" href="./assets/css/app/swiper-bundle.min.css" />
                <link rel="stylesheet" href="./assets/css/app/theme.min.css" />


                <title>HappyNess | Check Paiement</title>
                <script src='./assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js'></script>
                <script src='./assets/vendor/hs-header/dist/hs-header.min.js'></script>
                <script src='./assets/vendor/hs-mega-menu/dist/hs-mega-menu.min.js'></script>
                <script src='./assets/vendor/hs-show-animation/dist/hs-show-animation.min.js'></script>
                <script src='./assets/js/theme.min.js'></script>

            </Helmet>
        </>;
    }



export default StripeProcess
    ;


