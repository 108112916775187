import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import axios from 'axios';

import { currencies } from '../../constants';
import { guestActions, store } from '../../store';
import { Toast, checkEmailFormat } from '../../helpers';
import paypal_logo from '../../assets/img/logo/paypal.svg';
import card_logo from '../../assets/img/logo/card1.svg';
// import card_logo from '../../assets/img/logo/card.svg';
import appConfig from "../../config";



const UserPayView = ({ setModal, project, lang, navigation }) => {
    const user = useSelector(state => state.user.user);
    const token = useSelector(x => x.auth.token);

    const [tabSelect, setTabSelect] = useState(1);
    const [tab, setTab] = useState(<Paypal lang={lang} project={project} />);


    const chooseTab = (num, project) => {
        setTabSelect(num);

        switch (num) {
            case 1:
                setTab(<Paypal lang={lang} setModal={setModal} project={project} />);
                return;

            case 2:
                setTab(<Card navigation={navigation} lang={lang} project={project} user={user} token={token} />);
                return;

            case 3:
                setTab(<Mobile lang={lang} project={project} />);
                return;

            default:
                setTab(<Paypal lang={lang} setModal={setModal} project={project} />);
                return;

        }

    };




    return <div className={`modal fade`} id="userViewProject" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="userViewProjectLabel" style={{ display: 'none' }} aria-modal="true" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal(<></>)}></button>
                </div>

                <div className="modal-body">
                    {/* <div className="row">
                        <h3 className='mb-4'>Procceed to payment</h3>
                        <p>The budget is <span>$5000</span> whitch representing 75% of global budget</p>
                        <button className='btn btn-soft-success btn-block' type="button">Start Payment</button>
                    </div> */}

                    <div className="btn-group btn-group-segment d-flex mb-4" role="group" aria-label="Account add card radio button group">
                        <input type="radio" title='PayPal' className="btn-check" name="accountAddCardBtnRadio" id="accountAddCardBtnRadioOption1"
                            autoComplete="off" onChange={() => null} checked={tabSelect === 1} onClick={() => chooseTab(1, project)} />
                        <label title='PayPal' className={`btn btn-sm text-${tabSelect === 1 ? 'info' : ''}`} htmlFor="accountAddCardBtnRadioOption1" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <img src={paypal_logo} alt="paypal logo" style={{ height: 20 }} />
                        </label>

                        <input type="radio" title='Credit Card' className="btn-check" name="accountAddCardBtnRadio" id="accountAddCardBtnRadioOption2"
                            autoComplete="off" onChange={() => null} checked={tabSelect === 2} onClick={() => chooseTab(2, project)} />
                        <label title='Credit Card' className={`btn btn-sm text-${tabSelect === 2 ? 'info' : ''}`} htmlFor="accountAddCardBtnRadioOption2">
                            <img src={card_logo} alt="card logo" style={{ height: 35 }} />
                            {/* <label className="btn btn-sm" htmlFor="accountAddCardBtnRadioOption3"><span className="badge bg-soft-info text-danger">Coming soon</span></label> */}
                        </label>

                        <input type="radio" className="btn-check" name="accountAddCardBtnRadio" id="accountAddCardBtnRadioOption3"
                            autoComplete="off" disabled={true} onChange={() => null} checked={tabSelect === 3} onClick={() => chooseTab(3, project)} />
                        <label className="btn btn-sm" htmlFor="accountAddCardBtnRadioOption3">Mobile <span
                            className="badge bg-soft-info text-danger">Coming soon</span></label>
                    </div>

                    {tab}
                </div>
            </div>
        </div>
    </div>;
}

export default UserPayView;


const Paypal = ({ project, setModal, lang }) => {

    const [subContent, setSubContent] = useState(null);
    const [load, setLoad] = useState(true);



    useEffect(() => {

        $('body').append(`
            <script class='tomselecttt'>
                HSCore.components.HSTomSelect.init('.js-select');
            </script>
        `);


        setTimeout(() => {
            setSubContent(
                <FormPaypal lang={lang} setModal={setModal} project={project} />
            );
            setLoad(false);
        }, 1000);

        return () => {
            $('.tomselecttt').remove();
        };
    }, []);




    return load
        ? <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }} className='text-primary'>
            <span className="spinner-border spinner-border" role="status" aria-hidden="true"></span>
        </div>
        : subContent
};


const Mobile = () => {
    return <>Mobile coming soon</>
};


const FormPaypal = ({ project, setModal, lang }) => {
    const dispatch = useDispatch();


    const [useEmail, setUseEmail] = useState(false);
    const [initPay, setInitPay] = useState(false);
    const [control, setControl] = useState(false);

    const [showAmt, setShowAmt] = useState(convert("USD", project.budget)[0]);

    const [paypalInfos, setPaypalInfos] = useState({
        currency: 'XAF',
        email: '',
        price: project.budget,
        project_id: project.id,
        user_id: project.user_id,
        // amount: project.user_id,
        description: lang('items.user_project.pay.paypal.info.desc', { name: project.name }),
    });

    const handleInputChange = e => {
        setPaypalInfos({ ...paypalInfos, [e.target.name]: e.target.value });

        if (e.target.name === "currency") {
            setShowAmt(convert(e.target.value, project.budget)[0]);
            setPaypalInfos({
                ...paypalInfos,
                price: parseInt(convert("XAF", project.budget)[1]),
                currency: 'XAF'
            });
        }
    };

    const handleSubmit = () => {
        // console.log(paypalInfos);
        // window.sessionStorage.setItem('token', store.getState().auth.token);
        setInitPay(true);
        // console.log(paypalInfos);
        dispatch(guestActions.paypal_pay({ paypal: { ...paypalInfos } }));

        setTimeout(() => {
            $('body').removeAttr('class');
            $('body').removeAttr('style');

            $('.modal-backdrop').remove();
            $('#userViewProject').css('display', 'none');
            setModal(<></>);
        }, 15000);
    };


    const chMail = data => {
        if (data) {
            setPaypalInfos({ ...paypalInfos, email: store.getState().user.user.email });
        } else {
            setPaypalInfos({ ...paypalInfos, email: '' });
        }

        setUseEmail(data);
    };

    function handleChange(e) {
        setControl(checkEmailFormat(e.target.value));
    };

    return <div className="mb-3">
        <div className="row">
            <div className="col-12">
                Pay: {showAmt}
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="mb-3">
                    <label className="form-label" htmlFor="exampleFormControlInput1">{lang('items.user_project.pay.paypal.lb_currency')}</label>
                    <select className="form-select" id='exampleFormControlInput1' name='currency' onChange={e => handleInputChange(e)}>
                        {
                            currencies.map((curr, key) => {
                                return <option key={key} selected={curr.code === 'USD'} value={curr.code}>{curr.code} ({curr.title})</option>
                            })
                        }
                    </select>
                </div>

            </div>

            <div className="col-md-6">
                <div className="mb-3">
                    <div className="form-check form-switch">
                        <label className="form-label" htmlFor="flexSwitchCheckDefault">{lang('items.user_project.pay.paypal.my_mail')}</label>
                        <input className="form-check-input text-success" type="checkbox" checked={useEmail} onChange={() => chMail(!useEmail)} role="switch" id="flexSwitchCheckDefault" />
                    </div>
                    {
                        useEmail
                            ? <input className="form-control" type="text" style={{ border: 'none', backgroundColor: 'white' }} value={store.getState().user.user.email} disabled={true} />
                            : <input className="form-control" value={paypalInfos.email} type="email" name='email' onChange={e => { handleInputChange(e); handleChange(e); }} id="dflexSwitchCheckDefault" />
                    }
                </div>

            </div>
        </div>

        <div style={{
            display: 'flex',
            justifyContent: 'flex-end'
        }}>
            <div className="col-md-6 mt-2" style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'relative'
            }}>

                {
                    useEmail
                        ? !initPay
                            ? <button style={{ width: '100%' }} className="btn btn-success" onClick={() => handleSubmit()}>
                                {lang('items.user_project.pay.paypal.btn_pay')}
                            </button>
                            : <button style={{ width: '100%' }} className="btn btn-soft-info text-white fw-bold" disabled={true}>
                                <span className="spinner-border spinner-border" style={{ height: 15, width: 15 }} role="status" aria-hidden="true"></span> {lang('items.user_project.pay.paypal.in_progress')}
                            </button>
                        : control
                            ? !initPay
                                ? <button style={{ width: '100%' }} className="btn btn-success" onClick={() => handleSubmit()}>
                                    {lang('items.user_project.pay.paypal.btn_pay')}
                                </button>
                                : <button style={{ width: '100%' }} className="btn btn-soft-info text-white fw-bold" disabled={true}>
                                    <span className="spinner-border spinner-border" style={{ height: 15, width: 15 }} role="status" aria-hidden="true"></span> {lang('items.user_project.pay.paypal.in_progress')}
                                </button>
                            : paypalInfos.email.length >= 4
                                ? <small className="text-danger" style={{ fontSize: '12px', position: 'absolute' }}>{lang('items.user_project.pay.paypal.error')}</small>
                                : null
                }
            </div>
        </div>
    </div>
}



const Card = ({ project, lang, user, token }) => {

    const [initPay, setInitPay] = useState(false);
    const [load, setLoad] = useState(true);

    const [currentCurr, setCurrentCur] = useState("USD");
    const [payCurr, setPayCur] = useState("USD");
    const [devises, setDevises] = useState([{ code: "USD" }]);



    useEffect(() => {

        $.getJSON(appConfig.get_json_json, (data) => {
            if (data.curr) {
                setDevises(data.curr);
                setTimeout(() => {
                    setLoad(false);
                }, 2000);
            }
        });

        $('body').append(`
            <script class='imsk'>
                HSCore.components.HSMask.init('.js-input-mask');
            </script>
        `);

        return () => {
            $('body').find('.imsk').remove();
        };
    }, []);

    const handleChange = e => {
        // setCardData({ ...cardData, [e.target.name]: e.target.value });
        setCurrentCur(e.target.value);
        if (e.target.value === "EUR" || e.target.value === "USD") {
            setPayCur(e.target.value);
        }
    };

    const handleSubmit = async (e) => {
        /*Pour déclencher le paiement : "happynessarl.com/make/viewer/payment" en POST 
        voici l'url sur laquelle tu dois recevoir les données venant de stripe après le paiement : "happynessarl.com/payment-stripe-process"
         ( Donc tu conçois une page labas qui devras avoir l'url suivante, qui récupérer les données et qui envois directement à la route qui
          est en bas 👇🏽👇🏽👇🏽👇🏽 ) 
        et enfin tu enverras les données ici pour check si le paiement s'est bien effectué sur cette route : "happynessarl.com/execute" en POST*/
        e.preventDefault();
        setInitPay(true);
        
        try {
            const { data } = await axios.post('https://api.happynessarl.com/api/make/viewer/payment', {
                stripe: {
                    email: user.email,
                    user_id: user.id,
                    project_id: project.id,
                    description: project.description,
                    // price: convert2(currentCurr, project.budget, devises).pay_value,
                    price_in_xaf: parseInt(project.budget), // Montant a sauver en BD par Fred en XAF
                    // amount_to_stripe: convert2(currentCurr, project.budget, devises).pay_value})
                    currency: payCurr, //Device a payer sur Stripe [EUR ou USD]
                    price: convert(payCurr, project.budget)[1], // Montant a payer sur stripe
                    // price: 1, // Montant a payer sur stripe
                }
            },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            // console.log(data.data);
            localStorage.setItem('stripe', data.data.payment_token);
            const url = data.data.payment_url;
            setInitPay(false);
            window.open(url, '_blank');
        } catch (error) {
            setInitPay(false);
            Toast('error', error.response.data.reason, 'tr', false, false, false, 3000, false);
            console.log(error);
        }


        setTimeout(() => {
        }, 3000);
    }


    return load
        ? <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }} className='text-primary'>
            <span className="spinner-border spinner-border" role="status" aria-hidden="true"></span>
        </div>

        : <form onSubmit={handleSubmit}>

            <div className="col-md-6">
                <div className="mb-3">
                    <label className="form-label" htmlFor="exampleFormControlInput1">{lang('items.user_project.pay.paypal.lb_currency')}</label>
                    <select className="form-select" id='exampleFormControlInput1' name='currency' onChange={e => handleChange(e)}>
                        {
                            devises.map((curr, key) => {
                                return <option key={key} selected={curr.code === currentCurr} value={curr.code}>{curr.code} ({curr.title})</option>
                            })
                        }
                    </select>
                </div>

            </div>


            <div style={{
                display: 'flex',
                justifyContent: 'flex-end'
            }}>
                <div className="col-md-6">
                    {
                        !initPay
                            ? <input style={{ width: '100%' }} type='submit' className="btn btn-success text-bold" value={`${lang('items.user_project.pay.card.btn_pay')} ${(convert(currentCurr, project.budget)[1]).toFixed(3)} ${convert2(currentCurr, project.budget, devises).curr}`} />
                            : <button style={{ width: '100%' }} className="btn btn-soft-info text-white fw-bold" disabled={true}>
                                <span className="spinner-border spinner-border" style={{ height: 15, width: 15 }} role="status" aria-hidden="true"></span> {lang('items.user_project.pay.card.in_progress')}
                            </button>
                    }
                </div>
            </div>
        </form>

};

const around = (value) => {
    return (parseInt(value) + 1);
};

function convert(curr, budget) {

    var to_print = currencies.filter(cur => cur.code === curr)[0];
    return [
        <>
            <span className='text-primary fw-bold'>
                {around(parseFloat(parseFloat(budget) * to_print.rate)).toFixed(3)}
            </span> <span>{to_print.code}</span>
        </>,
        around(parseFloat(budget) * to_print.rate), //XAF to currency
        parseInt(parseFloat(budget) / to_print.rate) + 1, // currency to XAF
    ]
};

const convert2 = (code, value, devise) => {
    const int_val = parseInt(value);
    const rate = [...devise].filter(curr => curr.code === code)[0].rate;

    return {
        pay_value: parseInt(int_val * rate), // en xaf
        show_value: Math.round(parseInt(int_val * rate)),
        curr: code
    };
};
