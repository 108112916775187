import React, { useEffect, useState } from 'react';
import { store, userActions } from '../../store';
import { SlangObj, formatDate, getFileFromBack } from '../../helpers';
import { ViewProjectUser } from '../../components/modals';
import { useDispatch } from 'react-redux';

const GoToWork = ({ setModal, lang }) => {

    const [projects, setProjects] = useState([]);

    const dispatch = useDispatch();

    const t_thead = SlangObj('page.profile.items.go_work.table.thead');
    const t_status = SlangObj('page.profile.items.go_work.status');


    useEffect(() => {

        let tim = setInterval(() => {
            setProjects(store.getState().user.projects);
        }, 100);

        return () => {
            clearInterval(tim);
        };
    }, [])


    const handleView = project => {
        dispatch(userActions.gettechnology({ projets_id: project.id }));

        setModal(<></>);
        setModal(<ViewProjectUser lang={lang} project={project} setModal={setModal} />)
    };

    const handleLoadTasks = project => {
        dispatch(userActions.projecttasks({ project_id: project.id }));
        dispatch(userActions.get_task({ project_id: parseInt(project.id) }));
        // console.log(project.id)
    };



    return <div className="card">
        <div className="card-header">
            <h4 className="card-header-title">{lang('items.go_work.title')}</h4>
        </div>

        <div className="table-responsive">
            {
                projects.length !== 0
                    ? <table className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table">
                        <thead className="thead-light">
                            <tr>
                                <th>{t_thead[0]}</th>
                                <th>{t_thead[1]}</th>
                                <th>{t_thead[2]}</th>
                                <th>{t_thead[3]}</th>
                                <th style={{ width: "5%" }}></th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                projects.map((project, key) => {
                                    return <tr key={key}>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0">
                                                    <img
                                                        className="avatar avatar-sm avatar-circle"
                                                        src={getFileFromBack(project.image)}
                                                        alt="Description_Image"
                                                    />
                                                </div>

                                                <div className="flex-grow-1 ms-3">
                                                    <span className="d-inline-block link-dark">
                                                        <h6 className="text-primary mb-0">
                                                            {project.name}
                                                        </h6>
                                                    </span>
                                                    <small className="d-block">{lang('items.go_work.table.created_at')}: {formatDate(project.created_at)}</small>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{project.categories.name}</td>
                                        <td>
                                            {
                                                ((
                                                    isNaN(project.tasks.filter(task => task.completed === 1).length / project.tasks.length)
                                                        ? 0
                                                        : project.tasks.filter(task => task.completed === 1).length / project.tasks.length
                                                ) * 100).toFixed(2)
                                            }&nbsp;% {lang('items.go_work.table.completed')}
                                        </td>
                                        <td><span className={`badge bg-${stateProjectBadge(project.status, t_status)[0]}`}>{stateProjectBadge(project.status, t_status)[1]}</span></td>
                                        <td>
                                            {
                                                project.status === "APPROVED"
                                                    ? <span
                                                        onClick={() => handleView(project)}
                                                        className="text-body"
                                                        data-bs-placement="top"
                                                        title={lang('items.go_work.table.db_click')}
                                                        data-bs-original-title="Show"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#view_project_work_on"
                                                        style={{ cursor: 'pointer' }}
                                                        onMouseOver={() => handleLoadTasks(project)}
                                                    >
                                                        {/* <i className="bi-lock-fill"></i> */}
                                                        <i className="fa fa-eye"></i>
                                                    </span>
                                                    : <span
                                                        className="text-body"
                                                        data-bs-placement="top"
                                                        title={lang('items.go_work.table.info')}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {/* <i className="bi-lock-fill"></i> */}
                                                        <i className="fa fa-eye-slash"></i>
                                                    </span>
                                            }
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                    : <div className='d-flex' style={{ justifyContent: 'center' }}>
                        <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img src={require('../../assets/img/stickers/sticker70_f.png')} alt="sad_stikers" style={{ borderRadius: '50%', height: 200, width: 200 }} />
                            <small style={{ color: "#b5285a", fontStyle: 'italic' }} className='mb-4'>{lang('items.go_work.table.no_project')}</small>
                        </span>
                    </div>
            }

        </div>

        <div className="card-footer border-top">
            {/* <div className="d-flex justify-content-end gap-3">
                <a className="btn btn-white" href="#/">Cancel</a>
                <a className="btn btn-primary" href="#/">Save changes</a>
            </div> */}
        </div>
    </div>;
}

export default GoToWork;

const stateProjectBadge = (status, langObj) => {
    return status === 'PENDING'
        ? ['secondary', langObj[0]]
        : status === 'REJECTED'
            ? ['danger', langObj[1]]
            : status === 'CANCELLED'
                ? ['danger', langObj[2]]
                : status === 'APPROVED'
                    ? ['info', langObj[3]]
                    : status === 'TESTING'
                        ? ['warning', langObj[4]] : ['success', langObj[3]]
};
