import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';
import { checkPasswordFormat, Toast } from '../../helpers';
import $ from 'jquery';

import { isEmail } from 'validator';

import Button from 'react-validation/build/button';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { Input as MyInput } from '../myValidatorFields';
import { adminActions } from '../../store';
import appConfig from "../../config";



const AdminCreateWorker = ({ setModal }) => {

    return (
        <div className="modal fade" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" id="create-worker" style={{ display: "none" }} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content bg-transparent">
                    <div className="modal-body">
                        <div className="card card-widget widget-user bg-white" style={{ boxShadow: '0px 0px 2px black' }}>
                            <div className="widget-user-header bg-primary">
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close" onClick={() => setModal(<></>)}>
                                    <span aria-hidden="true">×</span>
                                </button>

                                <h3 className="widget-user-username">
                                    <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>Create a new worker</span>
                                </h3>
                            </div>


                            <div className="card-body">
                                <div className="row">
                                    <div className="card bg-white" style={{ width: '100%', overflowY: 'auto' }}>
                                        <div className="card-body">

                                            <FormAddingNewWorker />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    );
}

export default AdminCreateWorker;




const FormAddingNewWorker = () => {

    const dispatch = useDispatch();
    const [showLoading, setShowLoading] = useState(false);

    const [lname, setLname] = useState(null);
    const [fname, setFname] = useState(null);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState("happyness1230");
    const [confirmPass, setConfirmPass] = useState("happyness1230");
    const [showPassword, setShowPassword] = useState(true);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(true);



    //Login functions
    const _rrequired = value => {
        if (!value) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    This field is required!
                </div>
            );
        }
    };

    const _fname = value => {
        return value !== ""
            ? value.length < 4
                ? <div className="alert alert-soft-danger mt-2" role="alert">The Firstname field require 4 caracters.</div>
                : null
            : <div className="alert alert-soft-danger mt-2" role="alert">This field is empty.</div>
    };

    const _lname = value => {
        return value !== ""
            ? value.length < 4
                ? <div className="alert alert-soft-danger mt-2" role="alert">The Lastname field require 4 caracters.</div>
                : null
            : <div className="alert alert-soft-danger mt-2" role="alert">This field is empty.</div>
    };

    const _email = value => {
        if (!isEmail(value)) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    This is not a valid email.
                </div>
            );
        }
    };

    const _password = value => {
        if (!value) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    This field is required!
                </div>
            );
        }
        if (!checkPasswordFormat(value)) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    Invalid password format!
                </div>
            );
        }
    };

    const _confirmPassVerif = value => {
        if (password !== confirmPass) {
            Toast('error', 'Password does not match the confirm password.', 'tr', true, true, true, 3000);
            setShowLoading(false);
            return false;
        }
        // Toast('success', 'Password match the confirm password.', 'tr', true, true, true, 2500);
        return true;
    };

    const handleFnameChange = e => {
        setFname(e.target.value);
    };

    const handleLnameChange = e => {
        setLname(e.target.value);
    };

    const handleEmailChange = e => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = e => {
        setPassword(e.target.value);
    };

    const handleConfirmPassChange = e => {
        setConfirmPass(e.target.value);
    };

    // const parseBool = val => val === "true" ? true : val === "false" ? false : undefined;

    const handleSubmit = event => {
        event.preventDefault();

        setShowLoading(true);

        // console.log({ name:lname, first_name:fname , email, password, password_confirmation: confirmPass })

        if (_confirmPassVerif()) {
            $.getJSON(appConfig.apip, function (data) {
                dispatch(adminActions.addNewWorker({
                    first_name: fname,
                    email,
                    name: lname,
                    password,
                    password_confirmation: confirmPass,
                    ip: data.ipString,
                }));

                setTimeout(() => {
                    setShowLoading(false);
                    // dispatch(userActions.getUser());
                }, 4000);
            });
        };
    };



    return (
        <>
            <Form onSubmit={e => handleSubmit(e)} >
                <div className="mb-3">
                    <label className="form-label" htmlFor="signupModalFormSignupName">First Name</label>
                    <Input
                        type="text"
                        className="form-control form-control-lg bg-white"
                        name="text"
                        id="signupModalFormSignupName"
                        placeholder="Alan"
                        aria-label="Alan"
                        validations={[_rrequired, _fname]}
                        onChange={e => handleFnameChange(e)}
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label" htmlFor="signupModalFormSignupName">Last Name</label>
                    <Input
                        type="text"
                        className="form-control form-control-lg bg-white"
                        name="text"
                        id="signupModalFormSignupName"
                        placeholder="Peter"
                        aria-label="Peter"
                        validations={[_rrequired, _lname]}
                        onChange={e => handleLnameChange(e)}
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label" htmlFor="signupModalFormSignupEmail">Your email</label>
                    <Input
                        type="email"
                        className="form-control form-control-lg bg-white"
                        name="email"
                        id="signupModalFormSignupEmail"
                        placeholder="email@site.com"
                        aria-label="email@site.com"
                        validations={[_rrequired, _email]}
                        onChange={e => handleEmailChange(e)}
                    />
                </div>

                {/* password */}
                <div className="mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <label className="form-label" htmlFor="signupModalFormLoginPassword">Password</label>
                    </div>

                    <MyInput
                        type={showPassword ? 'text' : 'password'}
                        className="form-control form-control-lg bg-white"
                        name="password"
                        id="signupModalFormLoginPassword"
                        placeholder="8+ characters required"
                        aria-label="8+ characters required"
                        disabled={true}
                        minLength="8"
                        value={password}
                        validations={[_rrequired, _password]}
                        onChange={e => handlePasswordChange(e)}
                    >
                        <Link
                            id="changePassTarget"
                            className="input-group-append input-group-text"
                            to="#/"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            <i id="changePassIcon" className={showPassword ? 'bi-eye' : 'bi-eye-slash'}></i>
                        </Link>
                    </MyInput>
                </div>

                {/* Confirm password */}
                <div className="mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <label className="form-label" htmlFor="ConfirmLoginPassword">Confirm password</label>
                    </div>

                    <MyInput
                        type={showPasswordConfirm ? 'text' : 'password'}
                        className="form-control form-control-lg bg-white"
                        name="confirmpassword"
                        id="ConfirmLoginPassword"
                        disabled={true}
                        placeholder="8+ characters required"
                        aria-label="8+ characters required"
                        minLength="8"
                        value={confirmPass}
                        validations={[_rrequired, _password]}
                        onChange={(e => handleConfirmPassChange(e))}
                    >
                        <Link
                            id="changePassTarget"
                            className="input-group-append input-group-text"
                            to="#/"
                            onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                        >
                            <i id="changePassIcon" className={showPasswordConfirm ? 'bi-eye' : 'bi-eye-slash'}></i>
                        </Link>
                    </MyInput>
                </div>


                <div className="d-grid mb-3">
                    {
                        !showLoading
                            ? <Button type="submit" className="btn btn-success btn-lg btn-block">
                                Sign up
                            </Button>
                            : <button type="button" className="btn btn-success btn-lg btn-block" disabled={showLoading}>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading
                            </button>
                    }
                </div>
            </Form>
        </>
    );
};
