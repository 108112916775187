import React, { useEffect } from 'react';
import { Locations } from '../../constants';
import $ from 'jquery';


const AdminCareerView = ({ career, setModal }) => {

    useEffect(() => {
        $('.career-desctipion').html(career.description)
        return () => {
        };
    }, []);

    return <div className="modal fade" id="view-career" style={{ display: "none" }} aria-hidden="true">
        <div className="modal-dialog modal-xl">
            <div className="modal-content bg-white">

                <div className="modal-body">
                    <div className="card bg-white">
                        <div className="card-header">
                            <h3 className="card-title">Career Viewer</h3>

                            <div className="card-tools">
                                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                    <i className="fas fa-minus"></i>
                                </button> */}
                                <button type="button" className="btn btn-tool" onClick={() => setModal(<></>)} data-dismiss="modal" aria-label="Close" title="Close">
                                    <i className="fas fa-times"></i>
                                </button>
                            </div>
                        </div>

                        <div className="card-body" style={{ display: 'block' }}>
                            <>
                                <div className="row justify-content-sm-between align-items-sm-center mb-5">
                                    <div className="col-sm mb-3 mb-sm-0">
                                        <h1 className="card-title h2">{career.title}</h1>
                                        <p className="card-text">{Locations.filter(loc => loc.code === career.location)[0].name} - {career.category} - {career.cycle} - <span style={{ textTransform: 'capitalize' }}>{career.status}</span></p>
                                    </div>
                                </div>

                                <div className="mb-5 career-desctipion">
                                </div>

                                <div className="mb-5">
                                    <h2 className="h4">We have:</h2>
                                    <ul>{formListItems(career.propose).map((item, key) => <li key={key}>{item}</li>)}</ul>
                                </div>

                                <div className="mb-5">
                                    <h3 className="h4">Requirements:</h3>
                                    <ul> {formListItems(career.requierment).map((item, key) => <li key={key}>{item}</li>)}</ul>
                                </div>

                                <div className="mb-5">
                                    <h3 className="h4">You have:</h3>
                                    <ul> {formListItems(career.skills).map((item, key) => <li key={key}>{item}</li>)}</ul>
                                </div>

                                <div className="mb-5">
                                    <h3 className="h4">Extra awesome:</h3>
                                    <ul> {formListItems(career.extraSkills).map((item, key) => <li key={key}>{item}</li>)}</ul>
                                </div>

                                <div className="mb-5">
                                    <p>{career.text}</p>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default AdminCareerView;

const formListItems = value => {
    return value.split('\n')
};