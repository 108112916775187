import React from 'react';

const MiniLoad = () => {

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        position: 'relative',
        left: 0,
        top: 0,
    }}>
        <span className="spinner-border spinner-border" role="status" aria-hidden="true"></span>
    </div>
};
export default MiniLoad;