import React, { useEffect, useState } from 'react';
import logo from '../assets/img/logo/logo_footer.png';
import { AdminContentLoader, Goto } from '../components';
import { LinkScript } from '../constants';
import { FooterLayout, HeaderLayout, CookiesLayout } from '../layouts';
import $ from 'jquery';
import { Helmet } from 'react-helmet-async';

import { connectionVerif } from './home';
import { store } from '../store';
import { useDispatch, useSelector } from 'react-redux';


const Privacy = () => {
    const [load, setLoad] = useState(true);
    const dispatch = useDispatch();
    const auth = useSelector(x => x.auth);


    useEffect(() => {

        setTimeout(() => {
            connectionVerif(dispatch, setLoad, store.getState().user, auth);
        }, 1200);

        let titi = setInterval(() => {
            $('iframe').remove();
        }, 200);


        return () => {
            clearInterval(titi);
        };
    }, []);

    return <>

        {
            load ? <AdminContentLoader /> : <Group logo={logo} />
        }
        <Helmet>
            <link rel="stylesheet" href="./assets/css/app/bootstrap-icons/font/bootstrap-icons.css" />
            <link rel="stylesheet" href="./assets/css/app/hs-mega-menu.min.css" />
            <link rel="stylesheet" href="./assets/css/app/quill.snow.css" />
            <link rel="stylesheet" href="./assets/css/app/tom-select.css" />
            <link rel="stylesheet" href="./assets/css/app/aos.css" />
            <link rel="stylesheet" href="./assets/css/app/swiper-bundle.min.css" />
            <link rel="stylesheet" href="./assets/css/app/theme.min.css" />


            <script src='./assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js'></script>
            <script src='./assets/vendor/hs-header/dist/hs-header.min.js'></script>
            <script src='./assets/vendor/hs-mega-menu/dist/hs-mega-menu.min.js'></script>
            <script src='./assets/vendor/hs-show-animation/dist/hs-show-animation.min.js'></script>
            <script src='./assets/vendor/hs-go-to/dist/hs-go-to.min.js'></script>
            <script src='./assets/js/theme.min.js'></script>
            <title>HappyNess | Cookies</title>
        </Helmet>

    </>;
}

export default Privacy;



const Group = ({ logo }) => {
    const [modal, setModal] = useState(<></>);

    useEffect(() => {

        let tt = setTimeout(() => {
            let html = `<script>
                new HSHeader('#header').init()
            
                new HSMegaMenu('.js-mega-menu', {
                    desktop: {
                        position: 'left'
                    }
                })
                
                new HSShowAnimation('.js-animation-link')
            
                HSBsDropdown.init()
            
                new HSGoTo('.js-go-to')
            
                // AOS.init({
                //     duration: 650,
                //     once: true
                // });
            
  
            </script>`;
            $('body').append(html);
        }, 1000);



        return () => {
            clearTimeout(tt);
        };
    }, []);

    return <>
        <HeaderLayout setModal={setModal} script={LinkScript('privacy')} />
        <CookiesLayout />
        <FooterLayout logo={logo} />
        <Goto />
        {modal}
    </>
};