import React from 'react';

const Goto = () => {
  return (
    <a className="js-go-to go-to position-fixed" href="#/" style={{ visibility: "hidden" }}
      data-hs-go-to-options='{
       "offsetTop": 700,
       "position": {
         "init": {
           "right": "2rem"
         },
         "show": {
           "bottom": "2rem"
         },
         "hide": {
           "bottom": "-2rem"
         }
       }
     }'>
      <i className="bi-chevron-up"></i>
    </a>
  );
}

export default Goto;
