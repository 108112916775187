import React from 'react';
import { Slang, SlangObj } from '../helpers';


const PrivacyLayout = () => {
    const t = Slang('page.privacy');
    const tObj = (sufix) => SlangObj(`page.privacy.${sufix}`);

    return (
        <main id="content" role="main">
            <div className="container content-space-3 content-space-lg-4">
                <div className="card card-lg">
                    <div className="card-header bg-primary py-sm-10">
                        <h1 className="card-title h2 text-white">{t('title')}</h1>
                        <p className="card-text text-white">{t('s_title')}</p>
                    </div>
                    <div className="card-body" style={{ textAlign: 'justify' }}>
                        <div className="mb-7">
                            <h3>{t('t1')}</h3>
                            <p>{t('p11')}</p>
                            <p>{t('p12')}</p>
                            <p>{t('p13')}</p>
                        </div>
                        <div className="mb-7">
                            <h3>{t('t2')}</h3>
                            <p>{t('p21')}</p>
                            <p>{t('p22')}</p>
                            <p>{t('p23')}</p>
                            <p>{t('p24')}</p>
                            <p><ul>
                                {(tObj('p24t')).map((item, key) => {
                                    return <li key={key}>{item}</li>
                                })}
                            </ul></p>
                            <p>{t('p25')}</p>
                            <p>{t('p26')}</p>
                        </div>
                        <div className="mb-7">
                            <h3>{t('t3')}</h3>
                            <p>{t('p31')}</p>
                            <p>{t('p32')}</p>
                            <p>{t('p33')}</p>
                        </div>
                        <div className="mb-7">
                            <h3>{t('t4')}</h3>
                            <p>{t('p41')}</p>
                            <p>{t('p42')}</p>
                        </div>
                        <div className="mb-7">
                            <h3>{t('t5')}</h3>
                            <h5>{t('st5.tit1')}</h5>
                            <p>{t('st5.p510')}</p>
                            <p><ul>
                                {(tObj('st5.list1')).map((item, key) => {
                                    return <li key={key}>{item}</li>
                                })}
                            </ul></p>
                            <p>{t('st5.p511')}</p>
                            <h5>{t('st5.tit2')}</h5>
                            <p>{t('st5.p520')}</p>
                            <p><ul>
                                {(tObj('st5.list2')).map((item, key) => {
                                    return <li key={key}>{item}</li>
                                })}
                            </ul></p>
                            <p>{t('st5.p521')}</p>
                        </div>

                        <div className="mb-7">
                            <h3>{t('t6')}</h3>
                            <p>{t('p61')}</p>
                            <p>{t('p62')}</p>
                            <h5>{t('st6.tit1')}</h5>
                            <p>{t('st6.p610')}</p>
                            <h5>{t('st6.tit2')}</h5>
                            <p>{t('st6.p620')}</p>
                            <h5>{t('st6.tit3')}</h5>
                            <p>{t('st6.p630')}</p>
                            <h5>{t('st6.tit4')}</h5>
                            <p>{t('st6.p640')}</p>
                        </div>

                        <div className="mb-7">
                            <h3>{t('t7')}</h3>
                            <p>{t('p71')}</p>
                            <p>{t('p72')}</p>
                        </div>

                        <div className="mb-7">
                            <h3>{t('t8')}</h3>
                            <p>{t('p81')}</p>
                        </div>

                        <div className="mb-7">
                            <h3>{t('t9')}</h3>
                            <p>{t('p91')}</p>
                        </div>

                        <div className="mb-7">
                            <h3>{t('t10')}</h3>
                            <p>{t('p101')}</p>
                            <p>{t('p102')}</p>
                            <p>{t('p103')}</p>
                            <p>{t('p104')}</p>
                        </div>

                        <div className="mb-7">
                            <h3>{t('t11')}</h3>
                            <p>{t('p111')}</p>
                            <h5>{t('st11.tit1')}</h5>
                            <p>{t('st11.p1101')}</p>
                            <h5>{t('st11.tit2')}</h5>
                            <p>{t('st11.p1201')}</p>
                            <h5>{t('st11.tit3')}</h5>
                            <h6>{t('st11.stit30')}</h6>
                            <p>{t('st11.p1301')}</p>
                            <h6>{t('st11.stit31')}</h6>
                            <p>{t('st11.p1302')}</p>
                            <h6>{t('st11.stit32')}</h6>
                            <p>{t('st11.p1303')}</p>
                        </div>

                        <div className="mb-7">
                            <h3>{t('t12')}</h3>
                            <h5>{t('st120')}</h5>
                            <p>{t('p121')}</p>
                            <h5>{t('st121')}</h5>
                            <p>{t('p122')}</p>
                            <h5>{t('st12.tit1')}</h5>
                            <p>{t('st12.p1201')}</p>
                            <h5>{t('st12.tit2')}</h5>
                            <p><ul>
                                {(tObj('st12.list1')).map((item, key) => {
                                    return <li key={key}>{item}</li>
                                })}
                            </ul></p>
                            <h5>{t('st12.tit3')}</h5>
                            <p>{t('st12.p1203')}</p>
                            <h5>{t('st12.tit4')}</h5>
                            <p>{t('st12.p1204')}</p>
                            <p><ul>
                                {(tObj('st12.list2')).map((item, key) => {
                                    return <li key={key}>{item}</li>
                                })}
                            </ul></p>
                            <p>{t('st12.p1205')}</p>
                        </div>

                        <div className="mb-7">
                            <h3>{t('t13')}</h3>
                            <p>{t('p131')}</p>
                            <p>{t('p132')}</p>
                            <p><ul>
                                {(tObj('st13.list')).map((item, key) => {
                                    return <li key={key}>{item}</li>
                                })}
                            </ul></p>
                            <p>{t('p133')}</p>
                        </div>

                        <div className="mb-7">
                            <h3>{t('t14')}</h3>
                            <h5>{t('st140')}</h5>
                            <p>{t('p141')}</p>
                            <p><ul>
                                {(tObj('st14.list')).map((item, key) => {
                                    return <li key={key}>{item}</li>
                                })}
                            </ul></p>
                        </div>

                        <div className="mb-7">
                            <h3>{t('t15')}</h3>
                            <p>{t('p151')}</p>
                            <p><ul>
                                {(tObj('st15.list')).map((item, key) => {
                                    return <li key={key}>{item}</li>
                                })}
                            </ul></p>
                            <p>{t('p152')}</p>
                        </div>

                        <div className="mb-7">
                            <h3>{t('t16')}</h3>
                            <p>{t('p161')}</p>
                            <h5>{t('st1601')}</h5>
                            <p>{t('p162')}</p>
                            <h5>{t('st1602')}</h5>
                            <p>{t('p163')}</p>
                            <p>{t('p164')}</p>
                            <h5>{t('st1603')}</h5>
                            <p>{t('p165')}</p>
                            <h5>{t('st1604')}</h5>
                            <p>{t('p166')}</p>
                            <h5>{t('st1605')}</h5>
                            <p>{t('p167')}</p>
                            <p><ul>
                                {(tObj('st16.list')).map((item, key) => {
                                    return <li key={key}>{item}</li>
                                })}
                            </ul></p>
                            <h5>{t('st1606')}</h5>
                            <p>{t('p168')}</p>
                            <h5>{t('st1607')}</h5>
                            <p>{t('p169')}</p>
                        </div>

                        <div className="mb-7">
                            <h3>{t('t17')}</h3>
                            <h5>{t('st1701')}</h5>
                            <p>{t('p171')}</p>
                            <h5>{t('st1702')}</h5>
                            <p>{t('p172')}</p>
                        </div>

                        <div className="mb-7">
                            <h3>{t('t18')}</h3>
                            <h5><em><i>{t('st18.term1')}</i></em></h5>
                            <p>{t('st18.pterm1')}</p>
                            <h5><em><i>{t('st18.term2')}</i></em></h5>
                            <p>{t('st18.pterm2')}</p>
                            <h5><em><i>{t('st18.term3')}</i></em></h5>
                            <p>{t('st18.pterm3')}</p>
                            <h5><em><i>{t('st18.term4')}</i></em></h5>
                            <p>{t('st18.pterm4')}</p>
                            <h5><em><i>{t('st18.term5')}</i></em></h5>
                            <p>{t('st18.pterm5')}</p>
                            <h5><em><i>{t('st18.term6')}</i></em></h5>
                            <p>{t('st18.pterm6')}</p>
                            <h5><em><i>{t('st18.term7')}</i></em></h5>
                            <p>{t('st18.pterm7')}</p>
                            <h5><em><i>{t('st18.term8')}</i></em></h5>
                            <p>{t('st18.pterm8')}</p>
                            <h5><em><i>{t('st18.term9')}</i></em></h5>
                            <p>{t('st18.pterm9')}</p>
                            <h5><em><i>{t('st18.term10')}</i></em></h5>
                            <p>{t('st18.pterm10')}</p>
                            <h5><em><i>{t('st18.term11')}</i></em></h5>
                            <p>{t('st18.pterm11')}</p>
                            <h5><em><i>{t('st18.term12')}</i></em></h5>
                            <p>{t('st18.pterm12')}</p>
                            <h5><em><i>{t('st18.term13')}</i></em></h5>
                            <p>{t('st18.pterm13')}</p>
                            <h5><em><i>{t('st18.term14')}</i></em></h5>
                            <p>{t('st18.pterm14')}</p>
                            <h5>{t('st18.st1801')}</h5>
                            <h5><em><i>{t('st18.ctx1')}</i></em></h5>
                            <p>{t('st18.pctx1')}</p>
                            <h5><em><i>{t('st18.ctx2')}</i></em></h5>
                            <p>{t('st18.pctx2')}</p>
                            <h5><em><i>{t('st18.ctx3')}</i></em></h5>
                            <p>{t('st18.pctx3')}</p>
                            <h5><em><i>{t('st18.ctx4')}</i></em></h5>
                            <p>{t('st18.pctx4')}</p>
                            <h5><em><i>{t('st18.ctx5')}</i></em></h5>
                            <p>{t('st18.pctx5')}</p>
                        </div>

                        <div className="mb-7">
                            <h4>{t('t19')}</h4>
                            <p>{t('p191')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default PrivacyLayout;
