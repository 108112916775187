import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'react-validation/build/button';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import logo from '../assets/img/logo/apines_red.png';
import { LinkScript } from '../constants';
import { AppendScript, history, Slang } from '../helpers';
import { AdminContentLoader } from '../components';
import { store, userActions } from '../store';
import { Helmet } from 'react-helmet-async';


const TwoFAForm = () => {

    const [load, setLoad] = useState(true);
    const [showLoading, setShowLoading] = useState(false);
    const [digiCode, setDigiCode] = useState(null);
    const dispatch = useDispatch();

    const t = Slang('page.2fa');

    useEffect(() => {
        if (store.getState().auth.token === null || (store.getState().user.twoFactActivated && store.getState().user.twoFactChecked)) {
            history.navigate('/');
        }

        LinkScript('verifacc').forEach(elt => {
            AppendScript(elt)
        });

        let tim = setTimeout(() => {
            setLoad(false);
        }, 3000);

        return () => {
            clearTimeout(tim);
        };
    }, []);



    //Login functions
    const _rrequired = value => {
        if (!value && isNaN(parseInt(value))) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    {t('errors.digit')}
                </div>
            );
        }
    };

    const handleDigitCodeChange = e => {
        setDigiCode(e.target.value);
    };

    const _digit = value => {
        return value !== ""
            ? value.length !== 6
                ? <div className="alert alert-soft-danger mt-2" role="alert">{t('errors.length', { value: 6 })}</div>
                : null
            : <div className="alert alert-soft-danger mt-2" role="alert">{t('errors.require')}</div>
    };


    const handleSubmit = event => {
        event.preventDefault();

        setShowLoading(true);
        // setShowLinkResend(true)
        dispatch(userActions.verif2facode({ code: digiCode }));

        setTimeout(() => {
            setShowLoading(false);
        }, 3000);
        // Toast('success', 'Well done.', 'tc', true, true, true, 2000);

    };


    return <>
        {
            load
                ? <AdminContentLoader />
                : <div className="row">
                    <div className="col-md-2 offset-5">
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100vh',
                            width: '100%',
                            position: 'fixed',
                            left: 0,
                            top: 0,
                        }}>
                            <Form onSubmit={e => handleSubmit(e)} >

                                <div className="card mt-5 elevation-3 text-center">
                                    <div className="card-body">
                                        <h1 className="card-title fs-5" id="staticBackdropLabel">
                                            {t('title')}
                                        </h1>
                                        <img src={logo} alt="logo" className='mb-2' width={100} height={75} />
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="signupModalFormSignupName">{t('form.code')}</label>
                                            <Input
                                                type="number"
                                                className="form-control form-control-lg"
                                                name="digit"
                                                id="signupModalFormSignupName"
                                                placeholder={t('form.pl_hld_code')}
                                                aria-label={t('form.pl_hld_code')}
                                                maxLength={6}
                                                minLength={6}
                                                validations={[_rrequired, _digit]}
                                                onChange={e => handleDigitCodeChange(e)}
                                            />
                                        </div>
                                        {
                                            !showLoading
                                                ? <Button type="submit" className="btn btn-success btn-block">
                                                    {t('form.btn')}
                                                </Button>
                                                : <button type="button" className="btn btn-success btn-block" disabled={showLoading}>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    {t('form.in_progress')}
                                                </button>
                                        }
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
        }

        <Helmet>
            <link rel="stylesheet" href="./assets/css/app/bootstrap-icons/font/bootstrap-icons.css" />
            <link rel="stylesheet" href="./assets/css/app/hs-mega-menu.min.css" />
            <link rel="stylesheet" href="./assets/css/app/quill.snow.css" />
            <link rel="stylesheet" href="./assets/css/app/tom-select.css" />
            <link rel="stylesheet" href="./assets/css/app/aos.css" />
            <link rel="stylesheet" href="./assets/css/app/swiper-bundle.min.css" />
            <link rel="stylesheet" href="./assets/css/app/theme.min.css" />

            {/* <title>HappyNess | Terms</title> */}
        </Helmet>
    </>
}

export default TwoFAForm;
