import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { history } from '../helpers';


const PrivateRoute = () => {
    const authUser = useSelector(x => x.auth.token ? true : false);

    if (!authUser) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/signin" state={{ from: history.location }} />
    }

    // authorized so return child components
    return <Outlet />;
}

export const Anonymous = ({auth}) => {
    const {token, user} = auth;
    // const authUser = useSelector(x => x.auth.token ? true : false);
    // const user = useSelector(x => x.user.user.id !== undefined ? true : false);
    const emailOk = useSelector(x => x.user.user.email_verified_at ? true : false);
    const twofact = useSelector(x => x.user.user.google2fa_secret !== null  ? true : false);
    // const twoFactChecked = useSelector(x => x.user.user.twoFactChecked ? true : false);


    if (token && user) {
        if (emailOk) {
            if (twofact) {
                console.log('2fa activer')
                return <Outlet />;
            } else {
                console.log('N1')
                // console.log(twofact, twoFactChecked);
                return <Navigate to="/" state={{ from: history.location }} />;
            }
        } else {
            console.log('email pas Ok')
            return <Outlet />;
        }
    } else {
        console.log('User pas authentifie')
        return <Outlet />;
    }
}


export default PrivateRoute;



/*
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { history } from '../helpers';

export default { PrivateRoute };

function PrivateRoute() {
    const auth = useSelector(x => x.auth.value);

    if (!auth) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/login" state={{ from: history.location }} />
    }

    // authorized so return outlet for child routes
    return <Outlet />;
}

*/