import React, { useState } from 'react';
import { formatDate } from 'helpers';
import Form from "react-validation/build/form";
import $ from 'jquery';
import { userActions } from 'store';
import { useDispatch } from 'react-redux';



const UserDisconnectDevices = ({ setModal, data, lang }) => {


    const [showLoading, setShowLoading] = useState(false);

    const dispatch = useDispatch();


    const handleSubmit = e => {
        e.preventDefault();
        setShowLoading(true);


        setTimeout(() => {
            dispatch(userActions.logoutdevice({ deviceId: data.id }));

            setTimeout(() => {
                setShowLoading(false);
                $('body').attr('class', '');
                $('body').attr('style', '');
                $('#userDeviceLogout').css('display', 'none');
                $('.modal-backdrop').remove();
                setModal(<></>)
            }, 2000);

        }, 2000);
    };


    return <div className={`modal fade`} id="userDeviceLogout" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="userDeviceLogoutLabel" style={{ display: 'none' }} aria-modal="true" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-xm" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title" id="userDeviceLogoutLabel">Disconnect a device</h4>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal(<></>)}></button>
                </div>

                <div className="modal-body">
                    <h2 className='text-uppercase'>Device informations</h2>
                    <div className="col-sm">
                        <ul className="list-pointer list-pointer-bg-dark">
                            <li className="list-pointer-item">Operating System: <span className='text-primary'>{data.os}</span></li>
                            <li className="list-pointer-item">Browser: <span className='text-primary'>{data.browser}</span></li>
                            <li className="list-pointer-item">Browser version: <span className='text-primary'>{data.payload.browser.version}</span></li>
                            <li className="list-pointer-item">Ip Address: <span className='text-primary'>{data.ip_address}</span></li>
                            <li className="list-pointer-item">Last activity: <span className='text-primary'>{formatDate(data.last_activity, true)}</span></li>
                        </ul>
                    </div>
                    <br />
                    <Form onSubmit={e => handleSubmit(e)} >
                        <div className="d-flex justify-content-center">
                            {
                                !showLoading
                                    ? <button type="submit" className="btn btn-outline-danger btn-block">
                                        Disconnect
                                    </button>
                                    : <button type="button" className="btn btn-outline-danger btn-block" disabled={showLoading}>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Disconnecting...
                                    </button>
                            }

                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>;
}

export default UserDisconnectDevices;
