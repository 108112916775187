import React from 'react';
import { formatDate, getFileFromBack } from 'helpers';



const AdminUserView = ({user, setModal}) => {




    return <div className="modal fade" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" id="view-user" style={{ display: "none" }} aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content bg-transparent">
                {/* 
                <div className="modal-header" style={{ borderBottom: '1px solid rgba(200, 200, 200, 0.5)' }}>
                    <h4 className="modal-title"><i className="fas fa-user text-default"></i> <span className='text-bold text-default'>{user !== null ? user.first_name : ''}'s</span></h4>

                </div> */}
                <div className="modal-body">
                    <div className="card card-widget widget-user bg-white" style={{ boxShadow: '0px 0px 2px black' }}>
                        <div className="widget-user-header bg-primary">
                            <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close" onClick={() => setModal(<></>)}>
                                <span aria-hidden="true">×</span>
                            </button>

                            <h3 className="widget-user-username">
                                <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>{user !== null ? user.name : ''}</span>
                            </h3>
                            <h5 className="widget-user-desc">Since: {user !== null ? formatDate(user.created_at) : ''}</h5>
                            <div className="widget-user-image mt-2">
                                <img className='avatar avatar-circle avatar-sm img-circle' src={getFileFromBack(user.avatar)} alt="User Avatar" />
                            </div>
                        </div>


                        <div className="card-footer" style={{ backgroundColor: 'white' }}>
                            <div className="row">
                                <div className="col-sm-2 border-right">
                                    <div className="description-block">
                                        <h5 className="description-header">Role</h5>
                                        <span className="description-text text-primary text-bold">
                                            {
                                                user !== null
                                                    ? user.roles.length !== 0
                                                        ? <span className="description-text text-primary text-bold">{user.roles[0].name}</span>
                                                        : <span className="description-text text-danger text-bold bg-danger" style={{ textTransform: 'uppercase', fontSize: '0.7rem' }}>Not set</span>
                                                    : ''}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-sm-7 border-right">
                                    <div className="description-block">
                                        <h5 className="description-header">Email</h5>
                                        <span className="description-text text-primary text-bold" style={{ textTransform: 'lowercase' }}>{user !== null ? user.email : ''}</span>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="description-block">
                                        <h5 className="description-header">Type</h5>
                                        <span className="description-text text-primary text-bold" style={{ fontSize: '0.8em' }}>{user !== null ? user.type : ''}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="row">
                                <div className="card bg-white" style={{ width: '100%' }}>
                                    <div className="card-body" style={{ width: '100%', overflowY: 'auto', height: '460px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div>
                                                <strong><i className="fas fa-user mr-1"></i> Name</strong>
                                                <p className="text-muted text-bold">{user !== null ? user.name : ''}</p>
                                            </div>
                                            <div>
                                                <strong>Status</strong><br />
                                                <p className={`badge badge-${user !== null ? user.deleted_at === null ? 'success' : 'danger' : null}`}>
                                                    {user !== null ? user.deleted_at === null ? 'Active' : 'Unactive' : null} account
                                                </p>
                                            </div>
                                            {/* <div>
                                                <strong>Status</strong>
                                            </div> */}
                                        </div>

                                        <hr />

                                        <strong><i className="fas fa-envelope mr-1"></i> Email</strong>
                                        <p className="text-muted text-bold">{user !== null ? user.email : ''}</p>
                                        <hr />

                                        <strong><i className="fas fa-pencil-alt mr-1"></i> Type</strong>
                                        <p className="text-muted text-bold">{user !== null ? user.type : ''}</p>
                                        <hr />

                                        <strong><i className="fas fa-clock mr-1"></i> Registered at</strong>
                                        <p className="text-muted text-bold">{user !== null ? formatDate(user.created_at) : ''}</p>
                                        <hr />

                                        <strong><i className="fas fa-phone mr-1"></i> Phone</strong>
                                        <p className="text-muted text-bold">{user !== null ? user.phone !== null ? user.phone : 'Not set' : ''}</p>
                                        <hr />

                                        <strong>Account status</strong>
                                        <br />
                                        <p className={`badge badge-${user !== null ? user.email_verified_at !== null ? 'success' : 'danger' : ''}`}>
                                            Account {user !== null ? user.email_verified_at !== null ? 'verified' : 'not verified   ' : ''}
                                        </p>
                                        {/* <hr /> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="modal-footer" style={{ borderTop: '1px solid rgba(200, 200, 200, 0.5)' }}>
                    <button type="button" className="btn btn-success">Save changes</button> 
                    <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                </div> */}

            </div>
        </div>
    </div>
};
export default AdminUserView;