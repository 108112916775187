import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const TableData = ({ config, size = 700, rowHeight = 52 }) => {
    return (
        <div style={{ height: `${size}px`, width: '100%' }}>
            <DataGrid
                rowHeight={rowHeight}
                rows={config.rows}
                columns={config.columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 25 },
                    },
                }}
                className='bg-white'
                pageSizeOptions={[10, 25, 50, 100]}
            />
        </div>
    );
}

export default TableData;