import React, { useEffect, useState } from 'react';
import { AdminContentLoader } from '../components';
import { AppendScript, RemoveAppendedScript, paypalExtractDataFromUrl } from '../helpers';
import $ from 'jquery';
import { guestActions } from '../store';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import config from '../config';

const PaypalProcess = () => {

    const [load, setLoad] = useState(true);

    const dispatch = useDispatch();


    useEffect(() => {



        AppendScript('./assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js');
        AppendScript('./assets/js/theme.min.js');

        $('body').addClass('d-flex flex-column justify-content-center align-items-center h-100');
        $('html').addClass('h-100');

        let tim = setTimeout(() => {
            setLoad(false);
        }, 3000);

        let timm = setTimeout(() => {
            dispatch(guestActions.paypal_pay_exec(paypalExtractDataFromUrl(window.location.href)));
        }, 6000);

        return () => {
            clearTimeout(tim);
            clearTimeout(timm);
            RemoveAppendedScript();
            $('body').removeAttr('class');
            $('html').removeAttr('class');
        };
    }, [])

    return <>
        {
            load
                ? <AdminContentLoader />
                : <>
                    <header id="header" className="navbar navbar-expand navbar-light navbar-absolute-top">
                        <div className="container">
                            <nav className="navbar-nav-wrap">
                                <span className="navbar-brand" aria-label="Front">
                                    <img className="navbar-brand-logo" src={require('../assets/img/logo/apines_white.png')} alt="Logo" width={155} height={47} />
                                </span>
                            </nav>
                        </div>
                    </header>

                    <main id="content" role="main">
                        <div className="container text-center">
                            <div className="mb-3">
                                <img className="img-fluid" src="/assets/img/svg/illustrations/oc-error.svg" alt="Description_image" style={{ width: "30rem" }} />
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }} className='text-primary'>
                                <span className="spinner-border spinner-border" role="status" aria-hidden="true"></span>
                            </div>

                            <div className="mb-4">
                                <p className="fs-4 mb-0">Waiting for payment status...</p>
                            </div>

                        </div>
                    </main>

                    <footer className="position-absolute start-0 end-0 bottom-0">
                        <div className="container pb-5 content-space-b-sm-1">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-sm mb-3 mb-sm-0">
                                    <p className="small mb-0">&copy; HappyNess. {config.app.footer.year}.</p>
                                </div>

                                <div className="col-sm-auto">
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item">
                                            <a className="btn btn-soft-secondary btn-xs btn-icon" href="https://www.facebook.com/profile.php?id=100080508300409" target='_blank'>
                                                <i className="bi-facebook"></i>
                                            </a>
                                        </li>

                                        {/* <li className="list-inline-item">
                                <a className="btn btn-soft-secondary btn-xs btn-icon" href="#/">
                                    <i className="bi-google"></i>
                                </a>
                            </li>

                            <li className="list-inline-item">
                                <a className="btn btn-soft-secondary btn-xs btn-icon" href="#/">
                                    <i className="bi-twitter"></i>
                                </a>
                            </li>

                            <li className="list-inline-item">
                                <a className="btn btn-soft-secondary btn-xs btn-icon" href="#/">
                                    <i className="bi-github"></i>
                                </a>
                            </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </footer>
                </>
        }
        <Helmet>
            <link rel="stylesheet" href="./assets/css/app/bootstrap-icons/font/bootstrap-icons.css" />
            <link rel="stylesheet" href="./assets/css/app/hs-mega-menu.min.css" />
            <link rel="stylesheet" href="./assets/css/app/quill.snow.css" />
            <link rel="stylesheet" href="./assets/css/app/tom-select.css" />
            <link rel="stylesheet" href="./assets/css/app/aos.css" />
            <link rel="stylesheet" href="./assets/css/app/swiper-bundle.min.css" />
            <link rel="stylesheet" href="./assets/css/app/theme.min.css" />

            <title>Payment</title>
        </Helmet>
    </>;
}

export default PaypalProcess;
