import { control } from 'react-validation';

const InputField = ({ error, isChanged, isUsed, children, ...props }) => (
    <>
        <div className="input-group input-group-merge" data-hs-validation-validate-classname>
            <input {...props} />
            {children}
        </div>

        {isChanged && isUsed && error}
    </>
);

const CheckField = ({ error, isChanged, isUsed, ...props }) => (
    <>
        <input {...props} />
        {isChanged && isUsed && error}
    </>
);

const Input = control(InputField);
const Check = control(CheckField);

export { Input, Check };
