import React from 'react';
import { formatDate } from '../../helpers';
import { MyCircle } from '../../components';

const UserProjectView = ({ setModal, project, lang }) => {

    const details = [
        { elm: lang('items.user_project.view.name'), value: project.name },
        { elm: lang('items.user_project.view.status'), value: project.status },
        { elm: lang('items.user_project.view.categ'), value: project.categories.name },
        { elm: lang('items.user_project.view.created'), value: project.created_at },
        { elm: lang('items.user_project.view.budget'), value: project.budget },
        // { elm: 'category', value: project.categories.name },
    ];

    return <div className={`modal fade`} id="userViewProject" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="userViewProjectLabel" style={{ display: 'none' }} aria-modal="true" role="dialog">
        <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title fw-bold" id="userViewProjectLabel"><i className="fa fa-book text-primary "></i> {project.name}</h4>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal(<></>)}></button>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row col-md-divider align-items-md-center">

                                        <h3 className='mb-4'>Project details</h3>

                                        <div className="row">
                                            {
                                                details.map((detail, key) => {
                                                    return <span key={key} className='mb-3' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <span style={{ textTransform: 'capitalize' }} className='fw-bold'>{detail.elm} </span>
                                                        <span className='text-primary'>{key !== 3 ? detail.value : formatDate(detail.value)}</span>
                                                    </span>
                                                })
                                            }
                                            <span>
                                                <span style={{ textTransform: 'capitalize' }} className='fw-bold'>{lang('items.user_project.view.desc')} </span><br />
                                                <span className='text-primary'>{project.description}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {
                                    makeStats(project).map((elm, key) => {
                                        return <MyCircle data={elm} key={key} />
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default UserProjectView;

const makeStats = projet => {
    const tasks = projet.tasks;

    const taskCompleted = tasks.filter(task => task.completed === 1).length;

    const val = (taskCompleted / tasks.length) * 100;
    const evolution = tasks.length !== 0 ? val.toFixed(2) : '0';

    const finale = {
        title: 'Progression',
        titleColor: 'black',
        circleSize: 100,
        animate: true,
        time: 2,
        lineWidth: 40,
        evolution,
        radius: true,
        Percent: true,
        evolutionColor: '#b5285d',
        bgColor: '#eabed0',
        textColor: '#b5285d',
        percentSymbol: true
    }


    return [finale];

};


// const convert = (code, value, devise) => {
//     const int_val = parseInt(value);
//     const rate = [...devise].filter(curr => curr.code === code)[0].rate;
//     return code === 'USD'
//         ? numberFormatter(int_val, localStorage.getItem('i18nextLng') === "en" ? 'en-US' : 'fr-FR')
//         : rate === 1
//             ? numberFormatter((int_val / 2) * 100, localStorage.getItem('i18nextLng') === "en" ? 'en-US' : 'fr-FR')
//             : rate > 1 && rate < 3
//                 ? numberFormatter(int_val * (parseInt(rate) + 1) * 100, localStorage.getItem('i18nextLng') === "en" ? 'en-US' : 'fr-FR')
//                 : rate > 3
//                     ? numberFormatter(int_val * (parseInt(rate) + 1) * 25, localStorage.getItem('i18nextLng') === "en" ? 'en-US' : 'fr-FR')
//                     : numberFormatter(int_val, localStorage.getItem('i18nextLng') === "en" ? 'en-US' : 'fr-FR');
// };
