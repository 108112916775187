import React from 'react';

const HomeLoader = () => {
    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        position: 'fixed',
        backgroundColor:'rgb(251, 251, 251)',
        left: 0,
        top: 0,
    }} className='text-primary'>
        <img src={require('../assets/img/anim/an12.gif')} alt="HomeLoader" style={{height: '15vh'}} />
    </div>
}

export default HomeLoader;
