import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';
import { guestActions, store } from '../store';
import { Locations } from '../constants';
import { Slang, SlangObj } from '../helpers';


const CareerLayout = () => {


    const t = Slang('page.careers');
    const t_values = SlangObj('page.careers.section2.values');

    const careers = useSelector(x => x.guest.careers);
    const [openingJobs, setOpeningJobs] = useState(useSelector(x => x.guest.careers));

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(guestActions.careers());

        let tim = setInterval(() => {
            dispatch(guestActions.careers());
            setOpeningJobs(store.getState().guest.careers);
        }, 8000);


        return () => {
            clearInterval(tim);
        };

    }, [dispatch]);


    const handleFilterByLocation = location => {
        // setLoca(location);
        if (location === 'all') {
            setOpeningJobs([...careers]);
        } else {
            setOpeningJobs(careers.filter(job => job.location === location));
        }
    };

    const handleFilterByCategory = category => {
        // setCate(category);
        if (category === 'all') {
            setOpeningJobs([...careers]);
        } else {
            setOpeningJobs(careers.filter(job => job.category === category));
        }
    };

    const handleResearchJob = value => {
        setOpeningJobs([...careers.filter(job => job.title.toLowerCase().startsWith(value.toLowerCase())
            || job.location.toLowerCase().startsWith(value.toLowerCase())
            || job.category.toLowerCase().startsWith(value.toLowerCase()))
        ]);
    };


    return (
        <main id="content" role="main">
            <div className="position-relative bg-img-start" style={{ backgroundImage: "url(./assets/img/svg/components/card-11.svg)" }}>
                <div className="container content-space-t-3 content-space-t-lg-5 content-space-b-md-2 position-relative zi-2">
                    <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-9">
                        <div className="mb-5">
                            <h1>{t('section1.title')}</h1>
                            <p>{t('section1.subtitle')}</p>
                            {/* <p>Work hard with highly motivated team of talented people and great teammates to launch perfectly crafted products you'll love.</p> */}
                        </div>

                        <Link className="js-go-to position-static btn btn-primary btn-transition" to="#openRolesSection/"
                            data-hs-go-to-options='{
                            "targetSelector": "#openRolesSection",
                            "compensationSelector": "#logoAndNav",
                            "offsetTop": 0,
                            "position": null,
                            "animationIn": false,
                            "animationOut": false
                        }'>{t('section1.btn')}</Link>
                        {/* }'>See job openings</Link> */}
                    </div>

                    <div className="row gx-2">
                        <div className="col-4 align-self-end mb-2">
                            <div className="ms-md-8">
                                <img className="img-fluid rounded-2" src="./assets/img/900x900/img1.jpg" alt="_Image Description" />
                            </div>
                        </div>


                        <div className="col-8 mb-2">
                            <img className="img-fluid rounded-2" src="./assets/img/900x450/img13.jpg" alt="_Image Description" />
                        </div>

                        <div className="col-4 mb-2">
                            <div className="me-md-8">
                                <img className="img-fluid rounded-2" src="./assets/img/750x750/img2.jpg" alt="_Image Description" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shape shape-bottom zi-1">
                    <svg width="3000" height="500" viewBox="0 0 3000 500" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 500H3000V0L0 500Z" fill="#fff" />
                    </svg>
                </div>
            </div>

            <div className="container">
                <div className="content-space-1 content-space-lg-2">
                    <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                        <span className="d-block small font-weight-bold text-cap mb-2">{t('section2.title')}</span>
                        <h2>{t('section2.subtitle')}</h2>
                    </div>

                    <div className="row">

                        {
                            t_values.map((value, key) => <div key={key} className="col-sm-6 col-md-4 mb-sm-7">
                                <div className="d-flex">
                                    <div className="flex-shrink-0">
                                        <span>&mdash;</span>
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h4>{value.title}</h4>
                                        <p>{value.desc}</p>
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </div>

            {/* <div className="border-top mx-auto mb-5" style={{ maxWidth: "25rem" }}></div> */}

            <div id="openRolesSection" className="container content-space-b-1 content-space-b-md-3">
                <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                    <h2>{t('section3.title')}</h2>
                    <p>{t('section3.subtitle')}</p>
                </div>

                <form>
                    <div className="row gx-2 gx-md-3 mb-7">
                        <div className="col-md-4 mb-2 mb-md-0">
                            <label className="form-label visually-hidden" htmlFor="searchJobCareers">{t('section3.form.search')}</label>

                            <div className="input-group input-group-merge">
                                <span className="input-group-prepend input-group-text">
                                    <i className="bi-search"></i>
                                </span>
                                <input type="text"
                                    className="form-control form-control-lg"
                                    id="searchJobCareers"
                                    placeholder={t('section3.form.search')}
                                    aria-label={t('section3.form.search')}
                                    onChange={e => handleResearchJob(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-4 mb-2 mb-sm-0">
                            {/* <label className="form-label visually-hidden" htmlFor="locationsJobCareers">Select location</label> */}

                            <select className="form-select form-select-lg" id="locationsJobCareers" aria-label="Select location" onChange={e => handleFilterByLocation(e.target.value)}>
                                <option value='all'>{t('section3.form.location')}</option>
                                {
                                    openingJobs.map((job, key) => <option key={key} value={job.location}>{Locations.filter(loc => loc.code === job.location)[0].name}</option>)
                                }
                            </select>
                        </div>

                        <div className="col-sm-6 col-md-4">
                            {/* <label className="form-label visually-hidden" htmlFor="departmentsJobCareers">Select department</label> */}

                            <select className="form-select form-select-lg" id="departmentsJobCareers" aria-label="Select department" onChange={e => handleFilterByCategory(e.target.value)}>
                                <option value='all'>{t('section3.form.departments')}</option>
                                {
                                    openingJobs.map((job, key) => <option key={key} value={job.category}>{job.category}</option>)
                                }
                            </select>
                        </div>
                    </div>
                </form>

                {/* Plus tard les users pourrons creer une alerte pour recevoir la notification par mail des nouvelles offre d'emplois en cliquant sur ce bouton */}

                {/* <div className="mb-4">
                    <button type="button" className="btn btn-ghost-secondary">
                        <i className="bi-bell me-1"></i> Create alert
                    </button> 
                    </div>
                */}

                <ul className="list-group list-group-lg">

                    {
                        openingJobs.length !== 0
                            ? openingJobs.map(job => <li key={job.id} className="list-group-item">
                                <div className="row">
                                    <div className="col-md-4 mb-2 mb-md-0">
                                        <span className="h6">{job.title}</span>
                                    </div>

                                    <div className="col-sm-5 col-md-2 mb-2 mb-sm-0">
                                        <span>{Locations.filter(loc => loc.code === job.location)[0].name}</span>
                                    </div>

                                    <div className="col-sm-5 col-md-4 mb-2 mb-sm-0">
                                        <span>{job.category}</span>
                                    </div>

                                    <div className="col-sm-2 text-sm-end">
                                        <Link to={`/career-overview/${job.id}`}>{t('section3.form.btn')} <i className="bi-chevron-right small ms-1 small ms-1"></i></Link>
                                    </div>
                                </div>
                            </li>)
                            : <div className="alert alert-soft-danger text-center"><span className="bi bi-exclamation-triangle-fill"></span> {t('section3.info')}</div>
                    }
                </ul>

                <div className="w-lg-65 text-center mx-lg-auto mt-7">
                    <p>{t('section3.text')}</p>
                    <Link className="btn btn-outline-primary" to="/contact">{t('section3.btn')}</Link>
                </div>
            </div>
        </main>
    );
}

export default CareerLayout;
