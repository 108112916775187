import React, { useEffect, useState } from 'react';
import appConfig from '../config';
import { useDispatch } from 'react-redux';
import { guestActions, store } from '../store';
import { Toast, Slang } from '../helpers';
//import $ from 'jquery';

const ContactLayout = () => {

    const t = Slang('page.contact');

    const dispatch = useDispatch();
    const [load, setLoad] = useState(false);

    const [first_name, setFirst_name] = useState('');
    const [last_name, setLast_name] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const resetForm = () => {
        let user = store.getState().user.user.id !== undefined ? store.getState().user.user : {
            first_name: '',
            name: '',
            phone: '',
            email: '',
        };

        setFirst_name(user.first_name);
        setLast_name(user.name);
        setPhone('');
        setEmail(user.email);
        setSubject('');
        setMessage('');

    }

    useEffect(() => {
        let user = store.getState().user.user.id !== undefined ? store.getState().user.user : {
            first_name: '',
            name: '',
            phone: '',
            email: '',
        };

        setTimeout(() => {
            setFirst_name(user.first_name);
            setLast_name(user.name);
            setPhone(user.phone);
            setEmail(user.email);
        }, 500);

        return () => {

        };
    }, []);


    const handleChangeFirstName = value => {
        setFirst_name(value);
    }

    const handleChangeLastName = value => {
        setLast_name(value);
    }

    const handleChangeEmail = value => {
        setEmail(value);
    }

    const handleChangePhone = value => {
        setPhone(value);
    }

    const handleChangeSubject = value => {
        setSubject(value);
    }

    const handleChangeMessage = value => {
        setMessage(value);
    }


    const handleSubmit = e => {
        e.preventDefault();
        setLoad(true)

        let infos = {
            first_name,
            last_name,
            phone,
            email,
            subject,
            message
        }

        if (first_name !== '' &&
            last_name !== '' &&
            email !== '' &&
            subject !== '' &&
            message !== '') {
            dispatch(guestActions.contact({
                first_name,
                last_name,
                phone,
                email,
                subject,
                message
            }));
            setTimeout(() => {
                resetForm();
                setTimeout(() => {
                    setLoad(false);
                }, 1000);
            }, 4000);
        } else {
            setLoad(false);
            Toast('warning', 'Please fill the form correctly', 'tr', false, false, true, 2000, false);
        }

    };

    return (
        <>
            <main id="content" role="main">
                <div className="container content-space-t-3 content-space-t-lg-5 content-space-b-2">
                    <div className="row">
                        <div className="col-lg-6 mb-9 mb-lg-0">
                            <div className="mb-5">
                                <h1>{t('col1.title')}</h1>
                                <p>{t('col1.subtitle')}</p>
                            </div>

                            <div className="overflow-hidden">
                                <div id="map" className="leaflet mb-5"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <h5 className="mb-1">{t('col1.call')}:</h5>
                                    <p><a href={`tel:${appConfig.app.footer.phone[0]}`}>{appConfig.app.footer.phone[0]}</a> / <a href={`tel:${appConfig.app.footer.phone[1]}`}>{appConfig.app.footer.phone[1]}</a></p>
                                </div>

                                <div className="col-sm-6">
                                    <h5 className="mb-1">{t('col1.mailing')}:</h5>
                                    <p><a href={`mailto:${appConfig.app.footer.email}`}>{appConfig.app.footer.email}</a></p>
                                </div>

                                <div className="col-sm-6">
                                    <h5 className="mb-1">{t('col1.addr')}:</h5>
                                    <p>{appConfig.app.footer.location}</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="ps-lg-5">
                                <div className="card">
                                    <div className="card-header border-bottom text-center">
                                        <h3 className="card-header-title">{t('col2.title')}</h3>
                                    </div>

                                    <div className="card-body">
                                        <form noValidate>
                                            <div className="row gx-3">
                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="hireUsFormFirstName">{t('col2.form.fname')}</label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-lg"
                                                            name="hireUsFormNameFirstName"
                                                            id="hireUsFormFirstName"
                                                            placeholder={t('col2.form.fname')}
                                                            value={first_name}
                                                            onChange={e => handleChangeFirstName(e.target.value)}
                                                            aria-label={t('col2.form.fname')} />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="hireUsFormLasttName">{t('col2.form.lname')}</label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-lg"
                                                            name="hireUsFormNameLastName"
                                                            id="hireUsFormLasttName"
                                                            placeholder={t('col2.form.lname')}
                                                            onChange={e => handleChangeLastName(e.target.value)}
                                                            value={last_name}
                                                            aria-label={t('col2.form.lname')} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row gx-3">
                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="hireUsFormWorkEmail">{t('col2.form.email')}</label>
                                                        <input
                                                            type="email"
                                                            className="form-control form-control-lg"
                                                            name="hireUsFormNameWorkEmail"
                                                            id="hireUsFormWorkEmail"
                                                            onChange={e => handleChangeEmail(e.target.value)}
                                                            value={email}
                                                            placeholder="email@site.com"
                                                            aria-label="email@site.com" />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="hireUsFormPhone">{t('col2.form.phone')} <span className="form-label-secondary">({t('col2.form.text_opt')})</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-lg"
                                                            name="hireUsFormNamePhone"
                                                            onChange={e => handleChangePhone(e.target.value)}
                                                            value={phone}
                                                            id="hireUsFormPhone"
                                                            placeholder="+x(xxx)xxx-x-xx"
                                                            aria-label="+x(xxx)xxx-xx-xx" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row gx-3">
                                                <div className="col-sm-12">
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="hireUsFormSubject">{t('col2.form.subject')}</label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-lg"
                                                            name="hireUsFormSubject"
                                                            id="hireUsFormSubject"
                                                            onChange={e => handleChangeSubject(e.target.value)}
                                                            placeholder={t('col2.form.subject')}
                                                            value={subject}
                                                            aria-label={t('col2.form.subject')} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="hireUsFormDetails">{t('col2.form.details')}</label>
                                                <textarea
                                                    onChange={e => handleChangeMessage(e.target.value)}
                                                    className="form-control form-control-lg"
                                                    name="hireUsFormNameDetails"
                                                    id="hireUsFormDetails"
                                                    placeholder={t('col2.form.place_holder_details')}
                                                    aria-label={t('col2.form.place_holder_details')}
                                                    rows="4"
                                                    value={message}
                                                ></textarea>
                                            </div>

                                            <div className="d-grid">
                                                {
                                                    load
                                                        ? <button type="button" disabled={load} className="btn btn-primary btn-lg">
                                                            <span className="spinner-border spinner-border" style={{ height: 20, width: 20 }} role="status" aria-hidden="true"></span>{t('col2.form.in_progress')}
                                                        </button>
                                                        : <button type="button" onClick={(e) => handleSubmit(e)} className="btn btn-primary btn-lg">{t('col2.form.btn')}</button>
                                                }
                                            </div>

                                            <div className="text-center">
                                                <p className="form-text">{t('col2.form.text')}</p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default ContactLayout;
