import React, { useEffect, useState } from 'react';
import logof from '../assets/img/logo/logo_footer.png';
import logo from "../assets/img/logo/new/logo_w.png";

import { Goto, HomeLoader, NetworkStatusIndicator } from '../components';
import { FooterLayout, HeaderLayout, MainLayout, NewsletterLayout } from '../layouts';
import { adminActions, authActions, guestActions, managerActions, store, userActions } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { SlangObj, history } from '../helpers';
import Fingerprint2 from 'fingerprintjs2';
import $ from 'jquery';
import { Helmet } from 'react-helmet-async';


const Home = () => {
    const user = useSelector(x => x.user.user);
    const auth = useSelector(x => x.auth);

    const [loading, setLoading] = useState(true);
    const [slide, setSlide] = useState(2);
    const [fp, setFp] = useState('');
    const tpdisitem = [...SlangObj('page.home.dicover_section.items')];


    const dispatch = useDispatch();


    useEffect(() => {
        let arr = [1, 2, 3, 4, 5], i = 0;

        setInterval(() => {
            if (i === 5) {
                i = 0;
            }
            setSlide(arr[i]);
            i++;
        }, 4000);

        setTimeout(() => {
            connectionVerif(dispatch, setLoading, store.getState().user, auth);
        }, 1200);

        Fingerprint2.get(components => {
            const values = components.map((component) => component.value);
            const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
            setFp(fingerprint);
        });

        return () => {
        };
    }, [fp]);



    return <>
        {
            loading
                ? <>
                    <HomeLoader />
                </>
                : <>
                    <GroupHome slide={slide} tpdisitem={tpdisitem} />
                </>
        }
        <Helmet>
            <link rel="stylesheet" href="./assets/css/app/bootstrap-icons/font/bootstrap-icons.css" />
            <link rel="stylesheet" href="./assets/css/app/hs-mega-menu.min.css" />
            <link rel="stylesheet" href="./assets/css/app/quill.snow.css" />
            <link rel="stylesheet" href="./assets/css/app/tom-select.css" />
            <link rel="stylesheet" href="./assets/css/app/aos.css" />
            <link rel="stylesheet" href="./assets/css/app/swiper-bundle.min.css" />
            <link rel="stylesheet" href="./assets/css/app/theme2.min.css" />

            <script defer async src='./assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js'></script>
            <script defer async src='./assets/vendor/hs-header/dist/hs-header.min.js'></script>
            <script defer async src='./assets/vendor/hs-mega-menu/dist/hs-mega-menu.min.js'></script>
            <script defer async src='./assets/vendor/hs-show-animation/dist/hs-show-animation.min.js'></script>
            <script defer async src='./assets/vendor/swiper/swiper-bundle.min.js'></script>
            {/* <script defer async src='./assets/vendor/aos/dist/aos.js'></script> */}
            <script defer async src='./assets/vendor/hs-sticky-block/dist/hs-sticky-block.min.js'></script>
            <script defer async src='./assets/vendor/hs-go-to/dist/hs-go-to.min.js'></script>
            <script defer async src='./assets/js/theme.min.js'></script>
            <title>HappyNess Web Sarl</title>
        </Helmet>
    </>

}

export default Home;


const GroupHome = ({ slide, tpdisitem }) => {
    const [modal, setModal] = useState(<></>);

    useEffect(() => {
        let html = `<script>
            new HSHeader('#header').init()
        
            new HSMegaMenu('.js-mega-menu', {
                desktop: {
                    position: 'left'
                }
            })
            
            new HSShowAnimation('.js-animation-link')
        
            HSBsDropdown.init()
        
            new HSGoTo('.js-go-to')
        
            var swiper = new Swiper('.js-swiper-blog-metro-hero',{
                effect: 'fade',
                autoplay: true,
                loop: true,
                pagination: {
                    el: '.js-swiper-blog-metro-hero-pagination',
                    clickable: true,
                }
            });

        </script>`;

        let tt = setTimeout(() => {
            $('body').append(html);
        }, 1000);



        return () => {
            clearTimeout(tt);
        };
    }, []);

    return <>
        <HeaderLayout setModal={setModal} logo={logo} />
        <MainLayout setModal={setModal} slide={slide} tpdisitem={tpdisitem} />
        <NewsletterLayout />
        <FooterLayout logo={logof} />
        <Goto />
        {modal}
    </>
};




export const connectionVerif = (dispatch, setLoading = null, user, auth) => {

    dispatch(userActions.getUser());
    dispatch(userActions.getdevices({ user_id: user.id }));


    setTimeout(() => {

        if (auth.token !== null) {
            console.log('user authentifie');

            let emailHasBeenVerified = (user.user.email_verified_at !== null);
            let twofactIsActive = (user.twoFactActivated);
            let twofactIsChecked = (user.twoFactChecked);




            if (emailHasBeenVerified) {
                console.log('email verifier');

                if (twofactIsActive) {
                    console.log('2 facteur active');
                    if (twofactIsChecked) {
                        // if (user.type === 'Entreprise') {
                        //     history.navigate('/business');
                        // } else if (user.type === 'Investisseur') {
                        //     history.navigate('/investor');
                        // } 
                        // dispatch(userActions.getdevices({ user_id: user.id }));
                        setLoading(false);

                        //console.log('2 facteur verfier');
                    } else {
                        history.navigate('/2fa');
                        // console.log('2fa active, redirection vers le control de code');
                    }
                } else {
                    // history.navigate('/');
                    // if (user.type === 'Entreprise') {
                    //     history.navigate('/business');
                    // } else if (user.type === 'Investisseur') {
                    //     history.navigate('/investor');
                    // }
                    setLoading(false);
                    //dispatch(userActions.getdevices({ user_id: user.id }));
                    // console.log('2fa non active connexion reussie');
                }
            } else {
                history.navigate('/email-validation');
                // console.log('email non verifie redirection vers la page de verification');
            }
        } else {
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    }, 3000);

};