import React from 'react';
import { Slang, SlangObj } from '../helpers';
import $ from 'jquery';

const CookiesLayout = () => {
    const t = Slang('page.cookies');
    const tObj = (sufix) => SlangObj(`page.privacy.${sufix}`);

    const htmlizer = (text, idd) => $(`#${idd}`).html(text);

    return (
        <main id="content" role="main">
            <div className="container content-space-3 content-space-lg-4">
                <div className="card card-lg">
                    <div className="card-header bg-primary py-sm-10">
                        <h1 className="card-title h2 text-white"><i className="fas fa-cookie"></i> &nbsp;&nbsp;{t('title')}</h1>
                        {/* <p className="card-text text-white">{t('s_title')}</p> */}
                    </div>
                    <div className="card-body" style={{ textAlign: 'justify' }}>
                        <div className="mb-7">
                            <p id='pp'>{htmlizer(t('p'), 'pp').map(el => el === 0 ? "" : "")}</p>
                            {/* <p className='pp'>{t('p')}</p> */}

                        </div>
                        <div className="mb-7">
                            <h2>{t('t1')}</h2>
                            <p id='p1'>{htmlizer(t('p1'), 'p1').map(el => el === 0 ? "" : "")}</p>
                        </div>
                        <div className="mb-7">
                            <h2>{t('t2')}</h2>
                            <p id='p2'>{htmlizer(t('p2'), 'p2').map(el => el === 0 ? "" : "")}</p>
                        </div>
                        <div className="mb-7">
                            <h2>{t('t3')}</h2>
                            <p id='p3'>{htmlizer(t('p3'), 'p3').map(el => el === 0 ? "" : "")}</p>
                        </div>
                        <div className="mb-7">
                            <h2>{t('t4')}</h2>
                            <p id='p4'>{htmlizer(t('p4'), 'p4').map(el => el === 0 ? "" : "")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default CookiesLayout;
