import React, { useEffect, useState } from 'react';
import { AdminContentLoader, ProgressBar } from '../../components';
import { adminActions, store } from '../../store';
import { useDispatch } from 'react-redux';
import { Toast, formatDate, getFileFromBack, progressValue } from '../../helpers';
import Swal from 'sweetalert2';
import { CreateCategory, CreateProject, EditCategory, EditProject, ViewCategory, ViewProject } from '../../components/modals';
import { TableData } from '../../components/table';

const ProjectsContent = () => {
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(<></>);

    const dispatch = useDispatch();

    useEffect(() => {

        //dispatch(adminActions.users());


        let tmer = setInterval(() => {
            //dispatch(adminActions.users());
        }, 10000);

        setTimeout(() => {
            setLoading(false)
        }, 3000);

        return () => {
            clearInterval(tmer);
        };
    }, []);

    return loading
        ? <AdminContentLoader />
        : <>
            <Content dispatch={dispatch} setModal={setModal} />
            {modal}
        </>
}

export default ProjectsContent;



const Content = ({ dispatch, setModal }) => {

    const [projects, setProjects] = useState(store.getState().admin.projects !== null ? store.getState().admin.projects : []);
    const [categories, setCategories] = useState(store.getState().admin.categories !== null ? store.getState().admin.categories : []);


    const [derouleProjectTable, setDerouleProjectTable] = useState(false);
    const [regrouprojectTable, setRegrouprojectTable] = useState(true);

    useEffect(() => {

        //dispatch(adminActions.users());

        let tmer = setInterval(() => {
            dispatch(adminActions.users());
            dispatch(adminActions.projects());
            dispatch(adminActions.categories())

        }, 8000);

        setInterval(() => {
            setProjects(store.getState().admin.projects !== null ? store.getState().admin.projects : []);
            setCategories(store.getState().admin.categories !== null ? store.getState().admin.categories : []);
        }, 3000);

        return () => {
            localStorage.removeItem('admin_project_actions');
            localStorage.removeItem('admin_categorie_actions');
            clearInterval(tmer);
        };
    }, [dispatch]);


    const loadProjectTask = project => {
        dispatch(adminActions.projecttasks({ project_id: project.id })); // Get the task of the project
        dispatch(adminActions.gettechnology({ projets_id: project.id })); // Get the technologies of the project
        window.sessionStorage.setItem('projects_files', store.getState().admin.projects.filter(proj => proj.id === project.id)[0].file);
    };

    const deroul = value => {
        setDerouleProjectTable(value)
        setTimeout(() => {
            setRegrouprojectTable(!value)
        }, 1500);
    };

    const handleView = project => {
        setModal(<></>);
        setModal(<ViewProject project={project} />);
    };

    const handleEdit = project => {
        setModal(<></>);
        setModal(<EditProject dispatch={dispatch} project={project} setModal={setModal} />);
    };

    const handleDelete = (project) => {
        Swal.fire({
            title: `Are you sure`,
            text: `You won't be able to revert this operation!`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            html: `<p>Are you sure you want to delete <br /><span style="color: #28a745">${project.name}</span></p>`
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(adminActions.deleteproject({ project_id: project.id }));
            }
        })
    };


    const handleViewCateg = categ => {
        setModal(<ViewCategory />)
    };

    const handleEditCateg = categ => {
        setModal(<EditCategory />)
    };

    const handleDeleteCateg = categ => {

        Swal.fire({
            title: `Are you sure`,
            text: `You won't be able to revert this operation!`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            html: `<p>Are you sure you want to delete <br /><span style="color: #28a745">This category</span></p>`
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(adminActions.deletecategory({ id: categ.id }));
            }
        })
    };

    const buildArrProjects = arr => {
        let tab = [];

        for (let i = 0; i < arr.length; i++) {
            tab.push({
                id: arr[i].id,
                name: arr[i].name,
                img: arr[i].image,
                created_at: arr[i].created_at,
                team: arr[i].users,
                tasks: arr[i].tasks,
                status: arr[i].status,
                item: arr[i]
            });
        }

        return tab;
    };


    const allProjectsTab = {
        columns: [
            { field: 'id', headerName: 'ID', width: 20 },
            {
                field: 'name',
                headerName: 'Project Name',
                width: 300,
                renderCell: param => <ul style={{ paddingLeft: 0, display: 'flex', alignContent: 'start', listStyleType: 'none', alignItems: 'center' }}>
                    <li>
                        <img
                            alt="Projet_image"
                            className="table-avatar mt-2"
                            style={{ height: 70 }}
                            src={param.row.img}
                        />
                    </li>

                    <li>
                        <a href='#/' className='text-bold' style={{ cursor: 'pointer', color: 'black' }}>{param.row.name}</a>
                        <br /><small>Created at: <span className='text-info text-bold'>{formatDate(param.row.created_at)}</span></small>
                    </li>
                </ul>
            },
            {
                field: 'team',
                headerName: 'Team members',
                width: 400,
                renderCell: param => <ul style={{ paddingLeft: 0, display: 'flex', alignContent: 'start', listStyleType: 'none', alignItems: 'center' }}>
                    {
                        param.row.team.map((worker, cle) => {
                            return <li key={cle}>
                                <div
                                    style={{ border: '', cursor: 'pointer' }}
                                    title={worker.name}
                                    onClick={() => Toast('info', `view ${worker.name} profile`)}
                                >
                                    <img
                                        alt="Avatar"
                                        className="table-avatar mr-2 elevation-2"
                                        style={{ height: 50, borderRadius: '50%', border: worker.pivot.is_owner === 1 ? '2px solid gold' : '' }}
                                        src={getFileFromBack(worker.avatar)}//getFileFromBack(worker.avatar)
                                    />
                                    {
                                        worker.pivot.is_owner === 1
                                            ? <i className='fa fa-star text-primary'
                                                style={{
                                                    fontSize: 15,
                                                    position: 'relative',
                                                    float: 'left',
                                                    top: 35,
                                                    left: 15,
                                                    marginLeft: -15
                                                }}></i>
                                            : null
                                    }
                                </div>
                            </li>
                        })
                    }
                </ul>
            },
            {
                field: 'progress',
                headerName: 'Project Progression',
                width: 400,
                renderCell: params => <>
                    {/* <div className="progress progress-sm">
                        <div className={`progress-bar`} role="progressbar" aria-valuenow={progressValue(params.row.tasks)}
                            aria-valuemin="0" aria-valuemax="100" style={{ width: `${progressValue(params.row.tasks)}%`, backgroundColor: progressBarColor(progressValue(params.row.tasks)) }}>
                        </div>
                    </div> */}
                    <ProgressBar value={progressValue(params.row.tasks)} /><br />
                    <small>{isNaN(progressValue(params.row.tasks)) ? '0' : progressValue(params.row.tasks)} % Completed</small>
                </>
            },
            {
                field: 'status',
                headerName: 'Status',
                width: 250,
                renderCell: (params) => <span className={`badge badge-${stateProjectBadge(params.row.status)}`} style={{ padding: '6px 15px' }}>{params.row.status}</span>,
            },
            {
                field: 'actions',
                headerName: 'Actions',
                width: 240,
                renderCell: (params) => <>
                    <span
                        className="btn btn-outline-info btn-sm ml-2"
                        data-toggle="modal"
                        data-target="#view-project"
                        title='View'
                        onClick={() => handleView(params.row.item)}
                        onMouseOver={() => loadProjectTask(params.row.item)}
                    >
                        <i className="fas fa-eye"></i>
                    </span>
                    <span
                        className="btn btn-outline-success btn-sm ml-2"
                        data-toggle="modal"
                        data-target="#edit-project"
                        title='Edit'
                        onClick={() => handleEdit(params.row.item)}
                        onMouseOver={() => loadProjectTask(params.row.item)}
                    >
                        <i className="fas fa-edit"></i>
                    </span>
                    <span
                        className="btn btn-outline-danger btn-sm ml-2"
                        onClick={() => handleDelete(params.row.item)} title='Delete' >
                        <i className="fas fa-trash"></i>
                    </span>
                </>,
            }
        ],
        rows: buildArrProjects(projects)
    }

    return <section className="content">

        <div className="row">
            <div className="col-md-8">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title text-info"> Projects Informations</h3>

                        <div className="card-tools">
                            <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                <i className={`fas fa-${derouleProjectTable ? 'plus' : 'minus'}`}></i>
                            </button>
                            {/* <button type="button" className="btn btn-tool" data-card-widget="remove" title="Remove">
                                <i className="fas fa-times"></i>
                            </button> */}
                        </div>
                    </div>
                    <div className="card-body p-0" style={{ maxHeight: '415px', overflow: 'auto', display: derouleProjectTable ? 'none' : 'block' }}>
                        <div className="col-md-12">
                            <div className="col-12 col-sm-6 col-md-12">
                                <div className="card card-success card-outline bg-white card-tabs">
                                    <div className="card-header p-0 pt-1">
                                        <ul className="nav nav-tabs" id="custom-tabs-two-tab" role="tablist">
                                            <li className="pt-2 px-3">
                                                <h3 className="card-title">Categories:</h3>
                                            </li>
                                            {
                                                store.getState().admin.categories.length > 0
                                                    ? store.getState().admin.categories.map((item, key) => {
                                                        return <li key={key} className="nav-item">
                                                            <a className="nav-link" id="custom-tabs-two-home-tab" data-toggle="pill" href={`#${item.slug}`} role="tab"
                                                                aria-controls="custom-tabs-two-home" aria-selected="false">
                                                                {item.name}
                                                                <span className={`badge badge-${store.getState().admin.projects.filter(proj => proj.categories_id === item.id).length === 0 ? 'danger' : 'success'} ml-2`}>
                                                                    {store.getState().admin.projects.filter(proj => proj.categories_id === item.id).length}
                                                                </span>
                                                            </a>
                                                        </li>
                                                    })
                                                    : null
                                            }


                                        </ul>
                                    </div>
                                    <div className="card-body" style={{ maxHeight: '420px', overflow: 'auto' }}>
                                        <div className="tab-content" id="custom-tabs-two-tabContent">
                                            {
                                                store.getState().admin.categories.length > 0
                                                    ? store.getState().admin.categories.map((item, key) => {
                                                        return <div key={key} className="tab-pane fade" id={item.slug} role="tabpanel" aria-labelledby="custom-tabs-two-home-tab">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="card card-primary card-outline">
                                                                        <div className="card-header">
                                                                            Category informations
                                                                        </div>
                                                                        <div className="card-body bg-white">
                                                                            <dl className="row">
                                                                                <dt className="col-sm-4 text-primary">Title:</dt>
                                                                                <dd className="col-sm-8 text-bold">{item.name}</dd>
                                                                                <dt className="col-sm-4 text-primary">Description:</dt>
                                                                                <dd className="col-sm-8">{item.description}</dd>
                                                                                <dt className="col-sm-4 text-primary">Created at:</dt>
                                                                                <dd className="col-sm-8">{formatDate(item.created_at)}</dd>
                                                                            </dl>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="card card-primary card-outline">
                                                                        <div className="card-header">
                                                                            Projects of this category: <span className='text-bold text-warning'>{store.getState().admin.projects.filter(proj => proj.categories_id === item.id).length}</span>
                                                                        </div>
                                                                        <div className="card-body bg-white" style={{ maxHeight: "300px", overflow: 'auto' }}>

                                                                            {
                                                                                store.getState().admin.projects.filter(proj => proj.categories_id === item.id)
                                                                                    .map((it, ke) => <div key={ke} className="info-box bg-white">
                                                                                        <span className="info-box-icon bg-white"><i className="fa fa-flag text-primary"></i></span>

                                                                                        <div className="info-box-content">
                                                                                            <span className="info-box-text text-bold">{it.name} </span>
                                                                                            <span className="info-box-number"><span className='badge badge-info'>{it.status}</span></span>
                                                                                            <span className="info-box-number">{it.users.length} Users on the project</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                                    : null
                                            }

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            {/* Categories */}
            <div className="col-md-4">
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title btn btn-outline-info" onClick={() => setModal(<CreateCategory dispatch={dispatch} />)} data-toggle="modal" data-target="#create-category">
                            <i className="fa fa-plus"></i> New Category
                        </h5>
                        <div className="card-tools">
                            <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                <i className={`fas fa-${derouleProjectTable ? 'plus' : 'minus'}`}></i>
                            </button>
                            {/* <button type="button" className="btn btn-tool" data-card-widget="remove" title="Remove">
                                <i className="fas fa-times"></i>
                            </button> */}
                        </div>
                    </div>
                    <div className="card-body p-0" style={{ maxHeight: "400px", overflow: 'auto', display: derouleProjectTable ? 'none' : 'block' }}>
                        <div className="col-md-12">
                            <table className="table table-striped table-bordered table-head-fixed projects table-dark">
                                <thead>
                                    <tr>
                                        <th style={{ width: "1%" }}>#</th>
                                        <th style={{ width: "30%" }}>Name</th>
                                        <th style={{ width: "40%" }}>Created at</th>
                                        <th style={{ width: "20%" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        categories.map((item, key) => {
                                            return <tr key={key}>
                                                <td>{key + 1}</td>
                                                <td>{item.name}</td>
                                                <td>{formatDate(item.created_at)}</td>

                                                <td className="project-actions text-right">
                                                    {/* <span className="btn btn-outline-info btn-sm ml-2" data-toggle="modal" data-target="#view-categ" title='View' onMouseOver={() => handleViewCateg(item)}>
                                                        <i className="fas fa-eye"></i>
                                                    </span>
                                                    <span className="btn btn-outline-success btn-sm ml-2" data-toggle="modal" data-target="#edit-categ" title='Edit' onMouseOver={() => handleEditCateg(item)}>
                                                        <i className="fas fa-edit"></i>
                                                    </span> */}
                                                    <span className="btn btn-outline-danger btn-sm ml-2" onClick={() => handleDeleteCateg(item)} title='Delete' >
                                                        <i className="fas fa-trash"></i>
                                                    </span>
                                                </td>

                                            </tr>
                                        })
                                    }

                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div className="row">

            {/* Projects */}
            <div className="col md-12">

                <div className="card">
                    <div className="card-header">
                        <h6 className="card-title btn btn-sm btn-outline-success" onClick={() => setModal(<CreateProject dispatch={dispatch} />)} data-toggle="modal" data-target="#create-project">
                            <i className="fa fa-plus"></i> New Project
                        </h6>
                        <div className="card-tools" style={{ display: 'flex' }}>

                            <div className="input-group input-group-sm" style={{ width: "150px" }}>
                                <input
                                    type="text"
                                    name="table_search"
                                    className="form-control float-right"
                                    placeholder="Search"
                                />
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-default">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="input-group input-group-sm" style={{ width: "30px", justifyContent: 'end' }}>
                                <button type="button" className="btn btn-tool" data-card-widget="collapse" onClick={() => deroul(!derouleProjectTable)}>
                                    <i className={`fas fa-${regrouprojectTable ? 'plus' : 'minus'}`}></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0" style={{ maxHeight: "500px", overflow: 'auto', display: regrouprojectTable ? 'none' : 'block' }}>
                        <TableData config={allProjectsTab} size={500} rowHeight={80} />
                    </div>

                </div>
            </div>

        </div>


    </section>
};




const stateProjectBadge = status => {
    return status === 'PENDING'
        ? 'secondary'
        : status === 'REJECTED' || status === 'CANCELLED'
            ? 'danger'
            : status === 'APPROVED'
                ? 'info'
                : status === 'TESTING'
                    ? 'warning' : 'success'
};