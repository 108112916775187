import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';



const CustomizedProgressBars = ({ value }) => {
    const progressBarColor = (value) => {
        return 0 < value && value < 20
            ? "error"
            : value > 19 && value < 41
                ? "warning"
                : value > 40 && value < 66
                    ? "secondary"
                    : value > 65 && value < 86
                        ? "primary"
                        : value > 85 && value < 94
                            ? "info"
                            : value > 93 && value < 97
                                ? "primary"
                                : value > 96 && value < 101
                                    ? "success" : 'inherit'
            ;
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <LinearProgress style={{ height: 15, borderRadius: 15, color:'gray' }} color={progressBarColor(value)} variant="determinate" value={value.toString()} />
        </Box>
    );
}

export default CustomizedProgressBars;
