import React, { useState } from 'react';
import { ModalForgotPassword, ModalLogin, ModalRegister } from '../../pages';
import { google_window_login } from '../../helpers';



const AuthModal = ({ setModal, lang }) => {

    const [content, setContent] = useState(0);

    const modalContents = [
        () => <GoogleLogin lang={lang} setContent={setContent} />,
        () => <GoogleRegister lang={lang} setContent={setContent} />,
        () => <ModalLogin lang={lang} setContent={setContent} />,
        () => <ModalRegister lang={lang} setContent={setContent} />,
        () => <ModalForgotPassword lang={lang} setContent={setContent} />
    ];


    return <div className="modal fade" id="signupModal" tabIndex="-1" style={{ display: "none", paddingLeft: "0px" }} aria-modal="true"
        role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-close">
                    <button type="button" className="btn-close" onClick={() => setModal(<></>)} data-bs-dismiss="modal" aria-label="Close"></button>
                </div>


                <div className="modal-body">
                    {modalContents[content]()}
                </div>

                <div className="modal-footer d-block text-center py-sm-5">
                    <small className="text-cap mb-4">{lang('footer.title')}</small>

                    <div className="w-85 mx-auto">
                        <div className="row justify-content-between">
                            <div className="col">
                                <img className="img-fluid" src="./assets/img/svg/brands/gitlab-gray.svg" alt="Logo" />
                            </div>

                            <div className="col">
                                <img className="img-fluid" src="./assets/img/svg/brands/fitbit-gray.svg" alt="Logo" />
                            </div>

                            <div className="col">
                                <img className="img-fluid" src="./assets/img/svg/brands/flow-xo-gray.svg" alt="Logo" />
                            </div>

                            <div className="col">
                                <img className="img-fluid" src="./assets/img/svg/brands/layar-gray.svg" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="modal-close">
                    <button type="button" className="btn btn-soft-danger" onClick={() => setModal(<></>)} data-bs-dismiss="modal">Close</button>
                </div> */}
            </div>
        </div>
    </div>


}

export default AuthModal;


const GoogleLogin = ({ setContent, lang }) => {

    const [loading, setLoading] = useState(false);

    return <div data-hs-show-animation-target-group="idForm">
        <div className="text-center mb-7">
            <h2>{lang('google_login.title')}</h2>
            <p>{lang('google_login.p')}
                <span className="js-animation-link link text-primary" role="button" style={{ cursor: "pointer" }}
                    data-hs-show-animation-options="{
                        &quot;targetSelector&quot;: &quot;#signupModalFormSignup&quot;,
                        &quot;groupName&quot;: &quot;idForm&quot;
                    }" data-hs-show-animation-link-group="idForm"
                    onClick={() => setContent(1)}
                >&nbsp;
                    {lang('google_login.link_signup')}
                </span>
            </p>
        </div>

        <div className="d-grid gap-2">
            {
                loading
                    ? <>
                        <span className="btn btn-white btn-lg" style={{ cursor: "progress" }}>
                            <span className="d-flex justify-content-center align-items-center">
                                <div className="spinner-border spinner-border-sm text-primary" role="status">
                                </div>&nbsp;&nbsp;
                                <img className="avatar avatar-xss me-2" src="./assets/img/svg/brands/google-icon.svg"
                                    alt="google_brand" />
                                {lang('google_login.btn_google')}
                            </span>
                        </span>
                    </>
                    : <a className="btn btn-white btn-lg disabled" href="#" onClick={() => console.log('in construction')}>
                        <span className="d-flex justify-content-center align-items-center">
                            {/* : <a className="btn btn-white btn-lg" href="#" onClick={() => google_window_login(setLoading)}> */}
                            <img className="avatar avatar-xss me-2" src="./assets/img/svg/brands/google-icon.svg"
                                alt="google_brand" />
                            {/* {lang('google_login.btn_google')} */}
                            Comming soon
                        </span>
                    </a>
            }


            <a className="js-animation-link btn btn-primary btn-lg" href="#" data-hs-show-animation-options="{
                &quot;targetSelector&quot;: &quot;#signupModalFormLoginWithEmail&quot;,
                &quot;groupName&quot;: &quot;idForm&quot;
                }" data-hs-show-animation-link-group="idForm" onClick={() => setContent(2)}>
                {lang('google_login.btn_email')}
            </a>
        </div>
    </div>
};

const GoogleRegister = ({ setContent, lang }) => {
    const [loading, setLoading] = useState(false);


    return <div data-hs-show-animation-target-group="idForm">
        <div className="text-center mb-7">
            <h2>{lang('google_register.title')}</h2>
            <p>{lang('google_register.p')}
                <span className="js-animation-link link text-primary" style={{ cursor: "pointer" }} role="button"
                    data-hs-show-animation-options="{
                    &quot;targetSelector&quot;: &quot;#signupModalFormLogin&quot;,
                    &quot;groupName&quot;: &quot;idForm&quot;
                }"
                    onClick={() => setContent(0)}
                    data-hs-show-animation-link-group="idForm"> {lang('google_register.link_login')}</span>
            </p>
        </div>

        <div className="d-grid gap-3">
            {
                loading
                    ? <>
                        <span className="btn btn-white btn-lg" style={{ cursor: "progress" }}>
                            <span className="d-flex justify-content-center align-items-center">
                                <div className="spinner-border spinner-border-sm text-primary" role="status">
                                </div>&nbsp;&nbsp;
                                <img className="avatar avatar-xss me-2" src="./assets/img/svg/brands/google-icon.svg"
                                    alt="google_brand" />
                                {lang('google_register.btn_google')}
                            </span>
                        </span>
                    </>
                    :
                    <a className="btn btn-white btn-lg disabled" href="#" onClick={() => console.log('In contruction')}>
                        {/* <a className="btn btn-white btn-lg" href="#" onClick={() => google_window_login(setLoading)}> */}
                        <span className="d-flex justify-content-center align-items-center">
                            <img className="avatar avatar-xss me-2" src="./assets/img/svg/brands/google-icon.svg"
                                alt="google_brand" />
                            {/* {lang('google_register.btn_google')} */}
                            Coming soon
                        </span>
                    </a>
            }

            <a className="js-animation-link btn btn-primary btn-lg" href="#" data-hs-show-animation-options="{
                &quot;targetSelector&quot;: &quot;#signupModalFormSignupWithEmail&quot;,
                &quot;groupName&quot;: &quot;idForm&quot;
                }" data-hs-show-animation-link-group="idForm" onClick={() => setContent(3)}>{lang('google_register.btn_email')}</a>

            <div className="text-center">
                <p className="small mb-0">{lang('google_register.text_terms')} <a href="/terms">{lang('google_register.text_link')}</a>
                </p>
            </div>
        </div>
    </div >
};
