import React, { useState } from 'react';
import { Input as MyInput } from '../../components/myValidatorFields';
import Button from 'react-validation/build/button';
import Form from "react-validation/build/form";
import { Toast, passwordFormatReg } from '../../helpers';
import { useDispatch } from 'react-redux';
import { userActions } from '../../store';
import { HashLink as Link } from 'react-router-hash-link';



const UpdatePassword = ({ lang }) => {

    const [showLoading, setShowLoading] = useState(false);
    // const [active, setActive] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);


    const [passSize, setPassSize] = useState(null);
    const [passLower, setPassLower] = useState(null);
    const [passUpper, setPassUpper] = useState(null);
    const [passNumber, setPassNumber] = useState(null);
    const [passSymbol, setPassSymbol] = useState(null);


    const [checkPassConfirm, setCheckPassConfirm] = useState(null);


    const dispatch = useDispatch();

    const _rrequired = value => {
        if (!value) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    {lang('items.security.password.errors.require')}
                </div>
            );
        }
    };

    const handleOldPasswordChange = e => {
        setOldPassword(e.target.value);
    };

    const handlePasswordChange = e => {
        setPassword(e.target.value);

        setPassSize(passwordFormatReg(e.target.value).size);
        setPassLower(passwordFormatReg(e.target.value).lower);
        setPassSymbol(passwordFormatReg(e.target.value).special);
        setPassNumber(passwordFormatReg(e.target.value).number);
        setPassUpper(passwordFormatReg(e.target.value).upper);

    };

    const handleConfirmPassChange = e => {
        setConfirmPass(e.target.value);
        setCheckPassConfirm(comparePasswords(password, e.target.value));
    };



    const handleSubmit = event => {
        event.preventDefault();
        setShowLoading(true);

        let ok = passLower && passUpper && passSize && passSymbol && passNumber;

        if (ok && !checkPassConfirm) {
            setTimeout(() => {
                setShowLoading(false);
                dispatch(userActions.resetPassword({ oldpassword: oldPassword, password, password_confirmation: confirmPass }));
            }, 4000);
        }
    };

    const handleResetForm = () => {
        setOldPassword('');
        setPassword('');
        setConfirmPass('');
    }





    return <div className="card">
        <div className="card-header border-bottom">
            <h5 className="card-header-title">{lang('items.security.password.title')}</h5>
        </div>

        <div className="card-body">

            <Form onSubmit={e => handleSubmit(e)} >
                {/* old password */}
                <div className="row mb-4">
                    <label className="col-sm-3 col-form-label form-label" htmlFor="signupModalFormLoginPassword">{lang('items.security.password.form.lb_cur_pass')}</label>
                    <div className="col-sm-9">
                        <MyInput
                            type={showOldPassword ? 'text' : 'password'}
                            className="form-control form-control-lg"
                            name="old_password"
                            id="signupModalFormLoginPassword"
                            placeholder={lang('items.security.password.form.pl_hld_cur_pass')}
                            aria-label={lang('items.security.password.form.pl_hld_cur_pass')}
                            minLength="8"
                            value={oldPassword}
                            validations={[_rrequired]}
                            onChange={e => handleOldPasswordChange(e)}
                        >
                            <span
                                id="changePassTarget"
                                className="input-group-append input-group-text"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setShowOldPassword(!showOldPassword)}
                            >
                                <i id="changePassIcon" className={showOldPassword ? 'bi-eye' : 'bi-eye-slash'}></i>
                            </span>
                        </MyInput>
                    </div>
                </div>

                {/* password */}
                <div className="row mb-4">
                    <label className="col-sm-3 col-form-label form-label" htmlFor="signupModalFormLoginPassword">{lang('items.security.password.form.lb_new_pass')}</label>
                    <div className="col-sm-9">
                        <MyInput
                            type={showPassword ? 'text' : 'password'}
                            className="form-control form-control-lg"
                            name="password"
                            id="signupModalFormLoginPassword"
                            placeholder={lang('items.security.password.form.pl_hld_pass')}
                            aria-label={lang('items.security.password.form.pl_hld_pass')}
                            minLength="8"
                            value={password}
                            validations={[_rrequired]}
                            onChange={e => handlePasswordChange(e)}
                        >
                            <span
                                id="changePassTarget"
                                className="input-group-append input-group-text"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                <i id="changePassIcon" className={showPassword ? 'bi-eye' : 'bi-eye-slash'}></i>
                            </span>
                        </MyInput>
                    </div>
                </div>

                {/* Confirm password */}
                <div className="row mb-4">
                    <label className="col-sm-3 col-form-label form-label" htmlFor="ConfirmLoginPassword">{lang('items.security.password.form.lb_cf_new_pass')}</label>
                    <div className="col-sm-9">
                        <div className="mb-3">
                            <MyInput
                                type={showPasswordConfirm ? 'text' : 'password'}
                                className="form-control form-control-lg"
                                name="confirmpassword"
                                id="ConfirmLoginPassword"
                                placeholder={lang('items.security.password.form.pl_hld_pass')}
                                aria-label={lang('items.security.password.form.pl_hld_pass')}
                                minLength="8"
                                value={confirmPass}
                                validations={[_rrequired]}
                                onChange={(e => handleConfirmPassChange(e))}
                            >
                                <span
                                    id="changePassTarget"
                                    className="input-group-append input-group-text"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                >
                                    <i id="changePassIcon" className={showPasswordConfirm ? 'bi-eye' : 'bi-eye-slash'}></i>
                                </span>
                            </MyInput>
                            {
                                checkPassConfirm
                                    ? <small className='text-danger' style={{ fontStyle: 'italic' }}>{lang('items.security.password.form.small')}</small>
                                    : null
                            }
                        </div>
                        <h5>{lang('items.security.password.form.check_pass.title')}:</h5>

                        <p className="card-text small">{lang('items.security.password.form.check_pass.subtitle')}:</p>

                        <ul className="small" style={{ listStyle: 'none' }}>
                            <li><i className={`fas fa-${passSize !== null ? passSize ? 'check' : 'times' : ''}-circle text-${passSize ? 'success' : 'danger'}`}></i> {lang('items.security.password.form.check_pass.size', { length: 8 })}</li>
                            <li><i className={`fas fa-${passLower !== null ? passLower ? 'check' : 'times' : ''}-circle text-${passLower ? 'success' : 'danger'}`}></i> {lang('items.security.password.form.check_pass.upper')}</li>
                            <li><i className={`fas fa-${passUpper !== null ? passUpper ? 'check' : 'times' : ''}-circle text-${passUpper ? 'success' : 'danger'}`}></i> {lang('items.security.password.form.check_pass.lower')}</li>
                            <li><i className={`fas fa-${passNumber !== null ? passNumber ? 'check' : 'times' : ''}-circle text-${passNumber ? 'success' : 'danger'}`}></i> {lang('items.security.password.form.check_pass.number')}</li>
                            <li><i className={`fas fa-${passSymbol !== null ? passSymbol ? 'check' : 'times' : ''}-circle text-${passSymbol ? 'success' : 'danger'}`}></i> {lang('items.security.password.form.check_pass.symbol')}</li>
                        </ul>

                    </div>
                </div>

                <div className="d-flex justify-content-end gap-3">
                    {/* <button type="submit" className="btn btn-primary btn-lg">Submit</button> */}
                    {
                        ((oldPassword.length > 7 && password.length > 7 && confirmPass.length > 7)
                            && (password === confirmPass)
                            && passSize
                            && passLower
                            && passUpper
                            && passNumber
                            && passSymbol)
                            ? !showLoading
                                ? <>
                                    <button className="btn btn-white" onClick={() => handleResetForm()}>{lang('items.security.password.form.cancel')}</button>
                                    <Button type="submit" className="btn btn-primary">
                                        {lang('items.security.password.form.btn')}
                                    </Button>
                                </>
                                : <button type="button" className="btn btn-primary" disabled={showLoading}>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    {lang('items.security.password.form.btn_load')}
                                </button>
                            : <>
                                <button className="btn btn-white" onClick={() => handleResetForm()}>{lang('items.security.password.form.cancel')}</button>
                                {/* <button type="button" className="btn btn-primary" disabled={true}>
                                    Update Password
                                </button> */}
                            </>
                    }
                </div>
            </Form>
        </div>
    </div>;
}

export default UpdatePassword;


const comparePasswords = (p1, p2) => {
    if (p2.length >= p1.length) {
        return p2 !== p1;
    }
    return false;
};