import React from 'react';

const AuthLeftCover = ({lang}) => {    
    return (
        <div className="col-lg-5 col-xl-4 d-none d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-primary" style={{ backgroundImage: `url("./assets/img/svg/components/wave-pattern-light.svg")` }}>
            <div className="flex-grow-1 p-5">
                <figure className="text-center">
                    <div className="mb-4">
                        <img className="avatar avatar-xl avatar-4x3" src={'./assets/img/svg/brands/mailchimp-white.svg'} alt="Logo" />
                    </div>

                    <blockquote className="blockquote blockquote-light">“ {lang('col.desc')} ”</blockquote>
                    {/* <blockquote className="blockquote blockquote-light">“ It has many landing page variations to choose from, which one is always a big advantage. ”</blockquote> */}

                    <figcaption className="blockquote-footer blockquote-light">
                        <div className="mb-3">
                            <img className="avatar avatar-circle" src={require("../assets/img/160x160/img9.jpg")} alt="DescriptonImage" />
                        </div>

                        Lida Reidy
                        <span className="blockquote-footer-source">Project Manager | Mailchimp</span>
                    </figcaption>
                </figure>

                <div className="position-absolute start-0 end-0 bottom-0 text-center p-5">
                    <div className="row justify-content-center">
                        <div className="col text-center py-3">
                            <img className="avatar avatar-lg avatar-4x3" src={require("../assets/img/logo/anc.png")} title='A nous le code' alt="Logo" />
                        </div>

                        <div className="col text-center py-3">
                            <img className="avatar avatar-lg avatar-4x3" src={require("../assets/img/logo/tth_hroi.png")} title='TeamThread' alt="Logo" />
                        </div>

                        <div className="col text-center py-3">
                            <img className="avatar avatar-lg avatar-4x3" src={require("../assets/img/logo/job.png")} title='HappyJob' alt="Logo" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AuthLeftCover;
