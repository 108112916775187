import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Toast, history } from '../helpers';
import { fetchWrapper } from '../helpers/fetch-wrapper';
import appConfig from '../config';
import $ from 'jquery';

// create slice

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        token: null,
        error: null,
        temp_token: null,
    }
}

function createReducers() {
    return {
        logout,
        resetSlice,
        addToken,
    };

    function resetSlice(state) { return createInitialState(); };
    function addToken(state) { state.token = sessionStorage.getItem('nekot'); };


    function logout(state) {
        state.token = null;
        state.temp_token = null;
        localStorage.removeItem('token');
        localStorage.removeItem('ftoken');
        localStorage.removeItem('nekot');
        localStorage.removeItem('emailResetPass');
        localStorage.removeItem('admin_project_actions');
        localStorage.removeItem('admin_user_actions');
        localStorage.removeItem('emailLogin');
        localStorage.removeItem('user');
        localStorage.removeItem('persist:root');
        sessionStorage.removeItem('applyJob');
        history.navigate('/');
    }
}

function createExtraActions() {
    const baseUrl = `${appConfig.apiUrl}`;
    const basePass = `${appConfig.apiUrl}/password`;

    return {
        login: login(),
        google_login_callback: google_login_callback(),
        register: register(),
        forgot: forgot(),
        passActiveCode: passActiveCode(),
        resetPassword: resetPassword(),
        resendCode: resendCode(),
        signout: signout(),
        waitConfirmDevicePage: waitConfirmDevicePage(),
    };

    function login() {
        return createAsyncThunk(
            `${name}/signin`,
            async ({ email, password, ip, device, fingerprint }) => await fetchWrapper.post(`${baseUrl}/login`, { email, password, ip, device, fingerprint })
        );
    }

    function google_login_callback() {
        return createAsyncThunk(
            `${name}/google/callback`,
            async ({ data, device, fingerprint, ip }) => await fetchWrapper.post(`${baseUrl}/auth/callback${data}`, { device, fingerprint, ip })
        );
    }

    function register() {
        return createAsyncThunk(
            `${name}/signup`,
            async ({ name, first_name, email, password, password_confirmation, ip, device, confidential }) => {

                //sessionStorage.setItem('r&n', btoa(JSON.stringify({ email, password })));

                return await fetchWrapper.post(
                    `${baseUrl}/register`,
                    { name, first_name, email, password, password_confirmation, ip, device, confidential }
                )
            }
        );
    }

    function signout() {
        return createAsyncThunk(
            `${name}/logout`,
            async ({ deviceId }) => await fetchWrapper.get(`${baseUrl}/logout/${deviceId}`)
        );
    }

    function forgot() {
        return createAsyncThunk(
            `${name}/password/reset`,
            async ({ email, ip }) => await fetchWrapper.post(
                `${basePass}/reset`,
                { email, ip }
            )
        );
    }

    function passActiveCode() {
        return createAsyncThunk(
            `${name}/password/active/code`,
            async ({ pin }) => await fetchWrapper.post(`${basePass}/active/code`, { pin })
        );
    }

    function resetPassword() {
        return createAsyncThunk(
            `${name}/update/password`,
            async ({ password, password_confirmation, ip }) => await fetchWrapper.post(`${baseUrl}/update/password/reset`, { password, password_confirmation, ip })
        );
    }

    function resendCode() {
        return createAsyncThunk(
            `${name}/resennt/password/reset`,
            async ({ email }) => await fetchWrapper.post(
                `${baseUrl}/resent/password/active/code`,
                { email }
            )
        );
    }

    function waitConfirmDevicePage() {
        return createAsyncThunk(
            `${name}/wait/confirm/device/page`,
            async ({ deviceId }) => await fetchWrapper.get(`${baseUrl}/device-is-confimr/${deviceId}`)
        );
    }

}

function createExtraReducers() {
    return (builder) => {
        login();
        register();
        forgot();
        passActiveCode();
        newPass();
        resendCode();
        signout();
        waitConfirmDevicePage();
        google_login_callback();


        function login() {
            var { pending, fulfilled, rejected } = extraActions.login;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload.data;

                    sessionStorage.setItem('init-btn', 0);
                    setTimeout(() => {
                        sessionStorage.removeItem('init-btn');
                        // window.location.reload();
                    }, 1800);

                    if (data.userdevice.length !== 0 && data.userdevice.filter(device => device.id === data.current_device.id).length === 1) {
                        Toast('success', "Successfully Logged in", 'tr', false, false, false, 3800, false);

                        state.token = data.token;

                        setTimeout(() => {
                            // history.navigate('/');
                            window.location.href = '/';
                        }, 3000);

                    } else {
                        Toast('info', data.message, 'tr', false, false, false, 3800, false);

                        sessionStorage.setItem('token', data.token);
                        sessionStorage.setItem('deviceId', data.current_device);
                        sessionStorage.setItem('message', data.message);
                        // console.log('device is not confirm');

                        setTimeout(() => {
                            window.location.href = '/wait-confirm-device';
                        }, 2000);
                    }
                })
                .addCase(rejected, (state, action) => {
                    console.log("login", action.error.message);
                    Toast('error', action.error.message.startsWith('Internal') ? "Somthing Wrong!" : action.error.message, 'tr', false, false, false, 6000, false);
                    sessionStorage.setItem('init-btn', 0);
                    setTimeout(() => {
                        sessionStorage.removeItem('init-btn');
                        // window.location.reload();
                    }, 1800);
                })
        };

        function google_login_callback() {
            var { pending, fulfilled, rejected } = extraActions.google_login_callback;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload.data;

                    if (data.userdevice.length !== 0 && data.userdevice.filter(device => device.id === data.current_device.id).length === 1) {
                        Toast('success', "Successfully Logged in", 'tr', false, false, false, 3800, false);

                        state.token = data.token;

                        setTimeout(() => {
                            history.navigate('/');
                        }, 3000);

                    } else {
                        Toast('info', data.message, 'tr', false, false, false, 3800, false);

                        sessionStorage.setItem('token', data.token);
                        sessionStorage.setItem('deviceId', data.current_device);
                        sessionStorage.setItem('message', data.message);

                        setTimeout(() => {
                            window.location.href = '/wait-confirm-device';
                        }, 2000);
                    }
                })
                .addCase(rejected, (state, action) => {
                    console.log("login", action.error.message);
                    Toast('error', action.error.message, 'tr', false, false, false, 6000, false);
                })
        };

        function register() {
            var { pending, fulfilled, rejected } = extraActions.register;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload;

                    sessionStorage.setItem('init-btn', 0);
                    setTimeout(() => {
                        sessionStorage.removeItem('init-btn');
                        // window.location.reload();
                    }, 1800);

                    if (data.status === 'success') {

                        sessionStorage.setItem('token', data.data.token);
                        Toast('success', 'Account created successfully!', 'tr', false, false, false, 6000, false);

                        setTimeout(() => {
                            $('.modal-backdrop').remove()
                        }, 4000);

                        setTimeout(() => {
                            history.navigate('/email-validation');
                        }, 3000);
                    }
                })
                .addCase(rejected, (state, action) => {
                    Toast('error', action.error.message, 'tr', false, false, false, 6000, false);
                    state.error = action.error;
                    console.log("register", action.error.message);
                    sessionStorage.setItem('init-btn', 0);
                    setTimeout(() => {
                        sessionStorage.removeItem('init-btn');
                        // window.location.reload();
                    }, 1800);

                })
        };

        //Recieve form data from the forgot-password form
        function forgot() {
            var { pending, fulfilled, rejected } = extraActions.forgot;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload;
                    sessionStorage.setItem('init-btn', 0);
                    setTimeout(() => {
                        sessionStorage.removeItem('init-btn');
                        // window.location.reload();
                    }, 1800);

                    if (data.status === "success") {
                        localStorage.setItem('token', data.data);
                        sessionStorage.setItem('sanitizer', 1);
                        Toast('success', 'Pin code sent successfully!', 'tr', false, false, false, 5000, false);

                        setTimeout(() => {
                            history.navigate('/cover-reset-pass');
                        }, 3000);
                        setTimeout(() => {
                            $('.modal-backdrop').remove();
                        }, 4000);
                    }
                })
                .addCase(rejected, (state, action) => {
                    Toast('error', action.error.message, 'tr', false, false, false, 6000, false);
                    state.error = action.error;
                    sessionStorage.setItem('init-btn', 0);
                    setTimeout(() => {
                        sessionStorage.removeItem('init-btn');
                        // window.location.reload();
                    }, 1800);
                    console.log("forgot", action.error.message);

                })
        }

        //Reciev form data from the digit-form
        function passActiveCode() {
            var { pending, fulfilled, rejected } = extraActions.passActiveCode;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload;

                    if (data.status === "success") {
                        sessionStorage.removeItem('sanitizer');
                        localStorage.removeItem('emailResetPass');
                        sessionStorage.setItem('where', 'reset-password');
                        Toast('success', 'Pin code verified successfully!', 'tr', false, false, false, 6000, false);
                        setTimeout(() => {
                            history.navigate('/reset-password');
                        }, 1800);
                    }
                })
                .addCase(rejected, (state, action) => {
                    Toast('error', action.error.message, 'tr', false, false, false, 6000, false);
                    state.error = action.error;
                })
        }

        //Reciev form data from the digit-form
        function newPass() {
            var { pending, fulfilled, rejected } = extraActions.resetPassword;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload;

                    if (data.status === "success") {
                        Toast('success', 'Password updated successfully!', 'tr', false, false, false, 6000, false);
                        localStorage.removeItem('token');
                        localStorage.removeItem('emailResetPass');
                        sessionStorage.removeItem('sanitizer');
                        sessionStorage.removeItem('where');


                        setTimeout(() => {
                            history.navigate('/signin');
                        }, 2500);
                    }
                })
                .addCase(rejected, (state, action) => {
                    Toast('error', action.error.message, 'tr', false, false, false, 6000, false);
                    console.log("new pass", action.error.message);
                    state.error = action.error;
                })
        }

        function resendCode() {
            var { pending, fulfilled, rejected } = extraActions.resendCode;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload;

                    if (data.status === "success") {
                        localStorage.setItem('token', data.data.token);
                        Toast('success', data.data.message, 'tr', false, false, false, 6000, false);

                        setTimeout(() => {
                            //Redirect to the reset-password form whwn the code is valid
                            history.navigate('/cover-reset-pass');
                        }, 1800);
                    }
                })
                .addCase(rejected, (state, action) => {
                    Toast('error', action.error.message, 'tr', false, false, false, 6000, false);
                    state.error = action.error;
                    console.log("resend code", action.error.message);

                })
        }


        function signout() {
            var { pending, fulfilled, rejected } = extraActions.signout;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload;


                    Toast('success', data.message, 'tr', false, false, false, 3000, false);

                    setTimeout(() => {
                        // history.navigate('/');
                        window.location.href = '/';
                    }, 3100);
                })
                .addCase(rejected, (state, action) => {
                    // state.error = action.error;
                    Toast('error', action.error.message, 'tr', false, false, false, 6000, false);
                    console.log("logout", action.error.message);
                    // history.navigate('/signin');
                })
        };

        function waitConfirmDevicePage() {
            var { pending, fulfilled, rejected } = extraActions.waitConfirmDevicePage;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload;

                    let tok = sessionStorage.getItem('token');


                    if (data.status === 'success') {
                        Toast('success', data.data.message, 'tr', false, false, false, 3000, false);
                        state.token = tok;

                        setTimeout(() => {

                            // Il faut penser a retirer tous les session storage

                            sessionStorage.removeItem('token');
                            sessionStorage.removeItem('deviceId');
                            sessionStorage.removeItem('message'); sessionStorage.removeItem('token');
                            sessionStorage.removeItem('message');

                            window.location.href = '/';
                        }, 3100);
                    } else {
                        Toast('warning', data.data.message, 'tr', false, false, false, 3000, false);
                    }
                })
                .addCase(rejected, (state, action) => {
                    // state.error = action.error;
                    Toast('error', action.error.message, 'tr', false, false, false, 6000, false);
                    console.log("waitConfirmDevicePage", action.error.message);
                    // history.navigate('/signin');
                })
        };

    }
}
