import img1 from '../assets/img/team/img1.jpg';
import img2 from '../assets/img/team/img2.jpg';
import img3 from '../assets/img/team/img3.jpg';
import img4 from '../assets/img/team/img4.jpg';


const date = new Date();

let exp = parseInt(date.getFullYear()) - 2020;

const dataTeam = [
    {
        name: 'Jeff',
        last_name: 'Fisher',
        avatar: img1,
        post: 'CEO, Director',
        describe: `Responsable et develppeur front. Passionne par le developpement, j'aime donner du plaisir aux utilisateurs de par la qualite d'experience qu'ils vive en utilisation nos applications (mobile, Tv ou web)`,
        socials: [
            {
                icon: 'facebook',
                link: '#face'
            },
            {
                icon: 'twitter',
                link: '#twit'
            }
        ]
    },
    {
        name: 'Christina',
        last_name: 'Kray',
        avatar: img2,
        post: 'Project Manager',
        describe: `Responsable et develppeur front. Passionne par le developpement, j'aime donner du plaisir aux utilisateurs de par la qualite d'experience qu'ils vive en utilisation nos applications (mobile, Tv ou web)`,
        socials: [
            {
                icon: 'facebook',
                link: '#face'
            },
            {
                icon: 'twitter',
                link: '#twit'
            }
        ]
    },
    {
        name: 'Lida',
        last_name: 'Reidy',
        avatar: img3,
        post: 'CFO, Director',
        describe: `Responsable et develppeur front. Passionne par le developpement, j'aime donner du plaisir aux utilisateurs de par la qualite d'experience qu'ils vive en utilisation nos applications (mobile, Tv ou web)`,
        socials: [
            {
                icon: 'facebook',
                link: '#face'
            },
            {
                icon: 'twitter',
                link: '#twit'
            }
        ]
    },
    {
        name: 'Amy',
        last_name: 'Forren',
        avatar: img4,
        post: 'Lead Designer',
        describe: `Graphiste avec ${exp} ans d'experience dans la creation de contenu web. Curieux et ouvert d'estprit, je compte plus de 1000 visuels issus de la collaboration avec plusieurs clients. Je pense posseder le savoir-faire necessaire et indispensable pour devenir garant de l'identite visuelle d'une entreprise.`,
        socials: [
            {
                icon: 'facebook',
                link: '#face'
            },
            {
                icon: 'twitter',
                link: '#twit'
            }
        ]
    },
];

export default dataTeam;