import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import $ from 'jquery';

import { managerActions } from '../../store';
import appConfig from "../../config";



const EditTask = ({ task, project, setModal }) => {

    return (
        <div className="modal fade" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" id="edit-task" style={{ display: "none" }} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content bg-transparent">
                    <div className="modal-body">
                        <div className="card card-widget widget-user bg-white" style={{ boxShadow: '0px 0px 2px black' }}>
                            <div className="widget-user-header bg-primary">
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close" onClick={()=>setModal(<></>)}>
                                    <span aria-hidden="true">×</span>
                                </button>

                                <h3 className="widget-user-username mt-5">
                                    <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>Update The task</span>
                                </h3>
                            </div>


                            <div className="card-body">
                                <div className="row">
                                    <div className="card bg-white" style={{ width: '100%', overflowY: 'auto' }}>
                                        <div className="card-body">

                                            <TaskUpdate task={task} project={project} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default EditTask;




const TaskUpdate = ({ task, project }) => {

    const dispatch = useDispatch();

    const [data, setData] = useState({
        taskname: task.name,
        taskdescription: task.description,
        start_date: task.start_date,
        end_date: task.end_date,
        end_date: task.end_date,
    });

    const handleChange = e => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value });
    }


    const handleSubmit = event => {
        event.preventDefault();


        let infos = {
            id:task.id,
            name: data.taskname,
            description: data.taskdescription,
            start_date: data.start_date,
            end_date: data.end_date,
            projects_id: project.id,
        };
        // console.log(infos);

        dispatch(managerActions.edit_task({ task: infos }));
        // $.getJSON(appConfig.apip, function (data) {

        //     setTimeout(() => {
        //         setShowLoading(false);
        //         // dispatch(userActions.getUser());
        //     }, 4000);
        // });
    }



    return (<>
        <div className="row">
            <div className="col md-12">
                <div className="form-group">
                    <label htmlFor="taskName">Task Name:&nbsp; </label>
                    <input
                        type="text"
                        id="taskName"
                        className={`form-control bg-white`}
                        value={data.taskname}
                        onChange={handleChange}
                        name='taskname'
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="taskDescription">Task Description:&nbsp;</label>

                    <textarea
                        id="taskDescription"
                        className={`form-control bg-white`}
                        rows="4"
                        onChange={handleChange}
                        name='taskdescription'
                        value={data.taskdescription}
                    ></textarea>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="start-date">Start date</label>
                    <div className="input-group date" id="start-date" data-target-input="nearest">
                        <input type="date" value={data.start_date.split(' ')[0]} onChange={handleChange} name='start_date' className="form-control bg-white datetimepicker-input" data-target="#start-date" />
                    </div>
                </div>
            </div>


            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="end-date">End date</label>
                    <div className="input-group date" id="end-date" data-target-input="nearest">
                        <input type="date" value={data.end_date.split(' ')[0]} onChange={handleChange} name='end_date' className="form-control bg-white datetimepicker-input" data-target="#end-date" />
                    </div>
                </div>
            </div>
            <div className="col-md-8 offset-2 mt-4">
                <button className="btn btn-primary btn-block text-bold" onClick={handleSubmit}>Update</button>
            </div>
        </div>
    </>
    );
};
