import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';

import { adminActions, store } from '../../store';
import { AdminContentLoader } from '../../components';
import { EditorModules } from '../../constants';

import 'react-quill/dist/quill.snow.css';
import { calculateTime, formatDate } from 'helpers';


const InboxMailContent = () => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [contacts, setContacts] = useState([]);
    const cnts = useSelector(x => x.admin.contacts);
    const prj_idea = useSelector(x => x.admin.project_ideas);

    const [folder, setFolder] = useState('inbox');
    const [box, setBox] = useState(<Inbox setBox={null} setFolder={setFolder} />);

    useEffect(() => {
        setBox(<Inbox setBox={setBox} setFolder={setFolder} />);
        dispatch(adminActions.allcontacts());

        setTimeout(() => {
            setLoading(false)
            dispatch(adminActions.getprojectidea());
        }, 2000);

        let timer = setInterval(() => {
            setContacts([...cnts, ...prj_idea]);
        }, 200);

        let tim = setInterval(() => {
            dispatch(adminActions.allcontacts());
        }, 8000);

        return () => {
            clearInterval(tim);
            clearInterval(timer);
        };
    }, [dispatch]);

    return loading
        ? <AdminContentLoader />
        : <div className="row">
            <Folder folder={folder} setBox={setBox} setFolder={setFolder} contacts={contacts} />

            {box}
            {/* <button type="button" onClick={() => Test()}>test</button> */}
        </div>
}

export default InboxMailContent;





const Folder = ({ folder, setBox, setFolder, contacts }) => {

    // const newContacts = contacts.filter(cont => cont.road === 0);
    const newContacts = contacts;

    return <div className="col-md-3">
        {
            folder === 'inbox'
                ? <span onClick={() => setBox(<Composebox setFolder={setFolder} setBox={setBox} />)} className="btn btn-primary btn-block mb-3">Compose</span>
                : <span className="btn btn-primary btn-block mb-3" onClick={() => setBox(<Inbox setFolder={setFolder} setBox={setBox} contacts={contacts} />)}>Back to Inbox</span>
        }
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">Folders</h3>

                <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-card-widget="collapse">
                        <i className="fas fa-minus"></i>
                    </button>
                </div>
            </div>
            <div className="card-body p-0">
                <ul className="nav nav-pills flex-column">
                    <li className="nav-item active">
                        <a href="#/" className="nav-link">
                            <i className="fas fa-inbox"></i> Inbox
                            <span className="badge bg-primary float-right">{newContacts.length < 10 ? `0${newContacts.length}` : newContacts.length}</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#/" className="nav-link">
                            <i className="far fa-envelope"></i> Sent
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#/" className="nav-link">
                            <i className="far fa-file-alt"></i> Drafts
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#/" className="nav-link">
                            <i className="fas fa-filter"></i> Junk
                            <span className="badge bg-warning float-right">65</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#/" className="nav-link">
                            <i className="far fa-trash-alt"></i> Trash
                        </a>
                    </li>
                </ul>
            </div>

        </div>

    </div>
};


const Inbox = ({ setBox, setFolder }) => {

    const [contacts, setContacts] = useState([]);
    const cnts = useSelector(x => x.admin.contacts);
    const prj_idea = useSelector(x => x.admin.project_ideas);

    useEffect(() => {
        setFolder('inbox');
        let timer = setInterval(() => {
            setContacts([...cnts, ...prj_idea]);
        }, 200);

        return () => {
            clearInterval(timer);
        };
    }, [setFolder]);

    return <div className="col-md-9">
        <div className="card card-primary card-outline">
            <div className="card-header">
                <h3 className="card-title">Inbox</h3>

                <div className="card-tools">
                    <div className="input-group input-group-sm">
                        <input type="text" className="form-control" placeholder="Search Mail" />
                        <div className="input-group-append">
                            <div className="btn btn-primary">
                                <i className="fas fa-search"></i>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="card-body p-0">
                <div className="mailbox-controls">

                    <button type="button" className="btn btn-default btn-sm checkbox-toggle"><i
                        className="far fa-square"></i>
                    </button>
                    <div className="btn-group">
                        <button type="button" className="btn btn-default btn-sm">
                            <i className="far fa-trash-alt"></i>
                        </button>
                        <button type="button" className="btn btn-default btn-sm">
                            <i className="fas fa-reply"></i>
                        </button>
                        <button type="button" className="btn btn-default btn-sm">
                            <i className="fas fa-share"></i>
                        </button>
                    </div>

                    <button type="button" className="btn btn-default btn-sm">
                        <i className="fas fa-sync-alt"></i>
                    </button>
                    <div className="float-right">
                        1-50/200
                        <div className="btn-group">
                            <button type="button" className="btn btn-default btn-sm">
                                <i className="fas fa-chevron-left"></i>
                            </button>
                            <button type="button" className="btn btn-default btn-sm">
                                <i className="fas fa-chevron-right"></i>
                            </button>
                        </div>

                    </div>

                </div>
                <div className="table-responsive mailbox-messages">
                    <table className="table table-hover table-striped">
                        <tbody>

                            {
                                contacts.map((contact, key) => {
                                    return <tr key={key}>
                                        <td>
                                            <i className="far fa-envelope"></i> - {key + 1}
                                        </td>
                                        <td className="mailbox-star">
                                            {/* <a href="#/"> <i className="fas fa-star text-warning"></i></a> */}
                                        </td>
                                        <td className="mailbox-name" onClick={() => setBox(<Readbox setFolder={setFolder} setBox={setBox} contact={contact} />)}><a href='#/'>{contact.last_name} {contact.first_name}</a></td>
                                        <td className="mailbox-subject">{contact.message !== undefined ? <><b>{contact.subject}</b> - {contact.message.slice(0, 20)}...</> : <b>Project Idea</b>}
                                        </td>
                                        <td className="mailbox-attachment">&nbsp;</td>
                                        <td className="mailbox-date">{calculateTime(contact.created_at)} ago</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>

                </div>

            </div>

            <div className="card-footer p-0">
                <div className="mailbox-controls">

                    <button type="button" className="btn btn-default btn-sm checkbox-toggle">
                        <i className="far fa-square"></i>
                    </button>
                    <div className="btn-group">
                        <button type="button" className="btn btn-default btn-sm">
                            <i className="far fa-trash-alt"></i>
                        </button>
                        <button type="button" className="btn btn-default btn-sm">
                            <i className="fas fa-reply"></i>
                        </button>
                        <button type="button" className="btn btn-default btn-sm">
                            <i className="fas fa-share"></i>
                        </button>
                    </div>

                    <button type="button" className="btn btn-default btn-sm">
                        <i className="fas fa-sync-alt"></i>
                    </button>
                    <div className="float-right">
                        1-50/200
                        <div className="btn-group">
                            <button type="button" className="btn btn-default btn-sm">
                                <i className="fas fa-chevron-left"></i>
                            </button>
                            <button type="button" className="btn btn-default btn-sm">
                                <i className="fas fa-chevron-right"></i>
                            </button>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>

};


const Composebox = ({ setFolder }) => {

    const [load, setload] = useState(true);

    const [text, setText] = useState('');


    const handleTextChange = value => {
        setText(value);
    };

    useEffect(() => {
        setFolder('composebox');


        setTimeout(() => {
            setload(false);
        }, 3000);


        return () => {

        };
    }, [setFolder]);


    return <div className="col-md-9">
        <div className="card card-primary card-outline">
            <div className="card-header">
                <h3 className="card-title">Compose New Message</h3>
            </div>
            <div className="card-body">
                <div className="form-group">
                    <input className="form-control" placeholder="To:" type='email' />
                </div>
                <div className="form-group">
                    <input className="form-control" placeholder="Subject:" type='text' />
                </div>
                <div className="form-group" style={{ borderRadius: 5 }}>
                    <ReactQuill
                        theme="snow"
                        modules={EditorModules}
                        onChange={handleTextChange}
                        placeholder='Write your text message here'
                        className='bg-white'
                        style={{ borderRadius: 5 }}
                    />


                </div>
                <div className="form-group">
                    <div className="btn btn-default btn-file">
                        <i className="fas fa-paperclip"></i> Attachment
                        <input type="file" name="attachment" />
                    </div>
                    <p className="help-block">Max. 32MB</p>
                </div>
            </div>
            <div className="card-footer">
                <div className="float-right">
                    <button type="button" className="btn btn-default mr-2"><i className="fas fa-pencil-alt"></i> Draft</button>
                    <button type="submit" className="btn btn-primary"><i className="fas fa-paper-plane"></i> Send</button>
                </div>
                <button type="reset" className="btn btn-default"><i className="fas fa-times"></i> Discard</button>
            </div>
        </div>
    </div>
};


const Readbox = ({ setFolder, contact }) => {

    useEffect(() => {
        setFolder('readbox');
        return () => {
        };
    }, [setFolder])

    return <div className="col-md-9">
        <div className="card card-primary card-outline">
            <div className="card-header">
                <h3 className="card-title">Read Mail</h3>

                <div className="card-tools">
                    <a href="#/" className="btn btn-tool" title="Previous"><i className="fas fa-chevron-left"></i></a>
                    <a href="#/" className="btn btn-tool" title="Next"><i className="fas fa-chevron-right"></i></a>
                </div>
            </div>
            <div className="card-body p-0">
                <div className="mailbox-read-info">
                    <h5>{contact.subject || "Project Idea"}</h5>
                    <h6>From: {contact.email}
                        <span className="mailbox-read-time float-right">{formatDate(contact.created_at, true)}</span>
                    </h6>
                </div>
                {/* <div className="mailbox-controls with-border text-center">
                    <div className="btn-group">
                        <button type="button" className="btn btn-default btn-sm" data-container="body" title="Delete">
                            <i className="far fa-trash-alt"></i>
                        </button>
                        <button type="button" className="btn btn-default btn-sm" data-container="body" title="Reply">
                            <i className="fas fa-reply"></i>
                        </button>
                        <button type="button" className="btn btn-default btn-sm" data-container="body" title="Forward">
                            <i className="fas fa-share"></i>
                        </button>
                    </div>
                    <button type="button" className="btn btn-default btn-sm" title="Print">
                        <i className="fas fa-print"></i>
                    </button>
                </div> */}
                <div className="mailbox-read-message" >
                    {
                        contact.message !== undefined
                            ? <p>{contact.message}</p>
                            : <>
                                <div>
                                    <div className="row">
                                        <div className="col-md-8 offset-2">
                                            <div className="card card-bordered h-100">
                                                <div className="card-body">
                                                    <h3>{contact.first_name} {contact.last_name} </h3>

                                                    <address className="text-body">
                                                        <i className="fa fa-envelope"></i>&nbsp;<span className="text-primary">{contact.email}</span><br />
                                                        <i className="fa fa-phone"></i>&nbsp;{contact.phone}<br />
                                                        <i className="fas fa-building"></i>&nbsp;{contact.company}<br />
                                                        <i className="fa fa-university"></i>&nbsp;{contact.budget} ({contact.currency})<br />
                                                        {/* UK <img className="avatar avatar-xss avatar-circle me-1" src="../assets/vendor/flag-icon-css/flags/1x1/gb.svg" alt="Great Britain Flag" /> */}
                                                    </address>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-8 offset-2">
                                            <div className="card card-bordered h-100">
                                                <div className="card-body">
                                                    {contact.details}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
            {/*<div className="card-footer bg-white">
                <ul className="mailbox-attachments d-flex align-items-stretch clearfix">
                    <li>
                        <span className="mailbox-attachment-icon"><i className="far fa-file-pdf"></i></span>

                        <div className="mailbox-attachment-info">
                            <a href="#/" className="mailbox-attachment-name"><i className="fas fa-paperclip"></i>
                                Sep2014-report.pdf</a>
                            <span className="mailbox-attachment-size clearfix mt-1">
                                <span>1,245 KB</span>
                                <a href="#/" className="btn btn-default btn-sm float-right download-attach-file-btn"><i
                                    className="fas fa-cloud-download-alt"></i></a>
                            </span>
                        </div>
                    </li>
                    <li>
                        <span className="mailbox-attachment-icon"><i className="far fa-file-word"></i></span>

                        <div className="mailbox-attachment-info">
                            <a href="#/" className="mailbox-attachment-name"><i className="fas fa-paperclip"></i> App
                                Description.docx</a>
                            <span className="mailbox-attachment-size clearfix mt-1">
                                <span>1,245 KB</span>
                                <a href="#/" className="btn btn-default btn-sm float-right download-attach-file-btn"><i
                                    className="fas fa-cloud-download-alt"></i></a>
                            </span>
                        </div>
                    </li>
                </ul>
            </div>*/}
            <div className="card-footer">
                <div className="float-right">
                    <button type="button" className="btn btn-default"><i className="fas fa-reply"></i> Reply</button>
                    <button type="button" className="btn btn-default"><i className="fas fa-share"></i> Forward</button>
                </div>
                <button type="button" className="btn btn-default"><i className="far fa-trash-alt"></i> Delete</button>
                <button type="button" className="btn btn-default"><i className="fas fa-print"></i> Print</button>
            </div>
        </div>
    </div>
};
