import React, { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Slang, Toast, getFileFromBack } from '../helpers';
import config from '../config';
import { useSelector, useDispatch } from 'react-redux';
import { adminActions, authActions, managerActions, store, userActions } from '../store';
import { AuthModal } from '../components/modals';

const TopBar = ({ setModal }) => {

    const t = Slang('topbar');
    const t_modals = Slang('topbar.modals');

    // const user = useSelector(x => x.user.user);

    const [here, setHere] = useState(window.location.pathname);
    const [usr, setUsr] = useState(store.getState().user.user);

    const [thisAgent, setThisAgent] = useState(null);
    const [avatar, setAvatar] = useState("");


    const authUser = useSelector(x => x.auth.token ? x.user.user : false);
    const devices = useSelector(x => x.user.devices);
    const dispatch = useDispatch();


    useEffect(() => {
        setHere(window.location.pathname);
        setThisAgent(window.navigator.userAgent);

        // setUsr(user);
        // setAvatar(user.avatar);

        

        let tim = setInterval(() => {
            setUsr(store.getState().user.user);
            //setUserProjest(store.getState().user.allprojects.filter(proj => proj.user_id === store.getState().user.user.id));
            setAvatar(() => {
                if (store.getState().user.user.avatar !== undefined) {
                    return getFileFromBack(store.getState().user.user.avatar)
                } else {
                    return getFileFromBack(config.defaultAvatar);
                }
            });
        }, 200);
    }, []);

    const handleLogout = () => {

        Toast('info', 'See you next time!', 'tr', true, true, true, 2800);

        // console.log({ deviceId: store.getState().user.devices.filter(device => device.payload.user_agent === thisAgent)[0].id });

        setTimeout(() => {
            dispatch(authActions.signout({ deviceId: devices.filter(device => device.payload.info.ua === thisAgent)[0].id }));
            dispatch(authActions.logout());
            dispatch(managerActions.logout());
            dispatch(userActions.logout());
            dispatch(adminActions.logout());
        }, 3000);
    };



    return (
        <div className="container navbar-topbar bg-transparent">
            <nav className="js-mega-menu navbar-nav-wrap">
                <button className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#topbarNavDropdown" aria-controls="topbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="d-flex justify-content-between align-items-center small">
                        <span className="navbar-toggler-text">Menu</span>

                        <span className="navbar-toggler-default">
                            <i className="bi-chevron-down ms-2"></i>
                        </span>
                        <span className="navbar-toggler-toggled">
                            <i className="bi-chevron-up ms-2"></i>
                        </span>
                    </span>
                </button>

                <div id="topbarNavDropdown" className="navbar-nav-wrap-collapse collapse navbar-collapse navbar-topbar-collapse">
                    <div className="navbar-toggler-wrapper">
                        <div className="navbar-topbar-toggler d-flex justify-content-between align-items-center">
                            <span className="navbar-toggler-text small">Menu</span>

                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#topbarNavDropdown" aria-controls="topbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="bi-x"></i>
                            </button>
                        </div>
                    </div>

                    <ul className="navbar-nav">

                        <li className="hs-has-mega-menu nav-item"
                            data-hs-mega-menu-item-options='{
                            "desktop": {
                                "maxWidth": "20rem"
                            }
                        }'>
                            {
                                !authUser
                                    ? <>
                                        <span
                                            onMouseOver={() => setModal(<AuthModal lang={t_modals} setModal={setModal} />)}
                                            id="docsMegaMenu"
                                            data-bs-toggle="modal"
                                            data-bs-target="#signupModal"
                                            className={`d-flex hs-mega-menu-invoker nav-link ${here === '/blog' ? 'text-light' : ''}`}
                                            role="button"
                                            style={{ display: 'flex', alignItems: 'center' }}
                                        >
                                            <i className="fa fa-lock"></i>&nbsp; {t('title')}
                                        </span>
                                        <div className="hs-mega-menu" aria-labelledby="docsMegaMenu"></div>
                                    </>
                                    : <>
                                        <span id="docsMegaMenu" className={`hs-mega-menu-invoker nav-link ${here === '/blog' ? 'text-light' : ''}`} role="button" onMouseOver={() => dispatch(userActions.getdevices({ user_id: usr.id }))}>
                                            {`${usr.id ? usr.first_name : ''}`} &nbsp;
                                            <img src={avatar} alt="user_photo" className='avatar avatar-xs avatar-circle' />
                                        </span>

                                        <div className="hs-mega-menu hs-position-right dropdown-menu" aria-labelledby="docsMegaMenu" style={{ minWidth: "20rem" }}>
                                            
                                            {
                                                usr.role_id === 1
                                                    ? (<>
                                                        <Link className="navbar-dropdown-menu-media-link" to='/dashboard' target='_blank' >
                                                            <div className="d-flex">
                                                                <div className="flex-shrink-0">
                                                                    <span className="svg-icon svg-icon-sm text-primary">
                                                                        <i className="fas fa-tachometer-alt" style={{ fontSize: '1.5em' }}></i>
                                                                    </span>
                                                                </div>

                                                                <div className="flex-grow-1 ms-3">
                                                                    <span className="navbar-dropdown-menu-media-title">Dashboard </span>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        <div className="dropdown-divider"></div>
                                                    </>)
                                                    : usr.role_id === 2
                                                        ? (<>
                                                            <Link className="navbar-dropdown-menu-media-link" to='/manager' target="_blank">
                                                                <div className="d-flex">
                                                                    <div className="flex-shrink-0">
                                                                        <span className="svg-icon svg-icon-sm text-primary">
                                                                            <i className="fas fa-tachometer-alt" style={{ fontSize: '1.5em' }}></i>
                                                                        </span>
                                                                    </div>

                                                                    <div className="flex-grow-1 ms-3">
                                                                        <span className="navbar-dropdown-menu-media-title">{t('sp1')}</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <div className="dropdown-divider"></div>
                                                        </>)
                                                        : null
                                            }

                                            <Link className="navbar-dropdown-menu-media-link" to="/profile" >
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                        <span className="svg-icon svg-icon-sm text-primary">
                                                            <i className="fas fa-user-circle" style={{ fontSize: '1.5em' }}></i>
                                                        </span>
                                                    </div>

                                                    <div className="flex-grow-1 ms-3">
                                                        <span className="navbar-dropdown-menu-media-title">{t('sp2')}</span>
                                                    </div>
                                                </div>
                                            </Link>

                                            {
                                                usr.type === "Visiteur" && usr.role === 4 ? (<>
                                                    <Link className="navbar-dropdown-menu-media-link" to="#/" >
                                                        <div className="d-flex">
                                                            <div className="flex-shrink-0">
                                                                <span className="svg-icon svg-icon-sm text-primary">
                                                                    <i className="fas fa-handshake" style={{ fontSize: '1.5em' }}></i>
                                                                </span>
                                                            </div>

                                                            <div className="flex-grow-1 ms-3">
                                                                <span className="navbar-dropdown-menu-media-title">Become a Partner</span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </>) : null
                                            }

                                            <div className="dropdown-divider"></div>

                                            <Link className="navbar-dropdown-menu-media-link" to="#/" onClick={() => handleLogout()} >
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                        <span className="svg-icon svg-icon-sm text-primary">
                                                            <i className="fas fa-sign-out-alt" style={{ fontSize: '1.5em' }}></i>
                                                        </span>
                                                    </div>


                                                    <div className="flex-grow-1 ms-3">
                                                        <span className="navbar-dropdown-menu-media-title">{t('sp3')}</span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </>
                            }


                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
}

export default TopBar;
