import React, { useEffect, useState } from 'react';
// import { Locations } from '../constants';
import {
    // AccountConnected,
    // AddressInfos,
    BasicInfos,
    // DeleteInfos,
    DevicesConnected,
    GoToWork,
    HeadProfile,
    // PrivacyInfos,
    // SocialsNetWorkConnected,
    TwoStepsAuth,
    UpdatePassword
} from '../partials';

import { useDispatch, useSelector } from 'react-redux';
import { adminActions, authActions, managerActions, store, userActions } from '../store';
import { formatDate, getFileFromBack, Slang } from '../helpers';
import { InvoiceViewerModal, UserCreateProject, UserPayView, /*UserEditProject,*/ UserViewProject } from '../components/modals';
import config from '../config';
import { useNavigate } from 'react-router';


import mastercard from '../assets/img/svg/mastercard.svg';
import visa from '../assets/img/svg/visa.svg';




const ProfileLayout = ({ setModal }) => {
    const navigation = useNavigate()

    const t = Slang('page.profile');

    const dispatch = useDispatch();
    const [thisAgent, setThisAgent] = useState(null);

    const devices = useSelector(x => x.user.devices);
    const user = useSelector(x => x.user.user);


    const handleLogout = () => {
        dispatch(authActions.signout({ deviceId: devices.filter(device => device.payload.info.ua === thisAgent)[0].id }));

        dispatch(authActions.logout());
        dispatch(userActions.logout());
        dispatch(adminActions.logout());
        dispatch(managerActions.logout());
    }

    const [profileContent, setProfileContent] = useState(<PersonnalInfos lang={t} />);
    const [navItem, setNavItem] = useState('Personal Informations');
    const [proj, setProj] = useState(0);


    useEffect(() => {
        // dispatch(userActions.getUser());
        dispatch(adminActions.projects());
        dispatch(userActions.categories());
        dispatch(userActions.getUser());
        dispatch(userActions.getdevices({ user_id: user.id }))
        setThisAgent(window.navigator.userAgent);

        window.sessionStorage.setItem("default_currency", "USD");  //ici je definis la devise par defaut et cela doit etre pris en compte automatiquement par le backend

        let tim = setInterval(() => {
            setProj(store.getState().user.projects.length)
        }, 300);

        return () => {
            clearInterval(tim);
        };

    }, [dispatch]);

    return (
        <>
            <main id="content" role="main" className="bg-light">
                <HeadProfile navItem={navItem} logout={handleLogout} lang={t} />

                <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
                    <div className="row">
                        <div className="col-lg-3">
                            <LeftAside navigation={navigation} lang={t} setProfileContent={setProfileContent} setNavItem={setNavItem} setModal={setModal} proj={proj} />
                        </div>
                        <div className="col-lg-9">
                            <div className="d-grid gap-3 gap-lg-5">
                                {profileContent}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default ProfileLayout;


const LeftAside = ({ setProfileContent, setNavItem, setModal, proj, lang, navigation }) => {

    const [active, setActive] = useState('basic_info');
    const [user, setUser] = useState(store.getState().user.user);
    const [avatar, setAvatar] = useState(store.getState().user.user.avatar);
    const [userProjects, setUserProjest] = useState(store.getState().user.allprojects.filter(proj => proj.user_id === store.getState().user.user.id));

    const dispatch = useDispatch();

    useEffect(() => {
        let tim = setInterval(() => {
            setUser(store.getState().user.user);
            setUserProjest(store.getState().user.allprojects.filter(proj => proj.user_id === store.getState().user.user.id));
            setAvatar(() => {
                if (store.getState().user.user.avatar !== undefined) {
                    return getFileFromBack(store.getState().user.user.avatar)
                } else {
                    return getFileFromBack(config.defaultAvatar);
                }
            });
        }, 200);
        dispatch(userActions.getdevices({ user_id: user.id }));
        return () => {
            clearInterval(tim)
        };
    }, []);

    const handleSelectNavItem = element => {
        setActive(element);
        if (element === 'security') {
            dispatch(userActions.getdevices({ user_id: user.id }));
        }
        setProfileContent(
            element === 'basic_info'
                ? <PersonnalInfos lang={lang} />
                : element === 'security'
                    ? <Security lang={lang} setModal={setModal} user={user} />
                    : element === 'notifs'
                        ? <>Notifications</>
                        : element === 'preferences'
                            ? <>Preferences</>
                            : element === 'payments'
                                ? <Payments setModal={setModal} lang={lang} dispatch={dispatch} />
                                : <Projects navigation={navigation} lang={lang} setModal={setModal} />
        );
        setNavItem(
            element === 'basic_info'
                ? lang('nav_items.basic_info')
                : element === 'security'
                    ? lang('nav_items.security')
                    : element === 'notifs'
                        ? lang('nav_items.notifs')
                        : element === 'preferences'
                            ? lang('nav_items.preferences')
                            : element === 'payments'
                                ? "Payments"
                                : lang('nav_items.projects')
        );

        if (element === 'security') {
            dispatch(userActions.getdevices());
        }
    };



    return (
        <div className="navbar-expand-lg navbar-light">
            <div id="sidebarNav" className="collapse navbar-collapse navbar-vertical">
                <div className="card flex-grow-1 mb-5 js-sticky-block" data-hs-sticky-block-options='{
                    "parentSelector": "#sidebarNav",
                    "targetSelector": "#header",
                    "breakpoint": "md",
                    "startPoint": "#sidebarNav",
                    "endPoint": "#stickyBlockEndPoint",
                    "stickyOffsetTop": 20,
                    "stickyOffsetBottom": 20
                }'>
                    <div className="card-body">

                        {/* Avatar of a user */}
                        <div className="d-none d-lg-block text-center mb-5">
                            <div className="avatar avatar-xxl avatar-circle mb-3">
                                <img className="avatar-img" src={avatar} alt="Description_Image" />
                                <img className="avatar-status avatar-lg-status" src="../assets/img/svg/illustrations/top-vendor.svg"
                                    alt="Description_Image" data-bs-toggle="tooltip" data-bs-placement="top" title="Verified user" />
                            </div>

                            <h4 className="card-title mb-0">{user.first_name}</h4>
                            <p className="card-text small">{user.email}</p>
                        </div>
                        {/* End Avatar of a user */}

                        <span className="text-cap">Account</span>

                        <ul className="nav nav-sm nav-tabs nav-vertical mb-4">
                            <li className="nav-item">
                                <span className={`nav-link ${active === 'basic_info' ? 'active' : ''}`} style={{ cursor: 'pointer' }} onClick={() => handleSelectNavItem('basic_info')}>
                                    <i className="bi-person-badge nav-icon"></i> Informations
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className={`nav-link ${active === 'security' ? 'active' : ''}`} style={{ cursor: 'pointer' }} onClick={() => handleSelectNavItem('security')}>
                                    <i className="bi-shield-shaded nav-icon"></i> {lang('nav_items.security')}
                                </span>
                            </li>
                            {/* <li className="nav-item">
                                <span className={`nav-link ${active === 'notifs' ? 'active' : ''}`} style={{ cursor: 'pointer' }} onClick={() => handleSelectNavItem('notifs')}>
                                    <i className="bi-bell nav-icon"></i> Notifications
                                    <span className="badge bg-soft-dark text-dark rounded-pill nav-link-badge">1</span>
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className={`nav-link ${active === 'preferences' ? 'active' : ''}`} style={{ cursor: 'pointer' }} onClick={() => handleSelectNavItem('preferences')}>
                                    <i className="bi-sliders nav-icon"></i> Preferences
                                </span>
                            </li> */}
                            {
                                store.getState().user.user.role_id === 4 // 4 is role_id of users, they can submit projects here
                                    ? <>
                                        <li className="nav-item">
                                            <span className={`nav-link ${active === 'projects' ? 'active' : ''}`} style={{ cursor: 'pointer' }} onClick={() => handleSelectNavItem('projects')}>
                                                <i className="bi-briefcase-fill nav-icon"></i> {lang('nav_items.projects')}
                                                <span className="badge bg-soft-dark text-dark rounded-pill nav-link-badge">{userProjects.length}</span>
                                            </span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link ${active === 'payments' ? 'active' : ''}`} style={{ cursor: 'pointer' }} onClick={() => handleSelectNavItem('payments')}>
                                                <i className="bi-credit-card-fill nav-icon"></i> Payments
                                            </span>
                                        </li>

                                    </>
                                    : null
                            }
                        </ul>

                        {
                            store.getState().user.user.role_id === 3 // 3 is role_id of wokers
                                ? <>
                                    <span className="text-cap">{lang('nav_items.work_space')}</span>

                                    <ul className="nav nav-sm nav-tabs nav-vertical mb-4">
                                        <li className="nav-item">
                                            <span className={`nav-link ${active === 'work' ? 'active' : ''}`} style={{ cursor: 'pointer' }} onClick={() => { setActive('work'); setProfileContent(<GoToWork setModal={setModal} lang={lang} />); setNavItem('WorkSpace'); }}>
                                                <i className="bi-briefcase-fill nav-icon"></i> Go to work
                                                <span className="badge bg-soft-dark text-dark rounded-pill nav-link-badge">{proj}</span>
                                            </span>
                                        </li>
                                    </ul>
                                </>
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const PersonnalInfos = ({ lang }) => {

    return (
        <>
            {/* Basic Informations */}
            <BasicInfos lang={lang} />

            {/* Editing address informations */}
            {/* <AddressInfos /> */}

            {/* <PrivacyInfos /> */}

            {/* <DeleteInfos /> */}



            {/* <div id="stickyBlockEndPoint"></div> */}
        </>
    );
};

const Security = ({ setModal, lang, user }) => {

    const dispatch = useDispatch();

    useEffect(() => {
        let timer = setInterval(() => {
            dispatch(userActions.getdevices({ user_id: user.id }));
        }, 6000);

        return () => {
            clearInterval(timer);
        };
    }, [dispatch])

    return <>
        <TwoStepsAuth lang={lang} />
        <UpdatePassword lang={lang} />
        {/* <AccountConnected /> */}
        {/* <SocialsNetWorkConnected /> */}
        <DevicesConnected lang={lang} setModal={setModal} />
    </>;
};

const Projects = ({ setModal, lang, navigation }) => {

    const dispatch = useDispatch();

    const [myProjects, setMyProjects] = useState([]);
    const [categories, setCategories] = useState(store.getState().user.categories);

    useEffect(() => {

        let tim = setInterval(() => {
            setMyProjects(store.getState().user.allprojects.filter(projet => projet.user_id === store.getState().user.user.id));
            setCategories(store.getState().user.categories);
        }, 1000);


        let timer = setInterval(() => {
            dispatch(adminActions.projects());
            dispatch(userActions.categories());
        }, 8000);

        return () => {
            clearInterval(tim);
            clearInterval(timer);
        };
    }, [dispatch])

    return <div className="card">
        <div className="card-header border-bottom">
            <h5 className="card-header-title">{lang('items.projects.title')}</h5>
        </div>

        <div className="card-body">
            <div className="mb-4">
                <p>
                    <span className='text-bold text-dark'>{store.getState().user.user.name}</span>, {lang('items.projects.text1')}
                    <br />{lang('items.projects.br')}
                </p>
            </div>

            <span
                style={{ cursor: 'pointer' }}
                className="card card-dashed card-centered mb-5"
                data-bs-toggle="modal"
                data-bs-target="#userCreateProject"
                onMouseOver={() => setModal(<UserCreateProject lang={lang} categ={categories} setModal={setModal} />)}
            >
                <div className="card-body card-dashed-body">
                    <span className="d-block text-success btn fw-bold"><i className="fa fa-plus"></i> {lang('items.projects.create.title')}</span>
                </div>
            </span>

            <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                <ul className="list-group">

                    {
                        myProjects.map((project, key) => {
                            return <li className="list-group-item" key={project.id}>
                                <div className="mb-2">
                                    <h5>{lang('items.projects.count_project', { num: key + 1 })}</h5>
                                </div>

                                <div className="d-flex">
                                    <div className="flex-shrink-0">
                                        <img className="avatar avatar-sm" src="../../assets/img/svg/folderprojet.png" alt="Description_Image" />
                                    </div>

                                    <div className="flex-grow-1 ms-3">
                                        <div className="row">
                                            <div className="col-sm mb-3 mb-sm-0">
                                                <span className="d-block text-dark">{project.name}</span>
                                                <small className="d-block text-muted">{lang('items.projects.created')} {formatDate(project.created_at)}</small>
                                            </div>

                                            <div className="col-sm-auto">
                                                <div className="d-flex gap-3">
                                                    <button
                                                        type="button"
                                                        className="btn btn-white btn-xs"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#userViewProject"
                                                        onMouseOver={() => setModal(<UserViewProject lang={lang} setModal={setModal} project={project} />)}
                                                    >
                                                        <i className="bi-eye me-1"></i> {lang('items.projects.view')}
                                                    </button>

                                                    <button
                                                        disabled={true}
                                                        // onMouseOver={() => setModal(<UserEditProject setModal={setModal} project={project} />)}
                                                        type="button"
                                                        className="btn btn-white btn-xs"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#userEditProject"
                                                    >
                                                        <i className="bi-pencil-fill me-1"></i> {lang('items.projects.edit')}
                                                    </button>

                                                    {
                                                        project.status === "APPROVED" || project.status === "PENDING"
                                                            ? <button
                                                                type="button"
                                                                className="btn btn-white btn-xs"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#userViewProject"
                                                                onMouseOver={() => setModal(<UserPayView navigation={navigation} lang={lang} setModal={setModal} project={project} />)}
                                                            >
                                                                <i className="fa fa-credit-card me-1"></i> {lang('items.projects.pay')} </button>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        })
                    }
                </ul>
            </div>
        </div>
    </div>
};

const Payments = ({ setModal, lang, dispatch }) => {

    const paiements = useSelector(x => x.user.paiements);

    useEffect(() => {
        dispatch(userActions.getuserpaiements());

        return () => {
        };
    }, []);

    return <div className="card">
        <div className="card-header">
            <h5 className="card-header-title">Order history</h5>
        </div>

        <div className="table-responsive">
            <table className="table table-borderless table-thead-bordered table-nowrap table-align-middle">
                <thead className="thead-light">
                    <tr>
                        {/* <th>Reference</th> */}
                        <th className='fw-bold'>Card</th>
                        <th className='fw-bold'>Date</th>
                        <th className='fw-bold'>Status</th>
                        <th className='fw-bold'>Amount</th>
                        {/* <th>Invoice</th> */}
                        <th className='fw-bold'>Action</th>
                    </tr>
                </thead>

                <tbody>

                   

                    {
                        paiements.from_back
                            ? paiements.data.length === 0
                                ? <tr>
                                    <td colSpan={5} style={{
                                        textAlign: 'center',
                                    }}>
                                        <span className="alert alert-soft-danger" aria-hidden="true">No data</span>
                                    </td>
                                </tr>

                                : paiements.data.map((itm, idx) => {
                                    return <tr key={idx}>
                                        {/* <td><a href="#">#9834283</a></td> */}
                                        <td>
                                            <img className="avatar avatar-xs me-2" src={
                                                itm.card_brand === "visa" ? visa : mastercard
                                            } alt="Description_Image" />
                                            <span className="text-dark">{itm.card_last4.slice(9, 19)}</span>
                                        </td>
                                        <td>{formatDate(itm.created_at, false, 'en')}</td>
                                        <td><span className={`badge bg-soft-${itm.status === 'succeeded' ? 'success' : itm.status === 'failed' ? 'danger' : 'warning'} text-${itm.status === 'succeeded' ? 'success' : itm.status === 'failed' ? 'danger' : 'warning'}`} style={{ textTransform: 'capitalize' }}>{itm.status}</span></td>
                                        <td>{itm.currency === 'eur' ? <span>{itm.amount}<sup>€</sup></span> : <span><sup>$</sup>{itm.amount}</span>}</td>
                                        {/* <td><span className="btn btn-white btn-xs"><i className="bi-file-earmark-arrow-down-fill me-1"></i> PDF</span></td> */}
                                        <td><span onClick={() => setModal(<InvoiceViewerModal data={itm} setModal={setModal} />)} className="btn btn-white btn-xs" data-bs-toggle="modal" data-bs-target="#accountInvoiceReceiptModal"><i className="bi-eye-fill me-1"></i> Show receipt</span></td>
                                    </tr>
                                })
                            :
                            <tr>

                                <td colSpan={5} style={{
                                    textAlign: 'center',
                                }}>
                                    <span className="spinner-border spinner-border-md text-primary" style={{ height: 25, width: 25 }} role="status" aria-hidden="true"></span>
                                </td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>
    </div>
};
