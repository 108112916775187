import React, { useEffect, useState } from 'react';
import { AdminContentLoader } from '../../components';
import { useDispatch } from 'react-redux';
import { store, managerActions } from '../../store';
import { ViewUser } from '../../components/modals';
import { TableData } from '../../components/table';

const WorkerContent = ({ setModal }) => {

    const [loading, setLoading] = useState(true);


    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(managerActions.users());


        let tmer = setInterval(() => {
            dispatch(managerActions.users());
        }, 10000);

        setTimeout(() => {
            setLoading(false)
        }, 3000);

        return () => {
            clearInterval(tmer);
        };
    }, [dispatch]);

    return loading
        ? <AdminContentLoader />
        : <Content dispatch={dispatch} setModal={setModal} />

}

export default WorkerContent;


const Content = ({ dispatch, setModal }) => {
    const [users, setUsers] = useState(store.getState().manager.users.filter(user => (user.id !== store.getState().user.user.id)));

    const verifedUsers = store.getState().manager.users.filter(
        user => (user.id !== store.getState().user.user.id))
        .filter(
            user => (user.id !== store.getState().user.user.id && user.email_verified_at !== null)
        ).filter(user => user.role_id === 3);


    const [verifedUsersTab, setVerifedUsersTab] = useState(verifedUsers);



    useEffect(() => {

        dispatch(managerActions.users());


        let tmer = setInterval(() => {
            dispatch(managerActions.users());
        }, 10000);

        let tim = setInterval(() => {
            setUsers(store.getState().manager.users);
            setVerifedUsersTab(store.getState().manager.users.filter(
                user => (user.id !== store.getState().user.user.id))
                .filter(
                    user => (user.id !== store.getState().user.user.id && user.email_verified_at !== null)
                ).filter(user => user.role_id === 3));
        }, 300);

        return () => {
            clearInterval(tmer);
            clearInterval(tim);
        };
    }, [dispatch]);

    const handleView = user => {
        setModal(<ViewUser user={user} setModal={setModal} />)
    };


    const buildArrVerifiedUsers = arr => {
        let tab = [];

        for (let i = 0; i < arr.length; i++) {
            tab.push({
                id: arr[i].id,
                name: arr[i].name,
                email: arr[i],
                type: arr[i].type,
                project: countProjectOn(arr[i].id),
                role: arr[i].roles[0].name,
                action: arr[i],
            });
        }

        return tab;
    };

    const tableUserVerifiedConfig = {
        columns: [
            { field: 'id', headerName: 'ID', width: 20 },
            { field: 'name', headerName: 'Name', width: 180 },
            {
                field: 'email',
                headerName: 'Email',
                width: 240,
                renderCell: (params) => params.row.action.email,
            },
            { field: 'type', headerName: 'Type', width: 170 },
            {
                field: 'project',
                headerName: 'Projects on',
                width: 170,
                renderCell: (params) => <span className={`badge badge-${params.formattedValue > 0 ? 'success' : 'danger'}`}>{parseInt(params.formattedValue)}</span>,
            },
            {
                field: 'role',
                headerName: 'Role',
                width: 120,
                renderCell: (params) =>
                    params.row.action.roles.length !== 0
                        ? params.row.action.roles[0].name === 'user'
                            ? <span style={{ textTransform: 'capitalize' }} className='badge badge-warning'>{params.row.action.roles[0].name}</span>
                            : params.row.action.roles[0].name === 'worker' || params.row.action.roles[0].name === 'worker'
                                ? <span style={{ textTransform: 'capitalize' }} className='badge badge-info'>{params.row.action.roles[0].name}</span>
                                : <span style={{ textTransform: 'capitalize' }} className='badge badge-success'>{params.row.action.roles[0].name}</span>
                        : <span className='text-bold bg-danger' style={{ textTransform: 'uppercase', fontSize: '0.7rem' }}>Not set</span>,
            },
            {
                field: "actions",
                headerName: "Actions",
                width: 190,
                sortable: false,
                renderCell: (params) => (
                    <span>
                        <span className="btn btn-outline-info btn-sm mr-1" data-toggle="modal" data-target="#view-user" title='View' onClick={() => handleView(params.row.action)}><i className="fas fa-eye"></i></span>
                    </span>
                ),
            },
        ],
        rows: buildArrVerifiedUsers(verifedUsersTab)
    }

    const handleResearchDataTable = (tab, value) => {
        return tab.filter(item =>
            item.name.toLowerCase().includes(value.toLowerCase()) ||
            item.type.toLowerCase().includes(value.toLowerCase()) ||
            item.roles[0].name.toLowerCase().includes(value.toLowerCase()) ||
            item.email.toLowerCase().includes(value.toLowerCase())
        );
    };



    return <>
        <div className="row">
            <div className="col-md-10 offset-1">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">
                            Total workers &nbsp; <span className='badge badge-success'>{verifedUsersTab.length}</span>
                        </h3>

                        <div className="card-tools" style={{ display: 'flex' }}>
                            <div className="input-group input-group-sm" style={{ width: "150px" }}>
                                <input
                                    type="text"
                                    name="table_search"
                                    className="form-control float-right"
                                    placeholder="Search"
                                    onChange={e => setVerifedUsersTab(handleResearchDataTable(verifedUsers, e.target.value))}
                                />
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-default">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="input-group input-group-sm" style={{ width: "30px", justifyContent: 'end' }}>
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                            </div>

                        </div>
                    </div>

                    <TableData config={tableUserVerifiedConfig} />

                </div>
            </div>
        </div>
    </>
};


const countProjectOn = user_id => {

    let arr = [], er = [];
    let user_project = store.getState().manager.projects;
    arr = [...user_project.map(elt => elt.users)]
    arr.forEach(elm => {
        er.push(...elm)
    })
    return er.filter(user => user.id === user_id).length;

};
