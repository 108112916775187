import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Toast } from '../helpers';
import { fetchWrapper } from '../helpers/fetch-wrapper';
import Swal from 'sweetalert2';
import appConfig from '../config';
import $ from 'jquery';

//create slice
const name = 'guest';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });


//Export
export const guestActions = { ...slice.actions, ...extraActions };
export const guestReducer = slice.reducer;


//Implementation

function createInitialState() {
    return {
        careers: [],
        paypal_pay: null,
        error: null,
    };
};

function createReducers() {
    return {
        logout,
        resetSlice,
    };

    function resetSlice(state) { return createInitialState(); };
    function logout(state) {
        state.error = null;
    }
};

function createExtraActions() {
    const baseUrl = `${appConfig.apiUrl}`;

    return {
        newsletter: newsletter(),
        careers: allCareers(),
        contact: createContact(),
        paypal_pay: paypal_pay(),
        stripe_pay: stripe_pay(),
        paypal_pay_exec: paypal_pay_exec(),
        createprojectidea: createProjectIdea(),
    }

    function newsletter() {
        return createAsyncThunk(
            `${name}/subscribe/newsletter`,
            async ({ email }) => await fetchWrapper.post(`${baseUrl}/newletters/create`, { email })
        )
    };

    function allCareers() {
        return createAsyncThunk(`${name}/get/all/careers`, async () => await fetchWrapper.get(`${baseUrl}/getcarrer`));
    }

    function createContact() {
        return createAsyncThunk(`${name}/create/contact`, async ({
            first_name,
            last_name,
            phone,
            email,
            subject,
            message
        }) => await fetchWrapper.post(`${baseUrl}/contacts/create`, {
            first_name,
            last_name,
            phone,
            email,
            subject,
            message
        }));
    }

    function paypal_pay() {
        return createAsyncThunk(
            `${name}/paypal/pay/make/payment`,
            async ({ paypal }) => await fetchWrapper.post(`${baseUrl}/make/payment`, { paypal })
        )
    }

    function stripe_pay() {
        return createAsyncThunk(
            `${name}/stripe/pay/make/payment`,
            async ({ card }) => await fetchWrapper.post(`${baseUrl}/make/full/payment`, { card })
        )
    }

    function paypal_pay_exec() {
        return createAsyncThunk(
            `${name}/paypal/pay/execute/payment`,
            async ({ token, PayerId }) => await fetchWrapper.post(`${baseUrl}/execute`, { token, PayerId })
        )
    }



    /**
* Post a project idea
* @method POST
* @param projet_id
*/
    function createProjectIdea() {
        return createAsyncThunk(
            `${name}/create/project/idea`,
            async ({
                last_name,
                first_name,
                budget,
                currency,
                company,
                email,
                phone,
                details,
                device,
            }) => await fetchWrapper.post(`${baseUrl}/project-idea`, {
                last_name,
                first_name,
                budget,
                currency,
                company,
                email,
                phone,
                details,
                device,

            })
        );
    }





};























function createExtraReducers() {
    return builder => {
        newsletter();
        allCareers();
        createContact();
        paypal_pay();
        paypal_pay_exec();
        stripe_pay();
        create_project_idea();


        function newsletter() {
            var { pending, fulfilled, rejected } = extraActions.newsletter;

            builder
                .addCase(pending, state => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload;
                    Swal.fire(
                        'Sucessfull!',
                        'Thank you for subscribing, you will be notify about any news.',
                        'success'
                    );
                })
                .addCase(rejected, (state, action) => {
                    const error = JSON.parse(action.error.message);
                    Toast('error', error.email[0], 'tr', false, false, false, 3000, false);
                })
        };

        function allCareers() {
            var { pending, fulfilled, rejected } = extraActions.careers;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload;
                    state.careers = data.data
                    //console.log("get all careers", action.payload)
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                    console.log("get all careers", action.error)
                })
        };

        function createContact() {
            var { pending, fulfilled, rejected } = extraActions.contact;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload;
                    setTimeout(() => {
                        Swal.fire(
                            'Well done!',
                            'Notification sent successfully.',
                            'success'
                        );
                    }, 3000);
                    // console.log("send a contact form", data)
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                    let errorKeys = [...Object.keys(JSON.parse(action.error.message))];
                    // console.log("send a contact form", JSON.parse(action.error.message)[Object.keys(JSON.parse(action.error.message))])
                    errorKeys.map(key => {
                        Toast('error', JSON.parse(action.error.message)[key], 'tr', false, false, false, 5000, false);
                    });
                })
        };

        function paypal_pay() {
            var { pending, fulfilled, rejected } = extraActions.paypal_pay;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload;


                    const windowFeatures = "left=300,top=250,width=500,height=750,toolbar=0,menubar=0,resizable=0";

                    window.open(
                        data.data,
                        "",
                        windowFeatures,
                    );

                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                    let errorKeys = [...Object.keys(JSON.parse(action.error.message))];
                    // console.log("send a contact form", JSON.parse(action.error.message)[Object.keys(JSON.parse(action.error.message))])
                    errorKeys.map(key => {
                        Toast('error', JSON.parse(action.error.message)[key], 'tr', false, false, false, 5000, false);
                    });
                })
        };

        function paypal_pay_exec() {
            var { pending, fulfilled, rejected } = extraActions.paypal_pay_exec;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload.data;

                    if (action.payload.status === 'success') {
                        setTimeout(() => {
                            Swal.fire(
                                'Well done!',
                                data.message,
                                'success'
                            );
                        }, 3000);
                        let timer = setTimeout(() => {
                            window.close();
                        }, 7000);
                    }

                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                    let errorKeys = [...Object.keys(JSON.parse(action.error.message))];
                    // console.log("send a contact form", JSON.parse(action.error.message)[Object.keys(JSON.parse(action.error.message))])
                    errorKeys.map(key => {
                        Toast('error', JSON.parse(action.error.message)[key], 'tr', false, false, false, 5000, false);
                    });
                })
        };

        function stripe_pay() {
            var { pending, fulfilled, rejected } = extraActions.stripe_pay;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload;


                    // const windowFeatures = "left=300,top=250,width=500,height=750,toolbar=0,menubar=0,resizable=0";

                    // window.open(
                    //     data.data,
                    //     "",
                    //     windowFeatures,
                    // );
                    console.log(data)

                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                    let errorKeys = [...Object.keys(JSON.parse(action.error.message))];
                    // console.log("send a contact form", JSON.parse(action.error.message)[Object.keys(JSON.parse(action.error.message))])
                    errorKeys.map(key => {
                        Toast('error', JSON.parse(action.error.message)[key], 'tr', false, false, false, 5000, false);
                    });
                })
        };

        function create_project_idea() {
            var { pending, fulfilled, rejected } = extraActions.createprojectidea;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    const data = action.payload.data;
                    console.log(data);
                    sessionStorage.setItem('init-btn', 0);
                    Toast('success', data.message, 'tr', false, false, false, 2800, false);
                    setTimeout(() => {
                        sessionStorage.removeItem('init-btn');
                        // window.location.reload();
                    }, 1800);

                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                    console.log("send a contact form", action);
                    // Toast('error', action.error.message, 'tr', false, false, false, 5000, false);
                    
                    let errorKeys = [...Object.keys(JSON.parse(action.error.message))];
                    errorKeys.map(key => {
                        Toast('error', JSON.parse(action.error.message)[key], 'tr', false, false, false, 5000, false);
                    });
                    
                    sessionStorage.setItem('init-btn', 0);
                    setTimeout(() => {
                        sessionStorage.removeItem('init-btn');
                    },  );

                })
        };





    };
}
