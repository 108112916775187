import { authActions, guestActions, managerActions, store, userActions } from '../store';
import appConfig from '../config';

const request = method => {
    return (url, body) => {
        const requestOptions = {
            method,
            headers: authHeader(url),

        };
        // console.log(requestOptions)
        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';
            // requestOptions.headers['Access-Control-Allow-Origin'] = 'http://127.0.0.1:3000';
            // requestOptions.headers['Access-Control-Allow-Headers'] = '*';
            requestOptions.headers['Accept'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }
        return fetch(url, requestOptions).then(handleResponse);
    }
}

// helper functions

const authHeader = url => {
    // return auth header with jwt if user is logged in and request is to the api url
    const token = authToken();
    const isLoggedIn = !!token;
    const isApiUrl = url.startsWith(appConfig.apiUrl);
    if (isLoggedIn && isApiUrl) {
        return { Authorization: `Bearer ${token}` };
    } else {
        return {};
    }
}

const authToken = () => {
    // console.log(store.getState().auth.token)
    return store.getState().auth.token === null ? sessionStorage.getItem('token') || localStorage.getItem('token') : store.getState().auth.token;
}

const handleResponse = response => {

    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok || data.error) {
            const err = data.reason;

            if ([401, 403].includes(response.status) && authToken()) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                // const logout = () => store.dispatch(authActions.logout());
                // const logout2 = () => store.dispatch(userActions.logout());
                // const logout3 = () => store.dispatch(managerActions.logout());
                // const logout4 = () => store.dispatch(guestActions.logout());
                // logout();
                // logout2();
                // logout3();
                // logout4();
            }
            const error = err;

            return Promise.reject(error);
        }
        //Toast('success', 'Well done!', 'tc', true, true, true, 4000);
        return data;
    });
}




export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    patch: request('PATCH'),
    put: request('PUT'),
    delete: request('DELETE')
};
