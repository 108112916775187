import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Form from "react-validation/build/form";
import Button from 'react-validation/build/button';
import Input from "react-validation/build/input";
import { useDispatch } from 'react-redux';
import { guestActions } from '../store';
import { Slang } from '../helpers';


const NewsletterLayout = () => {

    const dispatch = useDispatch();

    const ph = Slang('page.home');


    const [email, setEmail] = useState('');
    const [showLoading, setShowLoading] = useState(false);

    const handleEmailChange = e => {
        setEmail(e.target.value);
    };


    const handleSubmit = event => {
        event.preventDefault();
        setShowLoading(true);

        setTimeout(() => {
            dispatch(guestActions.newsletter({email}));

            setTimeout(() => {
                setShowLoading(false);
            }, 4000);

        }, 2000);
    };




    return (
        <>
            <div className="bg-dark text-light">
                <div className="container content-space-2">
                    <div className="w-md-75 w-lg-50 text-center mx-md-auto">
                        <div className="row justify-content-lg-between">
                            <div className="mb-5">
                                <span className="text-cap text-light">{ph('n_letter.title')}</span>
                                <h2 className='text-light'>{ph('n_letter.desc')} <span className='text-primary'>HAPPYNESS</span></h2>
                            </div>

                            <Form onSubmit={e => handleSubmit(e)}>
                                <div className="input-card input-card-pill input-card-sm border mb-3">
                                    <div className="input-card-form">
                                        {/* <label htmlFor="subscribeForm" className="form-label visually-hidden">Enter email</label> */}
                                        <Input
                                            type="email"
                                            className="form-control form-control-lg"
                                            onChange={e => handleEmailChange(e)}
                                            name="email"
                                            id="signupModalFormLoginEmail"
                                            placeholder="email@site.com"
                                            aria-label="email@site.com"
                                        />
                                    </div>
                                    {
                                        !showLoading
                                            ? <Button type="submit" className="btn btn-primary btn-lg rounded-pill">
                                            {ph('n_letter.form.btn')}
                                            </Button>
                                            : <button type="button" className="btn btn-primary btn-lg rounded-pill" disabled={showLoading}>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                {ph('n_letter.form.btn_load')}
                                            </button>
                                    }
                                </div>

                            </Form>

                            <p className="small text-light">{ph('n_letter.un_subs')} <Link to="/privacy#content">{ph('n_letter.privacy_link')}</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewsletterLayout;
