import React, { useEffect, useState } from 'react';
import DashContent from './dashContent';
import UserContent from './userContent';
import CareerContent from './careerContent';
import SettingsContent from './settingsContent';
import ProjectsContent from './projectsContent';
import { HashLink as Link } from 'react-router-hash-link';
import InboxMailContent from './inboxMailContent';
import NotificationsContent from './notificationsContent';
import { useDispatch, useSelector } from 'react-redux';
import { adminActions, store } from '../../store';
import { getFileFromBack } from '../../helpers';
import PaiementContent from './paiementContent';


const SidebarAdmin = ({ setContent, setModal }) => {

    const [active, setActive] = useState('dash');
    const [countProject, setCountProject] = useState(store.getState().admin.projects !== null ? store.getState().admin.projects.length : 0);
    const dispatch = useDispatch();

    const user = useSelector(x=>x.user.user);

    const handleSetContent = type_content => {

        setActive(type_content);

        if (type_content === 'dash') {
            setContent({
                icon: 'tachometer-alt',
                name: 'Dashboard',
                component: () => <DashContent />
            });
        }

        if (type_content === 'users') {
            setContent({
                icon: 'users',
                name: 'Users',
                component: () => <UserContent setModal={setModal} />
            });
        }

        if (type_content === 'career') {
            setContent({
                icon: 'flag',
                name: 'Careers',
                component: () => <CareerContent />
            });
        }

        if (type_content === 'inbox') {
            setContent({
                icon: 'envelope',
                name: 'HappyNess Mailer',
                component: () => <InboxMailContent />
            });
        }

        if (type_content === 'settings') {
            setContent({
                icon: 'cogs',
                name: 'Settings',
                component: () => <SettingsContent />
            });
        }

        if (type_content === 'projects') {
            dispatch(adminActions.projects());
            dispatch(adminActions.categories());
            setContent({
                icon: 'book',
                name: 'Projects',
                component: () => <ProjectsContent />
            });
        }

        if (type_content === 'pay') {
            setContent({
                icon: 'university',
                name: 'Paiements',
                component: () => <PaiementContent />
            });
        }

        if (type_content === 'notifications') {
            setContent({
                icon: 'bell',
                name: 'Notifications',
                component: () => <NotificationsContent />
            });
        }

    };


    useEffect(() => {

        let tim = setInterval(() => {
            setCountProject(store.getState().admin.projects !== null ? store.getState().admin.projects.length : 0);
        }, 1000);


        return () => {
            clearInterval(tim);
        };
    }, []);


    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <Link to="/" className="brand-link" style={{display: 'flex', justifyContent:'center'}}>
                <img src={require('../../assets/img/logo/logoWh.png')} alt="AdminLTE Logo" style={{ opacity: .8, maxWidth: '14rem', height: 50 }} />
                {/* <span className="brand-text font-weight-light fw-bold">HappyNess</span> */}
            </Link>

            <div className="sidebar">
                <div className="mt-3 mb-3 pb-0 mb-1 d-flex" style={{ justifyContent: 'center' }}>
                    <div>
                        <img src={getFileFromBack(user.avatar)} className="img-circle elevation-1" style={{ height: 70 }} alt="User_picture" />
                    </div>
                    <div className='mt-3 pb-3 mb-1 d-flex text-white ml-3' style={{ justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', flexDirection: 'column' }}>
                        <div className="info">
                            <span className="d-block text-info text-bold">{
                                user.first_name
                            }</span>
                        </div>
                        Administrator
                    </div>
                </div>
                {/* <div className="form-inline">
                    <div className="input-group" data-widget="sidebar-search">
                        <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                        <div className="input-group-append">
                            <button className="btn btn-sidebar">
                                <i className="fas fa-search fa-fw"></i>
                            </button>
                        </div>
                    </div>
                </div> */}

                {/* 
                    Remettre les lien que j'ai desactiver lorsqu'ils seront prets
                */}

                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        {/* <li className="nav-item">
                            <span style={{ cursor: 'pointer' }} className={`nav-link ${active === 'dash' ? 'active' : null}`} onClick={() => handleSetContent('dash')}>
                                <i className="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Dashboard
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </span>
                        </li> */}

                        <li className="nav-item">
                            <span style={{ cursor: 'pointer' }} className={`nav-link ${active === 'inbox' ? 'active' : null}`} onClick={() => handleSetContent('inbox')}>
                                <i className="nav-icon fas fa-envelope"></i>
                                <p>
                                    Inbox
                                    {/* <i className="right fas fa-angle-left"></i> */}
                                </p>
                            </span>
                        </li>

                        {/* <li className="nav-item">
                            <span style={{ cursor: 'pointer' }} className={`nav-link ${active === 'pay' ? 'active' : null}`} onClick={() => handleSetContent('pay')}>
                                <i className="nav-icon fas fa-university"></i>
                                <p>
                                    Paiements
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </span>
                        </li> */}

                        {/* <li className="nav-item">
                            <span style={{ cursor: 'pointer' }} className={`nav-link ${active === 'notifications' ? 'active' : null}`} onClick={() => handleSetContent('notifications')}>
                                <i className="nav-icon fas fa-bell"></i>
                                <p>
                                    Notifications
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </span>
                        </li> */}

                        <li className="nav-item">
                            <span style={{ cursor: 'pointer' }} className={`nav-link ${active === 'career' ? 'active' : null}`} onClick={() => handleSetContent('career')}>
                                <i className="nav-icon fas fa-flag"></i>
                                <p>
                                    Careers
                                    {/* <i className="right fas fa-angle-left"></i> */}
                                </p>
                            </span>
                        </li>

                        <li className="nav-item">
                            <span style={{ cursor: 'pointer' }} className={`nav-link ${active === 'users' ? 'active' : null}`} onClick={() => handleSetContent('users')}>
                                <i className="nav-icon fas fa-users"></i>
                                <p>
                                    Users
                                    {/* <i className="right fas fa-angle-left"></i> */}
                                </p>
                            </span>
                        </li>

                        <li className="nav-item">
                            <span style={{ cursor: 'pointer' }} className={`nav-link ${active === 'projects' ? 'active' : null}`} onClick={() => handleSetContent('projects')}>
                                <i className="nav-icon fas fa-book"></i>
                                <p>
                                    Projets
                                    <span className="badge badge-info right">{countProject < 10 ? `0${countProject}` : countProject}</span>
                                    {/* <i className="right fas fa-angle-left"></i> */}
                                </p>
                            </span>
                        </li>

                        {/* <li className="nav-item">
                            <span style={{ cursor: 'pointer' }} className={`nav-link ${active === 'settings' ? 'active' : null}`} onClick={() => handleSetContent('settings')}>
                                <i className="nav-icon fas fa-cogs"></i>
                                <p>
                                    Settings
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </span>
                        </li> */}
                    </ul>
                </nav>
            </div>
        </aside>
    );
};

export default SidebarAdmin;
