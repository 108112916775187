import React from 'react';
import { partners } from '../../constants';
import { Slang } from '../../helpers';

const Partners = () => {
    
    const t = Slang('page.home');

    return <div className="container content-space-2 content-space-b-lg-3">
        <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-6">
            <h2>{t('partners_section')}</h2>
        </div>

        <div className="row justify-content-center text-center">
            {
                partners.map((partner, key) => {
                    return <div key={key} className="col-4 col-sm-3 col-md-2 py-3">
                        <img className="avatar avatar-lg avatar-4x3 avatar-centered" src={`./assets/img/svg/brands/${partner.svg_icon}`} alt="Logo" />
                    </div>
                })
            }
        </div>
    </div>;
}

export default Partners;
