import React, { useEffect, useState } from 'react';
import { store, userActions } from '../../store';
import { calculProgressDate, formatDate, getFileFromBack } from '../../helpers';
import { useDispatch } from 'react-redux';
import MyCircle from '../../components/myCircle';
import { TimeProgressBarTask } from 'components';

const UserViewProject = ({ project, setModal, lang }) => {

    const dispatch = useDispatch();
    const [showContent, setShowContent] = useState(0);

    useEffect(() => {

        let tim = setInterval(() => {
            dispatch(userActions.projecttasks({ project_id: project.id }));
        }, 8000);

        return () => {
            clearInterval(tim);
        };
    }, [dispatch, project.id]);


    const projectTabs = [
        {
            title: 'General',
            icon: 'fas fa-globe',
            content: (project, lang, dispatch = null) => <General lang={lang} project={project} dispatch={dispatch} />
        },
        {
            title: 'Tasks',
            icon: 'fas fa-tasks',
            content: (project, lang, dispatch = null) => <Tasks lang={lang} project={project} dispatch={dispatch} />
        },
        {
            title: 'Teams',
            icon: 'fas fa-users',
            content: (project, lang, dispatch = null) => <Teams lang={lang} project={project} dispatch={dispatch} />
        },
        /*{
            title: 'Workers',
            icon: 'fa fa-users',
            content: (project, dispatch = null) => <Workers project={project} dispatch={dispatch} />
        },
        {
            title: 'Technos',
            icon: 'fa fa-laptop-code',
            content: (project, dispatch = null) => <Technologies project={project} dispatch={dispatch} />
        },
        {
            title: 'Files',
            icon: 'far fa-folder-open',
            content: (project, dispatch = null) => <Files project={project} dispatch={dispatch} />
        }*/
    ];

    return (<div className="modal fade bd-example-modal-xl" data-bs-backdrop="static" id='view_project_work_on' tabIndex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">

                <div className="modal-header">
                    <h3 className="modal-title h4" id="myExtraLargeModalLabel">{lang('items.go_work.view_project.title')}: {project.name}</h3>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal(<></>)}></button>
                </div>
                <div className="modal-body">
                    <div className="card card-light card-tabs">
                        <div className="card-header p-0 pt-1">
                            <ul className="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                                {
                                    projectTabs.map((tab, key) => {
                                        return <li key={key} className="nav-item" onClick={() => setShowContent(key)}>
                                            <span className={`nav-link text-bold ${key === (showContent) ? 'active' : ''}`}
                                                id={`custom-tabs-one-${tab.title}-tab`}
                                                data-toggle="pill" 
                                                //href={`#custom-tabs-one-${tab.title}`}
                                                role="tab" aria-controls={`custom-tabs-one-${tab.title}`}
                                                aria-selected="true"
                                                style={{cursor:'pointer'}}
                                            >
                                                <i className={`${tab.icon} mr-2`}></i>&nbsp;
                                                {tab.title}
                                            </span>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                        <div className="card-body bg-white" style={{ maxHeight: 700, overflow: 'auto' }}>
                            <div className="tab-content" id="custom-tabs-one-tabContent">
                                <div className={`tab-pane fade show active`} id={`#custom-tabs-one-${projectTabs[showContent].title}`} role="tabpanel"
                                    aria-labelledby={`custom-tabs-one-${projectTabs[showContent].title}-tab`}>
                                    {projectTabs[showContent].content(project, lang, dispatch)}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default UserViewProject;


const General = ({ project, lang }) => {
    const [technos, setTechnos] = useState(store.getState().user.projecttech.filter(techno => techno.projects_id === project.id));


    const detailsInfos = [
        { elm: 'name', value: project.name },
        { elm: 'status', value: project.status },
        { elm: 'category', value: project.categories.name },
        { elm: 'meet link', value: project.meetspacelink },
        { elm: 'created at', value: project.created_at },
    ];

    const detailsTechnos = [
        // { elm: 'budget', value: technos.length > 0 ? technos[0].budget : '...' },
        { elm: 'backend', value: technos.length > 0 ? technos[0].backend_name : '...' },
        { elm: 'Frontend', value: technos.length > 0 ? technos[0].frontend_name : '...' },
        { elm: 'database', value: technos.length > 0 ? technos[0].bdd : '...' },
        { elm: 'virtualizer', value: technos.length > 0 ? technos[0].virtualiseur : '...' },
        { elm: 'brocker', value: technos.length > 0 ? technos[0].brocker : '...' },
        { elm: 'security', value: technos.length > 0 ? technos[0].security : '...' },
    ];

    useEffect(() => {

        let tim = setInterval(() => {
            setTechnos(store.getState().user.projecttech.filter(techno => techno.projects_id === project.id));
        }, 1000);

        return () => {
            clearInterval(tim);
        };
    }, [project.id]);

    return <div className="row mb-4">
        <div className="list-group list-group-flush list-group-no-gutters">

            <div className="list-group-item">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="row col-md-divider align-items-md-center">

                                    <h3 className='mb-4 text-info'>{lang('items.go_work.view_project.general.title')}</h3>

                                    <div className="row">
                                        {
                                            detailsInfos.map((detail, key) => {
                                                return <span key={key} className='mb-3' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <span style={{ textTransform: 'capitalize' }} className='fw-bold'>{detail.elm} </span>
                                                    <span className='text-primary'>{
                                                        key !== 4
                                                            ? key !== 3
                                                                ? detail.value
                                                                : detail.value !== null
                                                                    ? <a href={detail.value} rel='noreferrer' target='_blank'>MeetSpace <i className="bi-box-arrow-up-right"></i></a>
                                                                    : <div className="my-alert my-alert-warning">{lang('items.go_work.view_project.general.meet_link_empty')}</div>
                                                            : formatDate(detail.value)
                                                    }</span>
                                                </span>
                                            })
                                        }
                                        <span>
                                            <span style={{ textTransform: 'capitalize' }} className='fw-bold'>{lang('items.go_work.view_project.general.desc')} </span><br />
                                            <span className='text-primary'>{project.description}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-6">
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {
                                makeStats(project).map((elm, key) => {
                                    return <MyCircle data={elm} key={key} />
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <hr />
        <div className="row">
            <div className="col-md-6">
                <div className="card-body">
                    <div className="row col-md-divider align-items-md-center">

                        <h3 className='mb-4'>{lang('items.go_work.view_project.general.techno.title')}</h3>
                        <div className="row">
                            {
                                technos.length !== 0
                                    ? detailsTechnos.map((detail, key) => {
                                        return <span key={key} className='mb-3' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span style={{ textTransform: 'capitalize' }} className='fw-bold'>{detail.elm} </span>
                                            <span className='text-primary'>{detail.value}</span>
                                        </span>
                                    })
                                    : <div className="my-alert my-alert-danger">{lang('items.go_work.view_project.general.techno.techno_empty')}</div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-6">
                <div className="card-body">
                    <div className="row col-md-divider align-items-md-center">

                        <h3 className='mb-4'>{lang('items.go_work.view_project.general.doc.title')}</h3>
                        <div className="row">
                            <div className="my-alert my-alert-danger">{lang('items.go_work.view_project.general.doc.file_empty')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

const Tasks = ({ dispatch, lang }) => {
    const [tasks, setTasks] = useState(store.getState().user.projecttasks);
    const [submit, setSubmit] = useState(false);
    const [ke, setKe] = useState(0);

    const handleSubmitCompletedTask = (task) => {
        setSubmit(true);

        dispatch(userActions.workerCompleteTask({ task_id: task.id, is_completed: 1 }));
        setTimeout(() => {
            setSubmit(false);
        }, 4000);
    };

    const handleUnsubmitCompletedTask = (task) => {
        setSubmit(true);

        dispatch(userActions.workerCompleteTask({ task_id: task.id, is_completed: 0 }));
        setTimeout(() => {
            setSubmit(false);
        }, 4000);
    };


    useEffect(() => {
        let tim = setInterval(() => {
            setTasks(store.getState().user.projecttasks);
        }, 1000);

        return () => {
            clearInterval(tim);
        };
    }, []);

    return <div className="row mb-1" style={{ overflowX: 'auto', height: 500 }}>
        <h2>
            {lang('items.go_work.view_project.task.title')}:&nbsp;
            <span style={{ fontSize: '1rem' }}>{lang('items.go_work.view_project.task.in1')}
                <span className='text-primary'> {tasks.length} </span>
                {
                    tasks.length > 1
                        ? lang('items.go_work.view_project.task.plur')
                        : lang('items.go_work.view_project.task.sing')
                }&nbsp;
                {lang('items.go_work.view_project.task.in2')}
            </span>
        </h2>
        {
            tasks.map((task, key) => {
                return <div key={key} className="col-lg-6 mb-2">
                    <div className="card card-bordered card-transition h-10">
                        <div className="card-body">
                            <div className="d-block d-sm-flex">
                                <div className="flex-shrink-0">
                                    <span className="svg-icon text-primary mb-2 mb-sm-0">
                                        <i className="fas fa-tasks fa-2x"></i>
                                    </span>
                                </div>

                                <div className="flex-grow-1 ms-sm-4">
                                    <h3 className="card-title" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>{task.name}</span>
                                        <span style={{ fontSize: '13px' }}>
                                            <span style={{ fontWeight: 'normal', fontStyle: 'italic', color: 'gray' }}>
                                                {lang('items.go_work.view_project.task.man_eva')}:</span> <span className={`badge bg-soft-${task.completed === 0 ? "danger" : "success"} text-${task.completed === 0 ? "danger" : "success"} text-bold`}>
                                                {task.completed === 0 ? lang('items.go_work.view_project.task.no_completed') : lang('items.go_work.view_project.task.completed')}
                                            </span>
                                        </span>
                                    </h3>
                                    <p className="card-text text-body">{task.description}</p>

                                    {
                                        task.users.filter(user => store.getState().user.user.id !== user.id).length !== 0
                                            ? <div className="d-flex">
                                                <div className="flex-shrink-0">
                                                    <div className="avatar-group avatar-group-xs">
                                                        {
                                                            task.users.map((user, ky) => store.getState().user.user.id !== user.id
                                                                ? <div key={ky} className="avatar avatar-xs avatar-circle">
                                                                    <img title={user.id === store.getState().user.user.id ? 'You' : user.name} className="avatar-img" src={getFileFromBack(user.avatar)} alt="descript_img" />
                                                                </div>
                                                                : null
                                                            )
                                                        }
                                                    </div>
                                                </div>

                                                <div className="flex-grow-1 ms-2">
                                                    <span className="card-text text-secondary mb-0" style={{ fontSize: '14px' }}>{lang('items.go_work.view_project.task.other_workers')}</span>
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            {
                                task.completed === 1
                                    ? <span className={`text-success mt-4`}><i className='fas fa-check-circle fa-2x'></i></span>
                                    : task.worker_completed === 1
                                        ? <>
                                            <button className="btn btn-outline-primary btn-sm mt-3" disabled={true}>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                &nbsp;{lang('items.go_work.view_project.task.btn.wait')}
                                            </button> &nbsp;&nbsp;
                                            <button
                                                disabled={submit}
                                                className="btn btn-outline-secondary btn-sm mt-3"
                                                onClick={() => handleUnsubmitCompletedTask(task)}
                                                onMouseOver={() => setKe(key)}
                                            >{lang('items.go_work.view_project.task.btn.cancel')}</button>
                                        </>
                                        : submit && ke === key
                                            ? <button className="btn btn-outline-primary btn-sm mt-3" disabled={submit}>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                {lang('items.go_work.view_project.task.btn.submiting')}
                                            </button>
                                            : calculProgressDate(task.start_date, task.end_date).val === "100%" || calculProgressDate(task.start_date, task.end_date).val === "0%"
                                                ? <button
                                                    className="btn btn-outline-danger btn-sm mt-3"
                                                    disabled
                                                >{lang('items.go_work.view_project.task.btn.submit')}</button>
                                                : <button
                                                    className="btn btn-outline-primary btn-sm mt-3"
                                                    onClick={() => handleSubmitCompletedTask(task)}
                                                    onMouseOver={() => setKe(key)}
                                                >{lang('items.go_work.view_project.task.btn.submit')}</button>
                            }
                            <TimeProgressBarTask task={task} />
                        </div>
                    </div>
                </div>
            })
        }
    </div>;
};

const Teams = ({ dispatch, project, lang }) => {

    useEffect(() => {

        // findUserTasks(project, store.getState().user.projectdisplaytasks);
    }, []);


    return <div className="row row-cols-1 row-cols-sm-2 mb-5">
        {
            findUserTasks(project, store.getState().user.projectdisplaytasks).map((user, key) => {
                return <div key={key} className="col mb-5">
                    <div className="card card-bordered h-10">
                        <div className="card-body">

                            <div className="row mb-3">
                                <div className="col">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                            <img className="avatar avatar-sm avatar-4x3" src={getFileFromBack(user.avatar)} alt="descriptionImage" />
                                        </div>

                                        <div className="flex-grow-1 ms-3">
                                            <h3 className="card-title">
                                                {user.name}
                                            </h3>
                                            <div className="progress" style={{ height: '20px' }}>
                                                <div className="progress-bar" role="progressbar" style={{ width: `${user.percent}%` }} aria-valuenow={parseInt(user.percent)} aria-valuemin="0" aria-valuemax="100">
                                                    {user.percent < 0.1 ? '' : `${parseInt(user.percent)} %`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-auto">
                                    {lang('items.go_work.view_project.team.total')}: {user.totalTask}
                                </div>
                            </div>

                            <div style={{ maxHeight: 300, overflow: 'auto' }}>
                                <ul className="list-group">
                                    {
                                        user.tasks.map((task, k) => {
                                            return <li key={k} className="list-group-item d-flex align-items-center" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    title={`${lang('items.go_work.view_project.team.desc')} ${task.name}`}>
                                                    <i className="bi-eye list-group-icon"></i> Tasks {task.name}
                                                </span>
                                                {
                                                    task.completed === 1
                                                        ? <span className="badge bg-soft-success text-success rounded-pill">{lang('items.go_work.view_project.team.completed')}</span>
                                                        : <span className="badge bg-soft-danger text-danger rounded-pill">{lang('items.go_work.view_project.team.no_completed')}</span>
                                                }
                                            </li>
                                        })
                                    }
                                </ul>

                            </div>

                        </div>
                    </div>
                </div>
            })
        }
    </div>
};







const makeStats = projet => {
    const tasks = projet.tasks;

    const taskCompleted = tasks.filter(task => task.completed === 1).length;

    const val = (taskCompleted / tasks.length) * 100;
    const evolution = tasks.length !== 0 ? val.toFixed(2) : '0';

    const finale = {
        title: 'Progression',
        titleColor: 'black',
        circleSize: 100,
        animate: true,
        time: 2,
        lineWidth: 40,
        evolution,
        radius: true,
        Percent: true,
        evolutionColor: '#b5285d',
        bgColor: '#eabed0',
        textColor: '#b5285d',
        percentSymbol: true
    }


    return [finale];

};


const findUserTasks = (project, tasks) => {

    var data = [];

    const users = project.users.filter(usr => usr.id !== store.getState().user.user.id && usr.role_id !== 2);

    [...users].forEach(user => {
        var inf = {
            avatar: user.avatar,
            name: user.name,
            tasks: []
        };

        [...tasks].forEach(tk => {

            [...tk.users].forEach(usr => {
                if (usr.id === user.id) {
                    inf.tasks.push(
                        {
                            name: tk.name,
                            completed: tk.completed,
                            description: tk.description,
                        }
                    )
                }
            })
        });

        inf = {
            ...inf, totalTask: inf.tasks.length, percent:
                isNaN(inf.tasks.filter(ts => ts.completed === 1).length / inf.tasks.length) ? 0 : (inf.tasks.filter(ts => ts.completed === 1).length / inf.tasks.length) * 100
        };

        data.push(inf);
    });

    return data;

}

