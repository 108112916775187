import React from "react";
import $ from 'jquery';
import { Outlet } from "react-router-dom";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { Projects } from '../constants';
import {
    adminActions,
    authActions,
    guestActions,
    managerActions,
    store,
    userActions,
} from "../store";
import appConfig from '../config';


const toastr = require('toastr/toastr');


const checkEmailFormat = (email) => {
    var Reg = /^[a-zA-Z]{1}[a-zA-Z0-9-_]{1,}[@][a-z]{1,}[.a-z]{1,}[.][a-z]{2,4}$/g;
    return Reg.test(email);
};

const checkPasswordFormat = (password) => {
    // var Reg = /^[a-zA-Z0-9!@#$%^&*() _+=\-[]{}'\|";:\/?.>,<~]{8,}$/g;
    var Reg = /^[a-zA-Z0-9!@#$%^&*() _+=\-\[\]{}'\\|\";:\/?.>,<~]{8,}$/g;
    return Reg.test(password);
};

const checkPhoneNumberFormat = (phone) => {
    var Reg1 = /^[6]{1}[5]{1}[01234]{1}[0-9]{6}$/g;
    var Reg2 = /^[6]{1}[78]{1}[0-9]{7}$/g;

    var Reg3 = /^[6]{1}[5]{1}[56789]{1}[0-9]{6}$/g;
    var Reg4 = /^[6]{1}[9]{1}[0-9]{7}$/g;
    var Reg5 = /^[6]{1}[6]{1}[0-9]{7}$/g;

    if (Reg1.test(phone)) {
        return { operator: "MTN", verif: true };
    } else if (Reg2.test(phone)) {
        return { operator: "MTN", verif: true };
    } else if (Reg3.test(phone)) {
        return { operator: "ORANGE", verif: true };
    } else if (Reg4.test(phone)) {
        return { operator: "ORANGE", verif: true };
    } else if (Reg5.test(phone)) {
        return { operator: "NEXTELL", verif: true };
    } else {
        return false;
    }
};


const choosePosition = str => {
    return str === 'bfw'
        ? "toast-bottom-full-width"
        : str === 'tl'
            ? "toast-top-left"
            : str === 'tc'
                ? "toast-top-center"
                : str === 'tfw'
                    ? "toast-top-full-width"
                    : str === 'br'
                        ? "toast-bottom-right"
                        : str === 'bl'
                            ? "toast-bottom-left"
                            : str === 'bc'
                                ? "toast-bottom-center"
                                : "toast-top-right"
};

const Toast = (type, message, position = null, close = false, progressBar = false, duplicate = false, duration = 5000, persist = false) => {
    toastr.options = {
        "closeButton": close,
        "debug": false,
        "newestOnTop": false,
        "progressBar": progressBar,
        "positionClass": choosePosition(position),
        "preventDuplicates": duplicate,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": persist ? 0 : duration,
        "extendedTimeOut": persist ? 0 : "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "slideDown",
        "hideMethod": "slideUp"
    };

    toastr[type](message);
};

const InterLang = [
    {
        flag: "./assets/vendor/flag-icon-css/flags/1x1/gb.svg",
        alt: "en_flag",
        code: "en",
        title: "English",
    },
    {
        flag: "./assets/vendor/flag-icon-css/flags/1x1/fr.svg",
        alt: "fr_flag",
        code: "fr",
        title: "Francais",
    }
];


const I18n = () => {
    return i18n
        .use(initReactI18next)
        .use(I18nextBrowserLanguageDetector)
        .use(HttpApi)
        .init({
            supportedLngs: ["en", "fr"],
            fallbackLng: "en",
            detection: {
                order: [
                    "localStorage",
                    "cookie",
                    "htmlTag",
                    "querystring",
                    "path",
                    "sessionStorage",
                    "subdomain",
                ],
                caches: ["localStorage"],
            },
            backend: {
                loadPath: "/assets/locales/{{lng}}/translation.json",
            },
            react: {
                useSuspense: false,
            },
        });
};

const Slang = (prefix) => {
    const { t } = useTranslation("translation", {
        keyPrefix: prefix,
    });
    return t;
};

const SlangObj = (prefix) => {
    const { t } = useTranslation();

    return t(prefix, { returnObjects: true });

};


const AppendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.type = 'text/javascript';
    script.async = true;
    document.body.appendChild(script);
}

const RemoveAppendedScript = () => {
    $('script').remove();
};

const projectsSliptTab = () => {
    let dataTab = Projects;
    let a = 0, tab = [], arr = [], size = dataTab.length;

    if (size % 3 !== 0) {
        let rest = size % 3;
        for (let i = 0; i < rest; i++) {
            arr[i] = dataTab[size - (i + 1)];
        }
    }
    arr.reverse();

    do {
        tab[a] = dataTab.slice(0, 3);
        dataTab = dataTab.slice(3);
        a++;
    } while (a < (((size - (size % 3)) / 3) + 1));
    return tab;
};

const sliptTab = (dataTab, modVal) => {
    let a = 0, tab = [], arr = [], size = dataTab.length;

    if (size % modVal !== 0) {
        let rest = size % modVal;
        for (let i = 0; i < rest; i++) {
            arr[i] = dataTab[size - (i + 1)];
        }
    }
    arr.reverse();

    do {
        tab[a] = dataTab.slice(0, modVal);
        dataTab = dataTab.slice(modVal);
        a++;
    } while (a < (((size - (size % modVal)) / modVal) + 1));
    return tab;
};

const history = {
    navigate: null,
    location: null
};


const RoleAccess = ({ roles = [] }) => {

    let user = store.getState().user.user.id !== undefined ? store.getState().user.user : null;
    if (user) {
        if (roles.includes(user.role_id)) {
            console.log(roles.includes(user.role_id))
            return <Outlet />
        } else {
            // return <Navigate to="/" replace />;
        }
    } else {
        // return <Navigate to="/" replace />;
    }
};

const formatDate = (dateString, time = false, lang = 'en') => {

    let months = ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let mois = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Decembre"];

    let d = new Date(dateString);
    let fr = `${d.getDate()} ${mois[d.getMonth()]} ${d.getFullYear()} ${time ? `at ${d.getHours() < 10 ? `0${d.getHours()}` : d.getHours()}:${d.getMinutes()}:${d.getSeconds()}` : ''}`;
    let en = `${d.getDate()}, ${months[d.getMonth()]} ${d.getFullYear()} ${time ? `at ${d.getHours() < 10 ? `0${d.getHours()}` : d.getHours()}:${d.getMinutes()}:${d.getSeconds()}` : ''}`;

    return lang === 'fr' ? fr : lang === 'en' ? en : en;
};

const formatDateTime = dateString => {
    let months = ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    let d = new Date(dateString);
    return `${d.getDate()}, ${months[d.getMonth()]} ${d.getFullYear()} ${d.getHours() < 10 ? `0${d.getHours()}` : d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
};


const progressValue = tasks => {
    let value = 0;
    if (tasks.length !== 0) {
        tasks.map(item => {
            if (item.completed === 1) {
                value++;
            }
        })
    }
    return ((value / tasks.length) * 100).toPrecision(4);
};

const uplaodFile = (url, method, data, dispatch = null, identifier = 'avatar', setShowLoading = null) => {
    $.ajax({
        url: url,
        type: method,
        data: data,
        dataType: 'json',
        contentType: false,
        processData: false,
        beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", `Bearer ${store.getState().auth.token}`);
        },
        success: data => {
            Toast('success', 'File uploaded successfully', 'tr', false, false, false, 3000, false);
            if (identifier === 'avatar') {
                setTimeout(() => {
                    dispatch(userActions.getUser());
                }, 2700);
            }
            setShowLoading(false);
        }
    });
}

const getFileFromBack = path => {
    if (path.includes('https://firebasestorage') || path.includes('https://lh3.googleusercontent')) {
        return path;
    } else if (path.includes('/storage')) {
        return appConfig.backendUrl + path;
    }
    return appConfig.apiUrl + '/' + path;
};


const arrayDiff = (array1, array2, attr) => {
    return array1.filter(object1 => {
        return !array2.some(object2 => {
            return eval("object1." + attr + " == object2." + attr);
        });
    });
}

const retrieveUserInProject = (userId, projects) => {
    let arr_pro = [];

    projects.filter(project => {
        let size = project.users.length;

        for (let i = 0; i < size; i++) {
            if (project.users[i].id === userId) {
                arr_pro = [...arr_pro, project];
            }
        }
    });

    return arr_pro;
};

const calculateTime = (daytime1, daytime2 = Date.now()) => {
    const dayBetween = (d1, d2) => {
        const oneDay = 24 * 60 * 60 * 1000;
        return Math.round(Math.abs((d1 - d2) / oneDay));
    };

    const da1 = new Date(daytime1);
    const da2 = new Date(daytime2);

    const time1 = da1.getTime();
    const time2 = da2.getTime();

    const diff = (time2 - time1) / 1000;

    let days = dayBetween(time1, time2);

    return diff < 60
        ? `${Math.trunc(diff)} ${diff < 2 ? 'second' : 'seconds'}`
        : diff > 60 && diff < 3600
            ? `${Math.trunc(diff / 60)} ${Math.trunc(diff / 60) < 2 ? 'min' : 'mins'}`
            : diff > 3600 && diff < 86400
                ? `${Math.trunc(diff / 3600)} ${Math.trunc(diff / 3600) < 2 ? 'hour' : 'hours'}`
                : diff > 86400 && diff < 2678400
                    ? `${Math.trunc(days)} ${Math.trunc(days) < 2 ? 'day' : 'days'}`
                    : diff > 2592000 && diff < 31104000
                        ? `${Math.trunc(diff / 2592000)} ${Math.trunc(diff / 2592000) < 2 ? 'month' : 'months'}`
                        : `${Math.trunc(diff / 31104000)} ${Math.trunc(diff / 31104000) < 2 ? 'year' : 'years'}`
};


const passwordFormatReg = text => {
    let upper = /[A-Z]{1,}/gs;
    let lower = /[a-z]{1,}/gs;
    let number = /[0-9]{1,}/gs;
    // let special = /[!@#$%^&*() _+=\-[]{}'|";:\/?.>,<~]/gs;
    let special = /[!@#$%^&*() _+=\-\[\]{}'\\|\";:\/?.>,<~]/gs;

    return {
        upper: upper.test(text),
        lower: lower.test(text),
        number: number.test(text),
        special: special.test(text),
        size: text.length >= 8,
    };
};

const resetStore = () => {
    store.dispatch(adminActions.resetSlice());
    store.dispatch(authActions.resetSlice());
    store.dispatch(guestActions.resetSlice());
    store.dispatch(managerActions.resetSlice());
    store.dispatch(userActions.resetSlice());
};

const closeTab = () => { window.close(``, `_parent`, ``); };

const checkPresenceOfUser = () => {
    let check = store.getState().user.user.id === undefined && store.getState().auth.token !== null;

    if (store.getState().user.user.id && store.getState().auth.token) {
        sessionStorage.setItem('reload', '0');
    }

    if (sessionStorage.getItem('reload') === null) {
        sessionStorage.setItem('reload', '0');
    }

    if (check && parseInt(sessionStorage.getItem('reload')) === 0) {
        sessionStorage.setItem('reload', '1');
        // store.dispatch(adminActions.resetSlice());
        // store.dispatch(authActions.resetSlice());
        // store.dispatch(guestActions.resetSlice());
        // store.dispatch(managerActions.resetSlice());
        // store.dispatch(userActions.resetSlice());
    }

};

const paypalExtractDataFromUrl = url => {
    let getData = url.split('?')[1].split('&');
    let token = getData[0].split('=')[1];
    let PayerId = getData[1].split('=')[1];

    return { token, PayerId };
};


const numberFormatter = (number, locale = "fr-FR") => {
    return new Intl.NumberFormat(locale).format(number);
};


const google_window_login = (loading) => {
    loading(true);
    $.getJSON(
        appConfig.google_auth,
        data => {
            window.location.href = data.data.url
            // window.open(data.data.url, "_blank", "toolbar=no,scrollbars=no, resizable=no, top=0, left=200, width=600, height=700");
        }
    );
};

/* const parseUserAgent = async (datas, ip = null, user_id = null) => {
    var device = {};

    var userParserToken1 = "ffaf55285cfa4515c6b01bbc18eb13aa86d385b1711a902b3a";
    var userParserToken2 = "92d5fc6d1de4baee5760f38caa7c7a1f80ac9deba803f0dad1";

    const setdata = (data) => {

        device['ip_address'] = data.query;
        device['user_id'] = user_id;
        device['browser'] = data.browser;
        device['browser_version'] = data.browser_version;
        device['device_type'] = data.device_type;
        device['os'] = data.os;
        device['osVersion'] = data.osVersion;
        device['currency'] = data.currency;
        device['country_code'] = data.country_code;

        if (data.ua.includes("Linux")) {
            device['os_family'] = "GNU/Linux";
        } else if (data.ua.includes("Windows")) {
            device['os_family'] = "Windows";
        } else if (data.ua.includes("Mac") || data.ua.includes("Macintosh")) {
            device['os_family'] = "Mac";
        }

        device['payload'] = { ...data, os_family: device['os_family'] };
    }


    await $.getJSON(
        // `${appConfig.userAgentParser}ua=${ua}&ip=${ip}&api_key=${userParserToken2}`,
        `${appConfig.userAgentParser}fields=28962815`,
        data => {
            setdata({
                query: data.query,
                user_id: user_id,
                browser: datas.family,
                browser_version: datas.userAgent,
                device_type: datas.device,
                os: datas.os,
                country_code: data.countryCode,
                osVersion: datas.versionMajor,
                ua: datas.userAgentRaw,
                currency: data.currency,
                informations: {
                    location: data,
                    data_agent: datas
                }
            });
        }
    );
    /**
     * On peut remplacer fields par ceci:
     * http://ip-api.com/json/{query}?fields=status,message,continent,continentCode,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,currency,isp,org,as,mobile,hosting,query
     * fields=28962815
     *//*
return device;
}*/

const parseUserAgent = async (ua, ip, user_id = null) => {
    var device = {};

    var userParserToken1 = "ffaf55285cfa4515c6b01bbc18eb13aa86d385b1711a902b3a";
    var userParserToken2 = "92d5fc6d1de4baee5760f38caa7c7a1f80ac9deba803f0dad1";

    function setdata(data) {
        device['ip_address'] = ip;
        device['user_id'] = user_id;
        device['browser'] = data.browser.name;
        device['browser_version'] = data.browser.version;
        device['device_type'] = data.device.type;
        device['os'] = data.operatingSystem.name;
        device['os'] = data.operatingSystem.name;
        device['osVersion'] = data.operatingSystem.version;

        if (ua.userAgentRaw.includes("Linux")) {
            device['os_family'] = "GNU/Linux";
        } else if (ua.userAgentRaw.includes("Windows")) {
            device['os_family'] = "Windows";
        } else if (ua.userAgentRaw.includes("Mac") || ua.userAgentRaw.includes("Macintosh")) {
            device['os_family'] = "Mac";
        }

        device['payload'] = { ...data, os_family: device['os_family'] };
    }
    await $.getJSON(
        `${appConfig.userAgentParser}ua=${ua.userAgentRaw}&ip=${ip}&api_key=${userParserToken2}`,
        data => {

            setdata(data);
        }
    );

    return device;
}

const calculProgressDate = (date1, date2, current_date=null) => {
    let d = new Date();
    let now = `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()} ${d.getHours() < 10 ? `0${d.getHours()}` : d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
    
    let t1 = new Date(date1);
    let t2 = new Date(date2);
    let td = new Date(now);

    const tps1 = t1.getTime();
    const tps2 = t2.getTime();
    const tps = td.getTime();




    let fin_val = tps < tps1 ? 0 : ((tps - tps1) / ((tps2 - tps) + (tps - tps1))) * 100;
    // let rest = new Date(tps2 - (tps - tps1));

    let r_jr = `${(tps2 - tps) / (1000 * 3600 * 24)}`.split('.')[0];
    let r_h = `${parseFloat(`0.${`${(tps2 - tps) / (1000 * 3600 * 24)}`.split('.')[1]}`) * 24}`.split('.')[0];
    let r_min = `${parseFloat(`0.${`${parseFloat(`0.${`${(tps2 - tps) / (1000 * 3600 * 24)}`.split('.')[1]}`) * 24}`.split('.')[1]}`) * 60}`.split('.')[0];
    let r_sec = `${parseFloat(`0.${`${parseFloat(`0.${`${parseFloat(`0.${`${(tps2 - tps) / (1000 * 3600 * 24)}`.split('.')[1]}`) * 24}`.split('.')[1]}`) * 60}`.split('.')[1]}`) * 60}`.split('.')[0];

    let str_time = tps < tps1 ? `Not started` : tps > tps2 ? 'Finished' : `${r_jr <= 1 ? `${r_jr} Day` : `${r_jr} Days`}, ${r_h}:${r_min}:${r_sec}`;

    if (tps < tps1) {
        return { color: "#154822", val: `0%`, rest: str_time };
    }

    if (tps === tps2 || tps > tps2) {
        return { color: "#C20000", val: `100%`, rest: str_time };
    }

    let ret = fin_val >= 0 && fin_val < 6
        ? { color: "#154822", val: `${fin_val === 0 ? "0" : fin_val}%`, rest: str_time }
        : fin_val > 5 && fin_val < 11
            ? { color: "#246C39", val: `${fin_val}%`, rest: str_time }
            : fin_val > 10 && fin_val < 16
                ? { color: "#359E53", val: `${fin_val}%`, rest: str_time }
                : fin_val > 15 && fin_val < 21
                    ? { color: "#48D670", val: `${fin_val}%`, rest: str_time }
                    : fin_val > 20 && fin_val < 26
                        ? { color: "#51F17E", val: `${fin_val}%`, rest: str_time }
                        : fin_val > 25 && fin_val < 31
                            ? { color: "#BDFCCF", val: `${fin_val}%`, rest: str_time }
                            : fin_val > 30 && fin_val < 36
                                ? { color: "#BDF9F8", val: `${fin_val}%`, rest: str_time }
                                : fin_val > 35 && fin_val < 41
                                    ? { color: "#40EBE8", val: `${fin_val}%`, rest: str_time }
                                    : fin_val > 40 && fin_val < 46
                                        ? { color: "#32B6B4", val: `${fin_val}%`, rest: str_time }
                                        : fin_val > 45 && fin_val < 51
                                            ? { color: "#227D7C", val: `${fin_val}%`, rest: str_time }
                                            : fin_val > 50 && fin_val < 56
                                                ? { color: "#175554", val: `${fin_val}%`, rest: str_time }
                                                : fin_val > 55 && fin_val < 61
                                                    ? { color: "#113F3E", val: `${fin_val}%`, rest: str_time }
                                                    : fin_val > 60 && fin_val < 66
                                                        ? { color: "#473514", val: `${fin_val}%`, rest: str_time }
                                                        : fin_val > 65 && fin_val < 71
                                                            ? { color: "#8B6C27", val: `${fin_val}%`, rest: str_time }
                                                            : fin_val > 70 && fin_val < 76
                                                                ? { color: "#E3B740", val: `${fin_val}%`, rest: str_time }
                                                                : fin_val > 75 && fin_val < 81
                                                                    ? { color: "#FECF49", val: `${fin_val}%`, rest: str_time }
                                                                    : fin_val > 80 && fin_val < 86
                                                                        ? { color: "#FFC7C7", val: `${fin_val}%`, rest: str_time }
                                                                        : fin_val > 85 && fin_val < 91
                                                                            ? { color: "#FFA5A5", val: `${fin_val}%`, rest: str_time }
                                                                            : fin_val > 90 && fin_val < 96
                                                                                ? { color: "#FF4444", val: `${fin_val}%`, rest: str_time }
                                                                                : { color: "#C20000", val: `100%`, rest: str_time };
    return ret;

}



export {
    parseUserAgent,
    calculProgressDate,
    google_window_login,
    paypalExtractDataFromUrl,
    checkPresenceOfUser,
    AppendScript,
    RemoveAppendedScript,
    InterLang,
    numberFormatter,
    SlangObj,
    resetStore,
    closeTab,
    formatDate,
    formatDateTime,
    arrayDiff,
    history,
    getFileFromBack,
    uplaodFile,
    RoleAccess,
    I18n,
    Slang,
    projectsSliptTab,
    Toast,
    checkEmailFormat,
    checkPhoneNumberFormat,
    checkPasswordFormat,
    progressValue,
    sliptTab,
    retrieveUserInProject,
    calculateTime,
    passwordFormatReg,
};