const locations = [
    { selected: false, code: "AF", img: 'af', name: "Afghanistan" },
    { selected: false, code: "AX", img: 'ax', name: "Aland Islands" },
    { selected: false, code: "AL", img: 'al', name: "Albania" },
    { selected: false, code: "DZ", img: 'dz', name: "Algeria" },
    { selected: false, code: "AS", img: 'as', name: "American Samoa" },
    { selected: false, code: "AD", img: 'ad', name: "Andorra" },
    { selected: false, code: "AO", img: 'ao', name: "Angola" },
    { selected: false, code: "AI", img: 'ai', name: "Anguilla" },
    { selected: false, code: "AG", img: 'ag', name: "Antigua and Barbuda" },
    { selected: false, code: "AR", img: 'ar', name: "Argentina" },
    { selected: false, code: "AM", img: 'am', name: "Armenia" },
    { selected: false, code: "AW", img: 'aw', name: "Aruba" },
    { selected: false, code: "AU", img: 'au', name: "Australia" },
    { selected: false, code: "AT", img: 'at', name: "Austria" },
    { selected: false, code: "AZ", img: 'az', name: "Azerbaijan" },
    { selected: false, code: "BS", img: 'bs', name: "Bahamas" },
    { selected: false, code: "BH", img: 'bh', name: "Bahrain" },
    { selected: false, code: "BD", img: 'bd', name: "Bangladesh" },
    { selected: false, code: "BB", img: 'bb', name: "Barbados" },
    { selected: false, code: "BY", img: 'by', name: "Belarus" },
    { selected: false, code: "BE", img: 'be', name: "Belgium" },
    { selected: false, code: "BZ", img: 'bz', name: "Belize" },
    { selected: false, code: "BJ", img: 'bj', name: "Benin" },
    { selected: false, code: "BM", img: 'bm', name: "Bermuda" },
    { selected: false, code: "BT", img: 'bt', name: "Bhutan" },
    { selected: false, code: "BO", img: 'bo', name: "Bolivia (Plurinational State of)" },
    { selected: false, code: "BQ", img: 'bq', name: "Bonaire, Sint Eustatius and Saba" },
    { selected: false, code: "BA", img: 'ba', name: "Bosnia and Herzegovina" },
    { selected: false, code: "BW", img: 'bw', name: "Botswana" },
    { selected: false, code: "BR", img: 'br', name: "Brazil" },
    { selected: false, code: "IO", img: 'io', name: "British Indian Ocean Territory" },
    { selected: false, code: "BN", img: 'bn', name: "Brunei Darussalam" },
    { selected: false, code: "BG", img: 'bg', name: "Bulgaria" },
    { selected: false, code: "BF", img: 'bf', name: "Faso" },
    { selected: false, code: "BI", img: 'bi', name: "Burundi" },
    { selected: false, code: "CV", img: 'cv', name: "Cabo Verde" },
    { selected: false, code: "KH", img: 'kh', name: "Cambodia" },
    { selected: true, code: "CM", img: 'cm', name: "Cameroon" },
    { selected: false, code: "CA", img: 'ca', name: "Canada" },
    { selected: false, code: "KY", img: 'ky', name: "Cayman Islands" },
    { selected: false, code: "CF", img: 'cf', name: "Central African Republic" },
    { selected: false, code: "TD", img: 'td', name: "Chad" },
    { selected: false, code: "CL", img: 'cl', name: "Chile" },
    { selected: false, code: "CN", img: 'cn', name: "China" },
    { selected: false, code: "CX", img: 'cx', name: "Christmas Island" },
    { selected: false, code: "CC", img: 'cc', name: "Cocos (Keeling) Islands" },
    { selected: false, code: "CO", img: 'co', name: "Colombia" },
    { selected: false, code: "KM", img: 'km', name: "Comoros" },
    { selected: false, code: "CK", img: 'ck', name: "Cook Islands" },
    { selected: false, code: "CR", img: 'cr', name: "Costa Rica" },
    { selected: false, code: "HR", img: 'hr', name: "Croatia" },
    { selected: false, code: "CU", img: 'cu', name: "Cuba" },
    { selected: false, code: "CW", img: 'cw', name: "Curaçao" },
    { selected: false, code: "CY", img: 'cy', name: "Cyprus" },
    { selected: false, code: "CZ", img: 'cz', name: "Czech Republic" },
    { selected: false, code: "CI", img: 'ci', name: "Côte d&apos;Ivoire" },
    { selected: false, code: "CD", img: 'cd', name: "Democratic Republic of the Congo" },
    { selected: false, code: "DK", img: 'dk', name: "Denmark" },
    { selected: false, code: "DJ", img: 'dj', name: "Djibouti" },
    { selected: false, code: "DM", img: 'dm', name: "Dominica" },
    { selected: false, code: "DO", img: 'do', name: "Dominican Republic" },
    { selected: false, code: "EC", img: 'ec', name: "Ecuador" },
    { selected: false, code: "EG", img: 'eg', name: "Egypt" },
    { selected: false, code: "SV", img: 'sv', name: "El Salvador" },
    { selected: false, code: "GB-ENG", img: "gb-eng", name: "England" },
    { selected: false, code: "GQ", img: 'gq', name: "Equatorial Guinea" },
    { selected: false, code: "ER", img: 'er', name: "Eritrea" },
    { selected: false, code: "EE", img: 'ee', name: "Estonia" },
    { selected: false, code: "ET", img: 'et', name: "Ethiopia" },
    { selected: false, code: "FK", img: 'fk', name: "Falkland Islands" },
    { selected: false, code: "FO", img: 'fo', name: "Faroe Islands" },
    { selected: false, code: "FM", img: 'fm', name: "Federated States of Micronesia" },
    { selected: false, code: "FJ", img: 'fj', name: "Fiji" },
    { selected: false, code: "FI", img: 'fi', name: "Finland" },
    { selected: false, code: "FR", img: 'fr', name: "France" },
    { selected: false, code: "GF", img: 'gf', name: "French Guiana" },
    { selected: false, code: "PF", img: 'pf', name: "French Polynesia" },
    { selected: false, code: "TF", img: 'tf', name: "French Southern Territories" },
    { selected: false, code: "GA", img: 'ga', name: "Gabon" },
    { selected: false, code: "GM", img: 'gm', name: "Gambia" },
    { selected: false, code: "GE", img: 'ge', name: "Georgia" },
    { selected: false, code: "DE", img: 'de', name: "Germany" },
    { selected: false, code: "GH", img: 'gh', name: "Ghana" },
    { selected: false, code: "GI", img: 'gi', name: "Gibraltar" },
    { selected: false, code: "GR", img: 'gr', name: "Greece" },
    { selected: false, code: "GL", img: 'gl', name: "Greenland" },
    { selected: false, code: "GD", img: 'gd', name: "Grenada" },
    { selected: false, code: "GP", img: 'gp', name: "Guadeloupe" },
    { selected: false, code: "GU", img: 'gu', name: "Guam" },
    { selected: false, code: "GT", img: 'gt', name: "Guatemala" },
    { selected: false, code: "GG", img: 'gg', name: "Guernsey" },
    { selected: false, code: "GN", img: 'gn', name: "Guinea" },
    { selected: false, code: "GW", img: 'gw', name: "Guinea-Bissau" },
    { selected: false, code: "GY", img: 'gy', name: "Guyana" },
    { selected: false, code: "HT", img: 'ht', name: "Haiti" },
    { selected: false, code: "VA", img: 'va', name: "Holy See" },
    { selected: false, code: "HN", img: 'hn', name: "Honduras" },
    { selected: false, code: "HK", img: 'hk', name: "Hong Kong" },
    { selected: false, code: "HU", img: 'hu', name: "Hungary" },
    { selected: false, code: "IS", img: 'is', name: "Iceland" },
    { selected: false, code: "IN", img: 'in', name: "India" },
    { selected: false, code: "ID", img: 'id', name: "Indonesia" },
    { selected: false, code: "IR", img: 'ir', name: "Iran (Islamic Republic of Iran)" },
    { selected: false, code: "IQ", img: 'iq', name: "Iraq" },
    { selected: false, code: "IE", img: 'ie', name: "Ireland" },
    { selected: false, code: "IM", img: 'im', name: "Isle of Man" },
    { selected: false, code: "IL", img: 'il', name: "Israel" },
    { selected: false, code: "IT", img: 'it', name: "Italy" },
    { selected: false, code: "JM", img: 'jm', name: "Jamaica" },
    { selected: false, code: "JP", img: 'jp', name: "Japan" },
    { selected: false, code: "JE", img: 'je', name: "Jersey" },
    { selected: false, code: "JO", img: 'jo', name: "Jordan" },
    { selected: false, code: "KZ", img: 'kz', name: "Kazakhstan" },
    { selected: false, code: "KE", img: 'ke', name: "Kenya" },
    { selected: false, code: "KI", img: 'ki', name: "Kiribati" },
    { selected: false, code: "KW", img: 'kw', name: "Kuwait" },
    { selected: false, code: "KG", img: 'kg', name: "Kyrgyzstan" },
    { selected: false, code: "LA", img: 'la', name: "Laos" },
    { selected: false, code: "LV", img: 'lv', name: "Latvia" },
    { selected: false, code: "LB", img: 'lb', name: "Lebanon" },
    { selected: false, code: "LS", img: 'ls', name: "Lesotho" },
    { selected: false, code: "LR", img: 'lr', name: "Liberia" },
    { selected: false, code: "LY", img: 'ly', name: "Libya" },
    { selected: false, code: "LI", img: 'li', name: "Liechtenstein" },
    { selected: false, code: "LT", img: 'lt', name: "Lithuania" },
    { selected: false, code: "LU", img: 'lu', name: "Luxembourg" },
    { selected: false, code: "MO", img: 'mo', name: "Macau" },
    { selected: false, code: "MG", img: 'mg', name: "Madagascar" },
    { selected: false, code: "MW", img: 'mw', name: "Malawi" },
    { selected: false, code: "MY", img: 'my', name: "Malaysia" },
    { selected: false, code: "MV", img: 'mv', name: "Maldives" },
    { selected: false, code: "ML", img: 'ml', name: "Mali" },
    { selected: false, code: "MT", img: 'mt', name: "Malta" },
    { selected: false, code: "MH", img: 'mh', name: "Marshall Islands" },
    { selected: false, code: "MQ", img: 'mq', name: "Martinique" },
    { selected: false, code: "MR", img: 'mr', name: "Mauritania" },
    { selected: false, code: "MU", img: 'mu', name: "Mauritius" },
    { selected: false, code: "YT", img: 'yt', name: "Mayotte" },
    { selected: false, code: "MX", img: 'mx', name: "Mexico" },
    { selected: false, code: "MD", img: 'md', name: "Moldova" },
    { selected: false, code: "MC", img: 'mc', name: "Monaco" },
    { selected: false, code: "MN", img: 'mn', name: "Mongolia" },
    { selected: false, code: "ME", img: 'me', name: "Montenegro" },
    { selected: false, code: "MS", img: 'ms', name: "Montserrat" },
    { selected: false, code: "MA", img: 'ma', name: "Morocco" },
    { selected: false, code: "MZ", img: 'mz', name: "Mozambique" },
    { selected: false, code: "MM", img: 'mm', name: "Myanmar" },
    { selected: false, code: "NA", img: 'na', name: "Namibia" },
    { selected: false, code: "NR", img: 'nr', name: "Nauru" },
    { selected: false, code: "NP", img: 'np', name: "Nepal" },
    { selected: false, code: "NL", img: 'nl', name: "Netherlands" },
    { selected: false, code: "NC", img: 'nc', name: "New Caledonia" },
    { selected: false, code: "NZ", img: 'nz', name: "New Zealand" },
    { selected: false, code: "NI", img: 'ni', name: "Nicaragua" },
    { selected: false, code: "NE", img: 'ne', name: "Niger" },
    { selected: false, code: "NG", img: 'ng', name: "Nigeria" },
    { selected: false, code: "NU", img: 'nu', name: "Niue" },
    { selected: false, code: "NF", img: 'nf', name: "Norfolk Island" },
    { selected: false, code: "KP", img: 'kp', name: "North Korea" },
    { selected: false, code: "MK", img: 'mk', name: "North Macedonia" },
    { selected: false, code: "GB-NIR", img: "gb-nir", name: "Northern Ireland" },
    { selected: false, code: "MP", img: 'mp', name: "Northern Mariana Islands" },
    { selected: false, code: "NO", img: 'no', name: "Norway" },
    { selected: false, code: "OM", img: 'om', name: "Oman" },
    { selected: false, code: "PK", img: 'pk', name: "Pakistan" },
    { selected: false, code: "PW", img: 'pw', name: "Palau" },
    { selected: false, code: "PA", img: 'pa', name: "Panama" },
    { selected: false, code: "PG", img: 'pg', name: "Papua New Guinea" },
    { selected: false, code: "PY", img: 'py', name: "Paraguay" },
    { selected: false, code: "PE", img: 'pe', name: "Peru" },
    { selected: false, code: "PH", img: 'ph', name: "Philippines" },
    { selected: false, code: "PN", img: 'pn', name: "Pitcairn" },
    { selected: false, code: "PL", img: 'pl', name: "Poland" },
    { selected: false, code: "PT", img: 'pt', name: "Portugal" },
    { selected: false, code: "PR", img: 'pr', name: "Puerto Rico" },
    { selected: false, code: "QA", img: 'qa', name: "Qatar" },
    { selected: false, code: "CG", img: 'cg', name: "Republic of the Congo" },
    { selected: false, code: "RO", img: 'ro', name: "Romania" },
    { selected: false, code: "RU", img: 'ru', name: "Russia" },
    { selected: false, code: "RW", img: 'rw', name: "Rwanda" },
    { selected: false, code: "RE", img: 're', name: "Réunion" },
    { selected: false, code: "BL", img: 'bl', name: "Saint Barthélemy" },
    { selected: false, code: "SH", img: 'sh', name: "Saint Helena, Ascension and Tristan da Cunha" },
    { selected: false, code: "KN", img: 'kn', name: "Saint Kitts and Nevis" },
    { selected: false, code: "LC", img: 'lc', name: "Saint Lucia" },
    { selected: false, code: "MF", img: 'mf', name: "Saint Martin" },
    { selected: false, code: "PM", img: 'pm', name: "Saint Pierre and Miquelon" },
    { selected: false, code: "VC", img: 'vc', name: "Saint Vincent and the Grenadines" },
    { selected: false, code: "WS", img: 'ws', name: "Samoa" },
    { selected: false, code: "SM", img: 'sm', name: "San Marino" },
    { selected: false, code: "ST", img: 'st', name: "Sao Tome and Principe" },
    { selected: false, code: "SA", img: 'sa', name: "Saudi Arabia" },
    { selected: false, code: "GB-SCT", img: "gb-sct", name: "Scotland" },
    { selected: false, code: "SN", img: 'sn', name: "Senegal" },
    { selected: false, code: "RS", img: 'rs', name: "Serbia" },
    { selected: false, code: "SC", img: 'sc', name: "Seychelles" },
    { selected: false, code: "SL", img: 'sl', name: "Sierra Leone" },
    { selected: false, code: "SG", img: 'sg', name: "Singapore" },
    { selected: false, code: "SX", img: 'sx', name: "Sint Maarten" },
    { selected: false, code: "SK", img: 'sk', name: "Slovakia" },
    { selected: false, code: "SI", img: 'si', name: "Slovenia" },
    { selected: false, code: "SB", img: 'sb', name: "Solomon Islands" },
    { selected: false, code: "SO", img: 'so', name: "Somalia" },
    { selected: false, code: "ZA", img: 'za', name: "South Africa" },
    { selected: false, code: "GS", img: 'gs', name: "South Georgia and the South Sandwich Islands" },
    { selected: false, code: "KR", img: 'kr', name: "South Korea" },
    { selected: false, code: "SS", img: 'ss', name: "South Sudan" },
    { selected: false, code: "ES", img: 'es', name: "Spain" },
    { selected: false, code: "LK", img: 'lk', name: "Sri Lanka" },
    { selected: false, code: "PS", img: 'ps', name: "State of Palestine" },
    { selected: false, code: "SD", img: 'sd', name: "Sudan" },
    { selected: false, code: "SR", img: 'sr', name: "Suriname" },
    { selected: false, code: "SJ", img: 'sj', name: "Svalbard and Jan Mayen" },
    { selected: false, code: "SZ", img: 'sz', name: "Swaziland" },
    { selected: false, code: "SE", img: 'se', name: "Sweden" },
    { selected: false, code: "CH", img: 'ch', name: "Switzerland" },
    { selected: false, code: "SY", img: 'sy', name: "Syrian Arab Republic" },
    { selected: false, code: "TW", img: 'tw', name: "Taiwan" },
    { selected: false, code: "TJ", img: 'tj', name: "Tajikistan" },
    { selected: false, code: "TZ", img: 'tz', name: "Tanzania" },
    { selected: false, code: "TH", img: 'th', name: "Thailand" },
    { selected: false, code: "TL", img: 'tl', name: "Timor-Leste" },
    { selected: false, code: "TG", img: 'tg', name: "Togo" },
    { selected: false, code: "TK", img: 'tk', name: "Tokelau" },
    { selected: false, code: "TO", img: 'to', name: "Tonga" },
    { selected: false, code: "TT", img: 'tt', name: "Trinidad and Tobago" },
    { selected: false, code: "TN", img: 'tn', name: "Tunisia" },
    { selected: false, code: "TR", img: 'tr', name: "Turkey" },
    { selected: false, code: "TM", img: 'tm', name: "Turkmenistan" },
    { selected: false, code: "TC", img: 'tc', name: "Turks and Caicos Islands" },
    { selected: false, code: "TV", img: 'tv', name: "Tuvalu" },
    { selected: false, code: "UG", img: 'ug', name: "Uganda" },
    { selected: false, code: "UA", img: 'ua', name: "Ukraine" },
    { selected: false, code: "AE", img: 'ae', name: "United Arab Emirates" },
    { selected: false, code: "GB", img: 'gb', name: "United Kingdom" },
    { selected: false, code: "UM", img: 'um', name: "United States Minor Outlying Islands" },
    { selected: false, code: "US", img: 'us', name: "United States of America" },
    { selected: false, code: "UY", img: 'uy', name: "Uruguay" },
    { selected: false, code: "UZ", img: 'uz', name: "Uzbekistan" },
    { selected: false, code: "VU", img: 'vu', name: "Vanuatu" },
    { selected: false, code: "VE", img: 've', name: "Venezuela (Bolivarian Republic of Venezuela)" },
    { selected: false, code: "VN", img: 'vn', name: "Vietnam" },
    { selected: false, code: "VG", img: 'vg', name: "Virgin Islands (British)" },
    { selected: false, code: "VI", img: 'vi', name: "Virgin Islands (U.S.)" },
    { selected: false, code: "GB-WLS", img: "gb-wls", name: "Wales" },
    { selected: false, code: "WF", img: 'wf', name: "Futuna" },
    { selected: false, code: "EH", img: 'eh', name: "Western Sahara" },
    { selected: false, code: "YE", img: 'ye', name: "Yemen" },
    { selected: false, code: "ZM", img: 'zm', name: "Zambia" },
    { selected: false, code: "ZW", img: 'zw', name: "Zimbabwe" }
];

export default locations;