import React, { useEffect, useState } from 'react';
import { store } from '../../store';
import { Toast, getFileFromBack, uplaodFile } from '../../helpers';
import { useDispatch, useSelector } from 'react-redux';

import config from '../../config';

const BasicInformations = ({ lang }) => {
    return (<>
        <AvatarPart lang={lang} />
        <BasicInfo lang={lang} />
    </>
    );
}

export default BasicInformations;






const BasicInfo = ({ lang }) => {

    const [firstName, setFirstName] = useState(store.getState().user.user.first_name);
    const [name, setName] = useState(store.getState().user.user.name);
    const [email, setEmail] = useState(store.getState().user.user.email);
    const [phone, setPhone] = useState(store.getState().user.user.phone);


    const handle_firstName_change = e => {
        setFirstName(e.target.value);
    };

    const handle_name_change = e => {
        setName(e.target.value);
    };

    const handle_email_change = e => {
        setEmail(e.target.value);
    };

    const handle_phone_change = e => {
        setPhone(e.target.value);
    };

    // Preparer le update de profile ici
    const handleSaveBasic = () => {
        console.log("pas encore fait", {
            //firstName,
            //name,
            email,
            phone
        });
        //user_id
        //first_name
        //last_name
        //facebook
        //twitter
        //whatsapp
        //linkedin
        //telegram
        //tiktok
        //snapchat
        //birth_date
        //picture
        //cover_picture
        //description
        //password
    };


    return <form>
        <div className="card">
            <div className="card-header border-bottom">
                <h4 className="card-header-title">{lang('items.pers_infos.basic.title')}</h4>
            </div>

            <div className="card-body">

                <div className="row mb-4">
                    <label htmlFor="firstNameLabel" className="col-sm-3 col-form-label form-label">{lang('items.pers_infos.basic.flname')} <i
                        className="bi-question-circle text-body ms-1" data-bs-toggle="tooltip" data-bs-placement="top"
                        title="Displayed on public forums, HappyNess."></i></label>

                    <div className="col-sm-9">
                        <div className="input-group">
                            <input type="text" className="form-control disable" name="firstName" id="firstNameLabel" placeholder={firstName}
                                aria-label={firstName} disabled defaultValue={firstName} onChange={e => handle_firstName_change(e)} />
                            <input type="text" className="form-control disable" name="lastName" id="lastNameLabel" placeholder={name}
                                aria-label={name} disabled defaultValue={name} onChange={e => handle_name_change(e)} />
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <label htmlFor="emailLabel" className="col-sm-3 col-form-label form-label">Email</label>

                    <div className="col-sm-9">
                        <input type="email" className="form-control" name="email" id="emailLabel" placeholder="clarice@example.com"
                            aria-label="clarice@example.com" defaultValue={email} onChange={e => handle_email_change(e)} />
                    </div>
                </div>

                <div className="js-add-field row mb-4" data-hs-add-field-options='{
                        "template": "#addPhoneFieldTemplate",
                        "container": "#addPhoneFieldContainer",
                        "defaultCreated": 0
                        }'>
                    <label htmlFor="phoneLabel" className="col-sm-3 col-form-label form-label">{lang('items.pers_infos.basic.phone')} <span
                        className="form-label-secondary">({lang('items.pers_infos.basic.text_opt')})</span></label>

                    <div className="col-sm-9">
                        <div className="input-group">
                            <input type="tel" className="form-control" name="phone" id="phoneLabel"
                                placeholder="+x(xxx)xxx-xx-xx" defaultValue={phone} onChange={e => handle_phone_change(e)} />
                        </div>

                    </div>
                </div>

                <div className="row mb-4 text-muted d-none">
                    <label className="col-sm-3 col-form-label form-label text-muted">{lang('items.pers_infos.basic.gender.text')}</label>

                    <div className="col-sm-9">
                        <div className="input-group input-group-md-down-break">
                            <label className="form-control" htmlFor="genderTypeRadio1">
                                <span className="form-check">
                                    <input type="radio" disabled className="form-check-input" name="genderTypeRadio" id="genderTypeRadio1" />
                                    <span className="form-check-label">{lang('items.pers_infos.basic.gender.m')}</span>
                                </span>
                            </label>

                            <label className="form-control" htmlFor="genderTypeRadio2">
                                <span className="form-check">
                                    <input type="radio" disabled className="form-check-input" name="genderTypeRadio" id="genderTypeRadio2" />
                                    <span className="form-check-label">{lang('items.pers_infos.basic.gender.f')}</span>
                                </span>
                            </label>

                            <label className="form-control" htmlFor="genderTypeRadio3">
                                <span className="form-check">
                                    <input type="radio" disabled className="form-check-input" name="genderTypeRadio" id="genderTypeRadio3" defaultChecked />
                                    <span className="form-check-label">{lang('items.pers_infos.basic.gender.o')}</span>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>

            </div>

            <div className="card-footer pt-0">
                <div className="d-flex justify-content-end gap-3">
                    <button className="btn btn-white" type='reset'>{lang('items.pers_infos.basic.btn.cancel')}</button>
                    <button className="btn btn-primary" type='button' onClick={() => handleSaveBasic()}>{lang('items.pers_infos.basic.btn.save')}</button>
                </div>
            </div>
        </div>
    </form>
};



const AvatarPart = ({ lang }) => {

    const user = useSelector(x => x.user.user);

    const [currentAvatar, setCurrentAvatar] = useState(undefined);
    const [previewAvatar, setPreviewAvatar] = useState("");
    const [showLoading, setShowLoading] = useState(false);

    const dispatch = useDispatch();

    const selectFile = (event) => {
        setCurrentAvatar(event.target.files[0]);
        setPreviewAvatar(URL.createObjectURL(event.target.files[0]));
    };

    useEffect(() => {
        if (user.avatar !== undefined) {
            setPreviewAvatar(getFileFromBack(user.avatar));
        } else {
            setPreviewAvatar(getFileFromBack(config.defaultAvatar));
        }

        return () => {
        };
    }, [user]);

    const handleSaveAvatar = () => {

        setShowLoading(true);
        var imag = document.getElementById('avatarImg');

        var width = imag.naturalWidth;
        var height = imag.naturalHeight;

        if ((width / height) >= 0.7 && (width / height) <= 1.3 && (height / width) >= 0.7 && (height / width) <= 1.3) {
            // if (100 < height && 751 > height && 100 < width && 751 > width) {
            if (currentAvatar !== undefined) {
                let formData = new FormData();

                formData.append('image', currentAvatar);

                setTimeout(() => {

                    uplaodFile(
                        `${config.apiUrl}/uplaod/user/avatar/${user.id}`,
                        'post',
                        formData,
                        dispatch,
                        'avatar',
                        setShowLoading
                    );
                }, 2000);
            }
            // } else {
            //     Toast(
            //         'error',
            //         "Les dimensions de l'image ne sont pas correctes. Voici les dimensions acceptees: entre 100x100 et 750x750",
            //         'tr',
            //         false,
            //         false,
            //         false,
            //         3000,
            //         false
            //     );
            // }
        } else {
            // setShowLoading(false);
            Toast(
                'warning',
                `Les dimensions ne sont pas proportionnelles. Voici les dimensions de votre image: ${width}x${height}. Les dimensions requises sont comprises entre 100x100 et 750x750`,
                'tr',
                false,
                false,
                false,
                3000,
                false
            );
        }



    };


    return <form>
        <div className="card">
            <div className="card-header border-bottom">
                <h4 className="card-header-title">Avatar</h4>
            </div>

            <div className="card-body">
                <div className="row mb-4">
                    <label className="col-sm-3 col-form-label form-label">{lang('items.pers_infos.avatar.label')}</label>

                    <div className="col-sm-9">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="d-flex align-items-center">
                                    <label className="avatar avatar-xxl avatar-circle" htmlFor="avatarUploader">
                                        <img id="avatarImg" className="avatar-img" src={previewAvatar} alt={`${user.first_name} avatar`} />
                                    </label>

                                    <div className="d-grid d-sm-flex gap-2 ms-4">
                                        <div className="form-attachment-btn btn-sm" style={{cursor:"pointer"}}>
                                            {lang('items.pers_infos.avatar.upload')}
                                            <input type="file" accept="image/*" onChange={selectFile} className="js-file-attach form-attachment-btn-label" id="avatarUploader"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3" style={{display:"flex", justifyContent:"center", paddingTop:25, paddingBottom:25}}>
                                {
                                    !showLoading
                                        ? <button type="button" onClick={handleSaveAvatar} className="btn btn-soft-success">
                                            {lang('items.pers_infos.avatar.btn')}
                                        </button>
                                        : <button type="button" className="btn btn-success" disabled={showLoading}>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            {lang('items.pers_infos.avatar.btn')}...
                                        </button>
                                }
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </form>
};