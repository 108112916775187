import React, { useEffect, useState } from 'react';
import { AdminContentLoader } from '../../components';
import { store } from '../../store';


const DashContent = () => {

    const [loading, setLoading] = useState(true);
    const [workers, setWorkers] = useState(countWorker(store.getState().manager.projects, store.getState().user.user.id));
    const [projects, setProjects] = useState(countProject(store.getState().manager.projects, store.getState().user.user.id));

    
    
    useEffect(() => {

        setTimeout(() => {
            setLoading(false)
        }, 2000);

        let tim = setInterval(() => {
            setWorkers(countWorker(store.getState().manager.projects, store.getState().user.user.id));
            setProjects(countProject(store.getState().manager.projects, store.getState().user.user.id));
        }, 200);

        return () => {
            clearInterval(tim);
        };
    }, []);


    return loading
        ? <AdminContentLoader />
        : <section className="content">
            <div className="container-fluid">
                {/* Widgets elements */}
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-3">
                        <div className="info-box">
                            <span className="info-box-icon bg-primary elevation-1"><i className="fas fa-book"></i></span>

                            <div className="info-box-content">
                                <h4 className="info-box-text">Total Projects</h4>
                                <h3 className="info-box-number">
                                    {projects}
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-3">
                        <div className="info-box">
                            <span className="info-box-icon bg-warning elevation-1"><i className="fas fa-book"></i></span>

                            <div className="info-box-content">
                                <h4 className="info-box-text">Projects in progress</h4>
                                <h3 className="info-box-number">
                                    ...
                                </h3>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 col-sm-6 col-md-3">
                        <div className="info-box">
                            <span className="info-box-icon bg-success elevation-1"><i className="fas fa-thumbs-up"></i></span>

                            <div className="info-box-content">
                                <h4 className="info-box-text">Projects Finished</h4>
                                <h3 className="info-box-number">
                                    ...
                                </h3>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 col-sm-6 col-md-3">
                        <div className="info-box mb-3">
                            <span className="info-box-icon bg-warning elevation-1"><i className="fas fa-users"></i></span>

                            <div className="info-box-content">
                                <h4 className="info-box-text">Your workers</h4>
                                <h3 className="info-box-number">{workers}</h3>
                            </div>

                        </div>

                    </div>
                </div>

                {/* Second Row */}
                <div className="row">
                    <div className="col-md-12">

                    </div>
                </div>


            </div>
        </section>
};

export default DashContent;



const countWorker = (data=[], user_id) => {
    let tab1 = [], tab2 = [], gTab = [];
    data.forEach(elm => {
        elm.users.forEach(user => {
            if (user.role_id === 2 && user.id === user_id) { //role_id === 2 means that it is the manager
                tab1.push(elm)
            }
        })
    });

    tab1.map(proj => {
        tab2.push(proj.users.filter(user => user.id !== user_id));
    });

    tab2.forEach(ele => {
        ele.forEach(user => {
            if(gTab.filter(usr => usr.id === user.id).length === 0){
                gTab.push(user);
            }
        })
    })

    return gTab.length;
};


const countProject = (data, user_id) => {
    let tab1 = [];
    data.forEach(elm => {
        elm.users.forEach(user => {
            if (user.role_id === 2 && user.id === user_id) { //role_id === 2 means that it is the manager
                tab1.push(elm)
            }
        })
    })

    return tab1.length;
};
