import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { authActions, userActions } from '../store';
import Button from 'react-validation/build/button';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import logo from '../assets/img/logo/apines_red.png';
import { LinkScript } from 'constants';
import { AppendScript } from 'helpers';
import AdminContentLoader from './adminContentLoader';
import { Helmet } from 'react-helmet-async';


const Forma = ({ type }) => {

    const [showLinkResend, setShowLinkResend] = useState(false);
    const [load, setLoad] = useState(true);
    const [delay, setDelay] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [digiCode, setDigiCode] = useState(null);
    const dispatch = useDispatch();

    var minutes = 0, seconds = 0;


    useEffect(() => {

        setTimeout(() => {
            setLoad(false);
        }, 3000);


        LinkScript('verifacc').forEach(elt => {
            AppendScript(elt)
        });

    }, []);


    const timer = type => {
        setShowLinkResend(true);
        minutes = 5;
        seconds = 0;
        let tt = inter(minutes, seconds);

        setInterval(() => {
            clearInterval(tt);
        }, (minutes * 60 + seconds + 1.5) * 1000);

        setTimeout(() => {
            if (type === 'email') {
                dispatch(userActions.resendCode({ email: localStorage.getItem('emailLogin') }));
            }
            if (type === 'reset_password') {
                dispatch(authActions.resendCode({ email: localStorage.getItem('emailResetPass') }));
            }
        }, 1200);
    };

    const inter = (minutes, seconds) => {
        setDelay(<span className='text-primary'>{`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}</span>);
        return setInterval(() => {
            if (minutes > 0 && seconds === 0) {
                seconds = 60;
                minutes--;
            }
            if (minutes === 0 && seconds === 0) {
                setShowLinkResend(false);
                seconds = seconds + 1;
            }
            seconds--;
            setDelay(<span className='text-primary'>{`${minutes}:${seconds < 10 ? `0${seconds}` : seconds < 0 ? seconds + 1 : seconds}`}</span>);
        }, 1000);
    };

    //Login functions
    const _rrequired = value => {
        if (!value) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    This is not digits characters.
                </div>
            );
        }
    };

    const handleLogout = () => {
        dispatch(authActions.signout());
        dispatch(authActions.logout());
        dispatch(userActions.logout());
        sessionStorage.clear();
        localStorage.removeItem('persist:root');
        window.location.href = '/signin';
    };

    const handleDigitCodeChange = e => {
        setDigiCode(e.target.value);
    };

    const _digit = value => {
        return value !== ""
            ? value.length !== 6
                ? <div className="alert alert-soft-danger mt-2" role="alert">The code should be 6 caracters.</div>
                : null
            : <div className="alert alert-soft-danger mt-2" role="alert">This field is required!</div>
    };


    const handleSubmit = event => {
        event.preventDefault();

        setShowLoading(true);
        // setShowLinkResend(true)


        setTimeout(() => {
            setShowLoading(false);
            setTimeout(() => {

                if (type === 'reset_password') {
                    dispatch(authActions.passActiveCode({ pin: digiCode }));
                } else {
                    dispatch(userActions.verifEmail({ pin: digiCode }));
                }
            }, 500);

        }, 4000);
        // Toast('success', 'Well done.', 'tc', true, true, true, 2000);

    };


    return <>
        {
            load ?
                <AdminContentLoader />
                : <>
                    <div className="row">
                        <div className="col-md-2 offset-5">
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                // backgroundColor: 'rgba(146, 27, 77, 0)', // Couleur de happyness
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                height: '100vh',
                                width: '100%',
                                position: 'fixed',
                                left: 0,
                                top: 0,
                            }}>
                                <Form onSubmit={e => handleSubmit(e)} >

                                    <div className="card elevation-3 text-center" style={{ padding: 10, boxShadow: "1px 2px 14px gray" }}>
                                        <div className="card-body">
                                            <h1 className="card-title fs-5" id="staticBackdropLabel">
                                                {type === 'email' ? 'Email verification' : "Reset password"}
                                            </h1>

                                            <img src={logo} alt="logo" width={120} height={80} />
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="signupModalFormSignupName">Enter the code below</label>
                                                <Input
                                                    type="number"
                                                    className="form-control form-control-lg"
                                                    name="digit"
                                                    id="signupModalFormSignupName"
                                                    placeholder="code"
                                                    aria-label="code"
                                                    maxLength={6}
                                                    minLength={6}
                                                    validations={[_rrequired, _digit]}
                                                    onChange={e => handleDigitCodeChange(e)}
                                                />
                                            </div>
                                            <small className='text-secondary'>
                                                {
                                                    !showLinkResend
                                                        ? <a href='#/' className='text-primary' onClick={() => timer(type)}>Resend a new Pin Code.</a>
                                                        : <span>You can resend pin at {delay}</span>
                                                }
                                            </small>
                                        </div>
                                        <div className="" style={{ display: 'flex', justifyContent: type === 'email' ? 'space-between' : 'center' }}>
                                            {type === 'email' ?
                                                <button type="button" onClick={handleLogout} style={{ width: '46%' }} className="btn btn-soft-danger">Logout</button>
                                                : null
                                            }

                                            {
                                                !showLoading
                                                    ? <Button type="submit" className="btn btn-success btn-lg" style={{ width: type === 'email' ? '46%' : '100%' }}>
                                                        Confirm
                                                    </Button>
                                                    : <button type="button" className="btn btn-success btn-lg" style={{ width: type === 'email' ? '46%' : '100%' }} disabled={showLoading}>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        Loading
                                                    </button>
                                            }
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </>

        }
        <Helmet>
            <link rel="stylesheet" href="./assets/css/app/bootstrap-icons/font/bootstrap-icons.css" />
            <link rel="stylesheet" href="./assets/css/app/hs-mega-menu.min.css" />
            <link rel="stylesheet" href="./assets/css/app/quill.snow.css" />
            <link rel="stylesheet" href="./assets/css/app/tom-select.css" />
            <link rel="stylesheet" href="./assets/css/app/aos.css" />
            <link rel="stylesheet" href="./assets/css/app/swiper-bundle.min.css" />
            <link rel="stylesheet" href="./assets/css/app/theme.min.css" />


            <title>{type === 'email' ? 'Email Verification' : "Reset Password"}</title>
            <script src='./assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js'></script>
            <script src='./assets/vendor/hs-header/dist/hs-header.min.js'></script>
            <script src='./assets/vendor/hs-mega-menu/dist/hs-mega-menu.min.js'></script>
            <script src='./assets/vendor/hs-show-animation/dist/hs-show-animation.min.js'></script>
            <script src='./assets/js/theme.min.js'></script>

        </Helmet>
    </>
}

export default Forma;
