import React from 'react';

const AdminContentLoader = () => {
    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        position: 'fixed',
        left: 0,
        top: 0,
    }} className='text-primary'>
        {/* <span className="spinner-border spinner-border" role="status" aria-hidden="true"></span> */}
        <img src={require('../assets/img/anim/happ23.gif')} alt="content loader" style={{height:'10vh'}} />
    </div>
}

export default AdminContentLoader;
