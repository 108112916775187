import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-validation/build/button';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { isEmail } from 'validator';
import appConfig from '../config';
import { Slang, parseUserAgent } from '../helpers';
import { authActions, store } from '../store';


const ModalForgotPassword = ({ setContent, lang }) => {

    const authUser = useSelector(x => x.auth.token && x.user.user.id ? x.user.user : false);

    const t = Slang('page.forgot_pass');

    const [showLoading, setShowLoading] = useState(false);

    const dispatch = useDispatch();

    const [email, setEmail] = useState(null);


    const _rrequired = value => {
        if (!value) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    {t('errors.require')}
                </div>
            );
        }
    };

    const _email = value => {
        if (!isEmail(value)) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    {t('errors.email')}
                </div>
            );
        }
    };

    const handleEmailChange = e => {
        setEmail(e.target.value);
    };

    const handleSubmit = event => {
        event.preventDefault();
        sessionStorage.setItem('init-btn', 1);

        $.getJSON(appConfig.apip, (datas) => {
            setTimeout(() => {
                var user = store.getState().user.user;

                parseUserAgent(datas, datas.ip || datas.ipString, user.id)
                    .then(data => {
                        dispatch(authActions.forgot({ email, ip: datas.ipString || datas.ip }));
                    });
            }, 4000);
        });
    };

    return (<>
        <div className="text-center mb-7">
            <h2>{lang('modal_forgot.title')}</h2>
            <p>{lang('modal_forgot.p')}</p>
        </div>
        <Form onSubmit={e => handleSubmit(e)} >
            <div className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                    <label className="form-label" htmlFor="signupModalFormSignupEmail" tabIndex="0">{t('form.email')}</label>
                    <span className="form-label-link text-primary" style={{ cursor: "pointer" }} onClick={() => setContent(2)}>
                        <i className="bi-chevron-left small ms-1"></i> {t('form.link_log')}
                    </span>
                </div>
                {/* <label className="form-label" htmlFor="signupModalFormSignupEmail">Your email</label> */}
                <Input
                    type="email"
                    className="form-control form-control-lg"
                    name="email"
                    id="signupModalFormSignupEmail"
                    placeholder="email@site.com"
                    aria-label="email@site.com"
                    validations={[_rrequired, _email]}
                    onChange={e => handleEmailChange(e)}
                />
            </div>

            <div className="d-grid mb-3">
                {/* <button type="submit" className="btn btn-primary btn-lg">Submit</button> */}
                {
                    !showLoading
                        ? <Button type="submit" className="btn btn-primary btn-lg">
                            {t('form.btn')}
                        </Button>
                        : <button type="button" className="btn btn-primary btn-lg" disabled={showLoading}>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {t('form.in_progress')}
                        </button>
                }
            </div>
        </Form>
    </>);
};

export default ModalForgotPassword;
