import React, { useEffect, useState } from 'react';
import { AdminContentLoader, Goto } from '../components';
import { FooterLayout, HeaderLayout, TermsLayout } from '../layouts';
import logo from '../assets/img/logo/logo_footer.png';
import { LinkScript } from '../constants';
import { Helmet } from 'react-helmet-async';
import $ from 'jquery';
import { connectionVerif } from './home';
import { store } from '../store';
import { useDispatch, useSelector } from 'react-redux';



const Terms = () => {
    const [load, setLoad] = useState(true);
    const dispatch = useDispatch();
    const auth = useSelector(x => x.auth);

    useEffect(() => {

        setTimeout(() => {
            connectionVerif(dispatch, setLoad, store.getState().user, auth);
        }, 1200);

        let titi = setInterval(() => {
            $('iframe').remove();
        }, 200);


        return () => {
            clearInterval(titi);
        };
    }, []);

    return <>

        {
            load ? <AdminContentLoader /> : <Group logo={logo}/>
        }

        <Helmet>
            <link rel="stylesheet" href="./assets/css/app/bootstrap-icons/font/bootstrap-icons.css" />
            <link rel="stylesheet" href="./assets/css/app/hs-mega-menu.min.css" />
            <link rel="stylesheet" href="./assets/css/app/quill.snow.css" />
            <link rel="stylesheet" href="./assets/css/app/tom-select.css" />
            <link rel="stylesheet" href="./assets/css/app/aos.css" />
            <link rel="stylesheet" href="./assets/css/app/swiper-bundle.min.css" />
            <link rel="stylesheet" href="./assets/css/app/theme.min.css" />


            <script src='./assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js'></script>
            <script src='./assets/vendor/hs-header/dist/hs-header.min.js'></script>
            <script src='./assets/vendor/hs-mega-menu/dist/hs-mega-menu.min.js'></script>
            <script src='./assets/vendor/hs-show-animation/dist/hs-show-animation.min.js'></script>
            <script src='./assets/vendor/hs-sticky-block/dist/hs-sticky-block.min.js'></script>
            <script src='./assets/vendor/hs-scrollspy/dist/hs-scrollspy.min.js'></script>
            <script src='./assets/vendor/hs-go-to/dist/hs-go-to.min.js'></script>
            <script src='./assets/vendor/leaflet/dist/leaflet.js'></script>
            <script src='./assets/js/theme.min.js'></script>
            <title>HappyNess | Terms</title>
        </Helmet>

    </>;
}

export default Terms;


const Group = ({ logo }) => {
    const [modal, setModal] = useState(<></>);

    useEffect(() => {

        let tt = setTimeout(() => {
            let html = `<script>
            
                new HSHeader('#header').init()

                new HSMegaMenu('.js-mega-menu', {
                desktop: {
                position: 'left'
                }
                })

                new HSShowAnimation('.js-animation-link')


                HSBsDropdown.init()


                new HSGoTo('.js-go-to')


                new HSStickyBlock('.js-sticky-block', {
                targetSelector: document.getElementById('header').classList.contains('navbar-fixed') ? '#header' : null
                })


                new bootstrap.ScrollSpy(document.body, {
                target: '#navbarSettings',
                offset: 10
                })

                new HSScrollspy('#navbarVerticalNavMenu', {
                breakpoint: 'lg'
                })
            </script>`;
            $('body').append(html);
        }, 1000);


        return () => {
            clearTimeout(tt);
        };
    }, []);

    return <>
        <HeaderLayout setModal={setModal} script={LinkScript('terms')} />
        <TermsLayout />
        <FooterLayout logo={logo} />
        {modal}
        <Goto />
    </>
};