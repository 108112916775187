import React from 'react';
import { Slang } from '../helpers';
import $ from 'jquery';


const TermsLayout = () => {

    const t = Slang('page.terms');

    return (
        <>
            <main id="content" role="main">
                <div className="bg-img-start" style={{ backgroundImage: "url(./assets/svg/components/card-11.svg)" }}>
                    <div className="container content-space-t-3 content-space-t-lg-5">
                        <div className="w-md-75 w-lg-50 text-center mx-md-auto">
                            <h1>{t('title')}</h1>
                            <p>{t('s_title')} <i className='bi bi-file-pdf text-primary' title='Print' style={{ cursor: "pointer" }}></i> </p>
                        </div>
                    </div>
                </div>

                <div className="container content-space-t-1" style={{ fontSize: '.7em' }}>
                    <h2 className='text-center text-uppercase mb-5'>{t('intro.title')}</h2>


                    <p style={{ fontSize: '1.5em', textAlign: 'justify' }} className='intro-p1'>
                        {htmlizer(t('intro.p1'), 'intro-p1').map(el => el === 0 ? "" : "")}
                    </p>

                    <p style={{ fontSize: '1.5em', textAlign: 'justify' }} className='intro-p2'>
                        {htmlizer(t('intro.p2'), 'intro-p2').map(el => el === 0 ? "" : "")}
                    </p>

                    <p style={{ fontSize: '1.5em', textAlign: 'justify' }} className='intro-p3'>
                        {htmlizer(t('intro.p3'), 'intro-p3').map(el => el === 0 ? "" : "")}
                    </p>

                    <p style={{ fontSize: '1.5em', textAlign: 'justify' }} className='intro-p4'>
                        {htmlizer(t('intro.p4'), 'intro-p4').map(el => el === 0 ? "" : "")}
                    </p>

                    <p style={{ fontSize: '1.5em', textAlign: 'justify' }} className='intro-p5'>
                        {htmlizer(t('intro.p5'), 'intro-p5').map(el => el === 0 ? "" : "")}
                    </p>

                    <p style={{ fontSize: '1.5em', textAlign: 'justify' }} className='intro-p6'>
                        {htmlizer(t('intro.p6'), 'intro-p6').map(el => el === 0 ? "" : "")}
                    </p>
                </div>


                <div className="container content-space-1 content-space-md-3">
                    <div className="row">
                        <div className="col-md-5 col-lg-4 mb-3 mb-md-0">
                            <div className="navbar-expand-md">
                                <div className="d-grid">
                                    <button type="button" className="navbar-toggler btn btn-white mb-3" data-bs-toggle="collapse" data-bs-target="#navbarVerticalNavMenu" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navbarVerticalNavMenu">
                                        <span className="d-flex justify-content-between align-items-center">
                                            <span className="text-dark">Menu</span>

                                            <span className="navbar-toggler-default">
                                                <i className="bi-list"></i>
                                            </span>

                                            <span className="navbar-toggler-toggled">
                                                <i className="bi-x"></i>
                                            </span>
                                        </span>
                                    </button>
                                </div>

                                <div id="navbarVerticalNavMenu" className="collapse navbar-collapse">
                                    <ul id="navbarSettings" className="js-sticky-block js-scrollspy nav nav-tabs nav-link-gray nav-vertical"
                                        data-hs-sticky-block-options='{
                                            "parentSelector": "#navbarVerticalNavMenu",
                                            "targetSelector": "#header",
                                            "breakpoint": "md",
                                            "startPoint": "#navbarVerticalNavMenu",
                                            "endPoint": "#stickyBlockEndPoint",
                                            "stickyOffsetTop": 80
                                        }'>
                                        <li className="nav-item">
                                            <a className="nav-link active" href="#content1">1. {t('t1')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#content2">2. {t('t2')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#content3">3. {t('t3')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#content4">4. {t('t4')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#content5">5. {t('t5')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#content6">6. {t('t6')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#content7">7. {t('t7')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#content8">8. {t('t8')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#content9">9. {t('t9')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#content10">10. {t('t10')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#content11">11. {t('t11')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#content12">12. {t('t12')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#content13">13. {t('t13')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#content14">14. {t('t14')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#content15">15. {t('t15')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#content16">16. {t('t16')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#content17">17. {t('t17')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#content18">18. {t('t18')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#content19">19. {t('t19')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#content20">20. {t('t20')}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-7 col-lg-8">


                            <div id="content1" className="mb-7">
                                <h4>1. {t('t1')}</h4>
                                <div className='p11' style={{ textAlign: 'justify' }}>{htmlizer(t('p11'), 'p11').map(el => el === 0 ? "" : "")}</div>
                            </div>

                            <div id="content2" className="mb-7">
                                <h4>2. {t('t2')}</h4>
                                <div className='p21' style={{ textAlign: 'justify' }}>{htmlizer(t('p21'), 'p21').map(el => el === 0 ? "" : "")}</div>
                            </div>

                            <div id="content3" className="mb-7">
                                <h4>3. {t('t3')}</h4>
                                <div className='p31' style={{ textAlign: 'justify' }}>{htmlizer(t('p31'), 'p31').map(el => el === 0 ? "" : "")}</div>
                            </div>

                            <div id="content4" className="mb-7" style={{ textAlign: 'justify' }}>
                                <h4>4. {t('t4')}</h4>
                                <span>{t('p41.text')}</span><br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- <span>{t('p41.list1')}</span><br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- <span>{t('p41.list2')}</span><br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- <span>{t('p41.list3')}</span><br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- <span>{t('p41.list4')}</span><br /><br />
                                <p>{t('p41.p')}</p><br />
                                <p>{t('p41.content.text')}</p>
                                <p>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{t('p41.content.list1')}</span><br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{t('p41.content.list2')}</span><br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{t('p41.content.list3')}</span><br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{t('p41.content.list4')}</span><br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{t('p41.content.list5')}</span><br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{t('p41.content.list6')}</span><br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{t('p41.content.list7')}</span><br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{t('p41.content.list8')}</span><br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{t('p41.content.list9')}</span><br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{t('p41.content.list10')}</span><br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{t('p41.content.list11')}</span><br />
                                </p>
                                <div className='pp41' style={{ textAlign: 'justify' }}>{htmlizer(t('p41.pp'), 'pp41').map(el => el === 0 ? "" : "")}</div>
                            </div>

                            <div id="content5" className="mb-7">
                                <h4>5. {t('t5')}</h4>
                                <div className='p51' style={{ textAlign: 'justify' }}>{htmlizer(t('p51'), 'p51').map(el => el === 0 ? "" : "")}</div>
                            </div>

                            <div id="content6" className="mb-7">
                                <h4>6. {t('t6')}</h4>
                                <div className='p61' style={{ textAlign: 'justify' }}>{htmlizer(t('p61'), 'p61').map(el => el === 0 ? "" : "")}</div>
                            </div>

                            <div id="content7" className="mb-7">
                                <h4>7. {t('t7')}</h4>
                                <div className='p71' style={{ textAlign: 'justify' }}>{htmlizer(t('p71'), 'p71').map(el => el === 0 ? "" : "")}</div>
                            </div>

                            <div id="content8" className="mb-7">
                                <h4>8. {t('t8')}</h4>
                                <div className='p81' style={{ textAlign: 'justify' }}>{htmlizer(t('p81'), 'p81').map(el => el === 0 ? "" : "")}</div>
                            </div>

                            <div id="content9" className="mb-7">
                                <h4>9. {t('t9')}</h4>
                                <div className='p91' style={{ textAlign: 'justify' }}>{htmlizer(t('p91'), 'p91').map(el => el === 0 ? "" : "")}</div>
                            </div>

                            <div id="content10" className="mb-7">
                                <h4>10. {t('t10')}</h4>
                                <div className='p101' style={{ textAlign: 'justify' }}>{htmlizer(t('p101'), 'p101').map(el => el === 0 ? "" : "")}</div>
                            </div>

                            <div id="content11" className="mb-7">
                                <h4>11. {t('t11')}</h4>
                                <div className='p111' style={{ textAlign: 'justify' }}>{htmlizer(t('p111'), 'p111').map(el => el === 0 ? "" : "")}</div>
                            </div>

                            <div id="content12" className="mb-7">
                                <h4>12. {t('t12')}</h4>
                                <div className='p121' style={{ textAlign: 'justify' }}>{htmlizer(t('p121'), 'p121').map(el => el === 0 ? "" : "")}</div>
                            </div>

                            <div id="content13" className="mb-7">
                                <h4>13. {t('t13')}</h4>
                                <div className='p131' style={{ textAlign: 'justify' }}>{htmlizer(t('p131'), 'p131').map(el => el === 0 ? "" : "")}</div>
                            </div>

                            <div id="content14" className="mb-7">
                                <h4>14. {t('t14')}</h4>
                                <div className='p141' style={{ textAlign: 'justify' }}>{htmlizer(t('p141'), 'p141').map(el => el === 0 ? "" : "")}</div>
                            </div>

                            <div id="content15" className="mb-7">
                                <h4>15. {t('t15')}</h4>
                                <div className='p151' style={{ textAlign: 'justify' }}>{htmlizer(t('p151'), 'p151').map(el => el === 0 ? "" : "")}</div>
                            </div>

                            <div id="content16" className="mb-7">
                                <h4>16. {t('t16')}</h4>
                                <div className='p161' style={{ textAlign: 'justify' }}>{htmlizer(t('p161'), 'p161').map(el => el === 0 ? "" : "")}</div>
                            </div>

                            <div id="content17" className="mb-7">
                                <h4>17. {t('t17')}</h4>
                                <div className='p171' style={{ textAlign: 'justify' }}>{htmlizer(t('p171'), 'p171').map(el => el === 0 ? "" : "")}</div>
                            </div>

                            <div id="content18" className="mb-7">
                                <h4>18. {t('t18')}</h4>
                                <div className='p181' style={{ textAlign: 'justify' }}>{htmlizer(t('p181'), 'p181').map(el => el === 0 ? "" : "")}</div>
                            </div>

                            <div id="content19" className="mb-7">
                                <h4>19. {t('t19')}</h4>
                                <div className='p191' style={{ textAlign: 'justify' }}>{htmlizer(t('p191'), 'p191').map(el => el === 0 ? "" : "")}</div>
                            </div>

                            <div id="content20" className="mb-7">
                                <h4>20. {t('t20')}</h4>
                                <div className='p201' style={{ textAlign: 'justify' }}>{htmlizer(t('p201'), 'p201').map(el => el === 0 ? "" : "")}</div>
                            </div>

                            <div id="stickyBlockEndPoint"></div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default TermsLayout;


export const htmlizer = (text, cls) => {
    return $(`.${cls}`).html(text);
}