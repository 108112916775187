import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-validation/build/button';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Textarea from "react-validation/build/textarea";
import Select from "react-validation/build/select";
import { isEmail } from 'validator';
import { currencies } from '../constants';
import { Slang, Toast, numberFormatter, parseUserAgent } from '../helpers';
import { Check } from '../components/myValidatorFields';
import { guestActions } from '../store';
import $ from 'jquery';
import appConfig from '../config';




const InitProjectLayout = () => {
    const t = Slang('page.register');
    const _t = Slang('page.init_project');
    const _t_f = Slang('page.init_project.form');


    const [showLoading, setShowLoading] = useState(false);
    const [selectedCurr, setSelectedCurr] = useState((state = "USD") => state);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [device, setDevice] = useState({ country_code: 'US' });
    const [devises, setDevises] = useState([{ code: "USD" }]);
    // const [devises, setDevises] = useState([]);
    const [country_code, setCountryCode] = useState("US");
    const user = useSelector(x => x.user.user);


    const dispatch = useDispatch();

    useEffect(() => {
        $.getJSON(appConfig.get_json_json, (data) => {
            setDevises(data.curr);
        });

        $.getJSON(appConfig.get_json_json, (data) => {
            setSelectedCountries(data.countries);
        });

        setTimeout(() => {

            $.getJSON(appConfig.apip, (datas) => {
                parseUserAgent(datas, datas.ip || datas.ipString)
                    .then(data => {
                        setSelectedCurr(data.payload.location.currencyCode);
                        setDevice(data);
                        setCountryCode(data.payload.location.countryCode2);
                    });
            });
        }, 1000);

        setInterval(() => {
            if (sessionStorage.getItem('init-btn') !== null) {
                if (parseInt(sessionStorage.getItem('init-btn')) === 0) {
                    setShowLoading(false);
                } else {
                    setShowLoading(true);
                }
            }
        }, 1000);

        return () => {
        };
    }, []);


    const [info, setInfo] = useState({
        lastname: user.id ? user.name : '',
        firstname: user.id ? user.first_name : '',
        budget: null,
        currency: selectedCurr,
        company: '',
        indicator: '',
        email: user.id ? user.email : '',
        phone: user.id ? user.phone !== null ? user.phone : '' : '',
        details: '',
        privacy_policy: user.id ? true : false,
    });


    const amounts = [
        "1000",
        "2000",
        "4000",
        "10000",
        "20000",
        "50000",
    ];



    const _rrequired = value => {
        if (!value || value.length === 0) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    This field is required!
                </div>
            );
        }
    };

    const _fname = value => {
        return value !== ""
            ? value.length < 4
                ? <div className="alert alert-soft-danger mt-2" role="alert">The Firstname field require 4 caracters.</div>
                : null
            : <div className="alert alert-soft-danger mt-2" role="alert">This field is empty.</div>
    };

    const _lname = value => {
        return value !== ""
            ? value.length < 4
                ? <div className="alert alert-soft-danger mt-2" role="alert">The Lastname field require 4 caracters.</div>
                : null
            : <div className="alert alert-soft-danger mt-2" role="alert">This field is empty.</div>
    };

    const _textarea = value => {
        return value !== ""
            ? value.length < 100
                ? <div className="alert alert-soft-danger mt-2" role="alert">The Lastname field require 100 caracters.</div>
                : null
            : <div className="alert alert-soft-danger mt-2" role="alert">This field is empty.</div>
    };

    const _email = value => {
        if (!isEmail(value)) {
            return (
                <div className="alert alert-soft-danger mt-2" role="alert">
                    This is not a valid email.
                </div>
            );
        }
    };
    const handleChange = e => {
        if (e.target.name === "privacy_policy") {
            setInfo({ ...info, [e.target.name]: e.target.checked });
        } else {
            if (e.target.name === "currency") {
                setInfo({ ...info, [e.target.name]: e.target.value });
                setSelectedCurr(e.target.value);
                $("#budget").val("");
            } else {
                if (e.target.name === "indicator") {
                    //setInfo({ ...info, [e.target.name]: e.target.value });
                    //setSelectedCurr(e.target.value);
                    // selectedCountries.filter(country => country.dial_code === e.target.value)[0].code
                    // console.log(devises.filter(curr => curr));
                    // console.log(devises);
                    setCountryCode(selectedCountries.filter(country => country.dial_code === e.target.value)[0].code);
                } else {
                    setInfo({ ...info, [e.target.name]: e.target.value });
                }
            }
        }
    }


    const handleSubmit = event => {
        event.preventDefault();
        // console.log(JSON.stringify(info));
        var bdg = $("#budget").val();
        sessionStorage.setItem('init-btn', 1);


        if (bdg.length === 0) {
            Toast('warning', 'Please choose your budget', 'tr', false, false, false, 3000, false);
        } else {
            dispatch(
                guestActions.createprojectidea({
                    last_name: info.lastname,
                    first_name: info.firstname,
                    budget: info.budget,
                    currency: selectedCurr,
                    company: info.company,
                    email: info.email,
                    phone: `${selectedCountries.filter(country => country.code === country_code)[0].dial_code} ${info.phone}`,
                    // phone: `${selectedCountries.filter(country => country.code === country_code)[0].dial_code}${info.phone}`,
                    details: info.details,
                    device,
                })
            );
        }
    };



    return (
        <main id="content" role="main">
            <div className="bg-img-start" style={{ backgroundImage: "url('./assets/img/svg/components/card-11.svg')" }}>
                <div className="container content-space-t-3 content-space-t-lg-5 content-space-b-2">
                    <div className="w-md-75 w-lg-50 text-center mx-md-auto">
                        <h1>{_t('title')}</h1>
                        <p>{_t('subtitle')}</p>
                    </div>
                </div>
            </div>

            <div className="container content-space-2 content-space-lg-3">
                <div className="mx-auto" style={{ maxWidth: "35rem" }}>
                    <Form onSubmit={e => handleSubmit(e)} >
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="signupModalFormSignupName">{_t_f('f_name')}</label>
                                    <Input
                                        autoFocus
                                        type="text"
                                        className="form-control form-control-lg"
                                        name="firstname"
                                        id="signupModalFormSignupName"
                                        placeholder="Alan"
                                        aria-label="Alan"
                                        validations={[_rrequired, _fname]}
                                        onChange={handleChange}
                                        value={info.firstname}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="signupModalFormSignupName">{_t_f('l_name')}</label>
                                    <Input
                                        type="text"
                                        className="form-control form-control-lg"
                                        name="lastname"
                                        id="signupModalFormSignupName"
                                        placeholder="Peter"
                                        aria-label="Peter"
                                        validations={[_rrequired, _lname]}
                                        onChange={handleChange}
                                        value={info.lastname}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-md-5">
                                <div className="mb-4">
                                    <label className="form-label" htmlFor="indicator">{_t_f('phone_ind')}</label>
                                    <select name="indicator" onChange={handleChange} id="indicator" className="form-select form-select-lg" aria-label="Choose your country">
                                        {
                                            selectedCountries.map((country, key) => {
                                                return <option key={key} selected={country.code === country_code} value={country.dial_code}>{`${country.emoji} ${country.name} (${country.dial_code})`}</option>
                                            })
                                        }
                                    </select>
                                </div>

                            </div>

                            {/* <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="indicator">{_t_f('phone_ind')}</label>
                                    <div className="form-control form-control-lg" style={{ border: 'none' }}>
                                        {
                                            selectedCountries.filter(country => country.code === country_code).map((country, key) => <span key={key}
                                                value={country.dial_code}
                                            >{`${country.emoji} ${country.name} (${country.dial_code})`}</span>
                                            )
                                        }
                                    </div>
                                </div>
                            </div> */}

                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">{_t_f('phone')}</label>

                                    <Input
                                        type="number"
                                        className="form-control form-control-lg"
                                        name="phone"
                                        id="phone"
                                        validations={[_rrequired]}
                                        onChange={handleChange}
                                        value={info.phone}
                                    />

                                </div>
                            </div>
                        </div>


                        <div className="row">

                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="signupModalFormSignupEmail">{_t_f('email')}</label>
                                    <Input
                                        type="email"
                                        className="form-control form-control-lg"
                                        name="email"
                                        id="signupModalFormSignupEmail"
                                        placeholder="email@site.com"
                                        aria-label="email@site.com"
                                        validations={[_rrequired, _email]}
                                        onChange={handleChange}
                                        value={info.email}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="hireUsFormCompanyName">{_t_f('company')} <span className="form-label-secondary">(Optional)</span></label>
                                    <Input
                                        onChange={handleChange}
                                        type="text"
                                        className="form-control form-control-lg"
                                        name="company"
                                        id="hireUsFormCompanyName" placeholder="Tranport Co.Ltd" aria-label="Tranport Co.Ltd" />
                                </div>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-5">
                                <div className="mb-4">
                                    <label className="form-label" htmlFor="currenciy">{_t_f('currency')} <span className='text-primary'>(Default: {selectedCurr})</span></label>
                                    <select name="currency" onChange={handleChange} id="currenci" className="form-select form-select-lg" aria-label="Choose your currency">
                                        {
                                            devises.filter(curr => curr.rate !== 0).map((curr, key) => {
                                                return <option key={key} selected={curr.code === selectedCurr} value={curr.code}>{curr.code} ({curr.title})</option>
                                            })
                                        }
                                    </select>
                                </div>

                            </div>
                            <div className="col-md-7">


                                <div className="mb-4">
                                    <label className="form-label" htmlFor="budget">{_t_f('budget')}</label>
                                    <Select
                                        validations={[_rrequired]}
                                        name="budget" id="budget" className="form-select form-select-lg" aria-label="Tell us your budget" onChange={handleChange}>
                                        <option selected value="">Tell us your budget</option>
                                        <option value={`${convert(selectedCurr, amounts[0], devises)} - ${convert(selectedCurr, amounts[1], devises)}`}>{convert(selectedCurr, amounts[0], devises)} ({selectedCurr}) - {convert(selectedCurr, amounts[1], devises)} ({selectedCurr})</option>
                                        <option value={`${convert(selectedCurr, amounts[1], devises)} - ${convert(selectedCurr, amounts[2], devises)}`}>{convert(selectedCurr, amounts[1], devises)} ({selectedCurr}) - {convert(selectedCurr, amounts[2], devises)} ({selectedCurr})</option>
                                        <option value={`${convert(selectedCurr, amounts[2], devises)} - ${convert(selectedCurr, amounts[3], devises)}`}>{convert(selectedCurr, amounts[2], devises)} ({selectedCurr}) - {convert(selectedCurr, amounts[3], devises)} ({selectedCurr})</option>
                                        <option value={`${convert(selectedCurr, amounts[3], devises)} - ${convert(selectedCurr, amounts[4], devises)}`}>{convert(selectedCurr, amounts[3], devises)} ({selectedCurr}) - {convert(selectedCurr, amounts[4], devises)} ({selectedCurr})</option>
                                        <option value={`${convert(selectedCurr, amounts[4], devises)} - ${convert(selectedCurr, amounts[5], devises)}`}>{convert(selectedCurr, amounts[4], devises)} ({selectedCurr}) - {convert(selectedCurr, amounts[5], devises)} ({selectedCurr})</option>
                                        <option value={`+${convert(selectedCurr, amounts[5], devises)}`}>{convert(selectedCurr, amounts[5], devises)} ({selectedCurr}) - and more</option>
                                    </Select>
                                </div>

                            </div>
                        </div>




                        <div className="mb-2">
                            <label className="form-label" htmlFor="description">{_t_f('description')}</label>

                            <Textarea className="form-control form-control-lg"
                                rows={5}
                                name="details"
                                id="details"
                                placeholder={_t_f('description')}
                                aria-label={_t_f('description')}
                                validations={[_rrequired, _textarea]}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="form-check mb-3">
                            <Check
                                className="form-check-input"
                                type="checkbox"
                                id="signupHeroFormPrivacyCheck"
                                name="privacy_policy"
                                validations={[_rrequired]}
                                onChange={handleChange}
                                value={info.privacy_policy}
                            />
                            <label className="form-check-label small" htmlFor="signupHeroFormPrivacyCheck">
                                {_t_f('check.text1')}&nbsp;
                                <a href="/privacy">{_t_f('check.policy')}</a>, &nbsp;
                                <a href="/terms">{_t_f('check.terms')}</a>, &nbsp;
                                {_t_f('check.and')} &nbsp;
                                <a href="/cookies">{_t_f('check.cookies')}</a>.
                            </label>
                        </div>



                        <div className="d-grid mt-4">
                            {
                                !showLoading
                                    ? <Button type="submit" className="btn btn-primary text-white fw-bold btn-lg">
                                        {_t_f('btn')}
                                    </Button>
                                    : <button type="button" className="btn btn-primary text-white fw-bold btn-lg" disabled={showLoading}>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        {_t_f('btn_load')}
                                    </button>
                            }
                            <div className="text-center">
                                <span className="form-text">{_t_f('get_back')}</span>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>

        </main>
    );
}

export default InitProjectLayout;

// const convert = (code, value, devises) => {
//     const int_val = parseInt(value);
//     const rate = devises.length !== 0 || devises === undefined ? devises.filter(curr => curr.rate !== 0).filter(curr => curr.code === code)[0].rate : 1;
//     return code === 'USD'
//         ? numberFormatter(int_val, localStorage.getItem('i18nextLng') === "en" ? 'en-US' : 'fr-FR')
//         : rate === 1
//             ? numberFormatter((int_val / 2) * 100, localStorage.getItem('i18nextLng') === "en" ? 'en-US' : 'fr-FR')
//             : rate > 1 && rate < 3
//                 ? numberFormatter(int_val * (parseInt(rate) + 1) * 100, localStorage.getItem('i18nextLng') === "en" ? 'en-US' : 'fr-FR')
//                 : rate > 3
//                     ? numberFormatter(int_val * (parseInt(rate) + 1) * 25, localStorage.getItem('i18nextLng') === "en" ? 'en-US' : 'fr-FR')
//                     : numberFormatter(int_val, localStorage.getItem('i18nextLng') === "en" ? 'en-US' : 'fr-FR');
// };

const convert = (code, value, devise) => {
    const int_val = parseInt(value);
    const rate = [...devise].filter(curr => curr.code === code)[0].rate;
    return code === 'USD'
        ? numberFormatter(int_val, localStorage.getItem('i18nextLng') === "en" ? 'en-US' : 'fr-FR')
        : rate === 1
            ? numberFormatter((int_val / 2) * 100, localStorage.getItem('i18nextLng') === "en" ? 'en-US' : 'fr-FR')
            : rate > 1 && rate < 3
                ? numberFormatter(int_val * (parseInt(rate) + 1) * 100, localStorage.getItem('i18nextLng') === "en" ? 'en-US' : 'fr-FR')
                : rate > 3
                    ? numberFormatter(int_val * (parseInt(rate) + 1) * 25, localStorage.getItem('i18nextLng') === "en" ? 'en-US' : 'fr-FR')
                    : numberFormatter(int_val, localStorage.getItem('i18nextLng') === "en" ? 'en-US' : 'fr-FR');
};
