const LinkScript = page => {
    switch (page) {
        

        case 'verifacc':
            return [
                './assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js',
                // './assets/js/theme.min.js',
            ];

        case 'blog':
            return [
                './assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js',
                './assets/vendor/hs-header/dist/hs-header.min.js',
                './assets/vendor/hs-mega-menu/dist/hs-mega-menu.min.js',
                './assets/vendor/hs-show-animation/dist/hs-show-animation.min.js',
                './assets/vendor/hs-go-to/dist/hs-go-to.min.js',
                './assets/vendor/swiper/swiper-bundle.min.js',
                './assets/vendor/hs-sticky-block/dist/hs-sticky-block.min.js',
                './assets/js/theme.min.js',
                '../assets/js/blogScript.js'
            ];

        case 'blog_article':
            return [
                '../assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js',
                '../assets/vendor/hs-header/dist/hs-header.min.js',
                '../assets/vendor/hs-mega-menu/dist/hs-mega-menu.min.js',
                '../assets/vendor/hs-show-animation/dist/hs-show-animation.min.js',
                '../assets/vendor/hs-go-to/dist/hs-go-to.min.js',
                '../assets/js/theme.min.js',
                '../assets/js/blogArticleScript.js'
            ];

        case 'blog_author_profile':
            return [
                '../assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js',
                '../assets/vendor/hs-header/dist/hs-header.min.js',
                '../assets/vendor/hs-mega-menu/dist/hs-mega-menu.min.js',
                '../assets/vendor/hs-show-animation/dist/hs-show-animation.min.js',
                '../assets/vendor/hs-go-to/dist/hs-go-to.min.js',
                '../assets/js/theme.min.js',
                '../assets/js/blogAuthorScript.js'
            ];

        case 'profile':
            return [
                '../../assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js',
                '../../assets/vendor/hs-header/dist/hs-header.min.js',
                '../../assets/vendor/hs-mega-menu/dist/hs-mega-menu.min.js',
                '../../assets/vendor/hs-show-animation/dist/hs-show-animation.min.js',
                '../../assets/vendor/hs-go-to/dist/hs-go-to.min.js',
                '../../assets/vendor/hs-file-attach/dist/hs-file-attach.min.js',
                '../../assets/vendor/hs-add-field/dist/hs-add-field.min.js',
                '../../assets/vendor/imask/dist/imask.min.js',
                '../../assets/vendor/quill/dist/quill.min.js',
                '../../assets/vendor/tom-select/dist/js/tom-select.complete.min.js',
                '../../assets/vendor/hs-sticky-block/dist/hs-sticky-block.min.js',

                '../../assets/js/theme.min.js',
                '../../assets/js/profileScript.js'
            ];

        case 'admin':
            return [
                '../assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js',
                '../assets/vendor/hs-header/dist/hs-header.min.js',
                '../assets/vendor/list.js/dist/list.min.js',
                '../assets/vendor/hs-go-to/dist/hs-go-to.min.js',
                '../assets/vendor/prism/prism.js',
                '../assets/js/theme.min.js',
                '../assets/js/adminScript.js',
            ];

        default:
            return [];
    }
};

export default LinkScript;
