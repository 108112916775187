export default [
    {
        "id": 1,
        "name": "Développement logiciel",
        "description": "développement d'applications, programmation, conception de logiciels, développement web, développement mobile, ingénierie logicielle, développement d'API, etc."
    },
    {
        "id": 2,
        "name": "Infrastructure et administration système",
        "description": "administration système, gestion des réseaux, gestion des serveurs, ingénierie des systèmes, gestion de la sécurité informatique, virtualisation, cloud computing, gestion de bases de données, etc."
    },
    {
        "id": 3,
        "name": "Réseaux et télécommunications",
        "description": "administration réseau, conception de réseaux, ingénierie réseau, sécurité réseau, architecture de télécommunication, téléphonie IP, etc."
    },
    {
        "id": 4,
        "name": "Analyse de données et intelligence artificielle",
        "description": "science des données, analyse de données, apprentissage automatique (machine learning), intelligence artificielle, traitement du langage naturel, vision par ordinateur, etc."
    },
    {
        "id": 5,
        "name": "Cybersécurité",
        "description": ": sécurité informatique, gestion des risques, tests d'intrusion, analyse de vulnérabilités, cryptographie, réponse aux incidents de sécurité, etc."
    },
    {
        "id": 6,
        "name": "Gestion de projet technologique",
        "description": "gestion de projet, gestion de portefeuille de projets, méthodologies agiles, planification stratégique, gestion des ressources, etc."
    },
    {
        "id": 7,
        "name": "Support technique",
        "description": "support informatique, service client, dépannage, résolution de problèmes techniques, assistance utilisateur, etc."
    },
    {
        "id": 8,
        "name": "Conception UX/UI",
        "description": "conception d'expérience utilisateur, conception d'interface utilisateur, architecture de l'information, tests d'utilisabilité, ergonomie, etc."
    },
    {
        "id": 9,
        "name": "Systèmes embarqués et IoT",
        "description": "développement de systèmes embarqués, Internet des objets (IoT), conception de circuits intégrés, automatisation industrielle, capteurs, etc."
    },
    {
        "id": 10,
        "name": "Ingénierie matérielle",
        "description": "conception de matériel informatique, architecture de systèmes, ingénierie électronique, développement de circuits, conception de microprocesseurs, etc."
    },
    {
        "id": 11,
        "name": "Gestion des données",
        "description": "gestion des bases de données, entrepôts de données, intégration de données, gouvernance des données, qualité des données, etc."
    },
    {
        "id": 12,
        "name": "DevOps",
        "description": "automatisation des déploiements, intégration continue, livraison continue, gestion de la configuration, orchestration, infrastructure en tant que code, etc."
    },
    {
        "id": 13,
        "name": "Stratégie de communication",
        "description": "Développement de plans de communication, identification des publics cibles, définition des objectifs de communication, positionnement de marque, etc."
    },
    {
        "id": 14,
        "name": "Marketing numérique",
        "description": "Marketing en ligne, publicité en ligne, marketing des médias sociaux, marketing par e-mail, marketing de contenu, marketing d'influence, référencement (SEO), marketing de recherche payant (SEM), etc."
    },
    {
        "id": 15,
        "name": "Relations publiques",
        "description": "Gestion des relations avec les médias, rédaction de communiqués de presse, organisation d'événements médiatiques, gestion de la réputation, relations avec les parties prenantes, etc."
    },
    {
        "id": 16,
        "name": "Marketing de contenu",
        "description": "Création de contenu, rédaction, édition, marketing de contenu vidéo, création de blogs, marketing d'articles, stratégie éditoriale, etc."
    },
    {
        "id": 17,
        "name": "Publicité",
        "description": "Planification et exécution de campagnes publicitaires, achat d'espaces publicitaires, conception publicitaire, médias traditionnels, publicité en ligne, etc."
    },
    {
        "id": 18,
        "name": "Branding",
        "description": "Développement de l'identité de marque, gestion de la marque, création de logos, design de marque, alignement de la marque avec la stratégie, etc."
    },
    {
        "id": 19,
        "name": "Marketing événementiel",
        "description": "Organisation d'événements, planification et exécution de salons professionnels, conférences, lancements de produits, événements promotionnels, etc."
    },
    {
        "id": 20,
        "name": "Marketing direct",
        "description": "Marketing par courrier, marketing par téléphone, marketing par SMS, marketing par publipostage, marketing personnalisé, etc."
    },
    {
        "id": 21,
        "name": "Recherche marketing",
        "description": "Recherche de marché, études de marché, analyse des tendances, études de consommation, analyse concurrentielle, etc."
    },
    {
        "id": 22,
        "name": "Marketing international",
        "description": "Marketing international, adaptation de la communication pour différents marchés, localisation de contenu, stratégie d'expansion mondiale, etc."
    },
    {
        "id": 23,
        "name": "Marketing de produit",
        "description": "Développement et lancement de nouveaux produits, stratégie de tarification, gestion du cycle de vie des produits, positionnement sur le marché, etc."
    },
    {
        "id": 24,
        "name": "Marketing expérientiel",
        "description": "création d'expériences de marque immersives, événements expérientiels, marketing sensoriel, activation de marque, etc."
    },
    {
        "id": 25,
        "name": "Administration et secrétariat",
        "description": "Gestion des tâches administratives, gestion des dossiers, planification de réunions, gestion des appels téléphoniques, gestion de l'agenda, etc."
    },
    {
        "id": 26,
        "name": "Support technique bureautique",
        "description": "Assistance aux utilisateurs pour les problèmes informatiques courants, résolution de problèmes de matériel et de logiciel, configuration de périphériques, dépannage, etc."
    },
    {
        "id": 27,
        "name": "Gestion de documents",
        "description": "Création, organisation et gestion de documents, archivage électronique, numérisation de documents, création de modèles, gestion des flux de travail, etc."
    },
    {
        "id": 28,
        "name": "Traitement de texte",
        "description": "Création et mise en forme de documents texte, utilisation de logiciels de traitement de texte, édition, révision, création de rapports, etc."
    },
    {
        "id": 29,
        "name": "Tableurs",
        "description": "Création et gestion de feuilles de calcul, utilisation de logiciels de tableur, calculs, analyse de données, création de graphiques, etc."
    },
    {
        "id": 30,
        "name": "Présentations",
        "description": "Création et conception de présentations professionnelles, utilisation de logiciels de présentation, création de diapositives, intégration de médias, communication visuelle, etc."
    },
    {
        "id": 31,
        "name": "Gestion de projet bureautique",
        "description": "Planification et suivi de projets, coordination des tâches, gestion des ressources, suivi des délais, gestion des budgets, etc."
    },
    {
        "id": 32,
        "name": "Gestion de la qualité et amélioration des processus",
        "description": "Mise en œuvre de méthodologies pour améliorer l'efficacité et la productivité, contrôle de la qualité des documents, analyse des processus, optimisation des flux de travail, etc."
    },
    {
        "id": 33,
        "name": "Gestion des systèmes d'information",
        "description": "Administration des systèmes d'information, gestion des bases de données, suivi des stocks, intégration de logiciels, gestion des licences, etc."
    },
    {
        "id": 34,
        "name": "Formation bureautique",
        "description": "Développement et prestation de formations sur les compétences bureautiques, création de supports de formation, formation des utilisateurs, développement de guides de référence, etc."
    },
    {
        "id": 35,
        "name": "Gestion de la communication et des relations professionnelles",
        "description": "Communication interne et externe, gestion des e-mails, rédaction professionnelle, relations avec les clients et les fournisseurs, coordination des communications, etc."
    },
    {
        "id": 36,
        "name": "Gestion de la confidentialité et de la sécurité",
        "description": " protection des informations sensibles, gestion des accès, respect de la confidentialité des données, gestion des risques de sécurité, conformité aux réglementations, etc."
    },
    {
        "id": 37,
        "name": "Comptabilité",
        "description": "Tenue des livres comptables, préparation des états financiers, analyse financière, gestion des comptes, établissement des budgets, etc."
    },
    {
        "id": 38,
        "name": "Audit",
        "description": "Vérification et évaluation de la conformité financière, évaluation des contrôles internes, audit externe, évaluation des risques financiers, etc."
    },
    {
        "id": 39,
        "name": "Gestion des investissements",
        "description": "Analyse et évaluation des opportunités d'investissement, gestion de portefeuille, gestion des actifs, évaluation des risques financiers, etc."
    },
    {
        "id": 40,
        "name": "Banque d'investissement",
        "description": "Fusion et acquisition, financement d'entreprise, émission d'actions et d'obligations, conseil en investissement, structuration financière, etc."
    },
    {
        "id": 41,
        "name": "Gestion des risques",
        "description": "Évaluation et gestion des risques financiers, gestion des risques de crédit, gestion des risques opérationnels, assurance, modélisation des risques, etc."
    },
    {
        "id": 42,
        "name": "Planification financière",
        "description": "Élaboration de plans financiers, planification de la retraite, planification successorale, allocation d'actifs, gestion de la dette, etc."
    },
    {
        "id": 43,
        "name": "Analyse financière",
        "description": "Évaluation des performances financières, analyse des ratios financiers, prévisions financières, évaluation des investissements, évaluation de la rentabilité, etc."
    },
    {
        "id": 44,
        "name": "Trésorerie",
        "description": "Gestion de la trésorerie et des liquidités, prévisions de trésorerie, gestion des flux de trésorerie, gestion des risques de change, gestion des placements, etc."
    },
    {
        "id": 45,
        "name": "Marchés financiers",
        "description": "Trading, vente et négociation d'instruments financiers, analyse des marchés, recherche en investissement, gestion de portefeuille, etc."
    },
    {
        "id": 46,
        "name": "Assurance",
        "description": "Évaluation des risques d'assurance, souscription de polices d'assurance, gestion des réclamations, gestion des risques liés aux sinistres, etc."
    },
    {
        "id": 47,
        "name": "Finance d'entreprise",
        "description": "Analyse financière des entreprises, évaluation d'entreprise, modélisation financière, levée de fonds, gestion de trésorerie d'entreprise, etc."
    },
    {
        "id": 48,
        "name": "Gestion des opérations financières",
        "description": "gestion des opérations quotidiennes liées aux finances, traitement des paiements, gestion des factures, gestion des fournisseurs, etc."
    },
    {
        "id": 49,
        "name": "Cultures céréalières",
        "description": "Production de céréales telles que le blé, le maïs, le riz, l'orge, etc."
    },
    {
        "id": 50,
        "name": "Horticulture",
        "description": "Production de fruits, légumes, fleurs, plantes ornementales, etc."
    },
    {
        "id": 51,
        "name": "Élevage",
        "description": "Élevage d'animaux tels que les bovins, les porcins, les ovins, les volailles, etc."
    },
    {
        "id": 52,
        "name": "Pisciculture et aquaculture",
        "description": "Élevage de poissons et de crustacés en milieu aquatique."
    },
    {
        "id": 53,
        "name": "Viticulture",
        "description": "Culture de la vigne et production de raisins destinés à la production de vin."
    },
    {
        "id": 54,
        "name": "Arboriculture",
        "description": "Culture des arbres fruitiers tels que les pommiers, les poiriers, les cerisiers, etc."
    },
    {
        "id": 55,
        "name": "Apiculture",
        "description": "Élevage des abeilles et production de miel, de cire d'abeille et de produits dérivés."
    },
    {
        "id": 56,
        "name": "Agroforesterie",
        "description": "Intégration d'arbres dans les systèmes agricoles pour des avantages environnementaux et économiques."
    },
    {
        "id": 57,
        "name": "Cultures maraîchères",
        "description": "Production de légumes et de plantes potagères en plein champ ou en serre."
    },
    {
        "id": 58,
        "name": "Sylviculture",
        "description": "Gestion des forêts et des plantations forestières pour la production de bois."
    },
    {
        "id": 59,
        "name": "Horticulture ornementale",
        "description": "Production de plantes ornementales pour les parcs, jardins, aménagements paysagers, etc."
    },
    {
        "id": 60,
        "name": "Agriculture biologique",
        "description": "Production d'aliments selon les principes de l'agriculture biologique, sans l'utilisation de produits chimiques de synthèse."
    },
    {
        "id": 61,
        "name": "Agroalimentaire",
        "description": "Transformation des produits agricoles en produits alimentaires, tels que la transformation de lait en produits laitiers, de céréales en farine, etc."
    },
    {
        "id": 62,
        "name": "Recherche agricole",
        "description": "Études et recherches visant à améliorer les techniques agricoles, développer de nouvelles variétés de cultures, etc."
    },
    {
        "id": 63,
        "name": "Agroéconomie",
        "description": "étude des aspects économiques de l'agriculture, y compris la gestion des exploitations agricoles, l'économie rurale, les politiques agricoles, etc."

    },

    {
        "id": 64,
        "name": "Enseignement primaire",
        "description": "Enseignement des matières de base (langue, mathématiques, sciences, etc.) aux élèves de l'école primaire."
    },
    {
        "id": 65,
        "name": "Enseignement secondaire",
        "description": "Enseignement des matières variées (langues, sciences, histoire, arts, etc.) aux élèves du collège et du lycée."
    },
    {
        "id": 66,
        "name": "Enseignement supérieur",
        "description": "Enseignement universitaire et de niveau post-secondaire dans différentes disciplines (sciences, lettres, ingénierie, commerce, etc.)."
    },
    {
        "id": 67,
        "name": "Enseignement spécialisé",
        "description": "Enseignement adapté aux besoins des élèves ayant des handicaps physiques, intellectuels ou comportementaux."
    },
    {
        "id": 68,
        "name": "Éducation préscolaire",
        "description": "Enseignement et encadrement des enfants en bas âge avant l'entrée à l'école primaire."
    },
    {
        "id": 69,
        "name": "Éducation spécialisée",
        "description": "Soutien et enseignement adapté aux élèves ayant des besoins particuliers, tels que les élèves en difficulté d'apprentissage, les élèves à haut potentiel, etc."
    },
    {
        "id": 70,
        "name": "Orientation scolaire et professionnelle",
        "description": "Conseil et accompagnement des élèves dans le choix de leur parcours scolaire et professionnel."
    },
    {
        "id": 81,
        "name": "Administration scolaire",
        "description": "Gestion et supervision des établissements scolaires, planification des programmes, coordination des ressources, etc."
    },
    {
        "id": 82,
        "name": "Psychologie de l'éducation",
        "description": "Étude des processus d'apprentissage, du développement de l'enfant et du comportement dans le contexte éducatif."
    },
    {
        "id": 83,
        "name": "Formation des enseignants",
        "description": "Préparation et formation des futurs enseignants, développement de compétences pédagogiques, etc."
    },
    {
        "id": 84,
        "name": "Éducation aux adultes",
        "description": "Enseignement et formation destinés aux adultes cherchant à acquérir de nouvelles compétences ou à compléter leur éducation."
    },
    {
        "id": 85,
        "name": "Technologie éducative",
        "description": "Intégration des technologies de l'information et de la communication dans l'apprentissage et l'enseignement."
    },
    {
        "id": 86,
        "name": "Recherche en éducation",
        "description": "études et recherches visant à améliorer les pratiques pédagogiques, évaluer les politiques éducatives, etc."
    },
    {
        "id": 87,
        "name": "Animation socio-éducative",
        "description": "organisation d'activités éducatives et ludiques pour les enfants et les jeunes en dehors du cadre scolaire."
    },
    {
        "id": 88,
        "name": "Éducation internationale",
        "description": "enseignement dans des établissements scolaires offrant un programme international et favorisant la mobilité des élèves."
    }
];  