import { combineReducers, configureStore } from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import { authReducer } from './auth.slice';
import { usersReducer } from './users.slice';
import { adminReducer } from './admin.slice';
import { managerReducer } from './manager.slice';
import { guestReducer } from './guest.slice';
import { businessReducer } from './business.slice';
import { investorReducer } from './investor.slice';

import thunk from 'redux-thunk';
import appConfig from '../config';


export * from './auth.slice';
export * from './users.slice';
export * from './admin.slice';
export * from './manager.slice';
export * from './guest.slice';
export * from './business.slice';
export * from './investor.slice';


const reducers = combineReducers({
    auth: authReducer,
    admin: adminReducer,
    manager: managerReducer,
    user: usersReducer,
    guest: guestReducer,
    business: businessReducer,
    investor: investorReducer,
});

const persistConfig = {
    key: 'root',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducers);


export const store = configureStore({
    reducer: persistedReducer,
    devTools: appConfig.app_env !== 'production',
    middleware: [thunk]
});

//development" | "production" | "test"