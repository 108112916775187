import React, { useState } from 'react';
import { Toast, arrayDiff, getFileFromBack } from 'helpers';
import { adminActions } from 'store';
import $ from 'jquery';
import Swal from 'sweetalert2';



const AdminAssignUserToTask = ({ dispatch, task, project }) => {

    const [users, setUsers] = useState(project.users);
    const [newUsers, setNewUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showLoading, setShowLoading] = useState(false);

    const handleChangeUser = e => setSelectedUser(parseInt(e.target.value));


    const addUser = () => {
        if (newUsers.values(user => user.id !== selectedUser)) {
            if (newUsers.length < 6) {
                setNewUsers([...newUsers, ...project.users.filter(user => user.id === selectedUser)]);
                setUsers(users.filter(user => user.id !== selectedUser));
            } else {
                Toast('error', 'You can\'t add more users to this task', 'tr', false, false, false, 3000, false);
            }

        }
        setSelectedUser(null)
        // console.log(project.users.filter(user => user.id === selectedUser));
        // console.log(users);
    };

    const removeUser = usr => {
        let ar = [];
        if (newUsers.filter(user => user.id === usr.id).length !== 0) {
            ar = [...newUsers.filter(user => user.id !== usr.id)];
        }
        setNewUsers(ar);
    };

    const handleSave = () => {
        let t = [];
        newUsers.forEach(user => {
            t.push(user.id)
        });

        setShowLoading(true);
        dispatch(adminActions.assignusertotask({ task_id: task.id, user_id: t }));
        setTimeout(() => {
            setShowLoading(false);
        }, 3000);
    };

    const handleRemoveUser = user => {
        Swal.fire({
            title: `Are you sure`,
            text: `You can be able to revert this operation!`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, remove him!',
            html: `<p>Are you sure you want to remove <br /><span style="color: #28a745">${user.name}?</span></p>`
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(adminActions.removeusertotask({ task_id: task.id, user_id: user.id }))
            }
        })
    }



    return <div className="modal fade" id="user-to-task" style={{ display: "none" }} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content bg-white">

                <div className="modal-body">
                    <h5 className='text-primary text-bold'>{task.name}</h5>
                    <div className="form-group">
                        <label htmlFor="leaderProject">Select a user</label>
                        <select id="leaderProject" className="form-control bg-white custom-select" onChange={e => handleChangeUser(e)}>
                            <option value={''}>-- --</option>
                            {
                                arrayDiff(project.users, task.users, 'id')
                                    .map((user, key) => {
                                        return <option key={key} value={user.id}>{user.name}</option>
                                    })
                            }
                        </select>
                    </div>

                    <div className="form-group">

                    </div>

                    <div className="form-group">
                        <button className="btn btn-primary" onClick={() => addUser()}>Add user</button>
                    </div>
                    <div className="row">
                        {
                            newUsers.map((item, key) => {
                                return <div className='col-md-3' key={key} style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <img src={getFileFromBack(item.avatar)} alt="User avatar" className='img-circle elevation-1' style={{ height: 65, width: 65, border: '1px solid green' }} />
                                    <span className='text-danger' style={{ cursor: 'pointer' }} onClick={() => removeUser(item)} title={`Remove ${item.name}`}><i className="fa fa-trash"></i></span>
                                </div>
                            })
                        }
                    </div>
                    <hr />
                    <div className="row">
                        {
                            task.users.map((item, key) => {
                                return <div className='col-md-3' key={key}>
                                    <img
                                        src={getFileFromBack(item.avatar)}
                                        alt="User avatar"
                                        className='img-circle elevation-1'
                                        style={{ height: 75, border: '2px solid skyblue', cursor: 'pointer' }}
                                        onClick={() => handleRemoveUser(item)}
                                        title={`Remove ${item.name}`}
                                    />
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className="modal-footer">
                    {
                        !showLoading
                            ? <button className="btn btn-success btn-block" onClick={() => handleSave()}>Save</button>
                            : <button type="button" className="btn btn-success btn-block " disabled={showLoading}>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Saving
                            </button>
                    }
                </div>
            </div>
        </div>
    </div>
};

export default AdminAssignUserToTask;
